import React from 'react';

import Card from '@material-ui/core/Card';
import CardMedia, { CardMediaProps } from '@material-ui/core/CardMedia';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    minHeight: 200,
    boxShadow: '0 0 20px #E4E4E4',
  },
  media: {
    height: 200,
  },
}));

type Props = Pick<CardMediaProps, 'image'>;

export default function RewardTemplateCard({ image }: Props) {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        {image ? (
          <CardMedia className={classes.media} image={image} />
        ) : (
          <Skeleton
            animation={false}
            variant="rect"
            className={classes.media}
          />
        )}
      </Card>
    </>
  );
}

import pick from 'lodash/pick';
import values from 'lodash/values';
import orderBy from 'lodash/orderBy';

import { Merchant } from './models';
import { RootState } from '../reducers';
import { Merchant as MerchantState } from '../../api/resources/merchant';
import { getImage } from '../images/selectors';

/**
 * Gets the merchants state
 *
 * @param state
 */
export const getMerchantsState = (state: RootState) => state.merchants;

/**
 * List the current campaigns for the page.
 *
 * @param state
 */
export const listMerchantsPage = (state: RootState) => {
  const ids = getPagination(state).ids;
  const merchants = pick(getMerchantsState(state).byId, ids);

  return values(orderBy(merchants, ['id'], ['desc'])).map(makeMerchant(state));
};

/**
 * Gets the merchant by id
 *
 * @param state
 * @param id
 */
export const getMerchant = (
  state: RootState,
  id: string
): Merchant | undefined => {
  const merchant = getMerchantsState(state).byId[id];

  if (!merchant) {
    return undefined;
  }

  return makeMerchant(state)(merchant);
};

/**
 * Gets the merchant by id.
 *
 * This is a experimental function to be used in useSelector hooks
 *
 * @param state
 * @param id
 */
export const getMerchantSelector =
  (id: string) =>
  (state: RootState): Merchant | undefined => {
    const merchant = getMerchantsState(state).byId[id];

    if (!merchant) {
      return undefined;
    }

    return makeMerchant(state)(merchant);
  };

/**
 * Are merchants being fetched?
 *
 * @param state
 */
export const isFetching = (state: RootState) =>
  getMerchantsState(state).ui.isFetching;

/**
 * Get the pagination data for the index page.
 *
 * @param state
 */
export const getPagination = (state: RootState) =>
  getMerchantsState(state).pagination;

const makeMerchant = (state: RootState) => (merchant: MerchantState) => {
  const { id, attributes, relationships } = merchant;
  const {
    applink,
    category,
    createdAt,
    name,
    about,
    url,
    phone,
    maxNumStores,
  } = attributes;
  const { logo, cover } = relationships;

  const logoImage = logo.data ? getImage(state, logo.data.id) : undefined;
  const coverImage = cover.data ? getImage(state, cover.data.id) : undefined;

  return {
    id,
    name,
    category: category,
    createdAt,
    url,
    applink,
    logoId: logo.data ? logo.data.id : undefined,
    logo: logoImage ? logoImage.versions.xl : undefined,
    coverId: cover.data ? cover.data.id : undefined,
    cover: coverImage ? coverImage.versions.lg : undefined,
    about,
    smallLogo: logoImage ? logoImage.versions.sm : undefined,
    phone,
    maxNumStores,
  };
};

import List from './List';
import New from './New';
import Show from './Show';
import Edit from './Edit';

export default {
  List,
  New,
  Show,
  Edit
};

import React from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import * as authActions from '../../../redux/auth/actions';
import { promiseListener } from '../../../redux/store';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import LoginForm from './LoginForm';
import { ErrorObject } from '../../../api/resources/errors';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  title: {
    marginBottom: theme.spacing(3)
  }
}));

const Login = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography
        component="h1"
        variant="h6"
        gutterBottom
        align="center"
        className={classes.title}
      >
        Flex Rewards Admin
      </Typography>

      <MakeAsyncFunction
        listener={promiseListener}
        start={getType(authActions.login)}
        resolve={getType(authActions.api.login.success)}
        reject={getType(authActions.api.login.failure)}
      >
        {(login: any) => (
          <LoginForm
            onSubmit={async (values, actions) => {
              try {
                await login(values);
              } catch (payload) {
                // Set the error
                const error: ErrorObject = payload.data.errors[0];

                actions.setFieldError(error.meta.attribute, error.detail);
                actions.setSubmitting(false);
              }
            }}
          />
        )}
      </MakeAsyncFunction>
    </Paper>
  );
};

export default Login;

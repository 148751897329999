import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { api, getDashboardAnalytics } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* getDashboardAnalyticsSaga(
  _: ActionType<typeof getDashboardAnalytics>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.analytics.getDashboardAnalytics(),
      api.fetchDashboardAnalytics
    )
  );
}

export default all([
  takeLatest(getType(getDashboardAnalytics), getDashboardAnalyticsSaga)
]);

import { createAction, createAsyncAction } from 'typesafe-actions';

import { CouponCollectionDoc } from '../../api/resources/coupon';
import { APIError } from '../api/models';

/**
 * Lists a coupon set's coupon
 */
export const listCouponSetCoupons = createAction(
  'coupons/LIST_BY_COUPON_SET',
  (couponSetId: string) => ({
    couponSetId,
    pagination: { page: 1, per: 10000 },
  })
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  listCouponSetCoupons: createAsyncAction(
    'api/coupons/LIST_BY_COUPON_SET_REQUEST',
    'api/coupons/LIST_BY_COUPON_SET_SUCCESS',
    'api/coupons/LIST_BY_COUPON_SET_FAILURE'
  )<any, CouponCollectionDoc, APIError>(),
};

import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

const create = (data: string): ApiRequest => ({
  path: `/admin/api/v2/images`,
  method: 'POST',
  authenticated: true,
  body: data,
  headers: {
    'Content-Type': 'text/plain',
  },
});

const createStockImage = (data: string): ApiRequest => ({
  path: `/admin/api/v2/images/library/stock_image`,
  method: 'POST',
  authenticated: true,
  body: data,
  headers: {
    'Content-Type': 'text/plain',
  },
});

const listStockImage = (opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/images/library/stock_images?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true,
});

export default {
  create,
  createStockImage,
  listStockImage,
};

import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { api, receivedToken, signout, login } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

import * as tokenStorage from '../../lib/tokenStorage';
import { addAuthenticationInterceptor, removeAuthenticationInterceptor } from '../../api/services';

/**
 * initAuth
 */
function* initAuth(): SagaIterator {
  // Get the session token from storage
  const token = yield call([tokenStorage, 'get']);

  if (token) {
    yield call(addAuthenticationInterceptor, token)
    yield put(receivedToken(token));
  }
}

function* loginSaga(action: ActionType<typeof login>): SagaIterator {
  const { email, password } = action.payload;

  yield put(callAPI(apiRequests.auth.login({ email, password }), api.login));
}

function* loginSuccessSaga(
  action: ActionType<typeof api.login.success>
): SagaIterator {
  const { token } = action.payload.data.attributes;

  yield call([tokenStorage, 'set'], token);
  yield call(addAuthenticationInterceptor, token)
  yield put(receivedToken(token));
}

function* signoutSaga(): SagaIterator {
  yield call(removeAuthenticationInterceptor)
  yield call([tokenStorage, 'remove']);
}

export default all([
  fork(initAuth),
  takeLeading(getType(signout), signoutSaga),
  takeLeading(getType(login), loginSaga),
  takeLeading(getType(api.login.success), loginSuccessSaga)
]);

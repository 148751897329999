import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../components/Form';
import {
  ImageUploader,
  MarkdownEditor,
  TextField,
} from '../../../components/FormikAdapter';
import {
  FormActions,
  FormRow,
  FormSection,
} from '../../../components/FormLayout';
import { MenuItem } from '@material-ui/core';

const CATEGORIES = [
  { value: 'beauty', label: 'Beauty & Cosmetics' },
  { value: 'household', label: 'Household' },
  { value: 'food_and_drink', label: 'Food & Drink' },
  { value: 'kids', label: 'Kids' },
  { value: 'gifts', label: 'Gifts' },
  { value: 'shopping', label: 'Shopping' },
  { value: 'things_to_do', label: 'Things to do' },
  { value: 'travel', label: 'Travel' },
  { value: 'health', label: 'Health & Fitness' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'jewelry', label: 'Watches & Jewelry' },
  { value: 'services', label: 'Services' },
];

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface NewMerchantFormValues {
  name: string;
  category: string;
  url: string;
  about: string;
  logoId?: string;
  coverId?: string;
  phone?: string;
  maxStore: number;
}

type Props = {
  onSubmit: (
    values: NewMerchantFormValues,
    actions: FormikHelpers<NewMerchantFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  url: Yup.string().url('Not a valid URL'),
  maxStore: Yup.number().required('Required').min(1, 'Minimum 1 store'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const NewMerchantForm: React.FC<Props> = ({ onSubmit }) => {
  const initialValues: NewMerchantFormValues = {
    name: '',
    category: '',
    url: '',
    about: '',
    logoId: '',
    coverId: '',
    phone: '',
    maxStore: 6,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Form translate="no" noValidate>
          <FormSection title="Details">
            <FormRow>
              <Field
                component={TextField}
                name="name"
                label="Name"
                fullWidth
                required
              />

              <Field
                component={TextField}
                name="category"
                label="Category"
                fullWidth
                required
                select
              >
                {CATEGORIES.map((category, idx) => (
                  <MenuItem value={category.value} key={idx}>
                    {category.label}
                  </MenuItem>
                ))}
              </Field>
            </FormRow>

            <FormRow>
              <Field
                component={TextField}
                name="url"
                label="Website URL"
                fullWidth
              />
            </FormRow>

            <FormRow>
              <Field
                component={TextField}
                name="phone"
                label="Contact"
                fullWidth
              />
            </FormRow>

            <FormRow>
              <Field
                component={TextField}
                name="maxStore"
                label="Max No. of Stores"
                fullWidth
                type="number"
              />
            </FormRow>

            <FormRow>
              <Field
                component={MarkdownEditor}
                name="about"
                label="About"
                md={initialValues.about}
              />
            </FormRow>
          </FormSection>

          <FormSection title="Logo">
            <Field component={ImageUploader} name="logoId" />
          </FormSection>

          <FormSection title="Cover Image">
            <Field component={ImageUploader} name="coverId" />
          </FormSection>

          <FormActions>
            <SubmitButton isSubmitting={formikBag.isSubmitting}>
              Create Merchant
            </SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};

export default NewMerchantForm;

import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../components/Form';
import {
  ImageUploader,
  MarkdownEditor,
  TextField,
} from '../../../components/FormikAdapter';
import {
  FormActions,
  FormRow,
  FormSection,
} from '../../../components/FormLayout';
import { MenuItem } from '@material-ui/core';

const CATEGORIES = [
  { value: 'beauty', label: 'Beauty & Cosmetics' },
  { value: 'household', label: 'Household' },
  { value: 'food_and_drink', label: 'Food & Drink' },
  { value: 'kids', label: 'Kids' },
  { value: 'gifts', label: 'Gifts' },
  { value: 'shopping', label: 'Shopping' },
  { value: 'things_to_do', label: 'Things to do' },
  { value: 'travel', label: 'Travel' },
  { value: 'health', label: 'Health & Fitness' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'jewelry', label: 'Watches & Jewelry' },
  { value: 'services', label: 'Services' },
];

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface EditMerchantFormValues {
  name: string;
  category: string;
  url: string;
  about: string;
  logoId?: string;
  coverId?: string;
  phone?: string;
  maxNumberStores: number;
}

type Props = {
  initialValues: EditMerchantFormValues;
  onSubmit: (
    values: EditMerchantFormValues,
    actions: FormikHelpers<EditMerchantFormValues>
  ) => void;
  logoURL?: string;
  coverURL?: string;
  phone?: string;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  url: Yup.string().url('Not a valid URL'),
  maxNumberStores: Yup.number().required('Required').min(1, 'Minimum 1 store'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const EditMerchantForm: React.FC<Props> = ({
  initialValues,
  coverURL,
  logoURL,
  onSubmit,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={ValidationSchema}
    onSubmit={onSubmit}
    enableReinitialize
  >
    {(formikBag) => (
      <Form translate="no" noValidate>
        <FormSection title="Details">
          <FormRow>
            <Field
              component={TextField}
              name="name"
              label="Name"
              fullWidth
              required
            />

            <Field
              component={TextField}
              name="category"
              label="Category"
              fullWidth
              required
              select
            >
              {CATEGORIES.map((category, idx) => (
                <MenuItem value={category.value} key={idx}>
                  {category.label}
                </MenuItem>
              ))}
            </Field>
          </FormRow>

          <FormRow>
            <Field
              component={TextField}
              name="url"
              label="Website URL"
              fullWidth
            />
          </FormRow>

          <FormRow>
            <Field
              component={TextField}
              name="phone"
              label="Contact"
              fullWidth
            />
          </FormRow>

          <FormRow>
            <Field
              component={TextField}
              name="maxNumberStores"
              label="Max No. of Stores"
              fullWidth
              type="number"
            />
          </FormRow>

          <FormRow>
            <Field
              component={MarkdownEditor}
              name="about"
              label="About"
              md={initialValues.about}
            />
          </FormRow>
        </FormSection>

        <FormSection title="Logo">
          <Field
            component={ImageUploader}
            name="logoId"
            selectedURL={logoURL}
          />
        </FormSection>

        <FormSection title="Cover Image">
          <Field
            component={ImageUploader}
            name="coverId"
            selectedURL={coverURL}
          />
        </FormSection>

        <FormActions>
          <SubmitButton isSubmitting={formikBag.isSubmitting}>
            Update Merchant
          </SubmitButton>
        </FormActions>
      </Form>
    )}
  </Formik>
);

export default EditMerchantForm;

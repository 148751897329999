import React from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Filters } from '../../../hooks/useFiltersQuery';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

export type Props = {
  selected: Filters;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const SelectedSection: React.FC<Props> = ({ selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="overline" display="block" gutterBottom>
        {isEmpty(selected) ? 'No filters selected' : 'Selected filters'}
      </Typography>

      {map(selected, (values, key) => (
        <React.Fragment key={key}>
          <Typography variant="caption" display="block" gutterBottom>
            {capitalize(key)}
          </Typography>

          {values.map(value => (
            <Typography key={value} display="block" gutterBottom>
              {capitalize(value)}
            </Typography>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SelectedSection;

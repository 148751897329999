import React from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../../../../components/Form';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface NewLoyaltyRewardFormValues {}

type Props = {
  onSubmit: (
    values: NewLoyaltyRewardFormValues,
    actions: FormikHelpers<NewLoyaltyRewardFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const CreateSchema = Yup.object().shape({});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const NewLoyaltyProgramForm: React.FC<Props> = ({ onSubmit }) => {
  const initialValues: NewLoyaltyRewardFormValues = {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Form translate="no" noValidate>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SubmitButton isSubmitting={formikBag.isSubmitting}>
              Create Loyalty Program
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewLoyaltyProgramForm;

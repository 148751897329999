import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type CouponSet = {
  id: string;
  type: 'coupon_sets';
  couponsCount: number;
  label: string;
  createdAt: number;
};

export type CouponSetList = {
  couponSets: CouponSet[];
  pagination: PaginationResult;
};

export function listByCampaign(campaignId: string, opts?: ListOptions) {
  return request<CouponSetList>({
    url: `/admin/api/v2/loyalty_campaigns/${campaignId}/coupon_sets?${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        couponSets: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

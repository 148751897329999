import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';
import { range } from 'lodash';

import { Checkbox } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { SubmitButton } from '../../../../../../../components/Form';
import {
  TextField,
  MarkdownEditor,
} from '../../../../../../../components/FormikAdapter';
import {
  FormActions,
  FormRow,
  FormSection,
} from '../../../../../../../components/FormLayout';
import { StampCampaign } from '../../../../../../../api/services/stampCampaignService';

const TOTAL_STAMPS_MENU_ITEMS = range(1, 21).map((i) => ({
  value: i,
  label: i.toString(),
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface EditPrizeDrawCampaignFormValues {
  description: string;
  totalStamps: number;
  notification: string;
  minSpend: string;
  multipleEntries: boolean;
  resendWelcomeReward: boolean;
  maxStampsPerStore: number;
}

type Props = {
  onSubmit: (
    values: EditPrizeDrawCampaignFormValues,
    actions: FormikHelpers<EditPrizeDrawCampaignFormValues>
  ) => void;
  campaign: StampCampaign;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
  totalStamps: Yup.number().required('Required'),
  notification: Yup.string().required('Required'),
  maxStampsPerStore: Yup.number().min(0, 'Mininum Store Stamp Limit is 0'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

export default function EditPrizeDrawCampaignForm({
  onSubmit,
  campaign,
}: Props) {
  const initialValues: EditPrizeDrawCampaignFormValues = {
    description: campaign.description,
    notification: campaign.notification,
    totalStamps: campaign.maxStamps,
    minSpend: campaign.details,
    multipleEntries: campaign.multipleCards,
    resendWelcomeReward: campaign.reissuedWelcomeRewards,
    maxStampsPerStore: campaign.storeStampLimit,
  };

  console.log('campaign: ', campaign);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form translate="no" noValidate>
          <FormSection title="Total number of stamps">
            <FormRow>
              <Field
                component={TextField}
                name="totalStamps"
                label=""
                autoFocus
                fullWidth
                required
                select
              >
                {TOTAL_STAMPS_MENU_ITEMS.map((cost, idx) => (
                  <MenuItem value={cost.value} key={idx}>
                    {cost.label}
                  </MenuItem>
                ))}
              </Field>
            </FormRow>
          </FormSection>
          <FormSection title="Restrict Max Stamps Per Store">
            <FormRow>
              <Field
                component={TextField}
                name="maxStampsPerStore"
                type="number"
                label=""
                autoFocus
                fullWidth
              />
            </FormRow>
          </FormSection>
          <FormSection title="Program Description">
            <FormRow>
              <Field
                component={MarkdownEditor}
                name="description"
                label=""
                fullWidth
                required
                md={initialValues.description}
              />
            </FormRow>
          </FormSection>
          <FormSection title="Success Notification">
            <FormRow>
              <Field
                component={MarkdownEditor}
                name="notification"
                label=""
                fullWidth
                required
                md={initialValues.notification}
              />
            </FormRow>
          </FormSection>
          <FormSection title="Minimum Spend">
            <FormRow>
              <Field component={TextField} name="minSpend" label="" required />
            </FormRow>
          </FormSection>
          <FormSection title="Allow Multiple Entries">
            <FormRow>
              <FormControlLabel
                control={<Field component={Checkbox} name="multipleEntries" />}
                label="Allow multiple entries so the stamp card can be completed more than once per customer"
              />
            </FormRow>
          </FormSection>
          <FormSection title="Resend Welcome Rewards">
            <FormRow>
              <FormControlLabel
                control={
                  <Field component={Checkbox} name="resendWelcomeReward" />
                }
                label="Resend Welcome Rewards to all legacy members?"
              />
            </FormRow>
          </FormSection>
          <FormActions>
            <SubmitButton isSubmitting={isSubmitting}>Update</SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
}

import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { connect } from 'react-redux';
import * as kycActions from '../../../redux/kyc/actions';
import * as usersActions from '../../../redux/users/actions';
import { KYCStatus } from '../../../redux/kyc/models';
import { RootState } from '../../../redux/reducers';
import { getUser } from '../../../redux/users/selectors';
import { getKYCByUserId } from '../../../redux/kyc/selectors';

import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FeatureBar from '../../../components/FeatureBar';
import { Column, Content, Grid } from '../../../components/Layout/Main';
import { dateFormat } from '../../../lib/date';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  panel: {
    margin: 0
  },
  column: {
    flexBasis: '33.33%',
    padding: theme.spacing(2)
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  img: {
    width: '100%',
    objectFit: 'cover'
  }
}));

//------------------------------------------------------------------------------
// Redux
//------------------------------------------------------------------------------

const mapStateToProps = (
  state: RootState,
  ownProps: RouteComponentProps<RouteParams>
) => ({
  user: getUser(state, ownProps.match.params.id),
  kyc: getKYCByUserId(state, ownProps.match.params.id)
});

const mapDispatch = {
  getUser: usersActions.getUser,
  getUserKYC: kycActions.getUserKYC,
  approveKYC: kycActions.approveUserKYC,
  rejectKYC: kycActions.rejectUserKYC
};

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatch;

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const KYC: React.FC<Props> = ({
  match: {
    params: { id }
  },
  getUser,
  getUserKYC,
  kyc,
  rejectKYC,
  user,
  approveKYC
}) => {
  const classes = useStyles();
  const [redirectToUser, setRedirectToUser] = useState(false);

  // Initialize the user's details
  useEffect(() => {
    getUser(id);
  }, [id, getUser]);

  // Initialize the user's KYC doc
  useEffect(() => {
    getUserKYC(id);
  }, [id, getUserKYC]);

  useEffect(() => {
    if (
      kyc &&
      (kyc.status === KYCStatus.Verified || kyc.status === KYCStatus.Rejected)
    ) {
      setRedirectToUser(true);
    }
  }, [kyc]);

  if (redirectToUser && user) {
    return <Redirect to={`/users/${user.id}`} />;
  }

  return (
    <Content>
      <FeatureBar backEnabled>{user && user.name}</FeatureBar>

      {user && (
        <Grid>
          <Column xs={12}>
            <ExpansionPanel expanded className={classes.panel}>
              <ExpansionPanelSummary>
                <Typography className={classes.title}>
                  KYC Verification
                </Typography>
              </ExpansionPanelSummary>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded className={classes.panel}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Details</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.column}>
                  <Typography>{kyc && kyc.name}</Typography>
                  <Typography>{kyc && dateFormat(kyc.dob)}</Typography>
                </div>
                <div className={classes.column}>
                  {kyc && (
                    <img
                      src={kyc.portraitURL}
                      alt="portrait"
                      className={classes.img}
                    />
                  )}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded className={classes.panel}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Identity</Typography>
              </ExpansionPanelSummary>
              {kyc && (
                <ExpansionPanelDetails>
                  {kyc &&
                    kyc.identityURLs.map((url, idx) => (
                      <div key={idx} className={classes.column}>
                        <img src={url} alt="portrait" className={classes.img} />
                      </div>
                    ))}
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded className={classes.panel}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Proof of Address Document
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.column}>
                  <Typography>{kyc && kyc.street}</Typography>
                  <Typography>{kyc && kyc.building}</Typography>
                  <Typography>{kyc && kyc.province}</Typography>
                  <Typography>{kyc && kyc.country}</Typography>
                </div>

                <div className={classes.column}>
                  {kyc && (
                    <img
                      src={kyc.addressURL}
                      alt="portrait"
                      className={classes.img}
                    />
                  )}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded className={classes.panel}>
              <ExpansionPanelActions>
                <Button
                  size="small"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to reject this KYC application?'
                      )
                    ) {
                      rejectKYC(id);
                    }
                  }}
                >
                  Reject
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => approveKYC(id)}
                >
                  Approve
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </Column>
        </Grid>
      )}
    </Content>
  );
};

export default connect(
  mapStateToProps,
  mapDispatch
)(KYC);

import React, { useEffect, useState } from 'react';

import MUIAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

import AccountMenu from './AccountMenu';
import { DRAWER_WIDTH } from './Drawer';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1
  },
  accountSection: {
    marginRight: theme.spacing(3)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  isDrawerOpen: boolean;
  onMenuPress: () => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const AppBar: React.FC<Props> = ({ onMenuPress }) => {
  const classes = useStyles();
  const [isShadowShowing, setIsShadowShowing] = useState(false);

  useEffect(() => {
    function handleScroll(e: Event) {
      if (window.pageYOffset >= 56 && !isShadowShowing) {
        setIsShadowShowing(true);

        return;
      } else if (window.pageYOffset < 56 && isShadowShowing) {
        setIsShadowShowing(false);
      }
    }

    const debouncedHandler = handleScroll;

    window.addEventListener('scroll', debouncedHandler);

    return function cleanup() {
      window.removeEventListener('scroll', debouncedHandler);
    };
  });

  return (
    <MUIAppBar
      position="fixed"
      className={classes.appBar}
      elevation={isShadowShowing ? 4 : 0}
    >
      <Toolbar disableGutters={true} variant="dense">
        <IconButton
          color="inherit"
          onClick={onMenuPress}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.grow} />
        <div className={classes.accountSection}>
          <AccountMenu />
        </div>
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;

import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  getMerchantStores,
  getStore,
  createStore,
  updateStore,
  deleteStore,
  api,
} from './actions';
import { callAPI } from '../api/actions';
import { notify } from '../notifications/actions';
import * as apiRequests from '../../api/requests';

/**
 * Fetch a store by id.
 *
 * @param action
 */
export function* getStoreSaga(
  action: ReturnType<typeof getStore>
): SagaIterator {
  const id = action.payload;

  yield put(callAPI(apiRequests.stores.get(id), api.fetchStore));
}

/**
 * Fetch the stores belong to a merchant.
 *
 * @param action
 */
export function* getMerchantStoreSaga(
  action: ReturnType<typeof getMerchantStores>
): SagaIterator {
  const { pagination, merchantId } = action.payload;

  yield put(
    callAPI(
      apiRequests.merchants.getStores(merchantId, { pagination }),
      api.fetchMerchantStores
    )
  );
}

export function* createStoreSaga(
  action: ReturnType<typeof createStore>
): SagaIterator {
  const { name, address, lat, lng, neighborhood, phone, merchantId } =
    action.payload;

  yield put(
    callAPI(
      apiRequests.stores.create(
        name,
        address,
        lat,
        lng,
        neighborhood,
        phone,
        merchantId
      ),
      api.createStore
    )
  );
}

/**
 * Update the store
 *
 * @param action
 */
export function* updateStoreSaga(
  action: ReturnType<typeof updateStore>
): SagaIterator {
  const { id, address, coords, name, neighborhood, phone } = action.payload;

  yield put(
    callAPI(
      apiRequests.stores.update({
        id,
        address,
        coords,
        name,
        neighborhood,
        phone,
      }),
      api.updateStore
    )
  );
}

/**
 * Delete the store
 *
 * @param action
 */
export function* deleteStoreSaga(
  action: ReturnType<typeof deleteStore>
): SagaIterator {
  const storeId = action.payload;

  yield put(callAPI(apiRequests.stores.removeStore(storeId), api.deleteStore));
}

/**
 * Alert the user the store has been created.
 *
 * @param _
 */
export function* createStoreSuccessSaga(
  _: ReturnType<typeof api.createStore.success>
): SagaIterator {
  yield put(notify('Store Created'));
}

/**
 * Alert the user the store has been updated.
 *
 * @param _
 */
export function* updateStoreSuccessSaga(
  _: ReturnType<typeof api.updateStore.success>
): SagaIterator {
  yield put(notify('Store Updated'));
}

/**
 * Alert the user the store has been deleted.
 *
 * @param _
 */
export function* deleteStoreSuccessSaga(
  _: ReturnType<typeof api.deleteStore.success>
): SagaIterator {
  yield put(notify('Store Deleted'));
}

export default all([
  takeLatest(getType(getStore), getStoreSaga),
  takeLatest(getType(getMerchantStores), getMerchantStoreSaga),
  takeLatest(getType(createStore), createStoreSaga),
  takeLatest(getType(updateStore), updateStoreSaga),
  takeLatest(getType(deleteStore), deleteStoreSaga),
  takeLeading(getType(api.createStore.success), createStoreSuccessSaga),
  takeLeading(getType(api.updateStore.success), updateStoreSuccessSaga),
  takeLeading(getType(api.deleteStore.success), deleteStoreSuccessSaga),
]);

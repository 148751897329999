import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useDispatch, useSelector } from 'react-redux';
import * as storeActions from '../../../../../../redux/stores/actions';
import * as storeSelectors from '../../../../../../redux/stores/selectors';
import * as storeService from '../../../../../../api/services/storeService';

import DataTable, {
  ActionIconButton,
  Columns,
} from '../../../../../../components/DataTable';
import { Column, Grid } from '../../../../../../components/Layout/Main';
import ButtonLink from '../../../../../../components/ButtonLink';
import usePaginationQuery from '../../../../../../hooks/usePaginationQuery';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const Overview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const paginationQuery = usePaginationQuery();

  const stores = useSelector(storeSelectors.listStoresByMerchantId(id));
  const isFetching = useSelector(storeSelectors.isFetching);
  const pagination = useSelector(storeSelectors.getPagination);

  const columns: Columns = [
    { title: 'ID', field: 'id' },
    { title: 'Name', field: 'name' },
    { title: 'Address', field: 'address' },
    { title: 'Phone', field: 'phone' },
    { title: 'PIN', field: 'pin' },
    {
      title: 'Contactless QR Code',
      field: 'storeId',
      format: (value: any) => (
        <>
          <ActionIconButton
            title="Contactless QR Code"
            Icon={FileCopyIcon}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              handleStoreQRContactless(value);
            }}
          />
        </>
      ),
    },
  ];

  // Load the merchant's stores
  useEffect(() => {
    dispatch(
      storeActions.getMerchantStores({
        pagination: { page: paginationQuery.page, per: paginationQuery.per },
        merchantId: id,
      })
    );
  }, [dispatch, id, paginationQuery]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      paginationQuery.updatePage(page.toString());
    },
    [paginationQuery]
  );

  const handleStoreQRContactless = async (storeId: string) => {
    if (id) {
      const data = await storeService.generateContactlessPDF(id, storeId);

      // 2. Create blob link to download
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `store-${storeId}-contactless.pdf`);

      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    }
  };

  return (
    <Grid>
      <Column xs={12} sm={12}>
        <DataTable
          Actions={
            <ButtonLink
              to={`/merchants/${id}/stores/new`}
              variant="contained"
              size="small"
              color="primary"
            >
              New Store
            </ButtonLink>
          }
          title="Stores"
          isLoading={isFetching}
          columns={columns}
          data={stores}
          onRowClick={(row) =>
            history.push(`/merchants/${id}/stores/${row[0].value}/edit`)
          }
          PaginationProps={{
            count: pagination.total,
            rowsPerPage: pagination.per,
            rowsPerPageOptions: [10, 25, 50],
            page: pagination.page,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: (event) => {
              paginationQuery.updatePer(event.target.value.toString());
            },
          }}
        />
      </Column>
    </Grid>
  );
};

export default Overview;

import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as LoyaltyProgramService from '../../../../../../../api/services/loyaltyProgramService';
import { Column, Grid } from '../../../../../../../components/Layout/Main';
import NewPrizeDrawCampaignForm from './NewPrizeDrawCampaignForm';
import * as StampCampaignService from '../../../../../../../api/services/stampCampaignService';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const NewPrizeDrawCampaign = () => {
  const classes = useStyles();
  const { id: merchantId } = useParams<RouteParams>();

  const [redirectTo, setRedirectTo] = useState('');
  const [program, setProgram] = useState<
    LoyaltyProgramService.LoyaltyProgram | undefined
  >(undefined);

  // Fetch the program
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        const res = await LoyaltyProgramService.getByMerchant(merchantId);

        if (isMounted) {
          setProgram(res);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [merchantId]);

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  if (!program) {
    return null;
  }

  console.log('Program', program);

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            New Prize-Draw Stamp Campaign
          </Typography>

          <NewPrizeDrawCampaignForm
            onSubmit={async (values) => {
              try {
                await StampCampaignService.createPrizeDraw({
                  details: values.minSpend,
                  maxStamps: values.totalStamps,
                  programId: program.id,
                  description: values.description,
                  notification: values.notification,
                  multipleCards: values.multipleEntries,
                  reissuedWelcomeRewards: values.resendWelcomeReward,
                  storeStampLimit: values.maxStampsPerStore,
                });
                setRedirectTo(`/merchants/${merchantId}/loyalty/`);
              } catch {
                alert('There was an error submitting this form');
              }
            }}
          />
        </Paper>
      </Column>
    </Grid>
  );
};

export default NewPrizeDrawCampaign;

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as campaignActions from '../../../../../redux/campaigns/actions';
import {
  getCampaign,
  getCampaignStats,
} from '../../../../../redux/campaigns/selectors';

import MuiGrid from '@material-ui/core/Grid';

import { Column, Grid } from '../../../../../components/Layout/Main';
import DetailsCard from './DetailsCard';
import TargetingCard from './TargetingCard';
import RewardTemplateCard from './RewardTemplateCard';
import { StatisticCard } from '../../../../../components/Card';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const TabOverview: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  // Load the campaign stats
  useEffect(() => {
    dispatch(campaignActions.getCampaignStats(id));
  }, [dispatch, id]);

  const campaign = useSelector(getCampaign(id));
  const stats = useSelector(getCampaignStats(id));

  if (!campaign) {
    return null;
  }

  return (
    <Grid>
      <Column xs={12} sm={4}>
        <DetailsCard campaign={campaign} />
        <TargetingCard {...campaign.target} />
        <RewardTemplateCard campaign={campaign} />
      </Column>

      <Column xs={12} sm={8}>
        {stats && (
          <MuiGrid container>
            <Column xs={12} sm={4}>
              <StatisticCard
                label="Delivered"
                value={`${stats.delivered}/${campaign.supply.max}`}
              />
            </Column>
            <Column xs={12} sm={4}>
              <StatisticCard label="Collected" value={stats.collected} />
            </Column>
            <Column xs={12} sm={4}>
              <StatisticCard label="Redeemed" value={stats.redeemed} />
            </Column>
          </MuiGrid>
        )}
      </Column>
    </Grid>
  );
};

export default TabOverview;

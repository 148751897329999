import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  content: {},
  icon: {
    marginRight: theme.spacing(2.5),
    fontSize: 52
  },
  label: {
    color: theme.palette.grey['500']
  }
}));

type Props = {
  icon?: React.ComponentType<SvgIconProps>;
  label: string;
  value: string | number;
};

const Statistic: React.FC<Props> = ({ label, value, icon: Icon }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {Icon && <Icon fontSize="large" className={classes.icon} />}

      <div className={classes.content}>
        <Typography gutterBottom variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
        <Typography variant="h5">{value}</Typography>
      </div>
    </Paper>
  );
};

export default Statistic;

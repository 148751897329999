import React, { useEffect, useState } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Redirect, useParams } from 'react-router';
import { getType } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';

import * as rewardTemplateActions from '../../../../../../redux/rewardTemplates/actions';
import { getRewardTemplateSelector } from '../../../../../../redux/rewardTemplates/selectors';
import { promiseListener } from '../../../../../../redux/store';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Column, Grid } from '../../../../../../components/Layout/Main';
import NewRewardTemplateForm from './NewRewardTemplateForm';
import useQueryParams from '../../../../../../hooks/useQueryParams';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<RouteParams>();

  const [redirectTo, setRedirectTo] = useState('');

  const resultQuery = useQueryParams('rewardTemplateId');

  const rewardTemplate = useSelector(getRewardTemplateSelector(resultQuery));

  useEffect(() => {
    if (resultQuery) {
      dispatch(rewardTemplateActions.getRewardTemplate(resultQuery));
    }
  }, [dispatch, resultQuery]);

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            New Reward Template
          </Typography>

          <MakeAsyncFunction
            listener={promiseListener}
            start={getType(rewardTemplateActions.createRewardTemplate)}
            resolve={getType(
              rewardTemplateActions.api.createRewardTemplate.success
            )}
            reject={getType(
              rewardTemplateActions.api.createRewardTemplate.failure
            )}
          >
            {(createRewardTemplate: any) => (
              <NewRewardTemplateForm
                onSubmit={async (values, actions) => {
                  try {
                    await createRewardTemplate({
                      ...values,
                      merchantId: id,
                    });

                    actions.setSubmitting(false);
                    setRedirectTo(`/merchants/${id}/reward_templates`);
                  } catch {
                    actions.setSubmitting(false);
                    alert('There was an error submitting this form');
                  }
                }}
                rewardTemplate={rewardTemplate}
              />
            )}
          </MakeAsyncFunction>
        </Paper>
      </Column>
    </Grid>
  );
};

export default New;

import { createAction, createAsyncAction } from 'typesafe-actions';
import { TokenDoc } from '../../api/resources/auth';
import { APIError } from '../api/models';

/**
 * Login the user
 */
export const login = createAction(
  'auth/LOGIN',
  (email: string, password: string) => ({ email, password })
)();

/**
 * Sign out the user
 */
export const signout = createAction('auth/SIGNOUT')();

/**
 * The auth token was received either from the api or storage.
 */
export const receivedToken = createAction(
  'auth/TOKEN_RECEIVED',
  (token: string) => token
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  login: createAsyncAction(
    'api/auth/LOGIN_REQUEST',
    'api/auth/LOGIN_SUCCESS',
    'api/auth/LOGIN_FAILURE'
  )<any, TokenDoc, APIError>()
};

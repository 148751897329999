import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import * as LoyaltyProgramService from '../../../../../../api/services/loyaltyProgramService';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { SubmitButton } from '../../../../../../components/Form';
import { TextField } from '../../../../../../components/FormikAdapter';
import { FormRow } from '../../../../../../components/FormLayout';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<DialogProps, 'open' | 'onExit'> & {
  merchantId: string;
  stampLimit: number;
  onClose: () => void;
};

interface EditRateLimitFormValues {
  timeLimit: number;
}

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  maxStampLimit: Yup.number()
    .required('Required')
    .min(0, 'Must be positive number'),
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function EditMaxStampCampaignLimitDialog({
  merchantId,
  stampLimit,
  open,
  onExit,
  onClose,
}: Props) {
  const initialValues = {
    maxStampLimit: stampLimit,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        const { maxStampLimit } = values;
        try {
          await LoyaltyProgramService.updateLoyaltyProgramMaxStampCampaign(
            merchantId,
            maxStampLimit
          );

          actions.setSubmitting(false);
          actions.resetForm();
          onClose();
        } catch {
          actions.setSubmitting(false);
          alert('There was an error submitting this form');
        }
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <Dialog open={open} onClose={onClose} onExit={onExit}>
          <Form translate="no" noValidate>
            <DialogTitle>Edit Max Stamp Campaign Limit</DialogTitle>

            <DialogContent>
              <FormRow>
                <Field
                  component={TextField}
                  name="maxStampLimit"
                  label="Max Stamp Campaign Limit"
                  autoFocus
                  fullWidth
                  required
                />
              </FormRow>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  onClose();
                }}
                color="primary"
              >
                Cancel
              </Button>
              <SubmitButton
                type="submit"
                size="small"
                isSubmitting={isSubmitting}
              >
                Update
              </SubmitButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}

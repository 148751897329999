import React from 'react';

import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { Columns } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  row: {
    height: 31,
  },
  cell: {
    padding: '8px 16px',
  },
}));

export type TableHeadProps = {
  columns: Columns;
};

const TableHead: React.FC<TableHeadProps> = ({ columns }) => {
  const classes = useStyles();

  return (
    <MuiTableHead className={classes.root}>
      <MuiTableRow className={classes.row}>
        {columns.map((col, idx) => (
          <MuiTableCell key={idx} className={classes.cell}>
            {col.title}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default TableHead;

import React from 'react';
import classNames from 'classnames';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.08)'
    },

    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  selectedListItem: {
    backgroundColor: 'none'
  },
  itemColor: {
    color: 'rgba(255,255,255,.7)'
  },
  selectedItemColor: {
    color: theme.palette.primary.light
  },
  textPrimary: {
    fontWeight: 500,
    lineHeight: '20px'
  },
  textDense: {
    fontSize: 14
  },
  listItemIcon: {
    marginRight: 0
  },
  selectedItem: {
    backgroundColor: 'none'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = ListItemProps & {
  to: string;
  title: string;
  icon: React.ComponentType<SvgIconProps>;
  selected: boolean;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const MenuListItem: React.FC<Props> = ({
  className,
  icon: Icon,
  selected,
  title,
  to
}) => {
  const classes = useStyles();
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<
        HTMLAnchorElement,
        Omit<RouterLinkProps, 'innerRef' | 'to'>
      >((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to]
  );

  return (
    <ListItem
      dense
      button
      component={renderLink}
      classes={{
        root: classNames(classes.listItem, className),
        selected: classes.selectedListItem
      }}
      disableRipple
    >
      <ListItemIcon
        className={classNames(
          classes.listItemIcon,
          selected ? classes.selectedItemColor : classes.itemColor
        )}
      >
        {<Icon fontSize="small" />}
      </ListItemIcon>
      <ListItemText
        primary={title}
        classes={{
          dense: classes.textDense,
          primary: classNames(
            classes.textPrimary,
            selected ? classes.selectedItemColor : classes.itemColor
          )
        }}
      />
    </ListItem>
  );
};

export default MenuListItem;

import React from 'react';

import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Card from './Card';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    padding: `${theme.spacing(1)}px 0`
  },
  label: {
    flex: 1
  },
  percentage: {
    fontWeight: 'bold'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  male: {
    count: number;
    percentage: string;
  };
  female: {
    count: number;
    percentage: string;
  };
  unknown: {
    count: number;
    percentage: string;
  };
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const DemographicsCard: React.FC<Props> = ({ male, female, unknown }) => {
  const classes = useStyles();

  return (
    <Card label="Demographics">
      <div className={classes.row}>
        <Typography variant="body2" className={classes.label}>
          Male
        </Typography>

        <Typography variant="body2">{male.count}&nbsp;</Typography>
        <Typography variant="body2" className={classes.percentage}>
          ({male.percentage}%)
        </Typography>
      </div>

      <Divider />

      <div className={classes.row}>
        <Typography variant="body2" className={classes.label}>
          Female
        </Typography>
        <Typography variant="body2">{female.count}&nbsp;</Typography>
        <Typography variant="body2" className={classes.percentage}>
          ({female.percentage}%)
        </Typography>
      </div>

      <Divider />

      <div className={classes.row}>
        <Typography variant="body2" className={classes.label}>
          Unknown
        </Typography>
        <Typography variant="body2">{unknown.count}&nbsp;</Typography>
        <Typography variant="body2" className={classes.percentage}>
          ({unknown.percentage}%)
        </Typography>
      </div>
    </Card>
  );
};

export default DemographicsCard;

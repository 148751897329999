import React from 'react';

import { Store } from '../../redux/stores/models';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { StoreMap } from '../Map';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  store: Store;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    marginBottom: 8,
    boxShadow: '0 2px 4px rgba(211, 211, 211, 0.5)',
    elevation: 1,
  },
  header: {
    fontSize: 18,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 500,
    fontStyle: 'normal',
    padding: '12px 18px 0',
  },
  map: {
    height: 280,
    width: '100%',
    margin: '10px 0',
  },
  container: {
    padding: '0 18px 12px',
  },
  text: {
    fontSize: 16,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  subtext: {
    fontSize: 14,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 300,
    fontStyle: 'normal',
    color: '#989AA2',
  },
  link: {
    fontSize: 14,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#EA0043',
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const StoreSection: React.FC<Props> = ({ store }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.header}>
        Where To Redeem
      </Typography>
      <div className={classes.map}>
        <StoreMap
          marker={store ? { lat: store.lat, lng: store.lng } : undefined}
          mapOption={{
            zoomControl: false,
            gestureHandling: 'none',
            fullscreenControl: false,
          }}
        />
      </div>
      <div className={classes.container}>
        <Typography gutterBottom className={classes.text}>
          {store && store.name}
        </Typography>
        <Typography gutterBottom className={classes.subtext}>
          {store && store.address}
        </Typography>
        <Typography gutterBottom className={classes.link}>
          View all locations
        </Typography>
      </div>
    </div>
  );
};

export default StoreSection;

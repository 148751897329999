import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';

import * as rewardTemplateActions from '../../../../../redux/rewardTemplates/actions';
import { getRewardTemplateSelector } from '../../../../../redux/rewardTemplates/selectors';
import { Campaign } from '../../../../../redux/campaigns/models';

import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DefinitionListItem from '../../../../../components/DefinitionListItem';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  content: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
  },
  title: {
    fontSize: '1rem',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  mediaPlaceholder: {
    paddingTop: '22%',
    paddingBottom: '22%',
    backgroundColor: theme.palette.grey['100'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderText: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  campaign: Campaign;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const RewardTemplateCard: React.FC<Props> = ({
  campaign: { rewardTemplateId },
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const rewardTemplate = useSelector(
    getRewardTemplateSelector(rewardTemplateId)
  );

  useEffect(() => {
    dispatch(rewardTemplateActions.getRewardTemplate(rewardTemplateId));
  }, [dispatch, rewardTemplateId]);

  if (!rewardTemplate) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          Reward Template
        </Typography>
      </div>

      <div className={classes.content}>
        {rewardTemplate.image ? (
          <CardMedia
            className={classes.media}
            image={rewardTemplate.image.versions.lg}
          />
        ) : (
          <div className={classes.mediaPlaceholder}>
            <Typography variant="subtitle1" className={classes.placeholderText}>
              Reward Image
            </Typography>
          </div>
        )}
        <List>
          <DefinitionListItem title="Title" value={rewardTemplate.title} />
          <DefinitionListItem
            title="What You'll Get"
            value={<ReactMarkdown source={rewardTemplate.what} />}
          />

          <DefinitionListItem
            title="The Fine Print"
            value={<ReactMarkdown source={rewardTemplate.info} />}
          />
        </List>
      </div>
    </Paper>
  );
};

export default RewardTemplateCard;

import React from 'react';
import ReactMarkdown from 'react-markdown';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  header: string;
  markdownText: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    marginBottom: 12,
    padding: '12px 18px 10px',
    boxShadow: '0 2px 4px rgba(211, 211, 211, 0.5)',
    elevation: 1,
  },
  header: {
    fontSize: 18,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  markdownContainer: {
  },
  markdown: {
    color: '#383838',
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'ClarikaGeometric',
    fontSize: 14,
    fontWeight: 400,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const MarkdownSection: React.FC<Props> = ({ header, markdownText }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography gutterBottom className={classes.header}>
        {header}
      </Typography>
      <div className={classes.markdownContainer}>
        <ReactMarkdown className={classes.markdown} source={markdownText} />
      </div>
    </div>
  );
};

export default MarkdownSection;

import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { api, createImage, createStockImage, listStockImages } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* createImageSaga(
  action: ReturnType<typeof createImage>
): SagaIterator {
  const { data } = action.payload;

  yield put(callAPI(apiRequests.images.create(data), api.createImage));
}

export function* createStockImageSaga(
  action: ReturnType<typeof createStockImage>
): SagaIterator {
  const { data } = action.payload;

  yield put(
    callAPI(apiRequests.images.createStockImage(data), api.createStockImage)
  );
}

export function* fetchStockImageListSaga(
  action: ReturnType<typeof listStockImages>
): SagaIterator {
  const { pagination } = action.payload;

  yield put(
    callAPI(
      apiRequests.images.listStockImage({
        pagination,
      }),
      api.fetchStockImageList
    )
  );
}

export default all([
  takeLatest(getType(createImage), createImageSaga),
  takeLatest(getType(createStockImage), createStockImageSaga),
  takeLatest(getType(listStockImages), fetchStockImageListSaga),
]);

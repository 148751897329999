import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  api,
  createBirthdayCampaign,
  getLoyaltyProgramBirthdayCampaign,
} from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* createBirthdayCampaignSaga(
  action: ReturnType<typeof createBirthdayCampaign>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.birthdayCampaign.create(action.payload),
      api.createBirthdayCampaign
    )
  );
}

export function* getLoyaltyProgramBirthdayCampaignSaga(
  action: ReturnType<typeof getLoyaltyProgramBirthdayCampaign>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.birthdayCampaign.getByLoyaltyProgramId(action.payload),
      api.getLoyaltyProgramBirthdayCampaign
    )
  );
}

export default all([
  takeLatest(getType(createBirthdayCampaign), createBirthdayCampaignSaga),
  takeLatest(
    getType(getLoyaltyProgramBirthdayCampaign),
    getLoyaltyProgramBirthdayCampaignSaga
  ),
]);

import queryString from 'query-string';
import { ListOptions, QueryParams } from './request';
import mapKeys from 'lodash/mapKeys';
import merge from 'lodash/merge';

export const toListQueryString = (opts: ListOptions) => {
  const { filters, pagination, search } = opts;
  const qp: QueryParams = {};

  if (pagination) {
    qp.page = pagination.page;
    qp.per = pagination.per;
  }

  if (search) {
    qp['q'] = search.term;
  }

  if (filters) {
    const filterParams = mapKeys(filters, (_, k) => `filter[${k}][eq]`);

    merge(qp, filterParams);
  }

  return queryString.stringify(qp);
};

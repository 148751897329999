import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as rewardTemplateActions from '../../../../../../redux/rewardTemplates/actions';
import * as rewardTemplateSelectors from '../../../../../../redux/rewardTemplates/selectors';

import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Modal from '@material-ui/core/Modal';

import ButtonLink from '../../../../../../components/ButtonLink';
import FilterBar from './FilterBar';
import DataTable, {
  ActionIconButton,
  Columns,
} from '../../../../../../components/DataTable';
import { Column, Grid } from '../../../../../../components/Layout/Main';
import useFiltersQuery from '../../../../../../hooks/useFiltersQuery';
import usePaginationQuery from '../../../../../../hooks/usePaginationQuery';
import Preview from './Preview';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const TabRewardTemplates = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState('0');
  const dispatch = useDispatch();
  const paginationQuery = usePaginationQuery();
  const filtersQuery = useFiltersQuery();
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const columns: Columns = [
    {
      title: 'ID',
      field: 'id',
      width: '50px',
    },
    { title: 'Title', field: 'title' },
    {
      title: '',
      field: 'rewardTemplateId',
      align: 'right',
      format: (value: any) => (
        <>
          <ActionIconButton
            title="Preview"
            Icon={VisibilityIcon}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedRow(value);
              setOpenModal(true);
            }}
          />
          <ActionIconButton
            title="Duplicate"
            Icon={FileCopyIcon}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              history.push(
                `/merchants/${id}/reward_templates/new?rewardTemplateId=${value}`
              );
            }}
          />
        </>
      ),
    },
  ];

  const rewardTemplates = useSelector(
    rewardTemplateSelectors.listRewardTemplatesByMerchantId(id)
  );
  const isFetching = useSelector(rewardTemplateSelectors.isFetching);
  const pagination = useSelector(rewardTemplateSelectors.getPagination);

  useEffect(() => {
    const { page, per } = paginationQuery;
    const { filters } = filtersQuery;

    dispatch(
      rewardTemplateActions.getMerchantRewardTemplates(id, {
        pagination: { page, per },
        filters: filters,
      })
    );
  }, [dispatch, filtersQuery, paginationQuery, id]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      paginationQuery.updatePage(page.toString());
    },
    [paginationQuery]
  );

  return (
    <Grid>
      <Column xs={12}>
        <FilterBar />
      </Column>

      <Column xs={12} sm={12}>
        <DataTable
          Actions={
            <ButtonLink
              to={`/merchants/${id}/reward_templates/new`}
              variant="contained"
              size="small"
              color="primary"
            >
              New Reward Template
            </ButtonLink>
          }
          title="Reward Templates"
          isLoading={isFetching}
          columns={columns}
          data={rewardTemplates}
          onRowClick={(row) =>
            history.push(`/merchants/${id}/reward_templates/${row[0].value}`, {
              test: 'testing',
            })
          }
          PaginationProps={{
            count: pagination.total,
            rowsPerPage: pagination.per,
            rowsPerPageOptions: [10, 25, 50],
            page: pagination.page,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: (event) => {
              paginationQuery.updatePer(event.target.value.toString());
            },
          }}
        />
      </Column>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onBackdropClick={() => setOpenModal(false)}
        disableAutoFocus
      >
        <Preview rewardTemplateId={selectedRow} merchantId={id} />
      </Modal>
    </Grid>
  );
};

export default TabRewardTemplates;

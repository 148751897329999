import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';
import * as birthdayCampaignActions from '../../../../../../redux/birthdayCampaigns/actions';
import { promiseListener } from '../../../../../../redux/store';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { SubmitButton } from '../../../../../../components/Form';
import { TextField } from '../../../../../../components/FormikAdapter';
import RewardTemplateSelectV2 from '../../../../../../components/FormikAdapter/RewardTemplateSelect/v2';
import { FormRow } from '../../../../../../components/FormLayout';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<DialogProps, 'open' | 'onExit'> & {
  merchantId: string;
  programId: string;
  onClose: () => void;
};

interface NewBirthdayCampaignFormValues {
  validityPeriodDays: number;
  rewardTemplateId: string;
}

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  validityPeriodDays: Yup.number().required('Required').positive().integer(),
  rewardTemplateId: Yup.string().required('Required'),
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function NewBirthdayCampaignDialog({
  merchantId,
  programId,
  open,
  onExit,
  onClose,
}: Props) {
  const initialValues: NewBirthdayCampaignFormValues = {
    validityPeriodDays: 0,
    rewardTemplateId: '',
  };

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={getType(birthdayCampaignActions.createBirthdayCampaign)}
      resolve={getType(
        birthdayCampaignActions.api.createBirthdayCampaign.success
      )}
      reject={getType(
        birthdayCampaignActions.api.createBirthdayCampaign.failure
      )}
    >
      {(createBirthdayCampaign: any) => (
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={async (values, actions) => {
            try {
              await createBirthdayCampaign({
                ...values,
                loyaltyProgramId: programId,
              });

              actions.setSubmitting(false);
              actions.resetForm();
              onClose();
            } catch {
              actions.setSubmitting(false);
              alert('There was an error submitting this form');
            }
          }}
        >
          {({ isSubmitting, resetForm }) => (
            <Dialog open={open} onClose={onClose} onExit={onExit}>
              <Form translate="no" noValidate>
                <DialogTitle>Create a birthday campaign</DialogTitle>

                <DialogContent>
                  <FormRow>
                    <Field
                      component={RewardTemplateSelectV2}
                      name="rewardTemplateId"
                      label="Reward Template"
                      merchantId={merchantId}
                      autoFocus
                      fullWidth
                      required
                    />
                  </FormRow>

                  <FormRow>
                    <Field
                      component={TextField}
                      name="validityPeriodDays"
                      label="Validity Period (days)"
                      type="number"
                      fullWidth
                      required
                    />
                  </FormRow>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <SubmitButton
                    type="submit"
                    size="small"
                    isSubmitting={isSubmitting}
                  >
                    Create Campaign
                  </SubmitButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
    </MakeAsyncFunction>
  );
}

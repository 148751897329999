import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { api, createDropZone, listByCampaign } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* getCampaignDropZonesSaga(
  action: ReturnType<typeof listByCampaign>
): SagaIterator {
  const ids = action.payload;

  yield put(
    callAPI(
      apiRequests.dropZones.listByCampaignId(ids),
      api.fetchCampaignDropZones
    )
  );
}

export function* createDropZoneSaga(
  action: ReturnType<typeof createDropZone>
): SagaIterator {
  const { lat, lng, radius, campaignId } = action.payload;

  yield put(
    callAPI(
      apiRequests.dropZones.create({ lat, lng, radius, campaignId }),
      api.createDropZone
    )
  );
}

export default all([
  takeLatest(getType(listByCampaign), getCampaignDropZonesSaga),
  takeLeading(getType(createDropZone), createDropZoneSaga)
]);

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';
import classNames from 'classnames';

import { TextFieldProps } from '@material-ui/core/TextField';
import TextField from '../TextField';
import { useStyles as useTextFieldStyles } from '../TextField';

import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  inputRoot: {
    padding: `${theme.spacing(1)}px !important`, // Override MuiAutocomplete padding
  },
  input: {
    padding: '0 !important', // Override MuiAutocomplete padding
  },
  endAdornment: {
    marginRight: theme.spacing(),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type AutocompleteService = google.maps.places.AutocompleteService;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlacesService = google.maps.places.PlacesService;
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus;
export type PlaceResult = Pick<
  google.maps.places.PlaceResult,
  'geometry' | 'address_components' | 'formatted_address'
>;

type GetQueryPredictionsCallback = (
  results: AutocompletePrediction[] | null,
  status: PlacesServiceStatus
) => void;

type GetPlaceDetailsCallback = (
  result: PlaceResult | null,
  status: PlacesServiceStatus
) => void;

export type Props = AutocompleteProps<
  AutocompletePrediction,
  false,
  false,
  true
> & {
  error?: boolean;
  helperText?: string;
  onPlaceSelected?: (result: PlaceResult | null) => void;
  TextFieldProps?: Partial<Omit<TextFieldProps, 'onChange' | 'variant'>>;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const AutocompletePlace: React.FC<Props> = ({
  error,
  helperText,
  onPlaceSelected,
  TextFieldProps,
  ...props
}) => {
  const classes = useStyles();
  const textFieldClasses = useTextFieldStyles();

  const autocompleteService = useRef<AutocompleteService>();
  const placesService = useRef<PlacesService>();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  // Fetches the autocompletion predictions
  const fetchPredictions = useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: GetQueryPredictionsCallback
        ) => {
          if (autocompleteService.current) {
            autocompleteService.current.getPlacePredictions(
              {
                // componentRestrictions: {
                //   country: ['sg', 'us', 'gb', 'au', 'nz'],
                // },
                ...request,
              },
              callback
            );
          }
        },
        200
      ),
    []
  );

  // Fetches the place details
  const fetchPlace = useCallback(
    (placeId: string, callback: GetPlaceDetailsCallback) => {
      if (placesService.current) {
        placesService.current.getDetails(
          {
            placeId: placeId,
            fields: [
              'geometry.location',
              'formatted_address',
              'address_component',
            ],
          },
          callback
        );
      }
    },
    []
  );

  // Initialize the Google Autocomplete and Places services
  useEffect(() => {
    autocompleteService.current = new google.maps.places.AutocompleteService();
    placesService.current = new google.maps.places.PlacesService(
      document.createElement('div') // API requires some element...Stupid
    );
  }, []);

  // Handles when the input changes through the user typing or an autocomplete
  // prediction being selected.
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const { InputProps, InputLabelProps, ...rest } = params;

      const inputProps = {
        ...InputProps,
        disableUnderline: true,
        classes: {
          error: textFieldClasses.error,
        },
      };

      return (
        <TextField
          InputLabelProps={{
            ...InputLabelProps,
            shrink: true,
            className: textFieldClasses.inputLabel,
          }}
          InputProps={inputProps}
          error={error}
          helperText={helperText}
          {...rest}
          onChange={handleInputChange}
          {...TextFieldProps}
        />
      );
    },
    [error, handleInputChange, helperText, TextFieldProps, textFieldClasses]
  );

  // Fetch the autocomplete predictions when the user types
  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);

      return undefined;
    }

    fetchPredictions({ input: inputValue }, (results) =>
      setOptions(results || [])
    );
  }, [inputValue, fetchPredictions]);

  return (
    <Autocomplete
      classes={{
        inputRoot: classNames(textFieldClasses.root, classes.inputRoot),
        input: classes.input,
        endAdornment: classes.endAdornment,
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      renderInput={renderInput}
      onChange={(e, value) => {
        if (onPlaceSelected) {
          if (value == null) {
            onPlaceSelected(null);

            return;
          }

          let id = '';

          if (typeof value === 'string') {
            id = value;
          } else {
            id = value.place_id;
          }

          fetchPlace(id, (result) => {
            onPlaceSelected(result);
          });
        }
      }}
      renderOption={(option) => {
        if (typeof option === 'string') {
          return null;
        }

        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      {...props}
    />
  );
};

export default AutocompletePlace;

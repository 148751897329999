import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import { useDispatch, useSelector } from 'react-redux';
import * as dropZoneActions from '../../../../../redux/dropZones/actions';
import * as dropZoneSelectors from '../../../../../redux/dropZones/selectors';
import { promiseListener } from '../../../../../redux/store';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { Column, Content, Grid } from '../../../../../components/Layout/Main';
import { DropZoneMap } from '../../../../../components/Map';
import NewDropZoneFormDialog from './NewDropZoneFormDialog';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing()}px`,
  },
  map: {
    height: '400px',
    width: '100%',
    padding: 8,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const TabDropZones: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const zones = useSelector(dropZoneSelectors.listDropZonesByCampaignId(id));

  const classes = useStyles();
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  useEffect(() => {
    dispatch(dropZoneActions.listByCampaign(id));
  }, [dispatch, id]);

  return (
    <Content>
      <Grid>
        <Column xs={12}>
          <Paper>
            <div className={classes.actions}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setIsNewModalOpen(true)}
              >
                New Drop Zone
              </Button>
            </div>

            <div className={classes.map}>
              <DropZoneMap zones={zones} />
            </div>
          </Paper>
        </Column>
      </Grid>

      <MakeAsyncFunction
        listener={promiseListener}
        start={getType(dropZoneActions.createDropZone)}
        resolve={getType(dropZoneActions.api.createDropZone.success)}
        reject={getType(dropZoneActions.api.createDropZone.failure)}
      >
        {(createDropZone: any) => (
          <NewDropZoneFormDialog
            open={isNewModalOpen}
            onClose={() => setIsNewModalOpen(false)}
            onSubmit={async (values, actions) => {
              const { place, radius } = values;

              if (!place) {
                alert('You must provide an address');

                return;
              }

              const { lat, lng } = place;

              try {
                await createDropZone({
                  campaignId: id,
                  lat,
                  lng,
                  radius: radius,
                });

                actions.setSubmitting(false);
                actions.resetForm();
                setIsNewModalOpen(false);
              } catch {
                actions.setSubmitting(false);
                alert('There was an error submitting this form');
              }
            }}
          />
        )}
      </MakeAsyncFunction>
    </Content>
  );
};

export default TabDropZones;

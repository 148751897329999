import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import reduce from 'lodash/reduce';

import * as couponActions from '../coupons/actions';
import { Coupon } from '../../api/resources';

export type CouponActions = ActionType<typeof couponActions>;

/**
 * byId contains all the coupon
 */

interface CouponById {
  [key: string]: Coupon;
}

const byId = createReducer<CouponById, CouponActions>({}).handleAction(
  [couponActions.api.listCouponSetCoupons.success],
  (state, action) => {
    const { coupons } = normalize(action.payload);

    return coupons ? { ...state, ...coupons } : state;
  }
);

/**
 * The coupon ids keyed to the coupon set
 */
interface CouponSets {
  [key: string]: string[];
}

const couponSets = createReducer<CouponSets, CouponActions>({}).handleAction(
  [couponActions.api.listCouponSetCoupons.success],
  (state, action) => {
    const { data } = action.payload;

    const cs = reduce(
      data,
      (couponSets, coupon) => {
        const couponSetId = coupon.relationships.couponSet.data.id;
        const id = coupon.id;

        if (couponSets[couponSetId]) {
          couponSets[couponSetId].push(id);
        } else {
          couponSets[couponSetId] = [id];
        }

        return couponSets;
      },
      {} as CouponSets
    );

    return { ...state, ...cs };
  }
);

/**
 * pagination contains the pagination data for campaigns
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, CouponActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 50,
}).handleAction(
  [couponActions.api.listCouponSetCoupons.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map((coupon) => coupon.id),
      count: count,
      page: page,
      total: total,
      per: per,
    };
  }
);

/**
 * ui
 */

interface CouponUI {
  isFetching: boolean;
}
const ui = createReducer<CouponUI, CouponActions>({
  isFetching: false,
})
  .handleAction(
    [
      couponActions.api.listCouponSetCoupons.success,
      couponActions.api.listCouponSetCoupons.failure,
    ],
    (state) => ({
      ...state,
      ...{ isFetching: false },
    })
  )
  .handleAction([couponActions.api.listCouponSetCoupons.request], (state) => ({
    ...state,
    ...{ isFetching: true },
  }));

/**
 * Coupon Set root reducer
 */
const couponsReducer = combineReducers({
  byId,
  couponSets,
  pagination,
  ui,
});

export default couponsReducer;
export type CouponsState = ReturnType<typeof couponsReducer>;

import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as authActions from './actions';

export type AuthActions = ActionType<typeof authActions>;

/**
 *
 */

type AuthToken = string | null;
export const token = createReducer<AuthToken, AuthActions>(null)
  .handleAction(authActions.receivedToken, (_, action) => {
    return action.payload;
  })
  .handleAction(authActions.signout, () => {
    return null;
  });

const authReducer = combineReducers({
  token
});

export default authReducer;
export type AuthState = ReturnType<typeof authReducer>;

import { ApiRequest } from '../../api/requests/request';
import { createAction } from 'typesafe-actions';

import { APIError } from './models';

interface CallAPIOptions {
  debug?: boolean;
  payloadMeta?: (response: any) => object;
}

/**
 * Call the API
 */
export const callAPI = createAction(
  'api/CALL',
  (
    request: ApiRequest,
    asyncAction: any,
    opts: CallAPIOptions = {
      debug: false
    }
  ) => ({ request, asyncAction, opts })
)();

/**
 * API call has failed
 */
export const callAPIFailure = createAction(
  'api/ERROR',
  (error: APIError) => error
)();

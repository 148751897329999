import React from 'react';
import { Formik, Field, Form } from 'formik';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import { SubmitButton } from '../Form';
import {
  DateTimePicker,
  RewardTemplateSelect,
  TextField,
} from '../FormikAdapter';
import * as MilestoneService from '../../api/services/milestoneService';
import { FormRow } from '../FormLayout';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles({
  form: {
    width: 800,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    width: 50,
  },
});

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<DialogProps, 'open'> & {
  merchantId: string;
  milestoneId: string;
  mValidityPeriodDays: number;
  mTemplateId: string;
  mRewardExpiryDate: number;
  onClose: () => void;
  onSuccess: () => void;
};

interface EditMilestoneFormValues {
  expiredAt: moment.Moment | null;
  templateId: string;
  validityPeriodDays: number;
}

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

// const ValidationSchema = Yup.object().shape({
//   expiredAt: Yup.mixed().required('Required'),
// });

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function EditMilestoneDialog({
  merchantId,
  milestoneId,
  mTemplateId,
  mValidityPeriodDays,
  mRewardExpiryDate,
  open,
  onClose,
  onSuccess,
}: Props) {
  const classes = useStyles();

  const initialValues: EditMilestoneFormValues = {
    expiredAt: mRewardExpiryDate ? moment(mRewardExpiryDate) : null,
    templateId: mTemplateId,
    validityPeriodDays: mValidityPeriodDays,
  };

  const handleSubmit = async (values: EditMilestoneFormValues) => {
    try {
      if (values.expiredAt) {
        await MilestoneService.updateMilestoneRewardExpiryDate({
          rewardExpiryDate: values.expiredAt.utc().unix(),
          id: milestoneId,
          rewardTemplateId: values.templateId,
          merchantId: merchantId,
        });

        onSuccess();
      }

      if (values.validityPeriodDays > 0) {
        await MilestoneService.updateMilestoneValidityPeriodDays({
          validityPeriodDays: values.validityPeriodDays,
          id: milestoneId,
          rewardTemplateId: values.templateId,
          merchantId: merchantId,
        });

        onSuccess();
      }
    } catch {
      alert('There was an error submitting this form');
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue }) => (
        <Dialog open={open} onClose={onClose} maxWidth="xl">
          <Form translate="no" noValidate className={classes.form}>
            <DialogTitle>Edit milestone</DialogTitle>

            <DialogContent>
              <FormRow>
                <Field
                  component={RewardTemplateSelect}
                  merchantId={merchantId}
                  name="templateId"
                  label="Reward"
                  value={initialValues.templateId}
                />
              </FormRow>
              <FormRow>
                <Field
                  component={TextField}
                  name="validityPeriodDays"
                  label="Validity Period (days)"
                  type="number"
                  onFocus={() => setFieldValue('expiredAt', null)}
                />
              </FormRow>
              <FormRow>
                <Field
                  component={DateTimePicker}
                  name="expiredAt"
                  disablePast
                  label="Expires At"
                  initialFocusedDate={moment().endOf('day')}
                  onFocus={() => setFieldValue('validityPeriodDays', 0)}
                />
              </FormRow>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <SubmitButton
                type="submit"
                size="small"
                isSubmitting={isSubmitting}
              >
                Update
              </SubmitButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}

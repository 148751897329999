import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import * as rewardTemplateActions from '../../../../../../redux/rewardTemplates/actions';
import * as rewardTemplateSelectors from '../../../../../../redux/rewardTemplates/selectors';
import * as merchantSelectors from '../../../../../../redux/merchants/selectors';

import { Column, Grid } from '../../../../../../components/Layout/Main';
import RewardTemplateDetails from './RewardTemplateDetails';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
  rewardTemplateId: string;
}

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Show = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { rewardTemplateId, id } = useParams<RouteParams>();

  const rewardTemplate = useSelector(
    rewardTemplateSelectors.getRewardTemplateSelector(rewardTemplateId)
  );
  const merchant = useSelector(merchantSelectors.getMerchantSelector(id));

  // Load the reward template
  useEffect(() => {
    dispatch(rewardTemplateActions.getRewardTemplate(rewardTemplateId));
  }, [dispatch, rewardTemplateId]);

  return (
    <Grid>
      <Column xs={12}>
        {rewardTemplate && merchant && (
          <RewardTemplateDetails
            rewardTemplate={rewardTemplate}
            merchant={merchant}
            onDelete={() => {
              dispatch(
                rewardTemplateActions.archiveRewardTemplate(rewardTemplateId)
              );
              history.push(`/merchants/${id}/reward_templates`);
            }}
          />
        )}
      </Column>
    </Grid>
  );
};

export default Show;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Extract the value of param in the query string.
 * */
export default function useQueryParams(param: string) {
  const location = useLocation();

  return useMemo(() => {
    return queryString.parse(location.search)[param] as string;
  }, [location, param]);
}

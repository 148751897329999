import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing()
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Actions: React.FC<Props> = ({ children, primary, secondary }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>{secondary}</div>
      <div>{primary ? primary : children}</div>
    </div>
  );
};

export default Actions;

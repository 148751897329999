import produce from 'immer';
import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as loyaltyProgramActions from '../loyaltyPrograms/actions';
import { LoyaltyProgramResource } from '../../api/resources';

export type LoyaltyProgramActions = ActionType<typeof loyaltyProgramActions>;

/**
 * byId contains all the store objects
 */

interface LoyaltyProgramById {
  [key: string]: LoyaltyProgramResource;
}

const byId = createReducer<LoyaltyProgramById, LoyaltyProgramActions>(
  {}
).handleAction(
  [loyaltyProgramActions.api.fetchMerchantLoyaltyProgram.success],
  (state, action) => {
    const { loyaltyPrograms } = normalize(action.payload);

    return loyaltyPrograms ? { ...state, ...loyaltyPrograms } : state;
  }
);

/**
 * The loyalty programs associated to a merchant
 */
interface MerchantRelationships {
  [key: string]: string;
}

const merchantRelationships = createReducer<
  MerchantRelationships,
  LoyaltyProgramActions
>({}).handleAction(
  [loyaltyProgramActions.api.fetchMerchantLoyaltyProgram.success],
  (state, action) => {
    const { data } = action.payload;
    const merchantId = data.relationships.merchant.data.id;

    return produce(state, (draft) => {
      draft[merchantId] = data.id;
    });
  }
);

/**
 * ui
 */
interface UI {
  isFetching: boolean;
}

const ui = createReducer<UI, LoyaltyProgramActions>({
  isFetching: false,
})
  .handleAction(
    [
      loyaltyProgramActions.api.fetchMerchantLoyaltyProgram.success,
      loyaltyProgramActions.api.fetchMerchantLoyaltyProgram.failure,
    ],
    (state) => {
      return produce(state, (draft) => {
        draft.isFetching = false;
      });
    }
  )
  .handleAction(
    [loyaltyProgramActions.api.fetchMerchantLoyaltyProgram.request],
    (state) => {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }
  );

/**
 * LoyaltyPrograms root reducer
 */
const loyaltyProgramsReducer = combineReducers({
  byId,
  merchantRelationships,
  ui,
});

export default loyaltyProgramsReducer;
export type LoyaltyProgramState = ReturnType<typeof loyaltyProgramsReducer>;

import React from 'react';
import get from 'lodash/get';

import Table from '@material-ui/core/Table';

import LoadingTableBody from './LoadingTableBody';
import TableBody, { TableBodyProps } from './TableBody';
import TableHead, { TableHeadProps } from './TableHead';
import TablePagination, { TablePaginationProps } from './TablePagination';
import TableToolbar, { TableToolbarProps } from './TableToolbar';
import { Cell } from './types';

type Props = Pick<TableToolbarProps, 'Actions' | 'title' | 'SearchProps'> &
  Pick<TableHeadProps, 'columns'> &
  Pick<TableBodyProps, 'onRowClick'> & {
    data: any;
    isLoading?: boolean;
    PaginationProps?: TablePaginationProps;
    footer?: React.ReactNode;
    toolbar?: boolean;
  };

const PlainTable: React.FC<Props> = ({
  Actions: ActionComponent,
  columns,
  data,
  footer,
  isLoading,
  onRowClick,
  PaginationProps,
  title,
  toolbar = true,
}: Props) => {
  const rows = data.map((obj: any) =>
    columns.map((col) => {
      let value = get(obj, col.field);

      if (col.format) {
        value = col.format(value);
      }

      const cell: Cell = { value, width: col.width, align: col.align };

      return cell;
    })
  );

  return (
    <div>
      {toolbar && <TableToolbar Actions={ActionComponent} title={title} />}

      <Table>
        <TableHead columns={columns} />
        {isLoading && data.length === 0 ? (
          <LoadingTableBody />
        ) : (
          <TableBody rows={rows} onRowClick={onRowClick} />
        )}
      </Table>

      {PaginationProps && <TablePagination {...PaginationProps} />}
      {footer}
    </div>
  );
};

export default PlainTable;

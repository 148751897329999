import React from 'react';
import MomentUtils from '@date-io/moment';

import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  DatePickerProps
} from '@material-ui/pickers';
import { useStyles } from '../TextField';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

export type Props = DatePickerProps;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const DatePicker: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiDatePicker
        {...props}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabel
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.input,
            root: classes.root,
            focused: classes.focused,
            error: classes.error
          },
          ...props.InputProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;

import React, { useCallback, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as StampCampaignService from '../../api/services/stampCampaignService';
import CampaignTabs from './CampaignTabs';
import CampaignSummary from './CampaignSummary';
import ActivateStampCampaignDialog from './ActivateStampCampaignDialog';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    flex: 1,
  },
  actions: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  listItem: {
    minWidth: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type StampCampaignCardProps = {
  campaign: StampCampaignService.StampCampaign;
  merchantId: string;
  onActivate?: (campaign: StampCampaignService.StampCampaign) => void;
  onDeactivate: (campaign: StampCampaignService.StampCampaign) => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function StampCampaignCard({
  campaign,
  merchantId,
  onActivate,
  onDeactivate,
}: StampCampaignCardProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [activateDialog, setActivateDialog] = useState(false);

  // Opens the menu
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  // Closes the menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Deactive the campaign
  const deactivateCampaign = useCallback(async () => {
    const confirmed = window.confirm(
      'Are you sure you want to deactivate this campaign?'
    );

    if (confirmed) {
      try {
        const res = await StampCampaignService.deactivate(campaign.id);

        if (onDeactivate) {
          handleCloseMenu();
          onDeactivate(res);
        }
      } catch (e) {
        alert('error');
      }
    }
  }, [campaign.id, onDeactivate]);

  const activateCampaign = useCallback(
    async (toIssueStampCard: boolean) => {
      try {
        const res = await StampCampaignService.activate(
          campaign.id,
          toIssueStampCard
        );

        if (onActivate) {
          handleCloseMenu();
          onActivate(res);
        }
      } catch (e) {
        alert('error');
      }
    },
    [campaign.id, onActivate]
  );

  return (
    <Paper>
      <div className={classes.root}>
        <div className={classes.body}>
          <CampaignSummary
            createdAt={campaign.createdAt}
            details={campaign.details}
            totalStamps={campaign.maxStamps}
            status={campaign.status}
          />
        </div>

        <div className={classes.actions}>
          <div>
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {campaign.status === 'active' && (
                <MenuItem onClick={deactivateCampaign}>
                  <ListItemIcon className={classes.listItem}>
                    <HighlightOffIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Deactivate</Typography>
                </MenuItem>
              )}
              {(campaign.status === 'inactive' ||
                campaign.status === 'draft') && (
                <MenuItem onClick={() => setActivateDialog(true)}>
                  <ListItemIcon className={classes.listItem}>
                    <PowerSettingsNewIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Activate</Typography>
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      </div>

      <CampaignTabs
        campaignId={campaign.id}
        merchantId={merchantId}
        maxStamps={campaign.maxStamps}
        status={campaign.status}
      />
      <ActivateStampCampaignDialog
        open={activateDialog}
        onExit={() => setActivateDialog(false)}
        onClose={() => setActivateDialog(false)}
        handleActivate={(issueStamp) => {
          const issueStampStatus = issueStamp === 'remove';
          console.log('IssueStamp: ', issueStampStatus);
          setActivateDialog(false);
          activateCampaign(issueStampStatus);
        }}
      />
    </Paper>
  );
}

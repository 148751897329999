import { BirthdayCampaign } from './models';
import { RootState } from '../reducers';
import { BirthdayCampaignResource } from '../../api/resources/birthdayCampaign';
import pick from 'lodash/pick';
import values from 'lodash/values';
import find from 'lodash/find';

/**
 * Gets the Birthday Campaign state
 *
 * @param state
 */
export const getBirthdayCampaignState = (state: RootState) =>
  state.birthdayCampaigns;

/**
 * Gets a loyalty program's birthday campaign .
 *
 * @param state
 * @param programId
 */
export const getProgramBirthdayCampaign = (programId: string) => (
  state: RootState
) => {
  const id = getBirthdayCampaignState(state).programRelationships[programId];

  if (!id) {
    return undefined;
  }

  const birthdayCampaign = pick(getBirthdayCampaignState(state).byId, id);

  if (!birthdayCampaign) {
    return undefined;
  }

  return find(values(birthdayCampaign).map(makeBirthdayCampaign(state)));
};

export const getBirthdayCampaignUI = (state: RootState) =>
  getBirthdayCampaignState(state).ui;

export const getIsFetching = (state: RootState) =>
  getBirthdayCampaignUI(state).isFetching;

/**
 *
 */
const makeBirthdayCampaign = (state: RootState) => (
  campaign: BirthdayCampaignResource
): BirthdayCampaign => {
  const { id, attributes, relationships } = campaign;
  const {
    loyaltyProgram: {
      data: { id: loyaltyProgramId },
    },
    rewardTemplate: {
      data: { id: rewardTemplateId },
    },
    reward: {
      data: { id: rewardId },
    },
  } = relationships;

  return {
    id,
    ...attributes,
    loyaltyProgramId,
    rewardTemplateId,
    rewardId,
  };
};

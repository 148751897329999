import { ActionType, createReducer } from 'typesafe-actions';

import * as profileActions from './actions';
import { User } from '../../api/resources';

export type ProfileActions = ActionType<typeof profileActions>;

export const profileReducer = createReducer<User | null, ProfileActions>(
  null
).handleAction(
  profileActions.api.fetchProfile.success,
  (_, action) => action.payload.data
);

export default profileReducer;
export type ProfileState = ReturnType<typeof profileReducer>;

import { RootState } from '../reducers';
import { Campaign, CampaignStats } from './models';

import pick from 'lodash/pick';
import values from 'lodash/values';
import {
  Campaign as CampaignState,
  CampaignStats as CampaignStatsState
} from '../../api/resources/campaign';
import { getMerchant } from '../merchants/selectors';

/**
 * Get the campaigns state
 *
 * @param state
 */
export const getCampaignsState = (state: RootState) => state.campaigns;

/**
 * List the current campaigns for the page.
 *
 * @param state
 */
export const listCampaignsPage = (state: RootState) => {
  const ids = getPagination(state).ids;
  const users = pick(getCampaignsState(state).byId, ids);

  return values(users).map(makeCampaign(state));
};

/**
 * Get a campaign
 *
 * @param state
 * @param id
 */
export const getCampaign = (id: string) => (state: RootState) => {
  const campaign = getCampaignsState(state).byId[id];

  if (!campaign) {
    return null;
  }

  return makeCampaign(state)(campaign);
};

/**
 * Get a merchant's campaign ids
 *
 * @param state
 * @param merchantId
 */
export const getMerchantCampaignIds = (state: RootState, merchantId: string) =>
  getCampaignsState(state).merchants[merchantId] || [];

/**
 * Get a merchant's campaigns
 *
 * @param state
 * @param merchantId
 */
export const listMerchantCampaigns = (merchantId: string) => (
  state: RootState
) => {
  const allCampaigns = getCampaignsState(state).byId;
  const ids = getMerchantCampaignIds(state, merchantId);
  const campaigns = pick(allCampaigns, ids);

  return values(campaigns).map(makeCampaign(state));
};

/**
 * Are campagins being fetched?
 * @param state
 */
export const isFetching = (state: RootState) =>
  getCampaignsState(state).ui.isFetching;

/**
 * Get the pagination data
 *
 * @param state
 */
export const getPagination = (state: RootState) =>
  getCampaignsState(state).pagination;

/**
 * Get a Campaign stats
 *
 * @param state
 * @param id
 */
export const getCampaignStats = (id: string) => (state: RootState) => {
  const campaignStats = getCampaignsState(state).stats[id];

  if (!campaignStats) {
    return null;
  }

  return makeCampaignStats(state)(campaignStats);
};

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeCampaign = (state: RootState) => (
  campaign: CampaignState
): Campaign => {
  const { id, attributes, relationships } = campaign;
  const {
    title,
    status,
    expiredAt,
    target,
    type,
    rewardExpiry,
    supply
  } = attributes;

  return {
    id,
    title,
    status,
    expiredAt,
    type,
    target,
    rewardExpiry,
    supply,
    merchant: getMerchant(state, relationships.merchant.data.id),
    rewardTemplateId: relationships.rewardTemplate.data.id
  };
};

const makeCampaignStats = (state: RootState) => (
  campaignStats: CampaignStatsState
): CampaignStats => {
  const { id, attributes } = campaignStats;
  const { collected, delivered, redeemed } = attributes;

  return {
    id,
    collected: collected.total,
    delivered: delivered.total,
    redeemed: redeemed.total
  };
};

import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import { Checkbox } from 'formik-material-ui';
import pick from 'lodash/pick';
import * as Yup from 'yup';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  ImageUploader,
  MarkdownEditor,
  TextField,
} from '../../../../../../components/FormikAdapter';
import {
  FormActions,
  FormRow,
  FormSection,
} from '../../../../../../components/FormLayout';
import { SubmitButton } from '../../../../../../components/Form';
import { RewardTemplate } from '../../../../../../redux/rewardTemplates/models';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface NewRewardTemplateFormValues {
  title: string;
  what: string;
  info: string;
  gift: boolean;
  sell: boolean;
  imageId: string;
}

type Props = {
  onSubmit: (
    values: NewRewardTemplateFormValues,
    actions: FormikHelpers<NewRewardTemplateFormValues>
  ) => void;
  rewardTemplate?: RewardTemplate | undefined;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const CreateSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  imageId: Yup.string().required('Required'),
  gift: Yup.boolean().required('Required'),
  sell: Yup.boolean().required('Required'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const NewRewardTemplateForm: React.FC<Props> = ({
  onSubmit,
  rewardTemplate,
}) => {
  const initialValues: NewRewardTemplateFormValues = rewardTemplate
    ? {
        ...pick(rewardTemplate, 'title', 'what', 'info', 'gift', 'sell'),
        imageId: rewardTemplate.image!.id,
      }
    : {
        title: '',
        what: '',
        info: '',
        imageId: '',
        gift: true,
        sell: true,
      };

  const selectedURL = rewardTemplate ? rewardTemplate.image!.versions.lg : '';

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Form translate="no" noValidate>
          <FormSection title="Details">
            <FormRow>
              <Field
                component={TextField}
                name="title"
                label="Title"
                fullWidth
                required
              />
            </FormRow>

            <FormRow>
              <Field
                component={MarkdownEditor}
                name="what"
                label="What You'll Get"
                fullWidth
                multiline
                md={initialValues.what}
              />
            </FormRow>

            <FormRow>
              <Field
                component={MarkdownEditor}
                name="info"
                label="The Fine Print"
                fullWidth
                multiline
                md={initialValues.info}
              />
            </FormRow>
          </FormSection>

          <FormSection title="Image">
            <Field
              component={ImageUploader}
              name="imageId"
              selectedURL={selectedURL}
            />
          </FormSection>

          <FormSection title="Options">
            <FormRow>
              <FormControlLabel
                control={<Field component={Checkbox} name="gift" />}
                label="Users can send this reward to others as a gift"
              />
            </FormRow>

            <FormRow>
              <FormControlLabel
                control={<Field component={Checkbox} name="sell" />}
                label="This reward can be listed in the Marketplace for sale"
              />
            </FormRow>
          </FormSection>
          <FormActions>
            <SubmitButton isSubmitting={formikBag.isSubmitting}>
              Create Reward Template
            </SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};

export default NewRewardTemplateForm;

import React from 'react';
import capitalize from 'lodash/capitalize';

import { Campaign } from '../../../../../redux/campaigns/models';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DefinitionListItem from '../../../../../components/DefinitionListItem';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  content: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  title: {
    fontSize: '1rem',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  campaign: Campaign;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Details: React.FC<Props> = ({ campaign: { status, type } }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          Details
        </Typography>
      </div>

      <div className={classes.content}>
        <List>
          <DefinitionListItem title="Type" value={capitalize(type)} />
          <DefinitionListItem title="Status" value={capitalize(status)} />
        </List>
      </div>
    </Paper>
  );
};

export default Details;

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

export const endpoint = createReducer(process.env.REACT_APP_API_HOST);

const apiReducer = combineReducers({
  endpoint
});

export default apiReducer;
export type APIState = ReturnType<typeof apiReducer>;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
    borderRight: `1px solid ${theme.palette.grey[200]}`
  }
}));

const FiltersSection: React.FC<{}> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default FiltersSection;

import React from 'react';

type Props = {
  height: string;
  width: string;
  color: string;
};

const MapMarkerStar: React.FC<Props> = ({ height, width, color }: Props) => (
  <svg width={width} height={height} viewBox="0 0 26 30" version="1.1">
    <path
      d="M3.47823648,0 L22.225685,0 C24.1466037,0 25.7039215,1.5579505 25.7039215,3.47964963 L25.7039215,22.2348598 C25.7039215,24.1567037 24.1466037,25.7145094 22.225685,25.7145094 L17.403341,25.7145094 C16.8848623,25.7145094 16.396346,25.9577808 16.0838403,26.371487 L13.6070985,29.6498585 C13.2556562,30.1148254 12.5581272,30.1171423 12.20379,29.6542026 L9.68405882,26.3630883 C9.37097412,25.9541607 8.88564216,25.7145094 8.37092685,25.7145094 L3.47823648,25.7145094 C1.55731778,25.7145094 -2.0250468e-13,24.1567037 -2.0250468e-13,22.2348598 L-2.0250468e-13,3.47964963 C-2.0250468e-13,1.5579505 1.55731778,0 3.47823648,0 Z M9.93883438,16.1488818 L9.89030683,18 L11.3538433,17.4535255 C12.4146465,17.0572029 13.5829359,17.0572029 14.6438903,17.4535255 L16.1072756,18 L16.0587481,16.1488818 C16.0316875,15.1108221 16.3499134,14.0931759 16.9638398,13.2551676 L17.9977336,11.843467 L16.494589,11.3780412 C15.4484499,11.0539972 14.548347,10.3749112 13.9501429,9.45827348 L12.9987912,8 L12.0474396,9.45827348 C11.4492355,10.3749112 10.5492838,11.0539972 9.50299348,11.3780412 L8,11.843467 L9.034045,13.2551676 C9.64782025,14.0931759 9.96604609,15.1108221 9.93883438,16.1488818 Z"
      id="Combined-Shape"
      fill={color}
    ></path>
  </svg>
);

export default MapMarkerStar;

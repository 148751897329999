import { createAction } from 'typesafe-actions';

/**
 * Display a notification
 */
export const notify = createAction('notification/SHOW', (msg: string) => msg)();

/**
 * Clear the notification
 */
export const clear = createAction('notification/CLEAR')();

import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';

/**
 * FILTER_QP_REGEX defines the format of a filter query param.
 *
 * Example
 *
 *  filter[status][eq]=active
 *  filter[status][eq]=active,complete
 *  filter[startTime][gte]=1571736034
 * */
const FILTER_QP_REGEX = /filter\[(.+)\]\[(.+)\]/;

// Filters defines the filter name mapped to its list of values
export type Filters = { [key: string]: string[] };

/**
 * Extract the filters term from the query string and provide an update function
 * and remove.
 *
 * Usage:
 *    filtersQuery.update({status: ['active']})
 *    filtersQuery.remove('status')
 * */
export default function useFiltersQuery() {
  const location = useLocation();
  const history = useHistory();

  return useMemo(() => {
    const params = queryString.parse(location.search, {
      arrayFormat: 'comma'
    });
    const filterParams = pickBy(params, (_, key) => FILTER_QP_REGEX.test(key));
    // Extract the filters
    const filters = mapKeys(filterParams, (_, key) => {
      const matches = key.match(FILTER_QP_REGEX);

      if (matches && matches[1]) {
        return matches[1];
      }

      return key;
    }) as Filters;

    return {
      filters: filters,

      /**
       * Updates the filters in the queryString.
       */
      update: (filters: Filters) => {
        const fp = mapKeys(filters, (_, k) => `filter[${k}][eq]`);

        // Merge the params
        const qp = {
          ...params,
          ...fp
        };

        history.push({
          search: queryString.stringify(qp, { arrayFormat: 'comma' })
        });
      },

      /**
       * Removes the filter query param from the queryString.
       */
      remove: (key: string) => {
        const queryParams = omit(params, `filter[${key}][eq]`);

        history.push({
          search: queryString.stringify(queryParams, { arrayFormat: 'comma' })
        });
      }
    };
  }, [history, location]);
}

import { createAction, createAsyncAction } from 'typesafe-actions';
import { LocationCollectionDoc } from '../../api/resources/location';
import { APIError } from '../api/models';

/**
 * Gets the user's locations
 */
export const getUserLocations = createAction(
  'locations/GET_USER_LOCATIONS',
  (userID: string) => userID
)();

/**
 * An address from a reverse geocode was received
 */
export const receivedLocationAddress = createAction(
  'locations/RECEIVED_LOCATION_ADDRESS',
  (id: string, address: string) => ({ id, address })
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchUsersLocations: createAsyncAction(
    'api/locations/FETCH_USER_LOCATIONS_REQUEST',
    'api/locations/FETCH_USER_LOCATIONS_SUCCESS',
    'api/locations/FETCH_USER_LOCATIONS_FAILURE'
  )<any, LocationCollectionDoc, APIError>()
};

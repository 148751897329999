import React, { useState } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Redirect, useParams } from 'react-router';
import { getType } from 'typesafe-actions';

import * as storeActions from '../../../../../../redux/stores/actions';
import { promiseListener } from '../../../../../../redux/store';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Column, Grid } from '../../../../../../components/Layout/Main';

import NewStoreForm from './NewStoreForm';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(4)
  }
}));

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New = () => {
  const classes = useStyles();
  const { id } = useParams<RouteParams>();

  const [redirectTo, setRedirectTo] = useState('');

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            New Store
          </Typography>

          <MakeAsyncFunction
            listener={promiseListener}
            start={getType(storeActions.createStore)}
            resolve={getType(storeActions.api.createStore.success)}
            reject={getType(storeActions.api.createStore.failure)}
          >
            {(createStore: any) => (
              <NewStoreForm
                onSubmit={async (values, actions) => {
                  const { name, phone, place } = values;

                  if (!place) {
                    alert('You must provide an address');

                    return;
                  }

                  const { address, lat, lng, neighborhood } = place;

                  try {
                    await createStore({
                      name,
                      phone,
                      address,
                      lat,
                      lng,
                      neighborhood,
                      merchantId: id
                    });

                    actions.setSubmitting(false);
                    setRedirectTo(`/merchants/${id}/stores`);
                  } catch {
                    actions.setSubmitting(false);
                    alert('There was an error submitting this form');
                  }
                }}
              />
            )}
          </MakeAsyncFunction>
        </Paper>
      </Column>
    </Grid>
  );
};

export default New;

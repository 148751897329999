import request from './request';
import deserialize from './deserialize';

export type MerchantProfile = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type Merchant = {
  id: string;
  about: string;
  applink: string;
  category: {
    key: string;
    label: string;
  };
  createAt: number;
  name: string;
  phone: string;
  url: string;
  maxNumStores: number;
};

export function getMerchantDetail(merchantId: string) {
  return request<MerchantProfile>({
    url: `/admin/api/v2/merchants/${merchantId}/owner`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function getMerchant(merchantId: string) {
  return request<Merchant>({
    url: `/admin/api/v2/merchants/${merchantId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

import React from 'react';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
    height: 256,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    outline: 'none'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

export type Props = {
  tabs: string[];
  selectedIndex: number;
  onMenuItemClick: (index: number) => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const MenuSection: React.FC<Props> = ({
  selectedIndex,
  onMenuItemClick,
  tabs
}) => {
  const classes = useStyles();

  return (
    <MenuList disablePadding classes={{ root: classes.root }}>
      {tabs.map((tab, index) => (
        <MenuItem
          key={tab}
          selected={index === selectedIndex}
          onClick={() => onMenuItemClick(index)}
        >
          {tab}
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MenuSection;

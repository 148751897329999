import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey['400'],
    fontSize: 32
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Placeholder = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CloudUploadIcon className={classes.icon} />
      <Typography variant="body2">Choose a file</Typography>
      <Typography variant="body2">or drag it here</Typography>
    </div>
  );
};

export default Placeholder;

import { createAction, createAsyncAction } from 'typesafe-actions';
import { PaginationOptions } from '../types';
import {
  CouponSetDoc,
  CouponSetCollectionDoc,
} from '../../api/resources/couponSet';
import { CouponSetPDFDoc } from '../../api/resources/couponSetPDF';
import { APIError } from '../api/models';

/**
 * Creates a coupon set
 */
export const createCouponSet = createAction(
  'coupon_sets/CREATE',
  (attrs: {
    label: string;
    couponsCount: number;
    expiresAt: number;
    loyaltyCampaignId: string;
  }) => attrs
)();

/**
 * Lists a loyalty package's coupon set
 */
export const listLoyaltyPackageCouponSet = createAction(
  'coupon_sets/LIST_BY_LOYALTY_PACKAGE',
  (loyaltyPackageId: string, opts: { pagination: PaginationOptions }) => ({
    loyaltyPackageId,
    pagination: opts.pagination,
  })
)();

export const getCouponSetPDF = createAction(
  'coupon_sets/GET_PDF',
  (setId: string) => setId
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  createCouponSet: createAsyncAction(
    'api/coupon_sets/CREATE_REQUEST',
    'api/coupon_sets/CREATE_SUCCESS',
    'api/coupon_sets/CREATE_FAILURE'
  )<any, CouponSetDoc, APIError>(),

  listLoyaltyPackageCouponSet: createAsyncAction(
    'api/coupon_sets/LIST_BY_LOYALTY_PACKAGE_REQUEST',
    'api/coupon_sets/LIST_BY_LOYALTY_PACKAGE_SUCCESS',
    'api/coupon_sets/LIST_BY_LOYALTY_PACKAGE_FAILURE'
  )<any, CouponSetCollectionDoc, APIError>(),

  getCouponSetPDF: createAsyncAction(
    'api/coupon_sets/GET_PDF_REQUEST',
    'api/coupon_sets/GET_PDF_SUCCESS',
    'api/coupon_sets/GET_PDF_FAILURE'
  )<any, CouponSetPDFDoc, APIError>(),
};

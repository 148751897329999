import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as StampCampaignService from '../../api/services/stampCampaignService';
import * as MerchantService from '../../api/services/merchantService';
import CampaignSummary from './CampaignSummary';
import StampCardList from './StampCardList';
import StampCampaignList from './StampCampaignList';
import MilestonesList from './MilestonesList';
import ActivatePrizeDrawCampaignDialog from './ActivatePrizeDrawCampaignDialog';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  listItem: {
    minWidth: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type PrizeDrawCampaignCardProps = {
  campaign: StampCampaignService.StampCampaign;
  merchantId: string;
  onActivate?: (campaign: StampCampaignService.StampCampaign) => void;
  onDeactivate: (campaign: StampCampaignService.StampCampaign) => void;
  programId: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function PrizeDrawCampaignCard({
  campaign,
  merchantId,
  onActivate,
  onDeactivate,
  programId,
}: PrizeDrawCampaignCardProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const history = useHistory();
  const [merchant, setMerchant] = useState<MerchantService.Merchant | null>(
    null
  );
  const [activateDialog, setActivateDialog] = useState(false);

  //fetch merchant
  useEffect(() => {
    const fetchMerchant = async () => {
      try {
        const res = await MerchantService.getMerchant(merchantId);

        setMerchant(res);
      } catch (e) {
        console.log('error: ', e);
      }
    };

    fetchMerchant();
  }, [merchantId]);

  // Opens the menu
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  // Closes the menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Deactive the campaign
  const deactivateCampaign = useCallback(async () => {
    const confirmed = window.confirm(
      'Are you sure you want to deactivate this campaign?'
    );

    if (confirmed) {
      try {
        const res = await StampCampaignService.deactivate(campaign.id);

        if (onDeactivate) {
          handleCloseMenu();
          onDeactivate(res);
        }
      } catch (e) {
        alert('error');
      }
    }
  }, [campaign.id, onDeactivate]);

  const activateCampaign = useCallback(
    async (toIssueStampCard: boolean) => {
      try {
        const res = await StampCampaignService.activate(
          campaign.id,
          toIssueStampCard
        );

        if (onActivate) {
          handleCloseMenu();
          onActivate(res);
        }
      } catch (e) {
        alert('error');
      }
    },
    [campaign.id, onActivate]
  );

  const editCampaign = () => {
    history.push(
      `/merchants/${merchantId}/loyalty/prize_draw_campaigns/edit/${campaign.id}`
    );
  };

  if (!merchant) {
    return null;
  }

  return (
    <Paper>
      <div className={classes.root}>
        <div className={classes.body}>
          <CampaignSummary
            createdAt={campaign.createdAt}
            minSpend={campaign.details}
            totalStamps={campaign.maxStamps}
            status={campaign.status}
            notification={campaign.notification}
            description={campaign.description}
            disableMultipleStamp={campaign.multipleCards}
            resendWelcomeRewards={campaign.reissuedWelcomeRewards}
            maxStoreStampLimit={campaign.storeStampLimit}
          />
          <MilestonesList
            campaignId={campaign.id}
            merchantId={merchant.id}
            maxStamps={campaign.maxStamps}
            status={campaign.status}
          />
          <StampCardList
            programId={programId}
            merchantName={merchant.name}
            campaignId={campaign.id}
          />
          <StampCampaignList
            programId={programId}
            merchantName={merchant.name}
          />
        </div>

        <div className={classes.actions}>
          <div>
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {campaign.status === 'active' && (
                <MenuItem onClick={deactivateCampaign}>
                  <ListItemIcon className={classes.listItem}>
                    <HighlightOffIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Deactivate</Typography>
                </MenuItem>
              )}
              {(campaign.status === 'inactive' ||
                campaign.status === 'draft') && (
                <MenuItem onClick={() => setActivateDialog(true)}>
                  <ListItemIcon className={classes.listItem}>
                    <PowerSettingsNewIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Activate</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={editCampaign}>
                <ListItemIcon className={classes.listItem}>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography>Edit</Typography>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <ActivatePrizeDrawCampaignDialog
        open={activateDialog}
        onExit={() => setActivateDialog(false)}
        onClose={() => setActivateDialog(false)}
        handleActivate={(issueStamp) => {
          const issueStampStatus = issueStamp === 'remove';
          console.log('IssueStamp: ', issueStampStatus);
          setActivateDialog(false);
          activateCampaign(issueStampStatus);
        }}
      />
    </Paper>
  );
}

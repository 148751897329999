import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(5)
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Main: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div id="main" className={classes.root}>
      {children}
    </div>
  );
};

export default Main;

import { ApiRequest } from './request';

const create = (merchantId: string): ApiRequest => ({
  path: `/admin/api/v2/loyalty_programs`,
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'loyalty_programs',
      relationships: {
        merchant: {
          data: {
            type: 'merchants',
            id: merchantId
          }
        }
      }
    }
  }
});

const getByMerchantId = (merchantId: string): ApiRequest => ({
  path: `/admin/api/v2/merchants/${merchantId}/loyalty_program`,
  method: 'GET',
  authenticated: true
});

export default {
  create,
  getByMerchantId
};

import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as rewardTemplateActions from '../../redux/rewardTemplates/actions';
import { listRewardTemplatesByMerchantId } from '../../redux/rewardTemplates/selectors';

import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 350
  },
  avatar: {
    width: 45,
    height: 45
  }
});

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  merchantId: string;
  open: boolean;
  onChange?: (templateId: string) => void;
  onClose: () => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const RewardSelectDialog: React.FC<Props> = ({
  merchantId,
  onChange,
  onClose,
  open
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const templates = useSelector(listRewardTemplatesByMerchantId(merchantId));

  useEffect(() => {
    dispatch(
      rewardTemplateActions.getMerchantRewardTemplates(merchantId, {
        pagination: { page: 1, per: 100 },
        filters: {}
      })
    );
  }, [dispatch, merchantId]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleListItemClick = useCallback(
    (templateId: string) => {
      if (onChange) {
        onChange(templateId);
      }
      onClose();
    },
    [onChange, onClose]
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>Select Reward</DialogTitle>
      <List>
        {templates.map(template => (
          <ListItem
            button
            onClick={() => handleListItemClick(template.id)}
            key={template.id}
          >
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                className={classes.avatar}
                src={template.image ? template.image.versions['xl'] : undefined}
              />
            </ListItemAvatar>
            <ListItemText primary={template.title} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default RewardSelectDialog;

import { RootState } from '../reducers';
import { UsersState } from './reducer';
import { User, UserList, UserRow } from './models';

import pick from 'lodash/pick';
import values from 'lodash/values';
import { User as UserState } from '../../api/resources/user';
import orderBy from 'lodash/orderBy';

export const getUsersState = (state: RootState) => state.users;

//------------------------------------------------------------------------------

export const usersSelector = (state: RootState): UsersState => state.users;

export const getUserPage = (state: RootState): UserList => {
  const ids = getPagination(state).ids;
  const users = pick(usersSelector(state).byId, ids);

  return values(users).map(makeUserRow);
};

export const getUser = (state: RootState, id: string): User | null => {
  const user = usersSelector(state).byId[id];

  if (!user) {
    return null;
  }

  const {
    email,
    firstName,
    lastName,
    kycStatus,
    referralCode,
    status,
    gender,
    interests,
    createdAt,
    dob,
  } = user.attributes;

  return {
    id: user.id,
    initials: `${firstName[0]}${lastName[0]}`,
    name: `${firstName} ${lastName}`,
    email,
    kycStatus,
    status,
    referralCode,
    gender,
    interests: orderBy(interests, ['key'], ['asc']),
    createdAt,
    dob,
  };
};

export const getUserSelector = (id: string) => (
  state: RootState
): User | null => getUser(state, id);

export const isFetching = (state: RootState): boolean =>
  usersSelector(state).ui.isFetching;

export const getPagination = (state: RootState) =>
  usersSelector(state).pagination;

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeUserRow = (user: UserState): UserRow => ({
  id: user.id,
  name: `${user.attributes.firstName} ${user.attributes.lastName}`,
  email: user.attributes.email,
  kycStatus: user.attributes.kycStatus,
  status: user.attributes.status,
});

import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../redux/users/actions';
import * as userSelectors from '../../../redux/users/selectors';

import DataTable from '../../../components/DataTable';
import FeatureBar from '../../../components/FeatureBar';
import FilterBar from './FilterBar';
import { Content, Column, Grid } from '../../../components/Layout/Main';
import usePaginationQuery from '../../../hooks/usePaginationQuery';
import useSearchQuery from '../../../hooks/useSearchQuery';
import useFiltersQuery from '../../../hooks/useFiltersQuery';

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const columns = [
  { title: 'ID', field: 'id', width: '50px' },
  { title: 'Name', field: 'name' },
  { title: 'Email', field: 'email' },
  { title: 'Status', field: 'status' },
  { title: 'KYC Status', field: 'kycStatus', width: '15%' }
];

const List = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paginationQuery = usePaginationQuery();
  const searchQuery = useSearchQuery();
  const filtersQuery = useFiltersQuery();

  const isFetching = useSelector(userSelectors.isFetching);
  const users = useSelector(userSelectors.getUserPage);
  const pagination = useSelector(userSelectors.getPagination);

  // Load the users
  useEffect(() => {
    const { page, per } = paginationQuery;
    const { filters } = filtersQuery;

    dispatch(
      userActions.listUsers({
        pagination: { page, per },
        query: searchQuery.term,
        filters: filters
      })
    );
  }, [dispatch, paginationQuery, filtersQuery, searchQuery]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      paginationQuery.updatePage(page.toString());
    },
    [paginationQuery]
  );

  return (
    <Content>
      <FeatureBar>Users</FeatureBar>

      <Grid>
        <Column xs={12}>
          <FilterBar />
        </Column>

        <Column xs={12}>
          <DataTable
            columns={columns}
            data={users}
            isLoading={isFetching}
            onRowClick={row => history.push(`/users/${row[0].value}`)}
            PaginationProps={{
              count: pagination.total,
              rowsPerPageOptions: [10, 25, 50],
              rowsPerPage: pagination.per,
              page: pagination.page,
              onChangePage: handleChangePage,
              onChangeRowsPerPage: event => {
                paginationQuery.updatePer(event.target.value.toString());
              }
            }}
            SearchProps={{
              placeholder: 'Search by name and email',
              searchText: searchQuery.term,
              onSubmit: searchQuery.update
            }}
          />
        </Column>
      </Grid>
    </Content>
  );
};

export default List;

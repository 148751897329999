import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as merchantActions from '../../../redux/merchants/actions';
import * as merchantSelectors from '../../../redux/merchants/selectors';

import ButtonLink from '../../../components/ButtonLink';
import DataTable from '../../../components/DataTable';
import FeatureBar from '../../../components/FeatureBar';
import { Column, Content, Grid } from '../../../components/Layout/Main';
import usePaginationQuery from '../../../hooks/usePaginationQuery';
import useSearchQuery from '../../../hooks/useSearchQuery';

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const columns = [
  { title: 'ID', field: 'id', width: '50px' },
  { title: 'Name', field: 'name' },
  { title: 'Category', field: 'category.label' }
];

const List = () => {
  const history = useHistory();
  const searchQuery = useSearchQuery();
  const paginationQuery = usePaginationQuery();
  const dispatch = useDispatch();

  const isFetching = useSelector(merchantSelectors.isFetching);
  const merchants = useSelector(merchantSelectors.listMerchantsPage);
  const pagination = useSelector(merchantSelectors.getPagination);

  useEffect(() => {
    dispatch(
      merchantActions.listMerchants({
        pagination: { page: paginationQuery.page, per: paginationQuery.per },
        query: searchQuery.term
      })
    );
  }, [dispatch, searchQuery, paginationQuery]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      paginationQuery.updatePage(page.toString());
    },
    [paginationQuery]
  );

  return (
    <Content>
      <FeatureBar>Merchants</FeatureBar>

      <Grid>
        <Column xs={12}>
          <DataTable
            Actions={
              <ButtonLink
                to="/merchants/new"
                variant="contained"
                size="small"
                color="primary"
              >
                New Merchant
              </ButtonLink>
            }
            columns={columns}
            data={merchants}
            isLoading={isFetching}
            onRowClick={row => history.push(`/merchants/${row[0].value}`)}
            PaginationProps={{
              count: pagination.total,
              rowsPerPage: pagination.per,
              rowsPerPageOptions: [10, 25, 50],
              page: pagination.page,
              onChangePage: handleChangePage,
              onChangeRowsPerPage: event => {
                paginationQuery.updatePer(event.target.value.toString());
              }
            }}
            SearchProps={{
              placeholder: 'Search by name',
              searchText: searchQuery.term,
              onSubmit: searchQuery.update
            }}
          />
        </Column>
      </Grid>
    </Content>
  );
};

export default List;

import { RootState } from '../reducers';
import { ProfileState } from './reducer';
import { Profile } from './models';

import pick from 'lodash/pick';

export const profileSelector = (state: RootState): ProfileState =>
  state.profile;

export const getProfile = (state: RootState): Profile | null => {
  const profile = profileSelector(state);

  if (!profile) {
    return null;
  }

  return pick(profile.attributes, 'firstName', 'lastName', 'email');
};

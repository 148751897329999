import { createAction, createAsyncAction } from 'typesafe-actions';
import { StoreCollectionDoc, StoreDoc } from '../../api/resources/store';
import { PaginationOptions } from '../types';
import { APIError } from '../api/models';

/**
 * Gets a store by id
 */
export const getStore = createAction('stores/GET', (id: string) => id)();

/**
 * Gets the stores for a merchant
 */
export const getMerchantStores = createAction(
  'stores/LIST_BY_MERCHANT',
  (opts: { merchantId: string; pagination?: PaginationOptions }) => opts
)();

/**
 * Create a merchant store
 */
export const createStore = createAction(
  'stores/CREATE',
  (attrs: {
    address: string;
    lat: number;
    lng: number;
    merchantId: string;
    name: string;
    neighborhood: string;
    phone: string;
  }) => attrs
)();

/**
 * Update a merchant store
 */
export const updateStore = createAction(
  'stores/UPDATE',
  (attrs: {
    id: string;
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    name: string;
    neighborhood: string;
    phone: string;
  }) => attrs
)();

/**
 * Delete Store
 */
export const deleteStore = createAction(
  'store/DELETE',
  (storeId: string) => storeId
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchStore: createAsyncAction(
    'api/stores/GET_REQUEST',
    'api/stores/GET_SUCCESS',
    'api/stores/GET_FAILURE'
  )<any, StoreDoc, APIError>(),

  fetchMerchantStores: createAsyncAction(
    'api/stores/LIST_BY_MERCHANT_REQUEST',
    'api/stores/LIST_BY_MERCHANT_SUCCESS',
    'api/stores/LIST_BY_MERCHANT_FAILURE'
  )<any, StoreCollectionDoc, APIError>(),

  createStore: createAsyncAction(
    'api/stores/CREATE_REQUEST',
    'api/stores/CREATE_SUCCESS',
    'api/stores/CREATE_FAILURE'
  )<any, StoreDoc, APIError>(),

  updateStore: createAsyncAction(
    'api/stores/UPDATE_REQUEST',
    'api/stores/UPDATE_SUCCESS',
    'api/stores/UPDATE_FAILURE'
  )<any, StoreDoc, APIError>(),

  deleteStore: createAsyncAction(
    'api/stores/DELETE_REQUEST',
    'api/stores/DELETE_SUCCESS',
    'api/stores/DELETE_FAILURE'
  )<any, StoreDoc, APIError>(),
};

import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  title: {
    fontSize: '1rem'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  title?: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const FormSection: React.FC<Props> = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormSection;

import React, { useState, useEffect } from 'react';

import * as LoyaltyProgramService from '../../../../../../api/services/loyaltyProgramService';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

import EditMaxStampCampaignLimitDialog from './EditMaxStampCampaignLimitDialog';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  subheader: {
    color: theme.palette.common.black,
  },
  listItem: {
    minWidth: theme.spacing(4),
  },
  root: {
    marginTop: 10,
  },
}));

const useBlankSlateStyles = makeStyles((theme) => ({
  root: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const useCampaignStyles = makeStyles((theme) => ({
  subheader: {
    color: theme.palette.common.black,
  },
  reward: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  programId: string;
  merchantId: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function BlankSlate({ onAction }: { onAction: () => void }) {
  const classes = useBlankSlateStyles();

  return (
    <div className={classes.wrapper}>
      <Button variant="contained" color="primary" onClick={onAction}>
        Get Started
      </Button>
    </div>
  );
}

function ShowMaxStampCampaign({ stampLimit }: { stampLimit: number }) {
  const classes = useCampaignStyles();

  return (
    <div className={classes.reward}>
      <Typography>Max Stamp Campaigns: {stampLimit}</Typography>
    </div>
  );
}

export default function MaxStampCampaign({ merchantId }: Props) {
  const classes = useStyles();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [maxStampLimit, setMaxStampLimit] = useState<number>(2);

  // Close the create dialog
  const handleCloseEditDialog = () => {
    fetchStampCampaignLimit();
    setEditDialogOpen(false);
  };

  // Closes the menu
  const handleCloseMenu = () => setAnchorEl(null);

  // Opens the menu
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  // fetch max stamp campaign Limit
  const fetchStampCampaignLimit = async () => {
    setIsFetching(true);
    const res = await LoyaltyProgramService.getByMerchant(merchantId);
    if (res) {
      setMaxStampLimit(res.maxStampCampaigns);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchStampCampaignLimit = async () => {
      setIsFetching(true);
      const res = await LoyaltyProgramService.getByMerchant(merchantId);
      if (res && isMounted) {
        setMaxStampLimit(res.maxStampCampaigns);
      }
      setIsFetching(false);
    };

    fetchStampCampaignLimit();
    return () => {
      isMounted = false;
    };
  }, [merchantId]);

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          action={
            <div>
              <IconButton onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem onClick={() => setEditDialogOpen(true)}>
                  <ListItemIcon className={classes.listItem}>
                    <CreateIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Update Max Stamp Campaign Limit</Typography>
                </MenuItem>
              </Menu>
            </div>
          }
          subheader="Max Stamp Campaign Limit"
          classes={{
            subheader: classes.subheader,
          }}
        />

        <CardContent>
          {(() => {
            if (isFetching) {
              return <CircularProgress size={30} />;
            } else {
              return maxStampLimit >= 0 ? (
                <ShowMaxStampCampaign stampLimit={maxStampLimit} />
              ) : (
                <BlankSlate onAction={() => setEditDialogOpen(true)} />
              );
            }
          })()}
        </CardContent>
      </Card>

      <EditMaxStampCampaignLimitDialog
        merchantId={merchantId}
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        onExit={handleCloseMenu}
        stampLimit={maxStampLimit ? maxStampLimit : 0}
      />
    </div>
  );
}

import React from 'react';

import { RedemptionsByMerchant } from '../../redux/analytics/models';

import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Card from './Card';
import Link from '../../components/Link';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    padding: `${theme.spacing(1)}px 0`
  },
  label: {
    flex: 1
  },
  count: {
    fontWeight: 'bold'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  redemptions: RedemptionsByMerchant;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const MerchantRedemptionsCard: React.FC<Props> = ({ redemptions }) => {
  const classes = useStyles();

  return (
    <Card label="Merchant Redemptions">
      {redemptions.map(r => (
        <React.Fragment key={r.id}>
          <div className={classes.row}>
            <Link
              to={`/merchants/${r.id}`}
              variant="body2"
              className={classes.label}
            >
              {r.name}
            </Link>

            <Typography variant="body2" className={classes.count}>
              {r.total}
            </Typography>
          </div>

          <Divider />
        </React.Fragment>
      ))}
    </Card>
  );
};

export default MerchantRedemptionsCard;

import { createAction, createAsyncAction } from 'typesafe-actions';
import { APIError } from '../api/models';
import { DashboardAnalyticsDoc } from '../../api/resources/analytics';

/**
 * getDashboardAnalytics gets the dashboard analytics from the api.
 */
export const getDashboardAnalytics = createAction('analytics/GET_DASHBOARD')();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchDashboardAnalytics: createAsyncAction(
    'api/analytics/GET_DASHBOARD_REQUEST',
    'api/analytics/GET_DASHBOARD_SUCCESS',
    'api/analytics/GET_DASHBOARD_FAILURE'
  )<any, DashboardAnalyticsDoc, APIError>()
};

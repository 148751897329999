import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import orderBy from 'lodash/orderBy';

import * as locationActions from './actions';
import { LocationState } from './models';

export type LocationActions = ActionType<typeof locationActions>;

/**
 *
 */
interface ByUserIdState {
  [key: string]: LocationState;
}

const byUserId = createReducer<ByUserIdState, LocationActions>({})
  .handleAction(
    locationActions.api.fetchUsersLocations.success,
    (state, action) => {
      const { data } = action.payload;

      // Get the latest location
      const location = orderBy(data, ['createdAt'], ['desc'])[0];
      const userId = location.relationships.user.data.id;

      // Convert the location into a location state.
      const locState = {
        id: location.id,
        coords: location.attributes.coords,
        createdAt: location.attributes.createdAt,
        userId: userId
      };

      return {
        ...state,
        ...{ [userId]: locState }
      };
    }
  )
  .handleAction(locationActions.receivedLocationAddress, (state, action) => ({
    ...state,
    ...{
      [`${action.payload.id}`]: {
        ...state[action.payload.id],
        address: action.payload.address
      }
    }
  }));

const locationsReducer = combineReducers({
  byUserId
});

export default locationsReducer;
export type LocationsState = ReturnType<typeof locationsReducer>;

import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

const list = (opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/users?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true
});

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/users/${id}`,
  method: 'GET',
  authenticated: true
});

export default {
  get,
  list
};

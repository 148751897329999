import { SagaIterator } from 'redux-saga';
import { all, takeLatest, takeLeading, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { api, approveUserKYC, getUserKYC, rejectUserKYC } from './actions';
import { callAPI } from '../api/actions';
import { notify } from '../notifications/actions';
import * as apiRequests from '../../api/requests';

export function* getUserKYCSaga(
  action: ReturnType<typeof getUserKYC>
): SagaIterator {
  yield put(
    callAPI(apiRequests.kyc.getUserKYC(action.payload), api.fetchUserKYC)
  );
}

/**
 * Verify the user's KYC doc
 *
 * @param action
 */
export function* verifyUserKYCSaga(
  action: ReturnType<typeof approveUserKYC>
): Generator {
  const userId = action.payload;

  yield put(
    callAPI(apiRequests.kyc.approveUserKYC(userId), api.approveUserKYC)
  );
}

/**
 * Alert the user the KYC has been approved.
 *
 * * @param _
 */
export function* approveUserKYCSuccessSaga(
  _: ReturnType<typeof api.approveUserKYC.success>
): SagaIterator {
  yield put(notify('KYC Approved'));
}

/**
 *
 * @param action
 */
export function* rejectUserKYCSaga(
  action: ReturnType<typeof rejectUserKYC>
): SagaIterator {
  const userId = action.payload;

  yield put(callAPI(apiRequests.kyc.rejectUserKYC(userId), api.rejectUserKYC));
}

/**
 * Alert the user the KYC has been rejected.
 *
 * @param _
 */
export function* rejectUserKYCSuccessSaga(
  _: ReturnType<typeof api.rejectUserKYC.success>
): SagaIterator {
  yield put(notify('KYC Rejected'));
}

export default all([
  takeLatest(getType(getUserKYC), getUserKYCSaga),
  takeLeading(getType(approveUserKYC), verifyUserKYCSaga),
  takeLeading(getType(api.approveUserKYC.success), approveUserKYCSuccessSaga),
  takeLeading(getType(rejectUserKYC), rejectUserKYCSaga),
  takeLeading(getType(api.rejectUserKYC.success), rejectUserKYCSuccessSaga)
]);

import React from 'react';
import capitalize from 'lodash/capitalize';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DefinitionListItem from '../../../../../components/DefinitionListItem';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  minAge: number;
  maxAge: number;
  gender: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  contentTitle: {
    fontSize: '1rem',
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const TargetingCard: React.FC<Props> = ({ gender, minAge, maxAge }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" className={classes.contentTitle}>
          Targeting
        </Typography>

        <List>
          <DefinitionListItem
            title="Age Range"
            value={`${minAge} - ${maxAge}`}
          />
          <DefinitionListItem title="Gender" value={capitalize(gender)} />
        </List>
      </CardContent>
    </Card>
  );
};

export default TargetingCard;

import { RootState } from '../reducers';
import { Store } from './models';

import maxBy from 'lodash/maxBy';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { Store as StoreState } from '../../api/resources/store';

/**
 * Get the store state
 *
 * @param state
 */
export const getStoreState = (state: RootState) => state.stores;

/**
 * Get a store
 *
 * @param state
 * @param id
 */
export const getStore = (state: RootState, id: string) => {
  const store = getStoreState(state).byId[id];

  if (!store) {
    return undefined;
  }

  return makeStore(state)(store);
};

export const getStoreSelector = (id: string) => (state: RootState) => {
  const store = getStoreState(state).byId[id];

  if (!store) {
    return null;
  }

  return makeStore(state)(store);
};

/**
 * Get merchant's store id
 *
 * @param state
 * @param merchantId
 */
export const getMerchantStoreIds = (state: RootState, merchantId: string) =>
  getStoreState(state).merchants[merchantId] || [];

/**
 * Get list of stores based on Merchant id
 *
 * @param state
 * @param merchantId
 */
export const listStoresByMerchantId =
  (merchantId: string) => (state: RootState) => {
    const allStores = getStoreState(state).byId;
    const ids = getMerchantStoreIds(state, merchantId);
    const stores = pick(allStores, ids);

    return values(stores).map(makeStore(state));
  };

/**
 * Are reward cards being fetched?
 * @param state
 */
export const isFetching = (state: RootState) =>
  getStoreState(state).ui.isFetching;

/**
 * Get the pagination data
 *
 * @param state
 */
export const getPagination = (state: RootState) =>
  getStoreState(state).pagination;

/**
 * Get merchant store count
 *
 * @param state
 * @param merchantId
 */
export const getMerchantStoreCount = (
  state: RootState,
  merchantId: string
): number => getMerchantStoreIds(state, merchantId).length;

/**
 * Get latest merchant store
 *
 * @param state
 * @param merchantId
 */
export const getLatestMerchantStore = (
  state: RootState,
  merchantId: string
) => {
  const latestStoreId = maxBy(getMerchantStoreIds(state, merchantId), (m) =>
    parseInt(m, 10)
  );

  return latestStoreId ? getStore(state, latestStoreId) : undefined;
};

/**
 * Get the first merchant store selector
 *
 * @param state
 * @param merchantId
 */
export const getFirstMerchantStore =
  (merchantId: string) => (state: RootState) => {
    const storeId = getMerchantStoreIds(state, merchantId)[0];

    return storeId ? getStore(state, storeId) : undefined;
  };

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeStore =
  (state: RootState) =>
  (store: StoreState): Store => {
    const { id, attributes } = store;
    const { address, name, phone, pin, coords, neighborhood } = attributes;
    const { lat, lng } = coords;

    return {
      id,
      name,
      address,
      phone,
      pin,
      lat,
      lng,
      neighborhood,
      storeId: id,
    };
  };

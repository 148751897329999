import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getMerchantSelector } from '../../../../../redux/merchants/selectors';
import * as MerchantService from '../../../../../api/services/merchantService';

import { Column, Grid } from '../../../../../components/Layout/Main';
import MerchantDetails from './MerchantDetails';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const TabOverview: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const merchant = useSelector(getMerchantSelector(id));
  const [merchantDetail, setMerchantDetail] = useState<
    MerchantService.MerchantProfile | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);

  // fetch merchant info
  useEffect(() => {
    const fetchMerchantDetail = async () => {
      if (merchant) {
        const res = await MerchantService.getMerchantDetail(merchant.id);

        if (res) {
          setMerchantDetail(res);
          setIsLoading(false);
        }
      }
    };

    if (isLoading) {
      fetchMerchantDetail();
    }
  }, [merchant, isLoading]);

  if (!merchant) {
    return null;
  }

  return (
    <Grid>
      <Column xs={12} sm={4}>
        <MerchantDetails merchant={merchant} merchantDetail={merchantDetail} />
      </Column>
    </Grid>
  );
};

export default TabOverview;

import React, { useEffect, useMemo } from 'react';
import {
  Link as RouterLink,
  RouteComponentProps,
  Route,
  Switch
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../redux/users/actions';
import * as locationActions from '../../../redux/locations/actions';
import { getUserSelector } from '../../../redux/users/selectors';
import { getUserMostRecentAddressSelector } from '../../../redux/locations/selectors';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FeatureBar from '../../../components/FeatureBar';
import { Content, Column, Grid } from '../../../components/Layout/Main';
import TabRewardCards from './Tabs/TabRewardCards';
import UserDetails from './UserDetails';
import UserInterests from './UserInterests';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'none'
  },
  tabsRoot: {
    height: 3
  },
  tabsIndicator: {
    backgroundColor: theme.palette.common.white
  }
}));

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const Show: React.FC<Props> = ({
  match: {
    url,
    params: { id }
  },
  location
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tabPaths = useMemo(
    () => ({
      overview: `${url}`,
      rewards: `${url}/rewards`
    }),
    [url]
  );

  const user = useSelector(getUserSelector(id));
  const address = useSelector(getUserMostRecentAddressSelector(id));

  // Load the user
  useEffect(() => {
    dispatch(userActions.getUser(id));
  }, [dispatch, id]);

  // Load the user locations
  useEffect(() => {
    dispatch(locationActions.getUserLocations(id));
  }, [dispatch, id]);

  return (
    <Content>
      <FeatureBar backEnabled>{user && user.name}</FeatureBar>

      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={location.pathname}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            component={RouterLink}
            label="Overview"
            value={tabPaths.overview}
            to={tabPaths.overview}
          />
          <Tab
            component={RouterLink}
            label="Reward Cards"
            value={tabPaths.rewards}
            to={tabPaths.rewards}
          />
        </Tabs>
      </AppBar>

      <Switch>
        <Route path={tabPaths.rewards}>
          <TabRewardCards userId={id} />
        </Route>

        <Route path={tabPaths.overview}>
          {user && (
            <Grid>
              <Column xs={12} sm={4}>
                <UserDetails user={user} address={address} />
              </Column>
              <Column xs={12} sm={4}>
                <UserInterests interests={user.interests} />
              </Column>
            </Grid>
          )}
        </Route>
      </Switch>
    </Content>
  );
};

export default Show;

import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    height: 56,
    minHeight: 56,
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius
  },
  isSearching: {
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0 1px 2px 0 rgba(60,64,67,.30), 0 2px 6px 2px rgba(60,64,67,.15)',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    '& div$actions, & div$spacer': {
      display: 'none'
    }
  },
  spacer: {
    flex: 1
  },
  title: {
    fontSize: theme.typography.pxToRem(16)
  },
  form: {
    flex: 1
  },
  searchIcon: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.54)'
  },
  searchField: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14)
  },
  actions: {
    marginLeft: 'auto'
  },
  chip: {
    marginLeft: theme.spacing(2),

    '&:focus': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export interface SearchProps {
  placeholder?: string;
  searchText?: string;
  onSubmit?: (value: string) => void;
}

export type TableToolbarProps = {
  title?: string;
  Actions?: React.ReactNode;
  SearchProps?: SearchProps;
};

const TableToolbar: React.FC<TableToolbarProps> = ({
  Actions,
  title,
  SearchProps
}) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    (SearchProps && SearchProps.searchText) || ''
  );

  const rootClasses = [classes.root];
  if (isSearching) {
    rootClasses.push(classes.isSearching);
  }

  return (
    <Toolbar className={classNames(...rootClasses)}>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}

      {SearchProps && (
        <div className={classes.searchField}>
          <SearchIcon className={classes.searchIcon} />

          {!isSearching && searchTerm !== '' ? (
            <Chip
              label={searchTerm}
              color="primary"
              clickable={false}
              className={classes.chip}
              onDelete={() => {
                setSearchTerm('');

                if (SearchProps.onSubmit) {
                  SearchProps.onSubmit('');
                }
              }}
            />
          ) : (
            <form
              className={classes.form}
              onSubmit={e => {
                e.preventDefault();
                if (inputRef.current) {
                  inputRef.current.blur();
                }
                setIsSearching(false);
              }}
            >
              <Input
                inputRef={inputRef}
                className={classes.searchInput}
                placeholder={SearchProps.placeholder}
                disableUnderline
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={() => setIsSearching(true)}
                onBlur={() => {
                  if (SearchProps.onSubmit) {
                    SearchProps.onSubmit(searchTerm);
                  }

                  setIsSearching(false);
                }}
                value={searchTerm}
                fullWidth
              />
            </form>
          )}
        </div>
      )}

      <div className={classes.actions}>{Actions}</div>
    </Toolbar>
  );
};

export default TableToolbar;

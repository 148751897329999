import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type Reward = {
  id: string;
  title: string;
  info: string;
  what: string;
  gift: boolean;
  sell: boolean;
  createdAt: number;
  updatedAt: number;
  images: {
    type: 'images';
    id: string;
  };
};

export type RewardList = {
  rewards: Reward[];
  pagination: PaginationResult;
};

export function listByIds(ids: string[]) {
  const opts: ListOptions = {
    pagination: { page: 1, per: ids.length },
    filters: { id: ids },
  };

  return request<RewardList>({
    url: `/admin/api/v2/rewards?${toListQueryString(opts)}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        rewards: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

import { useEffect, useState } from 'react';

import { matchPath, RouteProps, useLocation } from 'react-router-dom';

type MatchPathProps = string | string[] | RouteProps;
type TabMatcher = MatchPathProps[] | string;
type TabMatchers = TabMatcher[];

export default function useTabSelector(matchers: TabMatchers) {
  const { pathname } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  // Update the selected tab
  useEffect(() => {
    matchers.forEach((paths, idx) => {
      // Handle if the path matcher is just a string
      if (typeof paths === 'string') {
        if (matchPath(pathname, paths)) {
          setSelectedIndex(idx);
        }

        return;
      }

      // Handle many paths matching the selected tab
      paths.forEach(path => {
        if (matchPath(pathname, path)) {
          setSelectedIndex(idx);

          return;
        }
      });
    });
  }, [matchers, pathname]);

  return selectedIndex;
}

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

// Redux Thunk
import thunk from 'redux-thunk';

// Redux Saga
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';

// Redux Promise Listener
import createReduxPromiseListener from 'redux-promise-listener';

import { apiMiddleware } from './api/middleware';
import deferredMiddleware from './middleware';

const sagaMiddleware = createSagaMiddleware();
const reduxPromiseListener = createReduxPromiseListener();

const store = createStore(
  rootReducer /* preloadedState */,
  composeWithDevTools(
    applyMiddleware(
      apiMiddleware,
      reduxPromiseListener.middleware,
      deferredMiddleware,
      thunk,
      sagaMiddleware
    )
  )
);
export const promiseListener = reduxPromiseListener;

sagaMiddleware.run(rootSaga);

export default store;

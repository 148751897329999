import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Interest } from '../../../redux/users/models';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  cardHeader: {
    paddingBottom: 0
  },
  avatarIcon: {}
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  interests: Interest[];
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const UserInterests: React.FC<Props> = ({ interests }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <FavoriteIcon />
          </Avatar>
        }
        title="Interests"
        className={classes.cardHeader}
      />
      <CardContent>
        <List dense>
          {interests.map(interest => (
            <ListItem key={interest.key}>
              <ListItemText
                secondary={
                  <Typography variant="body2">{interest.name}</Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default UserInterests;

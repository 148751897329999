import { createAction, createAsyncAction } from 'typesafe-actions';
import { PaginationOptions } from '../types';
import {
  WelcomeCampaignDoc,
  WelcomeCampaignCollectionDoc,
} from '../../api/resources/welcomeCampaign';
import { APIError } from '../api/models';

/**
 * Creates a welcome campaign
 */
export const createWelcomeCampaign = createAction(
  'welcome_campaigns/CREATE',
  (attrs: {
    rewardTemplateId: string;
    loyaltyProgramId: string;
    validityPeriodDays: number;
    delayHours: number;
  }) => attrs
)();

/**
 * Gets a program's welcome campaign
 */
export const getLoyaltyProgramWelcomeCampaign = createAction(
  'welcome_campaigns/GET_BY_PROGRAM_ID',
  (programId: string) => programId
)();

/**
 * Lists a loyalty program's welcome campaign
 */
export const listLoyaltyProgramWelcomeCampaign = createAction(
  'welcome_campaigns/LIST_BY_PROGRAM_ID',
  (programId: string, opts: { pagination: PaginationOptions }) => ({
    programId,
    pagination: opts.pagination,
  })
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  createWelcomeCampaign: createAsyncAction(
    'api/welcome_campaigns/CREATE_REQUEST',
    'api/welcome_campaigns/CREATE_SUCCESS',
    'api/welcome_campaigns/CREATE_FAILURE'
  )<any, WelcomeCampaignDoc, APIError>(),

  getLoyaltyProgramWelcomeCampaign: createAsyncAction(
    'api/welcome_campaigns/GET_BY_PROGRAM_ID_REQUEST',
    'api/welcome_campaigns/GET_BY_PROGRAM_ID_SUCCESS',
    'api/welcome_campaigns/GET_BY_PROGRAM_ID_FAILURE'
  )<any, WelcomeCampaignDoc, APIError>(),

  listLoyaltyProgramWelcomeCampaign: createAsyncAction(
    'api/welcome_campaigns/LIST_BY_PROGRAM_ID_REQUEST',
    'api/welcome_campaigns/LIST_BY_PROGRAM_ID_SUCCESS',
    'api/welcome_campaigns/LIST_BY_PROGRAM_ID_FAILURE'
  )<any, WelcomeCampaignCollectionDoc, APIError>(),
};

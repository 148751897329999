import { LoyaltyProgram } from './models';
import { RootState } from '../reducers';
import { LoyaltyProgramResource } from '../../api/resources/loyaltyProgram';

/**
 * Gets the loyalty program state
 *
 * @param state
 */
export const getLoyaltyProgramsState = (state: RootState) =>
  state.loyaltyPrograms;

/**
 * Gets a merchant's loyalty program.
 *
 * @param state
 * @param id
 */
export const getMerchantLoyaltyProgram = (merchantId: string) => (
  state: RootState
) => {
  const id = getLoyaltyProgramsState(state).merchantRelationships[merchantId];

  if (!id) {
    return undefined;
  }

  const program = getLoyaltyProgramsState(state).byId[id];

  if (!program) {
    return undefined;
  }

  return makeLoyaltyProgram(program);
};

/**
 * Is a loyalty program being fetched?
 *
 * @param state
 */
export const isFetching = (state: RootState) =>
  getLoyaltyProgramsState(state).ui.isFetching;

/**
 *
 */
const makeLoyaltyProgram = (
  program: LoyaltyProgramResource
): LoyaltyProgram => ({
  id: program.id,
  ...program.attributes
});

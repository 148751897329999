import React from 'react';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Clock, MobileTick, AngleRight } from '../Icon';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  expiresAt: moment.Moment;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginBottom: 8,
    boxShadow: '0 2px 4px rgba(211, 211, 211, 0.5)',
    elevation: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 12,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderTopWidth: 1,
    borderTopColor: '#f4f5f8',
    opacity: 1,
    borderBottom: '1px solid #f4f5f8',
  },
  text: {
    fontSize: 14,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 400,
    fontStyle: 'normal',
    marginLeft: 8,
    color: '#383838',
    flex: 1,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const ValiditySection: React.FC<Props> = ({ expiresAt }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Clock height="22px" width="22px" color="#EA0043" />
        <Typography className={classes.text}>
          Valid till {expiresAt.format('D MMMM YYYY')}
        </Typography>
      </div>

      <div className={classes.container}>
        <MobileTick height="22px" width="22px" color="#EA0043" />
        <Typography className={classes.text}>
          You can easily redeem from your phone
        </Typography>
        <AngleRight height="16px" width="16px" color="#C0C0C0" />
      </div>
    </div>
  );
};

export default ValiditySection;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as couponSetActions from '../../redux/couponSets/actions';
import { listRecentlyCreatedCouponSet } from '../../redux/couponSets/selectors';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CouponSetDownloadButton from '../CouponSetDownloadButton';
import { Columns } from '../DataTable';
import { datetimeFormat } from '../../lib/date';
import Link from '../Link';
import NewCouponSetDialog from './NewCouponSetDialog';
import PlainTable from '../DataTable/PlainTable';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  campaignId: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
  },
  viewAll: {
    borderTop: '1px solid #ccc',
    padding: theme.spacing(1.5),
    textAlign: 'center',
  },
  blankSlate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(4)}px 0`,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const columns: Columns = [
  {
    title: 'Label',
    field: 'label',
  },
  { title: 'No. of Coupons', field: 'couponsCount' },
  {
    title: 'Created',
    field: 'createdAt',
    format: (value: number) => datetimeFormat(value),
  },
  {
    title: '',
    field: 'id',
    align: 'right',
    format: (value: any) => <CouponSetDownloadButton id={value} />,
  },
];

export default function TabCouponSets({ campaignId }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pathname } = useLocation();

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const couponSets = useSelector(listRecentlyCreatedCouponSet(campaignId));

  useEffect(() => {
    dispatch(
      couponSetActions.listLoyaltyPackageCouponSet(campaignId, {
        pagination: { page: 1, per: 5 },
      })
    );
  }, [dispatch, campaignId]);

  const handleCloseCreateDialog = () => setCreateDialogOpen(false);

  const renderBlankSlate = () => (
    <div className={classes.blankSlate}>
      <Typography variant="h6" gutterBottom>
        You have no coupon sets
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setCreateDialogOpen(true)}
      >
        Get Started
      </Button>
    </div>
  );

  const renderTable = () => (
    <PlainTable
      columns={columns}
      data={couponSets}
      footer={
        <div className={classes.viewAll}>
          <Link to={`${pathname}/${campaignId}/coupon_sets`}>
            View all coupon sets &rarr;
          </Link>
        </div>
      }
      Actions={
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setCreateDialogOpen(true)}
        >
          New Coupon Set
        </Button>
      }
    />
  );

  return (
    <div className={classes.root}>
      {couponSets.length === 0 ? renderBlankSlate() : renderTable()}

      <NewCouponSetDialog
        campaignId={campaignId}
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
      />
    </div>
  );
}

import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as analyticsActions from './actions';
import { DashboardAnalytics } from '../../api/resources';

export type AnalyticsActions = ActionType<typeof analyticsActions>;

const dashboard = createReducer<DashboardAnalytics, AnalyticsActions>({
  type: 'dashboard_analytics',
  id: 'dashboard_analytics',
  attributes: {
    demographics: {
      signup: {
        total: 0,
        today: 0
      },
      gender: {
        male: 0,
        female: 0,
        unknown: 0
      }
    },
    redemptions: {
      total: 0,
      today: 0,
      byMerchant: []
    }
  }
}).handleAction(
  analyticsActions.api.fetchDashboardAnalytics.success,
  (_, action) => {
    return action.payload.data;
  }
);

/**
 * ui
 */

interface AnalyticsUI {
  isFetchingDashboard: boolean;
}
const ui = createReducer<AnalyticsUI, AnalyticsActions>({
  isFetchingDashboard: false
})
  .handleAction(
    [analyticsActions.api.fetchDashboardAnalytics.request],
    state => ({
      ...state,
      isFetchingDashboard: true
    })
  )
  .handleAction(
    [
      analyticsActions.api.fetchDashboardAnalytics.success,
      analyticsActions.api.fetchDashboardAnalytics.failure
    ],
    state => ({
      ...state,
      isFetchingDashboard: false
    })
  );

const analyticsReducer = combineReducers({
  dashboard,
  ui
});

export default analyticsReducer;
export type AnalyticsState = ReturnType<typeof analyticsReducer>;

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import * as loyaltyProgramActions from '../../../../../../../redux/loyaltyPrograms/actions';
import { getMerchantLoyaltyProgram } from '../../../../../../../redux/loyaltyPrograms/selectors';
import { getMerchantSelector } from '../../../../../../../redux/merchants/selectors';

import { Column, Grid } from '../../../../../../../components/Layout/Main';
import MembersTable from './MembersTable';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function Memberships() {
  const dispatch = useDispatch();
  const { id: merchantId } = useParams<RouteParams>();
  const history = useHistory();

  const program = useSelector(getMerchantLoyaltyProgram(merchantId));
  const merchant = useSelector(getMerchantSelector(merchantId));

  useEffect(() => {
    dispatch(loyaltyProgramActions.getMerchantLoyaltyProgram(merchantId));
  }, [dispatch, merchantId]);

  const handleRowClick = useCallback(
    (userId: string) => {
      history.push(`/users/${userId}`);
    },
    [history]
  );

  if (!program) {
    return null;
  }

  if (!merchant) {
    return null;
  }

  return (
    <Grid>
      <Column xs={12} sm={12}>
        <MembersTable
          merchantId={merchant.id}
          programId={program.id}
          handleRowClick={handleRowClick}
          merchantName={merchant.name.replace(/\s+/g, '-')}
        />
      </Column>
    </Grid>
  );
}

import React, { useEffect, useCallback, useState } from 'react';

import DataTable from '../../../../../components/DataTable';
import FilterBar from './FilterBar';
import { Column, Grid } from '../../../../../components/Layout/Main';
import { PaginationResult } from '../../../../../api/services/request';
import * as RewardCardService from '../../../../../api/services/rewardCardService';
import useFiltersQuery from '../../../../../hooks/useFiltersQuery';
import usePaginationQuery from '../../../../../hooks/usePaginationQuery';
import { dateFormat } from '../../../../../lib/date';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  userId: string;
};

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const columns = [
  {
    title: 'ID',
    field: 'id',
    width: '50px',
  },
  { title: 'Title', field: 'title' },
  { title: 'Status', field: 'status' },
  {
    title: 'Campaign',
    field: 'campaign.type',
    format: (value: any) => {
      switch (value) {
        case 'welcome_campaigns':
          return 'Welcome';
        case 'airdrop_campaigns':
          return 'Airdrop';
        case 'loyalty_campaigns':
          return 'Stamp ';
        case 'birthday_campaigns':
          return 'Birthday ';
        default:
          return 'Unknown';
      }
    },
  },
  { title: 'Received At', field: 'receivedAt', format: dateFormat },
  { title: 'Expires At', field: 'expiresAt', format: dateFormat },
  { title: 'Redeemed At', field: 'redeemedAt', format: dateFormat },
];

export default function TabUserRewards({ userId }: Props) {
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const { filters } = useFiltersQuery();
  const [cards, setCards] = useState<RewardCardService.RewardCard[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );

  // Fetch the cards
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        setIsFetching(true);

        const res = await RewardCardService.listByOwner(userId, {
          pagination: { page, per },
          filters: filters,
        });

        if (isMounted) {
          setCards(res.cards);
          setPagination(res.pagination);
          setIsFetching(false);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [userId, page, per, filters]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  return (
    <Grid>
      <Column xs={12}>
        <FilterBar />
      </Column>

      <Column xs={12} sm={12}>
        <DataTable
          title="Reward Cards"
          isLoading={isFetching}
          columns={columns}
          data={cards}
          PaginationProps={
            pagination
              ? {
                  count: pagination.total,
                  rowsPerPage: pagination.per,
                  rowsPerPageOptions: [10, 25, 50],
                  page: pagination.page,
                  onChangePage: handleChangePage,
                  onChangeRowsPerPage: (event) => {
                    updatePer(event.target.value.toString());
                  },
                }
              : undefined
          }
        />
      </Column>
    </Grid>
  );
}

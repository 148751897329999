import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { api, getReward, listRewards } from './actions';
import { api as birthdayCampaignApi } from '../birthdayCampaigns/actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

/**
 *
 * @param action
 */
export function* fetchRewardSaga(
  action: ReturnType<typeof getReward>
): SagaIterator {
  yield put(callAPI(apiRequests.rewards.get(action.payload), api.fetchReward));
}

/**
 * Fetch all the rewards matching the filters. Supports pagination and
 * ordering.
 *
 * @param action
 */
export function* listRewardsSaga(
  action: ReturnType<typeof listRewards>
): SagaIterator {
  const {
    pagination: { page, per },
    filters,
  } = action.payload;

  yield put(
    callAPI(
      apiRequests.rewards.list({ pagination: { page, per }, filters }),
      api.listRewards
    )
  );
}

function* getAssociatedBirthdayCampaignRewardSaga(
  action: ReturnType<
    typeof birthdayCampaignApi.getLoyaltyProgramBirthdayCampaign.success
  >
): SagaIterator {
  const { data } = action.payload;

  const rewardId = data.relationships.reward.data.id;

  yield put(callAPI(apiRequests.rewards.get(rewardId), api.fetchReward));
}

export default all([
  takeLatest(getType(listRewards), listRewardsSaga),
  takeLatest(getType(getReward), fetchRewardSaga),
  takeEvery(
    getType(birthdayCampaignApi.getLoyaltyProgramBirthdayCampaign.success),
    getAssociatedBirthdayCampaignRewardSaga
  ),
]);

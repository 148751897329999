import { ApiRequest } from './request';

const listByCampaignId = (id: string): ApiRequest => ({
  path: `/admin/api/v2/campaigns/${id}/drop_zones`,
  method: 'GET',
  authenticated: true
});

interface CreateAttrs {
  lat: number;
  lng: number;
  radius: number;
  campaignId: string;
}
const create = ({ lat, lng, radius, campaignId }: CreateAttrs): ApiRequest => ({
  path: `/admin/api/v2/drop_zones`,
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'drop_zones',
      attributes: {
        coords: {
          lat,
          lng
        },
        radius
      },
      relationships: {
        campaign: {
          data: {
            type: 'campaigns',
            id: campaignId
          }
        }
      }
    }
  }
});

export default {
  create,
  listByCampaignId
};

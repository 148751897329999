import produce from 'immer';
import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as rewardActions from '../rewards/actions';
import { RewardResource } from '../../api/resources';

export type RewardActions = ActionType<typeof rewardActions>;

/**
 * byId contains all the store objects
 */
interface ByIdState {
  [key: string]: RewardResource;
}

const byId = createReducer<ByIdState, RewardActions>({}).handleAction(
  [
    rewardActions.api.fetchReward.success,
    rewardActions.api.listRewards.success,
  ],
  (state, action) => {
    const { rewards } = normalize(action.payload);

    return rewards ? { ...state, ...rewards } : state;
  }
);

/**
 * ui
 */
interface UIState {
  isFetching: boolean;
}

const ui = createReducer<UIState, RewardActions>({
  isFetching: false,
})
  .handleAction(
    [
      rewardActions.api.fetchReward.success,
      rewardActions.api.fetchReward.failure,
      rewardActions.api.listRewards.success,
      rewardActions.api.listRewards.failure,
    ],
    (state) => {
      return produce(state, (draft) => {
        draft.isFetching = false;
      });
    }
  )
  .handleAction(
    [
      rewardActions.api.fetchReward.request,
      rewardActions.api.listRewards.request,
    ],
    (state) => {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }
  );

/**
 * rewards root reducer
 */
const rewardsReducer = combineReducers({
  byId,
  ui,
});

export default rewardsReducer;
export type RewardsState = ReturnType<typeof rewardsReducer>;

import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/rewards/${id}`,
  method: 'GET',
  authenticated: true,
});

const list = (opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/rewards?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true,
});

export default {
  get,
  list,
};

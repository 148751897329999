import { createAction, createAsyncAction } from 'typesafe-actions';
import { PaginationOptions } from '../types';

import {
  ImageDoc,
  StockImageDoc,
  StockImageCollectionDoc,
} from '../../api/resources/image';
import { APIError } from '../api/models';

/**
 * Creates an image by uploading it to the server
 */
export const createImage = createAction(
  'images/CREATE',
  (attrs: { data: string }) => attrs
)();

/**
 * Creates an stock image by uploading it to the server
 */
export const createStockImage = createAction(
  'stock_images/CREATE',
  (attrs: { data: string }) => attrs
)();

/**
 * List Stock Images
 */
export const listStockImages = createAction(
  'stock_images/LIST',
  (opts: { pagination: PaginationOptions }) => opts
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  createImage: createAsyncAction(
    'api/images/CREATE_REQUEST',
    'api/images/CREATE_SUCCESS',
    'api/images/CREATE_FAILURE'
  )<any, ImageDoc, APIError>(),

  createStockImage: createAsyncAction(
    'api/stock_images/CREATE_REQUEST',
    'api/stock_images/CREATE_SUCCESS',
    'api/stock_images/CREATE_FAILURE'
  )<any, StockImageDoc, APIError>(),

  fetchStockImageList: createAsyncAction(
    'api/stock_images/FETCH_LIST_REQUEST',
    'api/stock_images/FETCH_LIST_SUCCESS',
    'api/stock_images/FETCH_LIST_FAILURE'
  )<any, StockImageCollectionDoc, APIError>(),
};

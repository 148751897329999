import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import ButtonLink from '../../../../../../../components/ButtonLink';
import { dateFormat } from '../../../../../../../lib/date';
import DataTable, {
  ActionIconButton,
  Columns,
} from '../../../../../../../components/DataTable';
import * as LoyaltyProgramService from '../../../../../../../api/services/loyaltyProgramService';
import * as StampCampaignService from '../../../../../../../api/services/stampCampaignService';
import { Column, Grid } from '../../../../../../../components/Layout/Main';
import { PaginationResult } from '../../../../../../../api/services/request';
import usePaginationQuery from '../../../../../../../hooks/usePaginationQuery';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function List() {
  const history = useHistory();
  const { id: merchantId } = useParams<RouteParams>();
  const { url } = useRouteMatch();
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const [campaigns, setCampaigns] = useState<
    StampCampaignService.StampCampaign[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );
  const [program, setProgram] = useState<
    LoyaltyProgramService.LoyaltyProgram | undefined
  >(undefined);

  // Activate the campaign
  const activateCampaign = async (campaignId: string) => {
    try {
      await StampCampaignService.activate(campaignId, false);

      history.push(`/merchants/${merchantId}/loyalty`);
    } catch (e) {
      alert('error');
    }
  };

  // Fetch the program
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      setIsFetching(true);

      try {
        const res = await LoyaltyProgramService.getByMerchant(merchantId);

        if (isMounted) {
          setProgram(res);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [merchantId]);

  // Fetch the campaigns
  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      if (program) {
        setIsFetching(true);

        try {
          const res = await StampCampaignService.listByProgram(program.id, {
            pagination: { page, per },
          });

          if (isMounted) {
            setCampaigns(res.campaigns);
            setPagination(res.pagination);
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsFetching(false);
        }
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [program, page, per]);

  const columns: Columns = [
    {
      title: 'ID',
      field: 'id',
    },
    { title: 'Redemption Cost', field: 'maxStamps' },
    { title: 'Created At', field: 'createdAt', format: dateFormat },
    { title: 'Status', field: 'status' },
    {
      title: '',
      field: 'id',
      align: 'right',
      format: (value: any) => (
        <ActionIconButton
          title="Activate"
          Icon={PowerSettingsNewIcon}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            activateCampaign(value);
          }}
        />
      ),
    },
  ];

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  return (
    <Grid>
      <Column xs={12} sm={12}>
        <DataTable
          title="Stamp Campaigns"
          columns={columns}
          data={campaigns}
          isLoading={isFetching}
          Actions={
            <ButtonLink
              to={`${url}/new`}
              variant="contained"
              size="small"
              color="primary"
            >
              New Campaign
            </ButtonLink>
          }
          onRowClick={(row) => history.push(`${url}/${row[0].value}`)}
          PaginationProps={
            pagination
              ? {
                  count: pagination.total,
                  rowsPerPage: pagination.per,
                  rowsPerPageOptions: [10, 25, 50],
                  page: pagination.page,
                  onChangePage: handleChangePage,
                  onChangeRowsPerPage: (event) => {
                    updatePer(event.target.value.toString());
                  },
                }
              : undefined
          }
        />
      </Column>
    </Grid>
  );
}

import { ApiRequest } from './request';

const getUserLocations = (id: string): ApiRequest => ({
  path: `/admin/api/v2/users/${id}/locations`,
  method: 'GET',
  authenticated: true
});

export default {
  getUserLocations
};

import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import { useDispatch, useSelector } from 'react-redux';
import * as merchantActions from '../../../redux/merchants/actions';
import * as merchantSelectors from '../../../redux/merchants/selectors';
import { promiseListener } from '../../../redux/store';
import { MerchantDoc } from '../../../api/resources/merchant';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Column, Content, Grid } from '../../../components/Layout/Main';
import FeatureBar from '../../../components/FeatureBar';
import EditMerchantForm from './EditMerchantForm';

const DEFAULT_FORM_VALUES = {
  name: '',
  category: '',
  url: '',
  about: '',
  logoId: undefined,
  coverId: undefined,
  phone: '',
  maxNumberStores: 6,
  loyaltyCampaignDuration: 7,
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  merchant: {
    marginLeft: theme.spacing(),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const Edit: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const merchant = useSelector(merchantSelectors.getMerchantSelector(id));
  const [redirectTo, setRedirectTo] = useState('');

  const classes = useStyles();

  // Load the merchant
  useEffect(() => {
    dispatch(merchantActions.getMerchant(id));
  }, [dispatch, id]);

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  const initialValues = merchant
    ? {
        name: merchant.name,
        category: merchant.category.key,
        url: merchant.url,
        about: merchant.about,
        logoId: merchant.logoId,
        coverId: merchant.coverId,
        phone: merchant.phone,
        maxNumberStores: merchant.maxNumStores,
        loyaltyCampaignDuration: 7,
      }
    : DEFAULT_FORM_VALUES;

  return (
    <Content>
      <FeatureBar backEnabled>{merchant && merchant.name}</FeatureBar>

      <Grid>
        <Column xs={12}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              className={classes.title}
            >
              Edit Merchant
            </Typography>

            <MakeAsyncFunction
              listener={promiseListener}
              start={getType(merchantActions.updateMerchant)}
              resolve={getType(merchantActions.api.updateMerchant.success)}
              reject={getType(merchantActions.api.updateMerchant.failure)}
            >
              {(updateMerchant: any) => (
                <EditMerchantForm
                  onSubmit={async (values, actions) => {
                    try {
                      const payload: MerchantDoc = await updateMerchant({
                        id: id,
                        ...values,
                      });

                      actions.setSubmitting(false);

                      setRedirectTo(`/merchants/${payload.data.id}`);
                    } catch {
                      alert('There was an error submitting this form');

                      actions.setSubmitting(false);
                    }
                  }}
                  initialValues={initialValues}
                  logoURL={merchant ? merchant.logo : undefined}
                  coverURL={merchant ? merchant.cover : undefined}
                />
              )}
            </MakeAsyncFunction>
          </Paper>
        </Column>
      </Grid>
    </Content>
  );
};

export default Edit;

import React, { useRef, useState } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import { promiseListener } from '../../redux/store';
import * as couponSetActions from '../../redux/couponSets/actions';

import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

//------------------------------------------------------------------------------
// Typrs
//------------------------------------------------------------------------------

type Props = {
  id: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * A button component which when clicked, fetches the coupon set download URL
 * and initiates a download to the user's computer
 *
 * @param id - The coupon set id
 */
const CouponSetDownloadButton: React.FC<Props> = ({ id }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [uri, setURI] = useState<string | null>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  return (
    <>
      <MakeAsyncFunction
        listener={promiseListener}
        start={getType(couponSetActions.getCouponSetPDF)}
        resolve={getType(couponSetActions.api.getCouponSetPDF.success)}
        reject={getType(couponSetActions.api.getCouponSetPDF.failure)}
      >
        {(getCouponSetPDF: any) => (
          <Tooltip title="Download">
            {isDownloading ? (
              <CircularProgress size={19} />
            ) : (
              <IconButton
                size="small"
                onClick={async (event) => {
                  event.stopPropagation();
                  setIsDownloading(true);

                  try {
                    const res = await getCouponSetPDF(id);

                    setURI(res.data.attributes.uri);

                    if (linkRef.current) {
                      linkRef.current.click();
                    }
                  } catch (e) {
                    console.log(e);
                  } finally {
                    setIsDownloading(false);
                  }
                }}
              >
                <CloudDownloadIcon fontSize="inherit" />
              </IconButton>
            )}
          </Tooltip>
        )}
      </MakeAsyncFunction>
      <a
        ref={linkRef}
        href={uri ? `${uri}&response-content-disposition=attachment;` : ''}
        style={{ display: 'none' }}
      >
        Get
      </a>
    </>
  );
};

export default CouponSetDownloadButton;

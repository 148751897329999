import React from 'react';
import { Formik, Field, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import * as couponSetActions from '../../redux/couponSets/actions';
import { promiseListener } from '../../redux/store';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { SubmitButton } from '../Form';
import { DatePicker, TextField } from '../FormikAdapter';
import { FormRow } from '../FormLayout';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<DialogProps, 'open'> & {
  campaignId: string;
  onClose: () => void;
};

interface NewCouponSetFormValues {
  label: string;
  couponsCount: number;
  expiresAt: moment.Moment | null;
}

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
  couponsCount: Yup.number()
    .required('Required')
    .min(1, 'Must be at least 1')
    .max(1000, 'Must be at most 1000'),
  expiresAt: Yup.mixed().required('Required'),
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function NewCouponSetDialog({
  campaignId,
  open,
  onClose,
}: Props) {
  const initialValues: NewCouponSetFormValues = {
    label: '',
    couponsCount: 0,
    expiresAt: null,
  };

  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={getType(couponSetActions.createCouponSet)}
      resolve={getType(couponSetActions.api.createCouponSet.success)}
      reject={getType(couponSetActions.api.createCouponSet.failure)}
    >
      {(createCouponSet: any) => (
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={async (values, actions) => {
            try {
              const expiresAt =
                values.expiresAt != null ? values.expiresAt.utc().unix() : 0;
              await createCouponSet({
                ...values,
                expiresAt,
                loyaltyCampaignId: campaignId,
              });

              actions.setSubmitting(false);
              actions.resetForm();
              onClose();
            } catch {
              actions.setSubmitting(false);
              alert('There was an error submitting this form');
            }
          }}
        >
          {({ isSubmitting }) => (
            <Dialog open={open} onClose={onClose}>
              <Form translate="no" noValidate>
                <DialogTitle>Create a coupon set</DialogTitle>

                <DialogContent>
                  <FormRow>
                    <Field
                      component={TextField}
                      name="label"
                      label="Label"
                      autoFocus
                      fullWidth
                      required
                    />

                    <Field
                      component={TextField}
                      name="couponsCount"
                      label="No. of Coupons"
                      type="number"
                      fullWidth
                      required
                    />

                    <Field
                      component={DatePicker}
                      name="expiresAt"
                      disablePast
                      label="Expires At"
                      required
                      initialFocusedDate={moment().endOf('day')}
                    />
                  </FormRow>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <SubmitButton
                    type="submit"
                    size="small"
                    isSubmitting={isSubmitting}
                  >
                    Create Coupon Set
                  </SubmitButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
    </MakeAsyncFunction>
  );
}

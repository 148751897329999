import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as rewardTemplateActions from '../../redux/rewardTemplates/actions';
import { listRewardTemplatesByMerchantId } from '../../redux/rewardTemplates/selectors';

import { makeStyles } from '@material-ui/core/styles';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';

import TextField from '../TextField';

import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  select: {
    display: 'flex',
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

export type Props = MuiTextFieldProps & {
  merchantId: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function RTSelect({ merchantId, ...rest }: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const templates = useSelector(listRewardTemplatesByMerchantId(merchantId));

  useEffect(() => {
    dispatch(
      rewardTemplateActions.getMerchantRewardTemplates(merchantId, {
        pagination: { page: 1, per: 100 },
        filters: {},
      })
    );
  }, [dispatch, merchantId]);

  return (
    <TextField
      select
      SelectProps={{
        defaultValue: '',
        classes: { select: classes.select },
      }}
      {...rest}
    >
      {templates.map((template, idx) => (
        <MenuItem value={template.id} key={idx}>
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              // className={classes.avatar}
              src={template.image ? template.image.versions['xl'] : undefined}
            />
          </ListItemAvatar>
          <ListItemText
            className={classes.listItemText}
            primary={template.title}
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

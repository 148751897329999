import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import produce from 'immer';

import * as welcomeCampaignActions from './actions';
import { WelcomeCampaign } from '../../api/resources';

export type WelcomeCampaignActions = ActionType<typeof welcomeCampaignActions>;

/**
 * byId contains all the coupon sets
 */

interface WelcomeCampaignById {
  [key: string]: WelcomeCampaign;
}

const byId = createReducer<WelcomeCampaignById, WelcomeCampaignActions>(
  {}
).handleAction(
  [
    welcomeCampaignActions.api.getLoyaltyProgramWelcomeCampaign.success,
    welcomeCampaignActions.api.createWelcomeCampaign.success,
  ],
  (state, action) => {
    return produce(state, (draft) => {
      draft[action.payload.data.id] = action.payload.data;
    });
  }
);

interface ByProgramId {
  [programId: string]: string;
}

const byProgramId = createReducer<ByProgramId, WelcomeCampaignActions>(
  {}
).handleAction(
  [
    welcomeCampaignActions.api.createWelcomeCampaign.success,
    welcomeCampaignActions.api.getLoyaltyProgramWelcomeCampaign.success,
  ],
  (state, action) => {
    return produce(state, (draft) => {
      draft[action.payload.data.relationships.loyaltyProgram.data.id] =
        action.payload.data.id;
    });
  }
);

interface UI {
  isFetching: boolean;
}
const ui = createReducer<UI, WelcomeCampaignActions>({
  isFetching: false,
})
  .handleAction(
    [
      welcomeCampaignActions.api.getLoyaltyProgramWelcomeCampaign.success,
      welcomeCampaignActions.api.getLoyaltyProgramWelcomeCampaign.failure,
    ],
    (state) => ({ ...state, ...{ isFetching: false } })
  )
  .handleAction(
    [welcomeCampaignActions.api.getLoyaltyProgramWelcomeCampaign.request],
    (state) => ({
      ...state,
      ...{ isFetching: true },
    })
  );

/**
 * pagination contains the pagination data for welcome rewards
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, WelcomeCampaignActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 50,
}).handleAction(
  [welcomeCampaignActions.api.listLoyaltyProgramWelcomeCampaign.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map((welcomeCampaign) => welcomeCampaign.id),
      count: count,
      page: page,
      total: total,
      per: per,
    };
  }
);

/**
 * Welcome Campaign root reducer
 */
const welcomeCampaignsReducer = combineReducers({
  byId,
  byProgramId,
  ui,
  pagination,
});

export default welcomeCampaignsReducer;
export type WelcomeCampaignsState = ReturnType<typeof welcomeCampaignsReducer>;

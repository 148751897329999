import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  cell: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  }
}));

const LoadingTableBody = () => {
  const classes = useStyles();

  return (
    <TableBody>
      <TableRow>
        {/* Hacky way to span all columns */}
        <TableCell colSpan={100} align="center" className={classes.cell}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default LoadingTableBody;

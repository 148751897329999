import { createAction, createAsyncAction } from 'typesafe-actions';

import { APIError } from '../api/models';
import { BirthdayCampaignDoc } from '../../api/resources/birthdayCampaign';

/**
 * Creates a birthday campaign
 */
export const createBirthdayCampaign = createAction(
  'birthday_campaigns/CREATE',
  (attrs: {
    validityPeriodDays: number;
    rewardTemplateId: string;
    loyaltyProgramId: string;
  }) => attrs
)();

/**
 * Gets a birthday campaign by loyalty program id
 */
export const getLoyaltyProgramBirthdayCampaign = createAction(
  'birthday_campaigns/GET_BY_LOYALTY_PROGRAM',
  (merchantId: string) => merchantId
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  createBirthdayCampaign: createAsyncAction(
    'api/birthday_campaigns/CREATE_REQUEST',
    'api/birthday_campaigns/CREATE_SUCCESS',
    'api/birthday_campaigns/CREATE_FAILURE'
  )<any, BirthdayCampaignDoc, APIError>(),

  getLoyaltyProgramBirthdayCampaign: createAsyncAction(
    'api/birthday_campaigns/FETCH_BY_LOYALTY_PROGRAM_REQUEST',
    'api/birthday_campaigns/FETCH_BY_LOYALTY_PROGRAM_SUCCESS',
    'api/birthday_campaigns/FETCH_BY_LOYALTY_PROGRAM_FAILURE'
  )<any, BirthdayCampaignDoc, APIError>(),
};

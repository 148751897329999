import request from './request';
import deserialize from './deserialize';
import FormData from 'form-data';

interface UploadAttrs {
  formdata: FormData;
}

export function uploadMemberCSVFile(attrs: UploadAttrs) {
  return request<any>({
    headers: {
      'Content-Type': 'text/plain',
    },
    url: `/admin/api/v2/upload/csv`,
    method: 'POST',
    data: attrs.formdata,
    transformResponse: deserialize,
  });
}

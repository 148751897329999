import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  api,
  getMerchantLoyaltyProgram,
  createLoyaltyProgram
} from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* createLoyaltyProgramSaga(
  action: ReturnType<typeof createLoyaltyProgram>
): SagaIterator {
  const { merchantId } = action.payload;

  yield put(
    callAPI(
      apiRequests.loyaltyPrograms.create(merchantId),
      api.createLoyaltyProgram
    )
  );
}

export function* getMerchantLoyaltyProgramSaga(
  action: ReturnType<typeof getMerchantLoyaltyProgram>
): SagaIterator {
  const id = action.payload;

  yield put(
    callAPI(
      apiRequests.loyaltyPrograms.getByMerchantId(id),
      api.fetchMerchantLoyaltyProgram
    )
  );
}

export default all([
  takeLatest(getType(getMerchantLoyaltyProgram), getMerchantLoyaltyProgramSaga),
  takeLatest(getType(createLoyaltyProgram), createLoyaltyProgramSaga)
]);

import React from 'react';

type Props = {
  height: string;
  width: string;
  color: string;
};

const MobileTick: React.FC<Props> = ({ height, width, color }: Props) => (
  <svg width={width} height={height} viewBox="0 0 30 30" version="1.1">
    <g id="Redeem" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M16.7655915,13.1747449 L17.489739,13.8988924 L14.0812334,17.307398 L12.3290816,15.5552462 L13.0532291,14.8310987 L14.0812334,15.859103 L16.7655915,13.1747449 Z M10.6531071,24 C9.74378571,24 9,23.2636875 9,22.3636473 L9,7.63639286 C9,6.7363125 9.74378571,6 10.6531071,6 L18.9183616,6 C19.8276429,6 20.5714286,6.7363125 20.5714286,7.63639286 L20.5714286,22.3636071 C20.5714286,23.2636875 19.8276429,24 18.9183616,24 L10.6531071,24 Z M10.4464286,9.21428571 L10.4464286,20.7857143 L19.125,20.7857143 L19.125,9.21428571 L10.4464286,9.21428571 Z"
        id="Shape"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default MobileTick;

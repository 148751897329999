import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  api,
  createWelcomeCampaign,
  getLoyaltyProgramWelcomeCampaign,
  listLoyaltyProgramWelcomeCampaign,
} from './actions';
import { notify } from '../notifications/actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* createWelcomeCampaignSaga(
  action: ReturnType<typeof createWelcomeCampaign>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.welcomeCampaigns.create(action.payload),
      api.createWelcomeCampaign
    )
  );
}

export function* fetchWelcomeCampaignSaga(
  action: ReturnType<typeof getLoyaltyProgramWelcomeCampaign>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.welcomeCampaigns.getByProgramId(action.payload),
      api.getLoyaltyProgramWelcomeCampaign
    )
  );
}

/**
 * Alert the user the welcome campaign has been created
 *
 * * @param _
 */
export function* createCouponSetSuccessSaga(
  _: ReturnType<typeof api.createWelcomeCampaign.success>
): SagaIterator {
  yield put(notify('Welcome Campaign Created'));
}

/**
 * Fetch the welcome rewards under loyalty package.
 *
 * @param action
 */
export function* listLoyaltyPackageWelcomeRewardSaga(
  action: ReturnType<typeof listLoyaltyProgramWelcomeCampaign>
): SagaIterator {
  const {
    programId,
    pagination: { page, per },
  } = action.payload;
  yield put(
    callAPI(
      apiRequests.welcomeCampaigns.listByProgramId(programId, {
        pagination: { page, per },
      }),
      api.listLoyaltyProgramWelcomeCampaign
    )
  );
}

export default all([
  takeLatest(getType(createWelcomeCampaign), createWelcomeCampaignSaga),
  takeLatest(
    getType(api.createWelcomeCampaign.success),
    createCouponSetSuccessSaga
  ),
  takeLatest(
    getType(getLoyaltyProgramWelcomeCampaign),
    fetchWelcomeCampaignSaga
  ),
  takeLatest(
    getType(listLoyaltyProgramWelcomeCampaign),
    listLoyaltyPackageWelcomeRewardSaga
  ),
]);

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import map from 'lodash/map';

import { getIsFetching } from '../../../../../../redux/welcomeCampaigns/selectors';
import usePaginationQuery from '../../../../../../hooks/usePaginationQuery';
import { PaginationResult } from '../../../../../../api/services/request';
import * as rewardService from '../../../../../../api/services/rewardService';
import * as welcomeCampaignService from '../../../../../../api/services/welcomeCampaignService';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

import NewWelcomeCampaignDialog from './NewWelcomeCampaignDialog';
import { dateFormat } from '../../../../../../lib/date';
import DataTable from '../../../../../../components/DataTable';
import { LoadingCard } from '../../../../../../components/Card';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  subheader: {
    color: theme.palette.common.black,
  },
  listItem: {
    minWidth: theme.spacing(4),
  },
  actionButton: {
    width: 100,
  },
}));

const useBlankSlateStyles = makeStyles((theme) => ({
  root: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  programId: string;
  merchantId: string;
};

type TableRow = {
  rewardTitle: string;
  delayHours: number;
  validityPeriodDays: number;
  status: string[];
  createdAt: number;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function BlankSlate({ onAction }: { onAction: () => void }) {
  const classes = useBlankSlateStyles();

  return (
    <div className={classes.wrapper}>
      <Button variant="contained" color="primary" onClick={onAction}>
        Get Started
      </Button>
    </div>
  );
}

export default function WelcomeCampaign({ merchantId, programId }: Props) {
  const classes = useStyles();
  const columns = [
    { title: 'Reward Title', field: 'rewardTitle' },
    { title: 'Hours of Delay', field: 'delayHours' },
    { title: 'Days of validity', field: 'validityPeriodDays' },
    { title: 'Created', field: 'createdAt', format: dateFormat },
    {
      title: 'Action',
      field: 'status',
      format: (value: any) => (
        <>
          <Button
            variant="contained"
            color={value[0] === 'inactive' ? 'primary' : 'default'}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              handleActivateDeactivate(value[0], value[1]);
            }}
            className={classes.actionButton}
          >
            {value[0] === 'inactive' ? 'Activate' : 'Deactivate'}
          </Button>
          {value[0] === 'inactive' && (
            <IconButton onClick={() => handleDelete(value[1])}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );
  const [rows, setRows] = useState<TableRow[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isFetching, setIsFetching] = useState(useSelector(getIsFetching));

  // handle delete
  const handleDelete = async (welcomeCampaignId: string) => {
    const resDelete = await welcomeCampaignService.archiveCampaign(
      welcomeCampaignId
    );

    if (resDelete) {
      fetchWelcomeRewards();
    }
  };

  // handle activate and deactive welcome reward
  const handleActivateDeactivate = async (
    status: string,
    welcomeCampaignId: string
  ) => {
    if (status === 'inactive') {
      const resActivate = await welcomeCampaignService.activateCampaign(
        welcomeCampaignId
      );

      if (resActivate) {
        fetchWelcomeRewards();
      }
    } else {
      const resDeactivate = await welcomeCampaignService.deactivateCampaign(
        welcomeCampaignId
      );

      if (resDeactivate) {
        fetchWelcomeRewards();
      }
    }
  };

  // Close the create dialog
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    fetchWelcomeRewards();
  };

  // Closes the menu
  const handleCloseMenu = () => setAnchorEl(null);

  // Opens the menu
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  // Fetch Welcome Reward List
  const fetchWelcomeRewards = async () => {
    let isMounted = true;
    try {
      setIsFetching(true);
      // Fetch the coupon set
      const res = await welcomeCampaignService.listByCampaign(programId, {
        pagination: { page, per },
        sort: [
          {
            key: 'createdAt',
            direction: 'desc',
          },
        ],
      });
      console.log(res);

      const rewardIds = uniq(map(res.welcomeCampaigns, 'reward.id'));
      console.log(rewardIds);

      // Fetch the rewards
      const rewardsRes = await rewardService.listByIds(rewardIds);
      const rewards = keyBy(rewardsRes.rewards, (r) => r.id);

      const tableRows = res.welcomeCampaigns.map((w) => {
        const reward = rewards[w.reward.id];

        return {
          rewardTitle: reward.title,
          delayHours: w.delayHours,
          validityPeriodDays: w.validityPeriodDays,
          status: [w.status, w.id],
          createdAt: w.createdAt,
        };
      });

      if (isMounted) {
        setRows(tableRows);
        setPagination(res.pagination);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  };

  // Intital loading Welcome Reward List
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        setIsFetching(true);
        // Fetch the coupon set
        const res = await welcomeCampaignService.listByCampaign(programId, {
          pagination: { page, per },
          sort: [
            {
              key: 'createdAt',
              direction: 'desc',
            },
          ],
        });
        console.log(res);

        const rewardIds = uniq(map(res.welcomeCampaigns, 'reward.id'));
        console.log(rewardIds);

        // Fetch the rewards
        const rewardsRes = await rewardService.listByIds(rewardIds);
        const rewards = keyBy(rewardsRes.rewards, (r) => r.id);

        const tableRows = res.welcomeCampaigns.map((w) => {
          const reward = rewards[w.reward.id];

          return {
            rewardTitle: reward.title,
            delayHours: w.delayHours,
            validityPeriodDays: w.validityPeriodDays,
            status: [w.status, w.id],
            createdAt: w.createdAt,
          };
        });

        if (isMounted) {
          setRows(tableRows);
          setPagination(res.pagination);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [page, per, programId]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  if (isFetching) {
    return <LoadingCard />;
  }

  return (
    <div>
      <Card>
        <CardHeader
          action={
            <div>
              <IconButton onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem
                  onClick={() => {
                    setCreateDialogOpen(true);
                    handleCloseMenu();
                  }}
                >
                  <ListItemIcon className={classes.listItem}>
                    <CreateIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>New Campaign</Typography>
                </MenuItem>
              </Menu>
            </div>
          }
          subheader="Welcome Campaign"
          classes={{
            subheader: classes.subheader,
          }}
        />

        <CardContent>
          {(() => {
            if (isFetching) {
              return <CircularProgress size={30} />;
            } else {
              return !!rows ? (
                <DataTable
                  title="List of Welcome Rewards"
                  columns={columns}
                  data={rows}
                  PaginationProps={
                    pagination && {
                      count: pagination.total,
                      rowsPerPageOptions: [10],
                      rowsPerPage: pagination.per,
                      page: pagination.page,
                      onChangePage: handleChangePage,
                      onChangeRowsPerPage: (event) => {
                        updatePer(event.target.value.toString());
                      },
                    }
                  }
                />
              ) : (
                <BlankSlate onAction={() => setCreateDialogOpen(true)} />
              );
            }
          })()}
        </CardContent>
      </Card>

      <NewWelcomeCampaignDialog
        merchantId={merchantId}
        programId={programId}
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        onExit={handleCloseMenu}
      />
    </div>
  );
}

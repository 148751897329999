import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import MuiLink, { LinkProps } from '@material-ui/core/Link';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = LinkProps & {
  to: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Link: React.FC<Props> = ({ children, to, ...rest }) => {
  const renderLink = React.useMemo(
    () =>
      // The use of React.forwardRef will no longer be required for react-router-dom v6.
      // See https://github.com/ReactTraining/react-router/issues/6056
      React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
        <RouterLink innerRef={ref} {...props} />
      )),
    []
  );

  return (
    <MuiLink component={renderLink} to={to} {...rest}>
      {children}
    </MuiLink>
  );
};

export default Link;

import { createAction, createAsyncAction } from 'typesafe-actions';

import { PaginationOptions, FilterOptions } from '../types';
import {
  RewardTemplateDoc,
  RewardTemplateCollectionDoc
} from '../../api/resources/rewardTemplate';
import { APIError } from '../api/models';

/**
 * Get the reward templates for a merchant.
 */
export const getMerchantRewardTemplates = createAction(
  'reward_templates/LIST_BY_MERCHANT',
  (
    merchantId: string,
    opts: { pagination: PaginationOptions; filters: FilterOptions }
  ) => ({
    merchantId,
    pagination: opts.pagination,
    filters: opts.filters
  })
)();

/**
 * Get the reward templates.
 */
export const getRewardTemplate = createAction(
  'reward_templates/GET',
  (id: string) => id
)();

/**
 * Create a reward template
 */
export const createRewardTemplate = createAction(
  'reward_templates/CREATE',
  (attrs: {
    title: string;
    what: string;
    info: string;
    gift: boolean;
    sell: boolean;
    imageId: string;
    merchantId: string;
  }) => attrs
)();

/**
 * Archive the reward templates.
 */
export const archiveRewardTemplate = createAction(
  'reward_templates/DELETE',
  (id: string) => id
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchMerchantRewardTemplates: createAsyncAction(
    'api/reward_templates/LIST_BY_MERCHANT_REQUEST',
    'api/reward_templates/LIST_BY_MERCHANT_SUCCESS',
    'api/reward_templates/LIST_BY_MERCHANT_FAILURE'
  )<any, RewardTemplateCollectionDoc, APIError>(),

  createRewardTemplate: createAsyncAction(
    'api/reward_templates/CREATE_REQUEST',
    'api/reward_templates/CREATE_SUCCESS',
    'api/reward_templates/CREATE_FAILURE'
  )<any, RewardTemplateDoc, APIError>(),

  fetchRewardTemplate: createAsyncAction(
    'api/reward_templates/FETCH_REQUEST',
    'api/reward_templates/FETCH_SUCCESS',
    'api/reward_templates/FETCH_FAILURE'
  )<any, RewardTemplateDoc, APIError>(),

  archiveRewardTemplate: createAsyncAction(
    'api/reward_templates/ARCHIVE_REQUEST',
    'api/reward_templates/ARCHIVE_SUCCESS',
    'api/reward_templates/ARCHIVE_FAILURE'
  )<any, RewardTemplateDoc, APIError>()
};

import { UserKYC } from './models';
import { RootState } from '../reducers';
import { getKYCImage } from '../images/selectors';

import find from 'lodash/find';
import values from 'lodash/values';
import without from 'lodash/without';

/**
 * Gets the KYC State
 *
 * @param state
 */
export const getKYCState = (state: RootState) => state.kyc;

/**
 * Gets the user's KYC
 *
 * @param state
 * @param id The user
 */
export const getKYCByUserId = (
  state: RootState,
  id: string
): UserKYC | undefined => {
  const byId = getKYCState(state).byId;
  const kyc = find(
    values(byId),
    data => id === data.relationships.user.data.id
  );

  if (kyc === undefined) {
    return undefined;
  }

  const {
    firstName,
    middleName,
    lastName,
    street1,
    street2,
    suburb,
    postcode,
    country,
    dob,
    status
  } = kyc.attributes;

  const { identityFiles, portraitFiles, addressFiles } = kyc.relationships;

  const portraitImage = getKYCImage(state, portraitFiles.data[0].id);
  const identityImages: string[] = without(
    identityFiles.data.map(identity => {
      const image = getKYCImage(state, identity.id);

      return image ? image.url : undefined;
    }),
    undefined
  ) as string[];
  const addressImage = getKYCImage(state, addressFiles.data[0].id);

  return {
    name: [firstName, middleName, lastName].join(' '),
    dob: dob,
    street: street1,
    building: street2,
    province: [suburb, postcode].join(' '),
    country: country,
    status: status,
    portraitURL: portraitImage ? portraitImage.url : undefined,
    identityURLs: identityImages,
    addressURL: addressImage ? addressImage.url : undefined
  };
};

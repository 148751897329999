import React, { useState } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import * as imageActions from '../../redux/images/actions';
import { promiseListener } from '../../redux/store';
import { ImageDoc } from '../../api/resources/image';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import ImageSelect, { Props as ImageSelectProps } from '../ImageSelect';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
    flex: 0,
  },
  select: {
    opacity: (props: StyleProps) =>
      props.disabled || props.isUploading ? 0.5 : 1,
  },
  loader: {
    display: (props: StyleProps) => (props.isUploading ? 'block' : 'none'),
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
  },
});

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type StyleProps = {
  disabled?: boolean;
  isUploading: boolean;
};

export type Props = Omit<ImageSelectProps, 'onChange' | 'onError'> & {
  onChange?: (id: string) => void;
  onError?: (errorMsg?: string) => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * ImageUploader uploads the selected image and returns an id
 */
const StockImageUploader: React.FC<Props> = ({
  onChange,
  onError,
  ...props
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const classes = useStyles({
    disabled: props.disabled,
    isUploading: isUploading,
  });

  return (
    <div className={classes.root}>
      <div className={classes.select}>
        <MakeAsyncFunction
          listener={promiseListener}
          start={getType(imageActions.createStockImage)}
          resolve={getType(imageActions.api.createStockImage.success)}
          reject={getType(imageActions.api.createStockImage.failure)}
        >
          {(createStockImage: any) => (
            <ImageSelect
              {...props}
              error={error}
              helperText={helperText}
              onError={(errorMsg?: string) => {
                setError(true);
                if (errorMsg) {
                  setHelperText(errorMsg);
                }
              }}
              onChange={(file) => {
                setError(false);
                setHelperText('');
                const reader = new FileReader();
                reader.onload = async () => {
                  setIsUploading(true);

                  const payload: ImageDoc = await createStockImage({
                    data: reader.result,
                  });

                  setIsUploading(false);

                  if (onChange) {
                    onChange(payload.data.id);
                  }
                };
                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.readAsDataURL(file);
              }}
            />
          )}
        </MakeAsyncFunction>
      </div>
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default StockImageUploader;

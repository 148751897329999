import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import produce from 'immer';

import * as imageActions from './actions';
import * as merchantActions from '../merchants/actions';
import * as rewardTemplateActions from '../rewardTemplates/actions';
import * as kycActions from '../kyc/actions';
import * as rewardActions from '../rewards/actions';
import { Image, KYCImage } from '../../api/resources';

export type ImageActions = ActionType<
  | typeof merchantActions
  | typeof rewardActions
  | typeof rewardTemplateActions
  | typeof kycActions
  | typeof imageActions
>;

/**
 *
 */

interface ImagesById {
  [key: string]: Image;
}

const byId = createReducer<ImagesById, ImageActions>({}).handleAction(
  [
    merchantActions.api.fetchMerchantList.success,
    merchantActions.api.fetchMerchant.success,
    rewardTemplateActions.api.fetchMerchantRewardTemplates.success,
    rewardTemplateActions.api.createRewardTemplate.success,
    rewardTemplateActions.api.fetchRewardTemplate.success,
    rewardActions.api.fetchReward.success,
    rewardActions.api.listRewards.success,
  ],
  (state, action) => {
    const { images } = normalize(action.payload);

    return images ? { ...state, ...images } : state;
  }
);

/**
 *
 */

interface KYCImagesById {
  [key: string]: KYCImage;
}
const kycById = createReducer<KYCImagesById, ImageActions>({}).handleAction(
  kycActions.api.fetchUserKYC.success,
  (state, action) => {
    const { files } = normalize(action.payload);

    return files ? { ...state, ...files } : state;
  }
);

interface StockImagesById {
  [key: string]: Image;
}

const stockImagebyId = createReducer<StockImagesById, ImageActions>(
  {}
).handleAction(
  [imageActions.api.fetchStockImageList.success],
  (state, action) => {
    const { images } = normalize(action.payload);

    return images ? { ...state, ...images } : state;
  }
);

/**
 * pagination contains the pagination data for campaigns
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, ImageActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 50,
}).handleAction(
  [imageActions.api.fetchStockImageList.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map((stockImage) => stockImage.id),
      count: count,
      page: page,
      total: total,
      per: per,
    };
  }
);

interface StockImagesUI {
  isFetching: boolean;
}
const ui = createReducer<StockImagesUI, ImageActions>({
  isFetching: false,
})
  .handleAction(
    imageActions.api.fetchStockImageList.request,
    produce((state) => {
      state.isFetching = true;
    })
  )
  .handleAction(
    [
      imageActions.api.fetchStockImageList.success,
      imageActions.api.fetchStockImageList.failure,
    ],
    produce((state) => {
      state.isFetching = false;
    })
  );

const imagesReducer = combineReducers({
  kycById,
  byId,
  stockImagebyId,
  pagination,
  ui,
});

export default imagesReducer;
export type ImagesState = ReturnType<typeof imagesReducer>;

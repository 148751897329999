import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button, { ButtonProps } from '@material-ui/core/Button';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = ButtonProps & {
  isSubmitting?: boolean;
};

const SubmitButton: React.FC<Props> = ({
  children,
  isSubmitting,
  ...props
}) => {
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;

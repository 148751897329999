import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  api,
  createCampaign,
  getCampaign,
  getCampaignStats,
  listCampaigns,
  listMerchantCampaigns
} from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

/**
 * Fetch all the campaigns matching the filters. Supports pagination and
 * ordering.
 *
 * @param action
 */
export function* listCampaignsSaga(
  action: ReturnType<typeof listCampaigns>
): SagaIterator {
  const {
    pagination: { page, per },
    filters
  } = action.payload;

  yield put(
    callAPI(
      apiRequests.campaigns.list({ pagination: { page, per }, filters }),
      api.listCampaigns
    )
  );
}

/**
 * Fetch the campaigns belong to a merchant.
 *
 * @param action
 */
export function* listMerchantCampaignsSaga(
  action: ReturnType<typeof listMerchantCampaigns>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.campaigns.listByMerchantId(action.payload),
      api.listMerchantCampaigns
    )
  );
}

export function* getCampaignSaga(
  action: ReturnType<typeof getCampaign>
): SagaIterator {
  yield put(
    callAPI(apiRequests.campaigns.get(action.payload), api.fetchCampaign)
  );
}

export function* createCampaignSaga(
  action: ReturnType<typeof createCampaign>
): SagaIterator {
  yield put(
    callAPI(apiRequests.campaigns.create(action.payload), api.createCampaign)
  );
}

export function* getCampaignStatsSaga(
  action: ReturnType<typeof getCampaignStats>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.campaigns.getStats(action.payload),
      api.fetchCampaignStats
    )
  );
}

export default all([
  takeLatest(getType(listCampaigns), listCampaignsSaga),
  takeLatest(getType(listMerchantCampaigns), listMerchantCampaignsSaga),
  takeLatest(getType(getCampaign), getCampaignSaga),
  takeLatest(getType(createCampaign), createCampaignSaga),
  takeLatest(getType(getCampaignStats), getCampaignStatsSaga)
]);

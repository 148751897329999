import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import New from './New';
import Overview from './Overview';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const TabCampaigns: React.FC<Props> = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path}>
      <Overview />
    </Route>

    <Route path={`${path}/new`} component={New} />
  </Switch>
);

export default TabCampaigns;

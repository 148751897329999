import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab, { TabProps } from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import TabCouponSets from './TabCouponSets';
import TabMilestones from './TabMilestones';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  actions: {},
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey['500']}`,
  },
  tabActions: {
    position: 'absolute',
    right: theme.spacing(0.5),
    zIndex: 100,
  },
}));

const TabBar = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    padding: `0 ${theme.spacing(2)}px`,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    height: 3,
  },
}))(Tabs);

const CampaignTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: theme.spacing(3),
    paddingBottom: 0,
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))((props: TabProps) => <Tab disableRipple {...props} />);

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  campaignId: string;
  merchantId: string;
  maxStamps: number;
  status: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function TabPanel({
  children,
  value,
  index,
  ...other
}: React.PropsWithChildren<{ value: number; index: number }>) {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

export default function CampaignTabs({
  campaignId,
  merchantId,
  maxStamps,
  status,
}: Props) {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState(0);

  // Set the tab to the new value
  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: any) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabActions}></div>

      <TabBar value={currentTab} onChange={handleTabChange}>
        <CampaignTab label="Milestones" />
        <CampaignTab label="Coupon Sets" />
      </TabBar>

      <TabPanel value={currentTab} index={0}>
        <TabMilestones
          campaignId={campaignId}
          merchantId={merchantId}
          maxStamps={maxStamps}
          status={status}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <TabCouponSets campaignId={campaignId} />
      </TabPanel>
    </div>
  );
}

import { ApiRequest } from './request';

const getDashboardAnalytics = (): ApiRequest => ({
  path: '/admin/api/v2/analytics/dashboard',
  method: 'GET',
  authenticated: true
});

export default {
  getDashboardAnalytics
};

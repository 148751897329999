import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type Membership = {
  id: string;
  user: {
    type: 'users';
    id: string;
  };
  program: {
    type: 'loyalty_programs';
    id: string;
  };
  createdAt: number;
};

export type MembershipList = {
  memberships: Membership[];
  pagination: PaginationResult;
};

export function listByProgram(programId: string, opts?: ListOptions) {
  return request<MembershipList>({
    url: `/admin/api/v2/loyalty_programs/${programId}/memberships?${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        memberships: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function exportByProgram(programId: string, timezone: string) {
  return request<string>({
    url: `/admin/api/v2/loyalty_programs/${programId}/memberships/export`,
    method: 'POST',
    data: {
      data: {
        attributes: {
          timeZone: timezone,
        },
      },
    },
  });
}

export function deleteAllMembers(programId: string) {
  return request<string>({
    url: `/admin/api/v2/loyalty_programs/${programId}/memberships/archive/all`,
    method: 'DELETE',
  });
}

import React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  column: {
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(2)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = GridProps;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Column: React.FC<Props> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.column} {...rest}>
      {children}
    </Grid>
  );
};

export default Column;

import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';
import { RelationshipsObject } from 'jsonapi-typescript';

const list = (opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/merchants?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true,
});

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/merchants/${id}`,
  method: 'GET',
  authenticated: true,
});

const create = (attrs: {
  name: string;
  category: string;
  url: string;
  about: string;
  logoId?: string;
  coverId?: string;
  phone?: string;
  maxNumberStores?: number;
}) => {
  const {
    name,
    category,
    about,
    url,
    logoId,
    coverId,
    phone,
    maxNumberStores,
  } = attrs;

  const logoData = logoId
    ? {
        type: 'images',
        id: logoId,
      }
    : null;

  const coverData = coverId
    ? {
        type: 'images',
        id: coverId,
      }
    : null;

  return {
    path: `/admin/api/v2/merchants`,
    method: 'POST',
    authenticated: true,
    body: {
      data: {
        type: 'merchants',
        attributes: {
          name,
          about,
          url,
          category,
          phone: phone ? phone : '',
          maxNumStores: maxNumberStores ? maxNumberStores : 6,
        },
        relationships: {
          cover: {
            data: coverData,
          },
          logo: {
            data: logoData,
          },
        },
      },
    },
  };
};

const update = (attrs: {
  id: string;
  name: string;
  category: string;
  about: string;
  url: string;
  logoId?: string;
  coverId?: string;
  phone?: string;
  maxNumberStores: number;
}) => {
  const {
    id,
    name,
    category,
    about,
    url,
    logoId,
    coverId,
    phone,
    maxNumberStores,
  } = attrs;

  const logoRel = logoId
    ? ({
        logo: {
          data: {
            type: 'images',
            id: logoId,
          },
        },
      } as RelationshipsObject)
    : {};

  const coverRel = coverId
    ? ({
        cover: {
          data: {
            type: 'images',
            id: coverId,
          },
        },
      } as RelationshipsObject)
    : {};

  return {
    path: `/admin/api/v2/merchants/${id}`,
    method: 'PATCH',
    authenticated: true,
    body: {
      data: {
        type: 'merchants',
        id,
        attributes: {
          name,
          about,
          url,
          category: {
            key: category,
          },
          phone: phone ? phone : '',
          maxNumStores: maxNumberStores,
        },
        relationships: {
          ...logoRel,
          ...coverRel,
        },
      },
    },
  };
};

//--------------------

const getEmployees = (id: string): ApiRequest => ({
  path: `/admin/api/v1/merchants/${id}/employees`,
  method: 'GET',
  authenticated: true,
});

const createEmployee = (merchantId: string, email: string): ApiRequest => ({
  path: `/admin/api/v1/merchants/${merchantId}/employees`,
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'users',
      attributes: {
        email,
      },
    },
  },
});

const getCampaignById = (merchantId: string, id: string): ApiRequest => ({
  path: `/admin/api/v1/merchants/${merchantId}/campaign/${id}`,
  method: 'GET',
  authenticated: true,
});

const getStores = (id: string, opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/merchants/${id}/stores?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true,
});

export default {
  list,
  get,
  create,
  update,
  createEmployee,
  getEmployees,
  getCampaignById,
  getStores,
};

import React from 'react';
import { useLocation } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import ImageIcon from '@material-ui/icons/Image';
import List from '@material-ui/core/List';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import StoreIcon from '@material-ui/icons/Store';
import { makeStyles } from '@material-ui/core/styles';

import MenuListItem from './MenuListItem';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('md')]: {
      minHeight: 50,
    },
  },
  divider: {
    backgroundColor: '#404854',
  },
  itemIcon: {
    color: '#ffffff',
  },
  itemText: {
    color: '#ffffff',
  },
  overviewButton: {
    justifyContent: 'flex-start',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.08)',
    },
  },
  overviewButtonLabel: {
    color: 'rgba(255,255,255,.7)',
    textTransform: 'none',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  activeButton: {
    span: {
      color: theme.palette.primary.main,
    },
  },
  overviewItem: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Menu: React.FC<{}> = () => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider className={classes.divider} />
      <MenuListItem
        className={classes.overviewItem}
        title="Overview"
        icon={HomeIcon}
        to="/overview"
        selected={pathname === '/overview'}
      />
      <Divider className={classes.divider} />
      <List>
        <MenuListItem
          title="Users"
          icon={PeopleIcon}
          to="/users"
          selected={RegExp('^/users.*').test(pathname)}
        />
        <MenuListItem
          title="Merchants"
          icon={StoreIcon}
          to="/merchants"
          selected={RegExp('^/merchants.*').test(pathname)}
        />
        <MenuListItem
          title="Campaigns"
          icon={RecordVoiceOverIcon}
          to="/campaigns"
          selected={RegExp('^/campaigns.*').test(pathname)}
        />
        <MenuListItem
          title="Stock Images"
          icon={ImageIcon}
          to="/stockImages"
          selected={RegExp('^/stockImages.*').test(pathname)}
        />
      </List>
    </div>
  );
};

export default Menu;

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import New from './New';
import Show from './Show';
import Overview from './Overview';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const TabRewardTemplates: React.FC<Props> = ({ match: { path } }) => (
  <Switch>
    <Route exact path={path}>
      <Overview />
    </Route>

    <Route exact path={`${path}/new`}>
      <New />
    </Route>

    <Route path={`${path}/:rewardTemplateId`}>
      <Show />
    </Route>
  </Switch>
);

export default TabRewardTemplates;

import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import produce from 'immer';
import reduce from 'lodash/reduce';

import * as couponSetActions from '../couponSets/actions';
import { CouponSet } from '../../api/resources';

export type CouponSetActions = ActionType<typeof couponSetActions>;

/**
 * byId contains all the coupon sets
 */

interface CouponSetById {
  [key: string]: CouponSet;
}

const byId = createReducer<CouponSetById, CouponSetActions>({})
  .handleAction(
    [couponSetActions.api.listLoyaltyPackageCouponSet.success],
    (state, action) => {
      const { couponSets } = normalize(action.payload);

      return couponSets ? { ...state, ...couponSets } : state;
    }
  )
  .handleAction(
    [couponSetActions.api.createCouponSet.success],
    (state, action) => {
      return produce(state, (draft) => {
        draft[action.payload.data.id] = action.payload.data;
      });
    }
  );

/**
 * The coupon set ids keyed to the loyalty campaign
 */
interface LoyaltyCampaigns {
  [key: string]: string[];
}

const loyaltyCampaigns = createReducer<LoyaltyCampaigns, CouponSetActions>({})
  .handleAction(
    [couponSetActions.api.listLoyaltyPackageCouponSet.success],
    (state, action) => {
      const { data } = action.payload;

      const lc = reduce(
        data,
        (loyaltyCampaigns, couponSet) => {
          const loyaltyCampaignId =
            couponSet.relationships.loyaltyCampaign.data.id;
          const id = couponSet.id;

          if (loyaltyCampaigns[loyaltyCampaignId]) {
            loyaltyCampaigns[loyaltyCampaignId].push(id);
          } else {
            loyaltyCampaigns[loyaltyCampaignId] = [id];
          }

          return loyaltyCampaigns;
        },
        {} as LoyaltyCampaigns
      );

      return { ...state, ...lc };
    }
  )
  .handleAction(
    [couponSetActions.api.createCouponSet.success],
    (state, action) => {
      const { id, relationships } = action.payload.data;

      return produce(state, (draft) => {
        if (draft[relationships.loyaltyCampaign.data.id] === undefined) {
          draft[relationships.loyaltyCampaign.data.id] = [];
        }

        draft[relationships.loyaltyCampaign.data.id].push(id);
      });
    }
  );

/**
 * pagination contains the pagination data for campaigns
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, CouponSetActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 50,
}).handleAction(
  [couponSetActions.api.listLoyaltyPackageCouponSet.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map((couponSet) => couponSet.id),
      count: count,
      page: page,
      total: total,
      per: per,
    };
  }
);

/**
 * PDFs contain the uris to the coupon set pdfs
 */

interface PDFs {
  [key: string]: string;
}

const pdfs = createReducer<PDFs, CouponSetActions>({}).handleAction(
  [couponSetActions.api.getCouponSetPDF.success],
  (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      [data.relationships.couponSet.data.id]: data.attributes.uri,
    };
  }
);

/**
 * Coupon Set root reducer
 */
const couponSetsReducer = combineReducers({
  byId,
  loyaltyCampaigns,
  pagination,
  pdfs,
});

export default couponSetsReducer;
export type CouponSetsState = ReturnType<typeof couponSetsReducer>;

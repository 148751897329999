import { MiddlewareAPI, Dispatch } from 'redux';
export const DEFERRED = Symbol('DEFERRED');

interface Deffered {
  resolve: any;
  reject: any;
}

const createExposedPromise = () => {
  const deferred: Deffered = {
    resolve: undefined,
    reject: undefined
  };

  const promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return [promise, deferred];
};

// Wraps the action in a promise when the DEFERRED key is passed into metadata
export default (store: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  if (!action.meta || (action.meta && !action.meta[DEFERRED])) {
    return next(action);
  }

  const [promise, deferred] = createExposedPromise();
  action.payload.deferred = deferred;

  console.log('Deferred', action);

  next(action);

  return promise;
};

import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import { SubmitButton } from '../../../../../../components/Form';
import {
  AutocompletePlace,
  TextField,
} from '../../../../../../components/FormikAdapter';
import { FormActions, FormRow } from '../../../../../../components/FormLayout';
import { SingleMarkerMap } from '../../../../../../components/Map';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  map: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    height: '400px',
    width: '100%',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Place = {
  lat: number;
  lng: number;
  address: string;
  neighborhood: string;
};

interface NewStoreFormValues {
  name: string;
  phone: string;
  place: Place | null;
}

type Props = {
  onSubmit: (
    values: NewStoreFormValues,
    actions: FormikHelpers<NewStoreFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  place: Yup.mixed().required('Required'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const NewStoreForm: React.FC<Props> = ({ onSubmit }) => {
  const classes = useStyles();

  const initialValues: NewStoreFormValues = {
    name: '',
    phone: '',
    place: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values: { place } }) => (
        <Form translate="no" noValidate>
          <FormRow>
            <Field
              component={TextField}
              name="name"
              label="Name"
              autoFocus
              fullWidth
              required
            />

            <Field component={TextField} name="phone" label="Phone" fullWidth />
          </FormRow>

          <FormRow>
            <Field
              component={AutocompletePlace}
              name="place"
              label="Address"
              TextFieldProps={{
                label: 'Address',
                required: true,
                placeholder: 'Search for an address',
                fullWidth: true,
              }}
            />
          </FormRow>

          <div className={classes.map}>
            <SingleMarkerMap
              marker={place ? { lat: place.lat, lng: place.lng } : undefined}
            />
          </div>

          <FormActions>
            <SubmitButton isSubmitting={isSubmitting}>
              Create Store
            </SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};

export default NewStoreForm;

import { RootState } from '../reducers';

import pick from 'lodash/pick';
import values from 'lodash/values';
import { DropZoneResource } from '../../api/resources';

/**
 * Get the drop zone state
 *
 * @param state
 */
export const getDropZonesState = (state: RootState) => state.dropZones;

/**
 * Get campaign's drop zone ids
 *
 * @param state
 * @param campaignId
 */
export const getCampaignDropZoneIds = (state: RootState, campaignId: string) =>
  getDropZonesState(state).campaignIds[campaignId];

/**
 * Get list of drop zones for a campaign
 *
 * @param state
 * @param campaignId
 */
export const listDropZonesByCampaignId = (campaignId: string) => (
  state: RootState
) => {
  const ids = getCampaignDropZoneIds(state, campaignId);
  const zones = pick(getDropZonesState(state).byId, ids);

  return values(zones).map(makeDropZone);
};

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeDropZone = (zone: DropZoneResource) => {
  const { id, attributes } = zone;
  const { coords, radius } = attributes;

  return {
    id,
    coords,
    radius
  };
};

import React, { useEffect, useCallback, useState } from 'react';
import { Redirect } from 'react-router';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { dateFormat } from '../../../../../../../lib/date';
import DataTable from '../../../../../../../components/DataTable';
import usePaginationQuery from '../../../../../../../hooks/usePaginationQuery';
import * as MembershipService from '../../../../../../../api/services/membershipService';
import * as RewardCardService from '../../../../../../../api/services/rewardCardService';
import { PaginationResult } from '../../../../../../../api/services/request';
import * as UsersService from '../../../../../../../api/services/userService';
import ConfirmationDialog from '../../../../../../../components/Dialog/ConfirmationDialog';

const useStyles = makeStyles({
  actionButton: {
    margin: 8,
  },
});

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  merchantId: string;
  programId: string;
  handleRowClick: (userId: string) => void;
  merchantName: string;
};

type TableRow = {
  id: string;
  userId: string;
  name: string;
  email: string;
  createdAt: number;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const columns = [
  { title: 'Membership ID', field: 'id' },
  { title: 'User ID', field: 'userId' },
  { title: 'Name', field: 'name' },
  { title: 'Email', field: 'email' },
  { title: 'Joined On', field: 'createdAt', format: dateFormat },
];

const MembersTable: React.FC<Props> = ({
  merchantId,
  programId,
  handleRowClick,
  merchantName,
}) => {
  const classes = useStyles();
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isExpireRewardDialogOpen, setIsExpireRewardDialogOpen] =
    useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  // Fetch the memberships
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        // Fetch the memberships
        const res = await MembershipService.listByProgram(programId, {
          pagination: { page, per },
          sort: [
            {
              key: 'createdAt',
              direction: 'asc',
            },
          ],
        });

        // Fetch the users
        const userIds = res.memberships.map((m) => m.user.id);
        const usersRes = await UsersService.listByIds(userIds);
        const users = keyBy(usersRes.users, 'id');

        const tableRows = res.memberships.map((m) => {
          const user = users[m.user.id];

          return {
            id: m.id,
            userId: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            createdAt: m.createdAt,
          };
        });

        if (isMounted) {
          setRows(tableRows);
          setPagination(res.pagination);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [page, per, programId]);

  const refreshList = async () => {
    if (programId) {
      try {
        // Fetch the memberships
        const res = await MembershipService.listByProgram(programId, {
          pagination: { page, per },
          sort: [
            {
              key: 'createdAt',
              direction: 'asc',
            },
          ],
        });

        // Fetch the users
        const userIds = res.memberships.map((m) => m.user.id);
        const usersRes = await UsersService.listByIds(userIds);
        const users = keyBy(usersRes.users, 'id');

        const tableRows = res.memberships.map((m) => {
          const user = users[m.user.id];

          return {
            id: m.id,
            userId: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            createdAt: m.createdAt,
          };
        });

        setRows(tableRows);
        setPagination(res.pagination);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // Download the file
  const downloadMemberships = useCallback(async () => {
    try {
      const currentDate = moment().format('YYYYMMDD');
      const response = await MembershipService.exportByProgram(
        programId,
        'utc'
      );

      // Creating a Blob for having a csv file format
      // and passing the data with type
      const blob = new Blob([response], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML
      const a = document.createElement('a');

      // Passing the blob downloading url
      a.setAttribute('href', url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute(
        'download',
        merchantName + '_memberlist_' + currentDate + '.csv'
      );

      // Performing a download with click
      a.click();
    } catch (e) {
      console.log('error: ', e);
    }
  }, [programId, merchantName]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  const handleDeleteAllMembers = async () => {
    if (programId) {
      try {
        await MembershipService.deleteAllMembers(programId);
        refreshList();
      } catch (e) {
        console.log('Error: ', e);
      }
    }
  };

  const handleExpireAllRewards = async () => {
    if (merchantId) {
      try {
        await RewardCardService.expireAllMerchantRewardCards(merchantId);
        setRedirectTo(`/merchants/${merchantId}`);
      } catch (e) {
        console.log('Error: ', e);
      }
    }
  };

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <DataTable
        title="Members"
        columns={columns}
        data={rows}
        onRowClick={(row) => handleRowClick(row[1].value.toString())}
        PaginationProps={
          pagination && {
            count: pagination.total,
            rowsPerPageOptions: [10, 25, 50],
            rowsPerPage: pagination.per,
            page: pagination.page,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: (event) => {
              updatePer(event.target.value.toString());
            },
          }
        }
        Actions={
          rows.length > 0 ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsExpireRewardDialogOpen(true)}
                className={classes.actionButton}
              >
                Expire All Rewards
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsDeleteDialogOpen(true)}
                className={classes.actionButton}
              >
                Delete All Members
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={downloadMemberships}
                className={classes.actionButton}
              >
                Download List
              </Button>
            </div>
          ) : null
        }
      />
      <ConfirmationDialog
        id={'1'}
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
        }}
        onConfirm={() => {
          setIsDeleteDialogOpen(false);
          handleDeleteAllMembers();
        }}
        title="Delete All Members"
        body="Permanently delete all members? This action cannot be undone."
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
      />
      <ConfirmationDialog
        id={'2'}
        open={isExpireRewardDialogOpen}
        onClose={() => {
          setIsExpireRewardDialogOpen(false);
        }}
        onCancel={() => {
          setIsExpireRewardDialogOpen(false);
        }}
        onConfirm={() => {
          setIsExpireRewardDialogOpen(false);
          handleExpireAllRewards();
        }}
        title="Permanently Expire All Rewards"
        body="Permanently expire all rewards? This action cannot be undone."
        confirmButtonText="Expire"
        cancelButtonText="Cancel"
      />
    </>
  );
};

export default MembersTable;

import React, { useEffect, useState, useCallback } from 'react';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import DataTable from '../DataTable';
import * as StampCampaignService from '../../api/services/stampCampaignService';
import * as UsersService from '../../api/services/userService';
import { PaginationResult } from '../../api/services/request';

import { dateFormat } from '../../lib/date';
import usePaginationQuery from '../../hooks/usePaginationQuery';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  programId: string;
  merchantName: string;
  campaignId: string;
};

type TableRow = {
  id: string;
  name: string;
  email: string;
  updatedAt: number;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const columns = [
  { title: 'ID', field: 'id' },
  {
    title: 'Name',
    field: 'name',
  },
  { title: 'Email', field: 'email' },
  {
    title: 'Completed Date',
    field: 'updatedAt',
    format: dateFormat,
    width: '20%',
  },
];

const StampCardList: React.FC<Props> = ({
  programId,
  merchantName,
  campaignId,
}) => {
  const classes = useStyles();
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );

  // Fetch the stampcard list
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        // Fetch the stampcard list
        const res = await StampCampaignService.listMembersByCompletedStampCard(
          programId,
          {
            pagination: { page, per },
            sort: [
              {
                key: 'updatedAt',
                direction: 'desc',
              },
            ],
          }
        );

        // Fetch the users
        const userIds = res.stampCards.map((sc) => sc.user.id);
        const usersRes = await UsersService.listByIds(userIds);
        const users = keyBy(usersRes.users, 'id');

        const tableRows = res.stampCards.map((sc) => {
          const user = users[sc.user.id];

          return {
            id: sc.id,
            name: sc.name,
            email: user.email,
            updatedAt: sc.updatedAt,
          };
        });

        if (isMounted) {
          setRows(tableRows);
          setPagination(res.pagination);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [programId, page, per]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  // Download the file
  const downloadList = useCallback(async () => {
    try {
      const currentDate = moment().format('YYYYMMDD');
      const response = await StampCampaignService.exportByCompletedStampCard(
        campaignId
      );

      let a = document.createElement('a');
      a.href = 'data:,' + response;
      a.download =
        merchantName + '_completedStampCardlist_' + currentDate + '.csv';
      a.click();
    } catch (e) {
      console.log('error: ', e);
    }
  }, [campaignId, merchantName]);

  return (
    <div className={classes.root}>
      <DataTable
        title="Customers who have completed the stamp card"
        columns={columns}
        data={rows}
        PaginationProps={
          pagination && {
            count: pagination.total,
            rowsPerPageOptions: [10, 25, 50],
            rowsPerPage: pagination.per,
            page: pagination.page,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: (event) => {
              updatePer(event.target.value.toString());
            },
          }
        }
        Actions={
          <Button variant="contained" color="primary" onClick={downloadList}>
            Download List
          </Button>
        }
      />
    </div>
  );
};

export default StampCardList;

import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

interface CreateAttrs {
  validityPeriodDays: number;
  rewardTemplateId: string;
  loyaltyProgramId: string;
  delayHours: number;
}

const create = (attrs: CreateAttrs): ApiRequest => ({
  path: `/admin/api/v2/welcome_campaigns`,
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'welcome_campaigns',
      attributes: {
        validityPeriodDays: attrs.validityPeriodDays,
        delayHours: attrs.delayHours,
      },
      relationships: {
        loyaltyProgram: {
          data: {
            type: 'loyalty_programs',
            id: attrs.loyaltyProgramId,
          },
        },
        rewardTemplate: {
          data: {
            type: 'reward_templates',
            id: attrs.rewardTemplateId,
          },
        },
      },
    },
  },
});

const getByProgramId = (programId: string): ApiRequest => ({
  path: `/admin/api/v2/loyalty_programs/${programId}/welcome_campaign`,
  method: 'GET',
  authenticated: true,
});

const listByProgramId = (programId: string, opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/loyalty_programs/${programId}/welcome_campaign?${toListQueryString(
    opts
  )}&sort=desc-createdAt`,
  method: 'GET',
  authenticated: true,
});

export default { create, getByProgramId, listByProgramId };

import React from 'react';
import { getIn, FieldProps } from 'formik';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import DateTimePicker, {
  Props as DateTimePickerProps
} from '../../DateTimePicker/DateTimePicker';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps &
  Omit<DateTimePickerProps, 'error' | 'name' | 'onChange' | 'value'>;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const fieldToDateTimePicker = ({
  form,
  field,
  ...props
}: Props): DateTimePickerProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    ...field,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    InputProps: {
      onBlur: field.onBlur
    },
    onChange: (date: MaterialUiPickersDate) => {
      form.setFieldValue(name, date ? date : null);
    },
    disabled: isSubmitting || props.disabled
  };
};

/**
 * DatePickerAdapter wraps the DateTimePicker component returning a moment time.
 */
const DateTimePickerAdapter: React.FC<Props> = props => (
  <DateTimePicker {...fieldToDateTimePicker(props)} />
);

export default DateTimePickerAdapter;

import React from 'react';
import { Provider } from 'react-redux';

import { MuiThemeProvider } from '@material-ui/core/styles';

import './App.css';
import AppRouter from './components/AppRouter';
import store from './redux/store';
import theme from './theme';

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <AppRouter />
    </MuiThemeProvider>
  </Provider>
);

export default App;

import request from './request';
import deserialize from './deserialize';

export type Billing = {
  id: string;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  status: string;
  cancelAtPeriodEnd: boolean;
};

export function getBilling(merchantId: string) {
  return request<Billing>({
    url: `/admin/api/v2/merchants/${merchantId}/billing`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

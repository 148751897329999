import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { isAuthenticated } from '../../redux/auth/selectors';

import { AuthLayout } from '../Layout';

type Props = RouteProps & {
  component: any; // TODO - Properly type this. Not sure how this should be typed
};

const PublicRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const authenticated = useSelector(isAuthenticated);

  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Redirect
            to={{
              pathname: '/overview',
              state: { from: props.location }
            }}
          />
        ) : (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        )
      }
    />
  );
};

export default PublicRoute;

import request from './request';
import deserialize from './deserialize';

export type Subscription = {
  id: string;
  plan: string;
};

export function getSubscription(merchantId: string) {
  return request<Subscription>({
    url: `/admin/api/v2/merchants/${merchantId}/subscription`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

import React from 'react';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    minWidth: 200
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  options: {
    key: string;
    label?: string;
    value: boolean;
  }[];
  onChange: (key: string, value: boolean) => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * CheckboxFilter renders a list of filters displayed as checkboxes
 */
const CheckboxFilter: React.FC<Props> = ({ options, onChange }) => {
  const classes = useStyles();

  const handleChange = (name: string) => (event: any) => {
    onChange(name, event.target.checked);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormGroup>
          {map(options, (opt, key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={opt.value}
                  onChange={handleChange(opt.key)}
                  value={key}
                  color="primary"
                />
              }
              label={opt.label || startCase(opt.key)}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default CheckboxFilter;

import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppBar from './AppBar';
import Drawer from './Drawer';
import Notification from '../../Notification';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  layout: {
    display: 'flex',
    flex: 1,
    height: '100%'
  },
  content: {
    flexGrow: 1,
    paddingTop: 48,
    overflowY: 'auto'
  },
  toolbar: theme.mixins.toolbar
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const AppLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.layout}>
        <AppBar
          onMenuPress={() => setIsDrawerOpen(!isDrawerOpen)}
          isDrawerOpen={isDrawerOpen}
        />

        <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />

        <main className={classes.content}>{children}</main>

        <Notification />
      </div>
    </React.Fragment>
  );
};

export default AppLayout;

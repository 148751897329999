import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../../redux/auth/actions';
import * as profileActions from '../../../redux/profile/actions';
import { getProfile } from '../../../redux/profile/selectors';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import deepOrange from '@material-ui/core/colors/deepOrange';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 32,
    height: 32,
    color: theme.palette.common.white,
    backgroundColor: deepOrange[500],

    '&:hover': {
      cursor: 'pointer'
    }
  },
  popover: {
    borderRadius: 0,
    marginTop: 20
  },
  popoverContent: {
    display: 'flex',
    margin: 20
  },
  popoverAvatar: {
    width: 96,
    height: 96,
    color: theme.palette.common.white,
    backgroundColor: deepOrange[500],
    fontSize: 42,
    marginRight: 20
  },
  popoverInfo: {},
  button: {
    marginTop: theme.spacing(1)
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const AccountMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const profile = useSelector(getProfile);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const initials = profile && profile.firstName[0];
  const name = profile && `${profile.firstName} ${profile.lastName}`;
  const email = profile && `${profile.email}`;

  // Load the profile
  useEffect(() => {
    dispatch(profileActions.getProfile());
  }, [dispatch]);

  return (
    <div>
      <Avatar
        className={classes.avatar}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          setAnchorEl(e.currentTarget)
        }
      >
        {initials}
      </Avatar>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.popover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.popoverContent}>
          <Avatar className={classes.popoverAvatar}>{initials}</Avatar>
          <div className={classes.popoverInfo}>
            <Typography variant="subtitle2">{name}</Typography>
            <Typography variant="caption">{email}</Typography>
            <br />
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                if (window.confirm('Are you sure you want to sign out?')) {
                  dispatch(authActions.signout());
                }
              }}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default AccountMenu;

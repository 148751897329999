import { ApiRequest } from './request';
import pick from 'lodash/pick';
import { AttributesObject } from 'jsonapi-typescript';

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/stores/${id}`,
  method: 'GET',
  authenticated: true
});

const create = (
  name: string,
  address: string,
  lat: number,
  lng: number,
  neighborhood: string,
  phone: string,
  merchantId: string
): ApiRequest => ({
  path: `/admin/api/v2/stores`,
  method: 'POST',
  body: {
    data: {
      type: 'stores',
      attributes: {
        coords: {
          lat,
          lng
        },
        address,
        name,
        neighborhood,
        phone
      },
      relationships: {
        merchant: {
          data: {
            type: 'merchants',
            id: merchantId
          }
        }
      }
    }
  },
  authenticated: true
});

interface UpdateAttrs {
  id: string;
  address: string;
  coords: object;
  name: string;
  neighborhood: string;
  phone: string;
}
const update = (attrs: UpdateAttrs): ApiRequest => {
  const { id } = attrs;

  const attributes = pick(attrs, [
    'address',
    'name',
    'neighborhood',
    'phone',
    'coords'
  ]);

  return {
    path: `/admin/api/v2/stores/${id}`,
    method: 'PATCH',
    authenticated: true,
    body: {
      data: {
        type: 'stores',
        id: id,
        attributes: attributes as AttributesObject
      }
    }
  };
};

const removeStore = (storeId: string): ApiRequest => ({
  path: `/admin/api/v2/stores/${storeId}`,
  method: 'DELETE',
  authenticated: true
});

export default { create, get, update, removeStore };

import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

const listByLoyaltyCampaign = (id: string, opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/loyalty_campaigns/${id}/coupon_sets?${toListQueryString(
    opts
  )}`,
  method: 'GET',
  authenticated: true,
});

interface CreateAttrs {
  label: string;
  couponsCount: number;
  expiresAt: number;
  loyaltyCampaignId: string;
}
const create = ({
  label,
  couponsCount,
  expiresAt,
  loyaltyCampaignId,
}: CreateAttrs) => ({
  path: '/admin/api/v2/coupon_sets',
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'couponSets',
      attributes: {
        label,
        couponsCount,
        expiresAt,
      },
      relationships: {
        loyaltyCampaign: {
          data: {
            type: 'loyalty_campaigns',
            id: loyaltyCampaignId,
          },
        },
      },
    },
  },
});

export default {
  create,
  listByLoyaltyCampaign,
};

import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type UserList = {
  users: User[];
  pagination: PaginationResult;
};

export function listByIds(ids: string[]) {
  const opts: ListOptions = {
    pagination: { page: 1, per: ids.length },
    filters: { id: ids },
  };

  return request<UserList>({
    url: `/admin/api/v2/users?${toListQueryString(opts)}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        users: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function list(opts?: ListOptions) {
  return request<UserList>({
    url: `/admin/api/v2/users?${toListQueryString(opts)}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        users: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

import React, { useState, useEffect } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { getType } from 'typesafe-actions';

import { useDispatch, useSelector } from 'react-redux';
import * as campaignActions from '../../../redux/campaigns/actions';
import * as merchantActions from '../../../redux/merchants/actions';
import { getMerchantSelector } from '../../../redux/merchants/selectors';
import { promiseListener } from '../../../redux/store';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Column, Content, Grid } from '../../../components/Layout/Main';
import FeatureBar from '../../../components/FeatureBar';
import NewCampaignForm from './NewCampaignForm';
import { CampaignDoc } from '../../../api/resources';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  merchant: {
    marginLeft: theme.spacing()
  },
  title: {
    marginBottom: theme.spacing(4)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New: React.FC<Props> = ({
  match: {
    params: { id }
  }
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const merchant = useSelector(getMerchantSelector(id));
  const [redirectTo, setRedirectTo] = useState('');

  // Load the merchant
  useEffect(() => {
    dispatch(merchantActions.getMerchant(id));
  }, [dispatch, id]);

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   const {
  //     expiresAt,
  //     title,
  //     gender,
  //     minAge,
  //     maxAge,
  //     supply,
  //     notify,
  //     rewardExpiresAt,
  //     rewardTemplateId
  //   } = inputs;

  //   createCampaign({
  //     title,
  //     type: 'airdrop',
  //     gender,
  //     minAge,
  //     maxAge,
  //     supply: supply !== '' ? supply : 0,
  //     notify,
  //     expiresAt: expiresAt !== null ? expiresAt.utc().unix() : 0,
  //     rewardExpiresAt:
  //       rewardExpiresAt !== null
  //         ? rewardExpiresAt
  //             .endOf('day')
  //             .utc()
  //             .unix()
  //         : 0,
  //     merchantId: merchant !== undefined ? merchant.id : '',
  //     rewardTemplateId: rewardTemplateId !== undefined ? rewardTemplateId : ''
  //   });
  // };

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Content>
      <FeatureBar backEnabled>{merchant && merchant.name}</FeatureBar>

      <Grid>
        <Column xs={12}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              className={classes.title}
            >
              New Campaign
            </Typography>

            <MakeAsyncFunction
              listener={promiseListener}
              start={getType(campaignActions.createCampaign)}
              resolve={getType(campaignActions.api.createCampaign.success)}
              reject={getType(campaignActions.api.createCampaign.failure)}
            >
              {(createCampaign: any) => (
                <NewCampaignForm
                  merchantId={id}
                  onSubmit={async (values, actions) => {
                    try {
                      const rewardExpiresAt =
                        values.rewardExpiresAt != null
                          ? values.rewardExpiresAt.utc().unix()
                          : 0;
                      const expiresAt =
                        values.expiresAt != null
                          ? values.expiresAt.utc().unix()
                          : 0;

                      const payload: CampaignDoc = await createCampaign({
                        ...values,
                        type: 'airdrop',
                        expiresAt,
                        rewardExpiresAt,
                        merchantId: id
                      });

                      actions.setSubmitting(false);
                      setRedirectTo(`/campaigns/${payload.data.id}`);
                    } catch {
                      actions.setSubmitting(false);
                      alert('There was an error submitting this form');
                    }
                  }}
                />
              )}
            </MakeAsyncFunction>
          </Paper>
        </Column>
      </Grid>
    </Content>
  );
};

export default New;

import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import { WelcomeCampaign } from './models';

import { WelcomeCampaign as WelcomeCampaignState } from '../../api/resources';

export const getWelcomeCampaignsState = (state: RootState) =>
  state.welcomeCampaigns;

export const getWelcomeCampaignById = (state: RootState) =>
  getWelcomeCampaignsState(state).byId;

export const getWelcomeCampaignByProgramId = (state: RootState) =>
  getWelcomeCampaignsState(state).byProgramId;

export const getWelcomeCampaignUI = (state: RootState) =>
  getWelcomeCampaignsState(state).ui;

/**
 * makeGetWelcomeCampaignByProgramId returns a function which gets the welcome
 * campaign matching the program id
 *
 * @param state
 * @param id
 */
export const makeGetWelcomeCampaignByProgramId = (programId: string) =>
  createSelector(
    [getWelcomeCampaignById, getWelcomeCampaignByProgramId],
    (byId, byProgramId) => {
      const campaignId = byProgramId[programId];
      const campaign = byId[campaignId];

      return !!campaign ? newWelcomeCampaign(campaign) : null;
    }
  );

export const getIsFetching = (state: RootState) =>
  getWelcomeCampaignUI(state).isFetching;

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const newWelcomeCampaign = (
  campaign: WelcomeCampaignState
): WelcomeCampaign => {
  const { id, attributes, relationships } = campaign;
  const { validityPeriodDays, createdAt, updatedAt, status, delayHours } =
    attributes;
  const {
    loyaltyProgram: {
      data: { id: programId },
    },
    rewardTemplate: {
      data: { id: rewardTemplateId },
    },
    reward: {
      data: { id: rewardId },
    },
  } = relationships;

  return {
    id,
    validityPeriodDays,
    programId,
    rewardTemplateId,
    rewardId,
    createdAt,
    updatedAt,
    status,
    delayHours,
  };
};

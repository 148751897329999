import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  content: {
    flex: 1
  },
  label: {
    color: theme.palette.grey['500'],
    marginBottom: theme.spacing(2)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  label: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Card: React.FC<Props> = ({ children, label }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.content}>
        <Typography variant="subtitle2" className={classes.label}>
          {label}
        </Typography>

        {children}
      </div>
    </Paper>
  );
};

export default Card;

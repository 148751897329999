import React, { useRef, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import { Merchant } from '../../../../../../redux/merchants/models';
import { RewardTemplate } from '../../../../../../redux/rewardTemplates/models';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import DefinitionListItem from '../../../../../../components/DefinitionListItem';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  avatar: {
    backgroundColor: theme.palette.grey['100']
  },
  avatarColorDefault: {
    color: theme.palette.text.primary
  },
  card: {
    maxWidth: 345
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  mediaPlaceholder: {
    paddingTop: '22%',
    paddingBottom: '22%',
    backgroundColor: theme.palette.grey['100'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  placeholderText: {
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  chip: {
    margin: `0 ${theme.spacing(1)}px 0 0`
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  rewardTemplate: RewardTemplate;
  merchant: Merchant;
  onDelete: () => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const RewardTemplateDetails: React.FC<Props> = ({
  rewardTemplate: { title, image, what, info, gift, sell },
  merchant: { logo, name },
  onDelete
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const prevOpen = React.useRef(open);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleDelete = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    onDelete();
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            src={logo}
            classes={{
              root: classes.avatar,
              colorDefault: classes.avatarColorDefault
            }}
          >
            {!logo && name[0]}
          </Avatar>
        }
        action={
          <React.Fragment>
            <IconButton
              aria-label="settings"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MoreVertIcon />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              transition
              disablePortal
              placement="bottom-end"
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow">
                        <MenuItem onClick={handleDelete}>
                          Delete Reward
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        }
        title={title}
      />
      {image ? (
        <CardMedia className={classes.media} image={image.versions.lg} />
      ) : (
        <div className={classes.mediaPlaceholder}>
          <Typography variant="subtitle1" className={classes.placeholderText}>
            Reward Image
          </Typography>
        </div>
      )}
      <CardContent>
        <List>
          <DefinitionListItem
            title="What You'll Get"
            value={<ReactMarkdown source={what} />}
          />

          <DefinitionListItem
            title="The Fine Print"
            value={<ReactMarkdown source={info} />}
          />
        </List>
        {gift && (
          <Chip
            className={classes.chip}
            size="medium"
            label="Gift"
            color="primary"
          />
        )}
        {sell && (
          <Chip
            className={classes.chip}
            size="medium"
            label="Sell"
            color="primary"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default RewardTemplateDetails;

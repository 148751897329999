import { ApiRequest } from './request';

interface CreateAttrs {
  validityPeriodDays: number;
  rewardTemplateId: string;
  loyaltyProgramId: string;
}
const create = (attrs: CreateAttrs): ApiRequest => ({
  path: `/admin/api/v2/birthday_campaigns`,
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'birthday_campaigns',
      attributes: {
        validityPeriodDays: attrs.validityPeriodDays,
      },
      relationships: {
        loyaltyProgram: {
          data: {
            type: 'loyalty_programs',
            id: attrs.loyaltyProgramId,
          },
        },
        rewardTemplate: {
          data: {
            type: 'reward_templates',
            id: attrs.rewardTemplateId,
          },
        },
      },
    },
  },
});

const getByLoyaltyProgramId = (loyaltyProgramId: string): ApiRequest => ({
  path: `/admin/api/v2/loyalty_programs/${loyaltyProgramId}/birthday_campaign`,
  method: 'POST',
  authenticated: true,
});

export default {
  create,
  getByLoyaltyProgramId,
};

import React from 'react';
import { getIn, FieldProps } from 'formik';

import AutocompletePlace, {
  Props as AutocompletePlaceProps,
  PlaceResult
} from '../../AutocompletePlace';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps &
  Omit<AutocompletePlaceProps, 'onChange'> & {
    md: string;
  };

type Place = {
  lat: number;
  lng: number;
  address: string;
  neighborhood: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const fieldToAutocompletePlace = ({
  form,
  field,
  TextFieldProps,
  ...props
}: Props): AutocompletePlaceProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    onPlaceSelected: result => {
      form.setFieldValue(name, transformPlaceResult(result));
    },
    disabled: isSubmitting,
    TextFieldProps: {
      onBlur: () => {
        form.setFieldTouched(name);
      },
      ...TextFieldProps
    },
    value: field.value ? field.value.address : ''
  };
};

// Transforms a PlaceResult into a Place
const transformPlaceResult = (place: PlaceResult | null): Place | null => {
  if (place && place.geometry) {
    const {
      address_components,
      formatted_address,
      geometry: { location }
    } = place;

    const neighborhoodComponent = address_components
      ? address_components
          .filter(address => {
            return address.types.includes('neighborhood');
          })
          .pop()
      : undefined;
    const neighborhood = neighborhoodComponent
      ? neighborhoodComponent.long_name
      : '';
    const address = formatted_address || '';

    return {
      lat: location.lat(),
      lng: location.lng(),
      address,
      neighborhood
    };
  }

  return null;
};

/**
 * ImageUploader uploads the image selected with and ImageSelect and it for
 * Formik.
 */
const AutoCompletePlaceAdapter: React.FC<Props> = props => (
  <AutocompletePlace {...fieldToAutocompletePlace(props)} />
);

export default AutoCompletePlaceAdapter;

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as analyticsActions from '../../redux/analytics/actions';
import * as analyticsSelectors from '../../redux/analytics/selectors';

import { StatisticCard } from '../../components/Card';
import DemographicsCard from './DemographicsCard';
import FeatureBar from '../../components/FeatureBar';
import { Column, Content, Grid } from '../../components/Layout/Main';
import MerchantRedemptionsCard from './MerchantRedemptionsCard';

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const Overview = () => {
  const dispatch = useDispatch();

  const genderDemographics = useSelector(
    analyticsSelectors.getGenderDemographics
  );
  const redemptionsAnalytics = useSelector(
    analyticsSelectors.getRedemptionsAnalytics
  );
  const signupDemographics = useSelector(
    analyticsSelectors.getSignupDemographics
  );
  const redemptions = useSelector(analyticsSelectors.getRedemptionsByMerchant);

  useEffect(() => {
    dispatch(analyticsActions.getDashboardAnalytics());
  }, [dispatch]);

  return (
    <Content>
      <FeatureBar>Overview</FeatureBar>

      <Grid>
        <Column xs={12} sm={6} lg={3}>
          <StatisticCard label="New Users" value={signupDemographics.today} />
        </Column>

        <Column xs={12} sm={6} lg={3}>
          <StatisticCard label="Total Users" value={signupDemographics.total} />
        </Column>

        <Column xs={12} sm={6} lg={3}>
          <StatisticCard
            label="New Redemptions"
            value={redemptionsAnalytics.today}
          />
        </Column>

        <Column xs={12} sm={6} lg={3}>
          <StatisticCard
            label="Total Redemptions"
            value={redemptionsAnalytics.total}
          />
        </Column>

        <Column xs={12} sm={6} lg={6}>
          <DemographicsCard
            male={genderDemographics.male}
            female={genderDemographics.female}
            unknown={genderDemographics.unknown}
          />
        </Column>

        <Column xs={12} sm={6} lg={6}>
          <MerchantRedemptionsCard redemptions={redemptions} />
        </Column>
      </Grid>
    </Content>
  );
};

export default Overview;

import { createAction, createAsyncAction } from 'typesafe-actions';
import { FilterOptions, PaginationOptions } from '../types';

import { APIError } from '../api/models';
import { RewardDoc, RewardCollectionDoc } from '../../api/resources/reward';

/**
 * Gets a reward
 */
export const getReward = createAction('rewards/GET', (id: string) => id)();

/**
 * List reward
 */
export const listRewards = createAction(
  'rewards/LIST',
  (opts: { pagination: PaginationOptions; filters: FilterOptions }) => opts
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchReward: createAsyncAction(
    'api/rewards/FETCH_REQUEST',
    'api/rewards/FETCH_SUCCESS',
    'api/rewards/FETCH_FAILURE'
  )<any, RewardDoc, APIError>(),

  listRewards: createAsyncAction(
    'api/rewards/LIST_REQUEST',
    'api/rewards/LIST_SUCCESS',
    'api/rewards/LIST_FAILURE'
  )<any, RewardCollectionDoc, APIError>(),
};

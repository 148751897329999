import React, { useEffect, useCallback, useState } from 'react';

import { dateFormat } from '../../../../../../../lib/date';
import { LoadingCard } from '../../../../../../../components/Card';
import DataTable from '../../../../../../../components/DataTable';
import usePaginationQuery from '../../../../../../../hooks/usePaginationQuery';
import * as couponSetService from '../../../../../../../api/services/couponSetService';
import { PaginationResult } from '../../../../../../../api/services/request';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  campaignId: string;
};

type TableRow = {
  id: string;
  label: string;
  couponCount: number;
  createdAt: number;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const columns = [
  { title: 'Coupon Set ID', field: 'id' },
  { title: 'Label', field: 'label' },
  { title: 'Coupon Count', field: 'couponCount' },
  { title: 'Created', field: 'createdAt', format: dateFormat },
];

const CouponSetsTable: React.FC<Props> = ({ campaignId }) => {
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [pagination, setPagination] =
    useState<PaginationResult | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);

  // Fetch the coupon sets list
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        setIsFetching(true);
        // Fetch the coupon set
        const res = await couponSetService.listByCampaign(campaignId, {
          pagination: { page, per },
          sort: [
            {
              key: 'createdAt',
              direction: 'desc',
            },
          ],
        });

        const tableRows = res.couponSets.map((m) => {
          return {
            id: m.id,
            label: m.label,
            couponCount: m.couponsCount,
            createdAt: m.createdAt,
          };
        });

        if (isMounted) {
          setRows(tableRows);
          setPagination(res.pagination);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [page, per, campaignId]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  if (isFetching) {
    return <LoadingCard />;
  }

  return (
    <DataTable
      title="Coupon Sets"
      columns={columns}
      data={rows}
      PaginationProps={
        pagination && {
          count: pagination.total,
          rowsPerPageOptions: [10, 25, 50],
          rowsPerPage: pagination.per,
          page: pagination.page,
          onChangePage: handleChangePage,
          onChangeRowsPerPage: (event) => {
            updatePer(event.target.value.toString());
          },
        }
      }
    />
  );
};

export default CouponSetsTable;

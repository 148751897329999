import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as campaignActions from '../../../../../../redux/campaigns/actions';
import * as campaignSelectors from '../../../../../../redux/campaigns/selectors';

import ButtonLink from '../../../../../../components/ButtonLink';
import DataTable from '../../../../../../components/DataTable';
import { dateFormat } from '../../../../../../lib/date';
import { Column, Grid } from '../../../../../../components/Layout/Main';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const columns = [
  { title: 'ID', field: 'id' },
  { title: 'Title', field: 'title' },
  { title: 'Type', field: 'type' },
  { title: 'Status', field: 'status' },
  {
    title: 'Expires At',
    field: 'expiredAt',
    format: dateFormat
  }
];

const TabCampaigns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const campaigns = useSelector(campaignSelectors.listMerchantCampaigns(id));
  const isFetching = useSelector(campaignSelectors.isFetching);

  // Load merchant campaigns
  useEffect(() => {
    dispatch(campaignActions.listMerchantCampaigns(id));
  }, [dispatch, id]);

  return (
    <Grid>
      <Column xs={12} sm={12}>
        <DataTable
          Actions={
            <ButtonLink
              to={`/merchants/${id}/campaigns/new`}
              variant="contained"
              size="small"
              color="primary"
            >
              New Campaign
            </ButtonLink>
          }
          title="Campaigns"
          isLoading={isFetching}
          columns={columns}
          data={campaigns}
          onRowClick={row => history.push(`/campaigns/${row[0].value}`)}
        />
      </Column>
    </Grid>
  );
};

export default TabCampaigns;

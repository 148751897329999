import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import DataTable from '../DataTable';
import * as StampCampaignService from '../../api/services/stampCampaignService';
import { PaginationResult } from '../../api/services/request';

import { dateFormat } from '../../lib/date';
import usePaginationQuery from '../../hooks/usePaginationQuery';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  programId: string;
  merchantName: string;
};

type TableRow = {
  campaignStart: number;
  campaignEnd: number;
  id: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const StampCampaignList: React.FC<Props> = ({ programId, merchantName }) => {
  const classes = useStyles();
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );

  const columns = [
    {
      title: 'Campaign Id',
      field: 'id',
      width: '30%',
    },
    {
      title: 'Campaign Start',
      field: 'campaignStart',
      format: dateFormat,
      width: '20%',
    },
    {
      title: 'Campaign End',
      field: 'campaignEnd',
      format: dateFormat,
      width: '20%',
    },
    {
      title: 'Download List',
      field: 'id',
      format: (value: any) => (
        <Button
          variant="contained"
          color="primary"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            downloadMemberList(value);
          }}
        >
          Download
        </Button>
      ),
    },
  ];

  // Fetch the stamp campaign list
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        // Fetch the stampcampaign list
        const res =
          await StampCampaignService.listInactivePrizeCampaignByProgram(
            programId,
            {
              pagination: { page, per },
              sort: [
                {
                  key: 'updatedAt',
                  direction: 'desc',
                },
              ],
            }
          );

        const tableRows = res.campaigns.map((sc) => {
          return {
            campaignStart: sc.createdAt,
            campaignEnd: sc.updatedAt,
            id: sc.id,
          };
        });

        if (isMounted) {
          setRows(tableRows);
          setPagination(res.pagination);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [programId, page, per]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  // Download the file
  const downloadMemberList = useCallback(
    async (campaignId: string) => {
      try {
        const currentDate = moment().format('YYYYMMDD');
        const response = await StampCampaignService.exportByCompletedStampCard(
          campaignId
        );

        let a = document.createElement('a');
        a.href = 'data:,' + response;
        a.download =
          merchantName +
          '_campaignId_' +
          campaignId +
          '_completedStampCardlist_' +
          currentDate +
          '.csv';
        a.click();
      } catch (e) {
        console.log('error: ', e);
      }
    },
    [merchantName]
  );

  return (
    <div className={classes.root}>
      <DataTable
        title="Prize-Draw Stamp Card Successful Customers Lists"
        columns={columns}
        data={rows}
        PaginationProps={
          pagination && {
            count: pagination.total,
            rowsPerPageOptions: [10, 25, 50],
            rowsPerPage: pagination.per,
            page: pagination.page,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: (event) => {
              updatePer(event.target.value.toString());
            },
          }
        }
      />
    </div>
  );
};

export default StampCampaignList;

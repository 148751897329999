import React, { useState } from 'react';
import { Redirect } from 'react-router';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FeatureBar from '../../../components/FeatureBar';
import { Column, Content, Grid } from '../../../components/Layout/Main';
import NewStockImageForm from './NewStockImageForm';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New = () => {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState('');

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Content>
      <FeatureBar backEnabled>Stock Images</FeatureBar>

      <Grid>
        <Column xs={12}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              className={classes.title}
            >
              New Stock Image
            </Typography>

            <NewStockImageForm
              onSubmit={(values, actions) => {
                if (values.stockImageId) {
                  actions.setSubmitting(false);
                  setRedirectTo(`/stockImages`);
                }
              }}
            />
          </Paper>
        </Column>
      </Grid>
    </Content>
  );
};

export default New;

import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as userActions from './actions';
import * as merchantActions from '../merchants/actions';
import { User } from '../../api/resources';

export type UserActions =
  | ActionType<typeof userActions>
  | ActionType<typeof merchantActions>;

/**
 * byId contains all the user objects
 */

interface UsersById {
  [key: string]: User;
}

const byId = createReducer<UsersById, UserActions>({}).handleAction(
  [
    userActions.api.fetchUserList.success,
    userActions.api.fetchUserByIdsList.success,
    userActions.api.fetchUser.success,
    userActions.api.fetchAutocompleteUserList.success
  ],
  (state, action) => {
    const { users } = normalize(action.payload);

    return users ? { ...state, ...users } : state;
  }
);

/**
 *
 */

const autocompleteIds = createReducer<string[], UserActions>([]).handleAction(
  userActions.api.fetchAutocompleteUserList.success,
  (_, action) => {
    return action.payload.data.map(user => user.id);
  }
);

/**
 * pagination contains the pagination data for users
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, UserActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 25
}).handleAction([userActions.api.fetchUserList.success], (state, action) => {
  const payload = action.payload;
  const { page, count, total, per } = payload.meta;

  return {
    ...state,
    ids: payload.data.map(user => user.id),
    count,
    page,
    total,
    per
  };
});

/**
 * ui
 */

interface UsersUI {
  isFetching: boolean;
}
const ui = createReducer<UsersUI, UserActions>({
  isFetching: false
})
  .handleAction(
    [
      userActions.api.fetchUserList.success,
      userActions.api.fetchUserList.failure,
      userActions.api.fetchUser.success,
      userActions.api.fetchUser.failure
    ],
    state => ({ ...state, ...{ isFetching: false } })
  )
  .handleAction(
    [userActions.api.fetchUserList.request, userActions.api.fetchUser.request],
    state => ({
      ...state,
      ...{ isFetching: true }
    })
  );

const usersReducer = combineReducers({
  byId,
  pagination,
  autocompleteIds,
  ui
});

export default usersReducer;
export type UsersState = ReturnType<typeof usersReducer>;

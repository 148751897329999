import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as kycActions from '../kyc/actions';
import { KYCData } from '../../api/resources';

export type KYCActions = ActionType<typeof kycActions>;

/**
 * byId contains all the KYC objects
 */

interface KYCById {
  [key: string]: KYCData;
}

const byId = createReducer<KYCById, KYCActions>({}).handleAction(
  [
    kycActions.api.fetchUserKYC.success,
    kycActions.api.approveUserKYC.success,
    kycActions.api.rejectUserKYC.success
  ],
  (state, action) => {
    const kyc = normalize(action.payload).kyc;

    return { ...state, ...kyc };
  }
);

const kycReducer = combineReducers({
  byId
});

export default kycReducer;
export type KYCState = ReturnType<typeof kycReducer>;

import { SagaIterator } from 'redux-saga';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { api, getProfile } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';
import { getCurrentUserId } from '../auth/selectors';

export function* getProfileSaga(
  _: ActionType<typeof getProfile>
): SagaIterator {
  const userId = yield select(getCurrentUserId);

  if (userId) {
    yield put(callAPI(apiRequests.profile.get(userId), api.fetchProfile));
  }
}

export default all([takeLatest(getType(getProfile), getProfileSaga)]);

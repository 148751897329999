import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import reduce from 'lodash/reduce';

import * as campaignActions from '../campaigns/actions';
import * as merchantActions from '../merchants/actions';
import { Campaign, CampaignStats } from '../../api/resources';
import produce from 'immer';

export type CampaignActions = ActionType<
  typeof merchantActions | typeof campaignActions
>;

/**
 * byId contains all the campaign objects
 */

interface CampaignsById {
  [key: string]: Campaign;
}

const byId = createReducer<CampaignsById, CampaignActions>({}).handleAction(
  [
    campaignActions.api.listMerchantCampaigns.success,
    campaignActions.api.listCampaigns.success,
    campaignActions.api.fetchCampaign.success,
    campaignActions.api.createCampaign.success
  ],
  (state, action) => {
    const { campaigns } = normalize(action.payload);

    return campaigns ? { ...state, ...campaigns } : state;
  }
);

/**
 * pagination contains the pagination data for campaigns
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, CampaignActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 50
}).handleAction(
  [campaignActions.api.listCampaigns.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map(user => user.id),
      count: count,
      page: page,
      total: total,
      per: per
    };
  }
);

/**
 * The campaigns ids keyed to the merchant
 */
interface Merchants {
  [key: string]: string[];
}

const merchants = createReducer<Merchants, CampaignActions>({})
  .handleAction(
    [
      campaignActions.api.listMerchantCampaigns.success,
      campaignActions.api.listCampaigns.success
    ],
    (state, action) => {
      const { data } = action.payload;

      const ms = reduce(
        data,
        (merchants, campaign) => {
          const merchantId = campaign.relationships.merchant.data.id;
          const id = campaign.id;

          if (merchants[merchantId]) {
            merchants[merchantId].push(id);
          } else {
            merchants[merchantId] = [id];
          }

          return merchants;
        },
        {} as Merchants
      );

      return { ...state, ...ms };
    }
  )
  .handleAction(
    [campaignActions.api.createCampaign.success],
    (state, action) => {
      const { id, relationships } = action.payload.data;

      return produce(state, draft => {
        if (draft[id] === undefined) {
          draft[relationships.merchant.data.id] = [];
        }

        draft[relationships.merchant.data.id].push(id);
      });
    }
  );

/**
 * ui
 */

interface CampaignUI {
  isFetching: boolean;
}
const ui = createReducer<CampaignUI, CampaignActions>({
  isFetching: false
})
  .handleAction(
    [
      campaignActions.api.listMerchantCampaigns.success,
      campaignActions.api.listMerchantCampaigns.failure,
      campaignActions.api.listCampaigns.success,
      campaignActions.api.listCampaigns.failure
    ],
    state => ({
      ...state,
      ...{ isFetching: false }
    })
  )
  .handleAction(
    [
      campaignActions.api.listMerchantCampaigns.request,
      campaignActions.api.listCampaigns.request
    ],
    state => ({
      ...state,
      ...{ isFetching: true }
    })
  );

/**
 * stats contain the campaign statistic
 */

interface CampaignsStatsById {
  [key: string]: CampaignStats;
}

const stats = createReducer<CampaignsStatsById, CampaignActions>(
  {}
).handleAction(
  [campaignActions.api.fetchCampaignStats.success],
  (state, action) => {
    const { campaignStats } = normalize(action.payload);

    return campaignStats ? { ...state, ...campaignStats } : state;
  }
);

/**
 * Campaign root reducer
 */
const campaignsReducer = combineReducers({
  byId,
  merchants,
  ui,
  pagination,
  stats
});

export default campaignsReducer;
export type CampaignsState = ReturnType<typeof campaignsReducer>;

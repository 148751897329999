import { RootState } from '../reducers';
import { RewardTemplate } from './models';

import pick from 'lodash/pick';
import values from 'lodash/values';
import { RewardTemplate as RewardTemplateState } from '../../api/resources/rewardTemplate';
import { getMerchant } from '../merchants/selectors';
import { getImage } from '../images/selectors';

/**
 * Get the reward template state
 *
 * @param state
 */
export const getRewardTemplatesState = (state: RootState) =>
  state.rewardTemplates;

/**
 * Get a reward template
 *
 * @param state
 * @param id
 */
export const getRewardTemplate = (state: RootState, id: string) => {
  const rewardTemplate = getRewardTemplatesState(state).byId[id];

  if (!rewardTemplate) {
    return undefined;
  }

  return makeRewardTemplate(state)(rewardTemplate);
};

/**
 * Get merchant's reward template
 *
 * @param state
 * @param merchantId
 */
export const getMerchantRewardTemplateIds = (
  state: RootState,
  merchantId: string
) => getRewardTemplatesState(state).merchants[merchantId];

/**
 * Get list of reward templates based on merchant id
 *
 * @param state
 * @param merchantId
 */
export const listRewardTemplatesByMerchantId = (merchantId: string) => (
  state: RootState
) => {
  const allRewardTemplates = getRewardTemplatesState(state).byId;
  const ids = getMerchantRewardTemplateIds(state, merchantId);
  const merchants = pick(allRewardTemplates, ids);

  return values(merchants).map(makeRewardTemplate(state));
};

/**
 * Are reward templates being fetched?
 * @param state
 */
export const isFetching = (state: RootState) =>
  getRewardTemplatesState(state).ui.isFetching;

/**
 * Get the pagination data
 *
 * @param state
 */
export const getPagination = (state: RootState) =>
  getRewardTemplatesState(state).pagination;

/**
 * Gets the reward template by id.
 *
 * This is a experimental function to be used in useSelector hooks
 *
 * @param state
 * @param id
 */
export const getRewardTemplateSelector = (id: string) => (
  state: RootState
): RewardTemplate | undefined => {
  const rewardTemplate = getRewardTemplatesState(state).byId[id];

  if (!rewardTemplate) {
    return undefined;
  }

  return makeRewardTemplate(state)(rewardTemplate);
};

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeRewardTemplate = (state: RootState) => (
  rewardTemplate: RewardTemplateState
): RewardTemplate => {
  const { id, attributes, relationships } = rewardTemplate;
  const { title, type, what, info, gift, sell } = attributes;

  return {
    id,
    title,
    type,
    merchant: getMerchant(state, relationships.merchant.data.id),
    image: getImage(state, relationships.images.data[0].id),
    what,
    info,
    gift,
    sell,
    rewardTemplateId: id,
  };
};

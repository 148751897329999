export const reverse = async (lat: number, lng: number) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
    process.env.REACT_APP_GOOGLE_API_KEY
  }`;

  const response = await fetch(url);
  const json = await response.json();

  // TODO - Handle error

  return json.results && json.results.length > 0 ? json.results[0] : null;
};

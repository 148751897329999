import produce from 'immer';
import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import * as birthdayCampaignActions from './actions';
import { BirthdayCampaignResource } from '../../api/resources';

export type BirthdayCampaignActions = ActionType<
  typeof birthdayCampaignActions
>;

/**
 * byId contains all the birthday Campaign objects
 */
interface ByIdState {
  [key: string]: BirthdayCampaignResource;
}

const byId = createReducer<ByIdState, BirthdayCampaignActions>({}).handleAction(
  [
    birthdayCampaignActions.api.createBirthdayCampaign.success,
    birthdayCampaignActions.api.getLoyaltyProgramBirthdayCampaign.success,
  ],
  (state, action) => {
    const { birthdayCampaigns } = normalize(action.payload);

    return birthdayCampaigns ? { ...state, ...birthdayCampaigns } : state;
  }
);

/**
 * The birthday campaign associated to a loyalty program
 */
interface ProgramRelationshipsState {
  [key: string]: string[];
}

const programRelationships = createReducer<
  ProgramRelationshipsState,
  BirthdayCampaignActions
>({}).handleAction(
  [
    birthdayCampaignActions.api.createBirthdayCampaign.success,
    birthdayCampaignActions.api.getLoyaltyProgramBirthdayCampaign.success,
  ],
  (state, action) => {
    const { data } = action.payload;
    const programId = data.relationships.loyaltyProgram.data.id;

    return produce(state, (draft) => {
      draft[programId] = [data.id];
    });
  }
);

interface UI {
  isFetching: boolean;
}
const ui = createReducer<UI, BirthdayCampaignActions>({
  isFetching: false,
})
  .handleAction(
    [
      birthdayCampaignActions.api.getLoyaltyProgramBirthdayCampaign.success,
      birthdayCampaignActions.api.getLoyaltyProgramBirthdayCampaign.failure,
    ],
    (state) => ({ ...state, ...{ isFetching: false } })
  )
  .handleAction(
    [birthdayCampaignActions.api.getLoyaltyProgramBirthdayCampaign.request],
    (state) => ({
      ...state,
      ...{ isFetching: true },
    })
  );

/**
 * Birthday Campaign root reducer
 */
const birthdayCampaignsReducer = combineReducers({
  byId,
  programRelationships,
  ui,
});

export default birthdayCampaignsReducer;
export type birthdayCampaignsState = ReturnType<
  typeof birthdayCampaignsReducer
>;

import React from 'react';

type Props = {
  height: string;
  width: string;
  color: string;
};

const Clock: React.FC<Props> = ({ height, width, color }: Props) => (
  <svg width={width} height={height} viewBox="0 0 30 30" version="1.1">
    <g id="Expiry" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M15.0674879,6 C20.0548629,6 24.1349759,10.0498411 24.1349759,14.9999294 C24.1349759,19.9502531 20.0548629,24 15.0674879,24 C10.0300208,24 6,19.9502531 6,14.9999294 C6,10.049794 10.0300208,6 15.0674879,6 Z M14.0599945,10.7556044 L14.0599945,15.3638017 C14.0599945,15.7583213 14.267664,16.1237129 14.6066226,16.3255862 L18.2180305,18.476429 C18.5652588,18.6832275 19.0143034,18.5709734 19.2233748,18.2251089 C19.428779,17.8853108 19.3198313,17.443337 18.9800332,17.2379328 C18.9767966,17.2359763 18.9735446,17.2340453 18.9702775,17.2321401 L15.5712347,15.2500137 L15.5712347,10.7556044 C15.5712347,10.3382869 15.232932,9.99998431 14.8156146,9.99998431 C14.3982971,9.99998431 14.0599945,10.3382869 14.0599945,10.7556044 Z"
        id="Shape"
        fill={color}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default Clock;

import React from 'react';
import startCase from 'lodash/startCase';

import { User } from '../../../redux/users/models';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Link from '../../../components/Link';
import DefinitionListItem from '../../../components/DefinitionListItem';
import { dateFormat } from '../../../lib/date';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  cardHeader: {
    paddingBottom: 0
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  kycContainer: {
    display: 'flex'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  user: User;
  address?: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const UserDetails: React.FC<Props> = ({
  address,
  user: {
    id,
    email,
    initials,
    name,
    kycStatus,
    referralCode,
    status,
    gender,
    createdAt
  }
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{initials}</Avatar>}
        title={name}
        subheader={email}
        className={classes.cardHeader}
      />
      <CardContent>
        <List>
          <DefinitionListItem title="Joined On" value={dateFormat(createdAt)} />
          <DefinitionListItem
            title="Gender"
            value={gender ? startCase(gender) : 'Unknown'}
          />
          <DefinitionListItem title="Status" value={startCase(status)} />
          <DefinitionListItem
            title="KYC"
            value={
              <div className={classes.kycContainer}>
                <Typography variant="body2" style={{ flex: 1 }}>
                  {startCase(kycStatus)}
                </Typography>

                {kycStatus === 'pending' && (
                  <Link to={`/users/${id}/kyc`} variant="body2">
                    Verify
                  </Link>
                )}
              </div>
            }
          />
          <DefinitionListItem title="Referral Code" value={referralCode} />
          <DefinitionListItem title="Last Known Location" value={address} />
        </List>
      </CardContent>
    </Card>
  );
};

export default UserDetails;

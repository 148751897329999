import { createAction, createAsyncAction } from 'typesafe-actions';
import { PaginationOptions, FilterOptions } from '../types';

import { APIError } from '../api/models';
import { UserCollectionDoc, UserDoc } from '../../api/resources/user';

/**
 * List users
 */
export const listUsers = createAction(
  'users/LIST',
  (opts: {
    pagination: PaginationOptions;
    filters?: FilterOptions;
    query?: string;
  }) => opts
)();

/**
 * List users by the specific ids
 */
export const listUsersByIds = createAction(
  'merchants/LIST_BY_IDS',
  (ids: string[]) => ids
)();

/**
 * Fetch the users for an autocomplete field
 */
export const autocompleteUsers = createAction(
  'users/AUTOCOMPLETE',
  (query: string) => query
)();

/**
 * Gets the user by id
 */
export const getUser = createAction('users/GET', (id: string) => id)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchUserList: createAsyncAction(
    'api/users/FETCH_LIST_REQUEST',
    'api/users/FETCH_LIST_SUCCESS',
    'api/users/FETCH_LIST_FAILURE'
  )<any, UserCollectionDoc, APIError>(),

  fetchUserByIdsList: createAsyncAction(
    'api/users/FETCH_BY_IDS_LIST_REQUEST',
    'api/users/FETCH_BY_IDS_LIST_SUCCESS',
    'api/users/FETCH_BY_IDS_LIST_FAILURE'
  )<any, UserCollectionDoc, APIError>(),

  fetchUser: createAsyncAction(
    'api/users/FETCH_REQUEST',
    'api/users/FETCH_SUCCESS',
    'api/users/FETCH_FAILURE'
  )<any, UserDoc, APIError>(),

  fetchAutocompleteUserList: createAsyncAction(
    'api/users/FETCH_AUTOCOMPLETE_LIST_REQUEST',
    'api/users/FETCH_AUTOCOMPLETE_LIST_SUCCESS',
    'api/users/FETCH_AUTOCOMPLETE_LIST_FAILURE'
  )<any, UserCollectionDoc, APIError>()
};

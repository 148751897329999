import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type RewardCard = {
  id: string;
  title: string;
  status: 'received' | 'collected' | 'redeemed';
  expiresAt: number;
  receivedAt: number;
  redemmedAt: number;
  campaign: {
    type:
      | 'airdrop_campaigns'
      | 'loyalty_campaigns'
      | 'birthday_campaigns'
      | 'welcome_campaigns';
    id: string;
  };
  owner: {
    type: 'users';
    id: string;
  };
};

export type RewardCardList = {
  cards: RewardCard[];
  pagination: PaginationResult;
};

export function listByOwner(ownerId: string, opts?: ListOptions) {
  return request<RewardCardList>({
    url: `/admin/api/v2/users/${ownerId}/cards?${toListQueryString(opts)}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        cards: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function listByAirdropCampaign(campaignId: string, opts?: ListOptions) {
  return request<RewardCardList>({
    url: `/admin/api/v2/campaigns/${campaignId}/cards?${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        cards: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function expireAllMerchantRewardCards(merchantId: string) {
  return request({
    url: `/admin/api/v2/merchants/${merchantId}/reward_cards/expire`,
    method: 'PATCH',
    transformResponse: deserialize,
  });
}

import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { getRewardTemplateSelector } from '../../redux/rewardTemplates/selectors';

import Avatar from '@material-ui/core/Avatar';
import Button, {
  ButtonProps as MuiButtonProps
} from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from './Dialog';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface StyleProps {
  error?: boolean;
}

export type Props = {
  ButtonProps?: MuiButtonProps;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  merchantId: string;
  onChange?: (id: string) => void;
  value?: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  button: (props: StyleProps) => ({
    borderColor: props.error ? theme.palette.error.main : undefined
  }),
  listItem: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    padding: 8
  },
  listItemAvatarRoot: {
    minWidth: 66
  },
  avatar: {
    width: 50,
    height: 50
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * RewardTemplateSelect provides a custom UI for selecting a reward template by
 * clicking on a button to bring up a modal with a list of all templates.
 */
const RewardTemplateSelect: React.FC<Props> = ({
  ButtonProps,
  disabled,
  error,
  helperText,
  merchantId,
  onChange,
  value
}) => {
  const classes = useStyles({ error });
  const template = useSelector(getRewardTemplateSelector(value ? value : ''));
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => setIsDialogOpen(true), [
    setIsDialogOpen
  ]);

  return (
    <>
      <FormControl>
        {template ? (
          <ListItem
            button
            disableGutters
            disabled={disabled}
            onClick={openDialog}
            className={classes.listItem}
          >
            <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
              <Avatar
                variant="rounded"
                className={classes.avatar}
                src={template.image ? template.image.versions['xl'] : undefined}
              />
            </ListItemAvatar>
            <ListItemText primary={template.title} />
          </ListItem>
        ) : (
          <Button
            fullWidth
            variant="outlined"
            onClick={openDialog}
            className={classes.button}
            disabled={disabled}
            {...ButtonProps}
          >
            Select a reward template
          </Button>
        )}

        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>

      <Dialog
        merchantId={merchantId}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onChange={onChange}
      />
    </>
  );
};

export default RewardTemplateSelect;

import React, { useEffect, useMemo } from 'react';
import {
  Link as RouterLink,
  RouteComponentProps,
  Route,
  Switch,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as merchantActions from '../../../redux/merchants/actions';
import { getMerchantSelector } from '../../../redux/merchants/selectors';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FeatureBar from '../../../components/FeatureBar';
import { Content } from '../../../components/Layout/Main';
import TabOverview from './Tabs/TabOverview';
import TabCampaigns from './Tabs/TabCampaigns';
import TabLoyalty from './Tabs/TabLoyalty';
import TabRewardTemplates from './Tabs/TabRewardTemplates';
import TabStores from './Tabs/TabStores';
import useTabSelector from '../../../hooks/useTabSelector';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
  },
  tabsRoot: {
    height: 3,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.common.white,
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const Show: React.FC<Props> = ({
  match: {
    path,
    url,
    params: { id },
  },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const merchant = useSelector(getMerchantSelector(id));
  const tabPaths = useMemo(
    () => [
      path,
      `${path}/campaigns`,
      `${path}/reward_templates`,
      `${path}/stores`,
      `${path}/loyalty`,
    ],
    [path]
  );
  const selectedIndex = useTabSelector(tabPaths);

  // Load the merchant
  useEffect(() => {
    dispatch(merchantActions.getMerchant(id));
  }, [dispatch, id]);

  return (
    <Content>
      <FeatureBar backEnabled>{merchant && merchant.name}</FeatureBar>

      <AppBar position="static" className={classes.appBar}>
        {selectedIndex !== null && (
          <Tabs
            value={selectedIndex}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab component={RouterLink} label="Overview" to={url} />
            <Tab
              component={RouterLink}
              label="Campaigns"
              to={`${url}/campaigns`}
            />
            <Tab
              component={RouterLink}
              label="Reward Templates"
              to={`${url}/reward_templates`}
            />
            <Tab component={RouterLink} label="Stores" to={`${url}/stores`} />
            <Tab component={RouterLink} label="Loyalty" to={`${url}/loyalty`} />
          </Tabs>
        )}
      </AppBar>

      <Switch>
        <Route exact path={path} component={TabOverview} />
        <Route path={`${path}/campaigns`} component={TabCampaigns} />
        <Route
          path={`${path}/reward_templates`}
          component={TabRewardTemplates}
        />
        <Route path={`${path}/stores`} component={TabStores} />
        <Route path={`${path}/loyalty`} component={TabLoyalty} />
      </Switch>
    </Content>
  );
};

export default Show;

import React from 'react';

import RewardTemplateSelect from '../../RewardTemplateSelect/Select';
import { TextFieldProps, fieldToTextField } from 'formik-material-ui';

type Props = TextFieldProps & { merchantId: string };

/**
 * Wraps a Material UI TextField for formik usage.
 *
 * This uses the `formik-material-ui` adapter function.
 *
 * @param props
 */
const RewardTemplateSelectAdapter: React.FC<Props> = ({
  children,
  merchantId,
  ...props
}) => (
  <RewardTemplateSelect merchantId={merchantId} {...fieldToTextField(props)} />
);

export default RewardTemplateSelectAdapter;

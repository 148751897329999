import React from 'react';
import { range } from 'lodash';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import MenuItem from '@material-ui/core/MenuItem';

import { SubmitButton } from '../../../../../../../components/Form';
import { TextField } from '../../../../../../../components/FormikAdapter';
import {
  FormActions,
  FormRow,
  FormSection,
} from '../../../../../../../components/FormLayout';

const MAX_STAMPS = 50;

const REDEMPTION_COST_MENU_ITEMS = range(1, MAX_STAMPS + 1).map((i) => ({
  value: i,
  label: i.toString(),
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface NewStampCampaignFormValues {
  description: string;
  redemptionCost: number;
}

type Props = {
  onSubmit: (
    values: NewStampCampaignFormValues,
    actions: FormikHelpers<NewStampCampaignFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required('Required'),
  redemptionCost: Yup.number().required('Required'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

export default function NewStampCampaignForm({ onSubmit }: Props) {
  const initialValues: NewStampCampaignFormValues = {
    description: '',
    redemptionCost: 5,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form translate="no" noValidate>
          <FormSection title="Details">
            <FormRow>
              <Field
                component={TextField}
                name="redemptionCost"
                label="Redemption Cost"
                autoFocus
                fullWidth
                required
                select
              >
                {REDEMPTION_COST_MENU_ITEMS.map((cost, idx) => (
                  <MenuItem value={cost.value} key={idx}>
                    {cost.label}
                  </MenuItem>
                ))}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                component={TextField}
                name="description"
                label="Description"
                fullWidth
                required
              />
            </FormRow>
          </FormSection>

          <FormActions>
            <SubmitButton isSubmitting={isSubmitting}>Create</SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
}

import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ButtonLink from '../../../../../../components/ButtonLink';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  title: string;
  linkText: string;
  to: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const BlankPage: React.FC<Props> = ({ linkText, title, to }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>

        <ButtonLink variant="contained" color="primary" size="large" to={to}>
          {linkText}
        </ButtonLink>
      </div>
    </Paper>
  );
};

export default BlankPage;

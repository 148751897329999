import React, { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import FiltersSection from './FiltersSection';
import MenuSection from './MenuSection';
import SelectedSection, {
  Props as SelectedSectionProps
} from './SelectedSection';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  popoverPaper: {
    borderRadius: 1
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[200]}`
  },
  actionButton: {
    margin: `${theme.spacing()}px ${theme.spacing(2)}px`
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<PopoverProps, 'anchorEl' | 'onClose'> &
  Pick<SelectedSectionProps, 'selected'> & {
    menuLabels: string[];
    FilterPages: React.ReactNode[];
    onApply: () => void;
    onCancel: () => void;
  };

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const FilterPopover: React.FC<Props> = ({
  anchorEl,
  FilterPages,
  menuLabels,
  onApply,
  onClose,
  onCancel,
  selected
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      classes={{
        paper: classes.popoverPaper
      }}
    >
      <div>
        <div className={classes.content}>
          <MenuSection
            tabs={menuLabels}
            selectedIndex={selectedIndex}
            onMenuItemClick={handleMenuItemClick}
          />

          <FiltersSection>{FilterPages[selectedIndex]}</FiltersSection>

          <SelectedSection selected={selected} />
        </div>

        <div className={classes.actionBar}>
          <Button
            color="secondary"
            className={classes.actionButton}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.actionButton}
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default FilterPopover;

import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import Button, { ButtonProps } from '@material-ui/core/Button';

// NOTE - Not sure why this typing works but it came from here
// https://github.com/mui-org/material-ui/issues/15827#issuecomment-506807829
type Props = ButtonProps<typeof RouterLink, RouterLinkProps> & {
  to: string;
};

const ButtonLink: React.FC<Props> = ({ children, to, ...rest }) => {
  const renderLink = React.useMemo(
    () =>
      // The use of React.forwardRef will no longer be required for react-router-dom v6.
      // See https://github.com/ReactTraining/react-router/issues/6056
      React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
        <RouterLink innerRef={ref} {...props} />
      )),
    []
  );

  return (
    <Button component={renderLink} to={to} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonLink;

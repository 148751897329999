import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import { Checkbox } from 'formik-material-ui';
import moment from 'moment';
import range from 'lodash/range';
import * as Yup from 'yup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { SubmitButton } from '../../../components/Form';
import {
  DatePicker,
  DateTimePicker,
  TextField,
} from '../../../components/FormikAdapter';
import {
  FormActions,
  FormRow,
  FormSection,
} from '../../../components/FormLayout';
import RewardTemplateSelect from '../../../components/FormikAdapter/RewardTemplateSelect';

const GENDER_MENU_ITEMS = [
  { value: 'all', label: 'All' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

const AGE_MENU_ITEMS = range(15, 81).map((i) => ({
  value: i,
  label: i.toString(),
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface NewCampaignFormValues {
  title: string;
  supply: number;
  gender: 'all' | 'male' | 'female';
  minAge: number;
  maxAge: number;
  notify: boolean;
  expiresAt: moment.Moment | null;
  rewardExpiresAt: moment.Moment | null;
  rewardTemplateId: string;
}

type Props = {
  merchantId: string;
  onSubmit: (
    values: NewCampaignFormValues,
    actions: FormikHelpers<NewCampaignFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  supply: Yup.number().required('Required').min(1, 'Must be at least 1'),
  gender: Yup.string()
    .required('Required')
    .oneOf(['all', 'male', 'female'], 'Invalid gender'),
  minAge: Yup.number()
    .min(15, 'Must be at least 15')
    .max(80, 'Must be at most 80'),
  maxAge: Yup.number()
    .min(15, 'Must be at least 15')
    .max(80, 'Must be at most 80')
    .when(
      'minAge',
      (minAge: number, schema: any) =>
        minAge && schema.min(minAge, 'Must be greater than the minimum age')
    ),
  expiresAt: Yup.mixed().required('Required'),
  rewardExpiresAt: Yup.mixed().required('Required'),
  rewardTemplateId: Yup.string().required('Required'),
  notify: Yup.boolean().required(),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const NewCampaignForm: React.FC<Props> = ({ merchantId, onSubmit }) => {
  const initialValues: NewCampaignFormValues = {
    title: '',
    supply: 0,
    gender: 'all',
    minAge: 15,
    maxAge: 80,
    notify: true,
    expiresAt: null,
    rewardExpiresAt: null,
    rewardTemplateId: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form translate="no" noValidate>
          <FormSection title="Details">
            <FormRow>
              <Field
                component={TextField}
                name="title"
                label="Title"
                autoFocus
                fullWidth
                required
              />
            </FormRow>

            <FormRow>
              <Field
                component={TextField}
                type="number"
                name="supply"
                label="Quantity"
                required
              />

              <Field
                component={DateTimePicker}
                name="expiresAt"
                disablePast
                label="Finishes At"
                required
                initialFocusedDate={moment().endOf('day')}
              />
            </FormRow>
          </FormSection>

          <FormSection title="Reward">
            <FormRow>
              <Field
                component={RewardTemplateSelect}
                merchantId={merchantId}
                name="rewardTemplateId"
              />
            </FormRow>

            <FormRow>
              <Field
                component={DatePicker}
                name="rewardExpiresAt"
                disablePast
                fullWidth
                label="Expires At"
                format="D MMM YYYY"
                required
              />

              <div>{/* This is a spacer  */}</div>
            </FormRow>
          </FormSection>

          <FormSection title="Targeting">
            <FormRow>
              <Field
                component={TextField}
                name="gender"
                label="Gender"
                fullWidth
                required
                select
              >
                {GENDER_MENU_ITEMS.map((gender, idx) => (
                  <MenuItem value={gender.value} key={idx}>
                    {gender.label}
                  </MenuItem>
                ))}
              </Field>

              <Field
                component={TextField}
                type="number"
                name="minAge"
                label="Min Age"
                fullWidth
                required
                select
              >
                {AGE_MENU_ITEMS.map((age, idx) => (
                  <MenuItem value={age.value} key={idx}>
                    {age.label}
                  </MenuItem>
                ))}
              </Field>

              <Field
                component={TextField}
                type="number"
                name="maxAge"
                label="Max Age"
                fullWidth
                required
                select
              >
                {AGE_MENU_ITEMS.map((age, idx) => (
                  <MenuItem value={age.value} key={idx}>
                    {age.label}
                  </MenuItem>
                ))}
              </Field>
            </FormRow>
          </FormSection>

          <FormSection title="Notify">
            <FormRow>
              <FormControlLabel
                control={<Field component={Checkbox} name="notify" />}
                label="Send push notifications when rewards are delivered"
              />
            </FormRow>
          </FormSection>

          <FormActions>
            <SubmitButton isSubmitting={isSubmitting}>
              Create Campaign
            </SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};

export default NewCampaignForm;

import { ApiRequest } from './request';

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/coupon_sets/${id}/pdf`,
  method: 'GET',
  authenticated: true,
});

export default {
  get,
};

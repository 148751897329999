import React from 'react';
import classNames from 'classnames';
import { convertToRaw, EditorState } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';

import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText, {
  FormHelperTextProps,
} from '@material-ui/core/FormHelperText';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const useEditorStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: (props: StyleProps) =>
      props.error ? theme.palette.error.main : '#ced4da',
    borderRadius: 4,
  },
  container: {
    marginTop: 0,
  },
  toolbar: {
    borderBottom: '1px solid #ced4da',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(),
  },
  editor: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    minHeight: 200,
  },
  editorContainer: {},
  placeholder: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    height: '100%',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type StyleProps = {
  error?: boolean;
};

export type Props = Pick<FormControlProps, 'error' | 'fullWidth'> & {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: React.ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  label?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: any;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const MarkdownEditor: React.FC<Props> = ({
  className,
  disabled = false,
  error = false,
  FormHelperTextProps,
  helperText,
  id,
  InputLabelProps,
  label,
  onChange,
  required = false,
  value,
}) => {
  const classes = useStyles();
  const editorClasses = useEditorStyles({ error: error });

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const [draftValue] = React.useState(JSON.stringify(markdownToDraft(value)));

  const handleChange = (state: EditorState) => {
    if (onChange) {
      let changeValue = draftToMarkdown(
        convertToRaw(state.getCurrentContent())
      );
      onChange(changeValue);
    }
  };

  return (
    <FormControl
      className={classNames(classes.root, className)}
      error={error}
      disabled={disabled}
      required={required}
    >
      {label && (
        <InputLabel
          shrink={true}
          htmlFor={id}
          id={inputLabelId}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      )}

      <MUIRichTextEditor
        inlineToolbar={true}
        controls={['title', 'bold', 'italic', 'numberList', 'bulletList']}
        value={draftValue}
        label="Start typing..."
        classes={{
          root: editorClasses.root,
          container: editorClasses.container,
          toolbar: editorClasses.toolbar,
          editor: editorClasses.editor,
          editorContainer: editorClasses.editorContainer,
          placeHolder: editorClasses.placeholder,
        }}
        onChange={handleChange}
        readOnly={disabled}
      />

      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default MarkdownEditor;

import React from 'react';

import { InputProps } from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

export const useStyles = makeStyles((theme) => ({
  inputLabel: {
    '&+ .MuiInput-formControl': {
      marginTop: theme.spacing(2.5),
    },
  },
  root: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1),
  },
  focused: {
    borderRadius: 4,
    borderColor: '#80bdff',
    boxShadow: '0 0 0 4px rgba(0,123,255,.25)',
  },
  input: {
    padding: 0,
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * Wraps a Formik Material UI Text field with our own styles.
 *
 * Inputs are type asserted because OutlinedInputProps does not define a
 * `disabledUnderline` prop and the type union of InputProps fails the
 * typecheck. Since we define our own styles and never use any variant, this
 * will not be a problem so we can safely assume it will use standard input
 * props.
 *
 * @param props
 */
const TextField: React.FC<MuiTextFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiTextField
      InputLabelProps={{ shrink: true, className: classes.inputLabel }}
      InputProps={
        {
          disableUnderline: true,
          classes: {
            root: classes.root,
            input: classes.input,
            error: classes.error,
            focused: classes.focused,
          },
        } as Partial<InputProps>
      }
      SelectProps={{
        classes: {
          root: classes.selectRoot,
        },
      }}
      {...props}
    />
  );
};

export default TextField;

import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto'
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4
      },
      label: {
        textTransform: 'none'
      }
    },
    MuiSnackbar: {
      root: {
        borderRadius: 4
      }
    }
  }
});

export default theme;

import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const QUERY_KEY = 'q';

/**
 * Extract the search term for the query string and provide an update function.
 * */
export default function useSearchQuery() {
  const location = useLocation();
  const history = useHistory();

  return useMemo(() => {
    const params = queryString.parse(location.search);
    const term = params[QUERY_KEY] as string;

    return {
      term: term,

      update: (value: string) => {
        value === '' ? delete params[QUERY_KEY] : (params[QUERY_KEY] = value);

        history.push({ search: queryString.stringify(params) });
      }
    };
  }, [history, location]);
}

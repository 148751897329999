import { ActionType, createReducer } from 'typesafe-actions';

import * as notificationActions from './actions';

export type NotificationActions = ActionType<typeof notificationActions>;

interface Notification {
  msg: string;
}
export const notificationReducer = createReducer<
  Notification,
  NotificationActions
>({ msg: '' })
  .handleAction(notificationActions.notify, (_, action) => ({
    msg: action.payload
  }))
  .handleAction(notificationActions.clear, () => ({
    msg: ''
  }));

export default notificationReducer;
export type NotificationState = ReturnType<typeof notificationReducer>;

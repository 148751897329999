import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  api,
  createCouponSet,
  getCouponSetPDF,
  listLoyaltyPackageCouponSet,
} from './actions';
import { notify } from '../notifications/actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* createCouponSetSaga(
  action: ReturnType<typeof createCouponSet>
): SagaIterator {
  yield put(
    callAPI(apiRequests.couponSets.create(action.payload), api.createCouponSet)
  );
}

/**
 * Fetch the coupon sets under loyalty package.
 *
 * @param action
 */
export function* listLoyaltyPackageCouponSetSaga(
  action: ReturnType<typeof listLoyaltyPackageCouponSet>
): SagaIterator {
  const {
    loyaltyPackageId,
    pagination: { page, per },
  } = action.payload;
  yield put(
    callAPI(
      apiRequests.couponSets.listByLoyaltyCampaign(loyaltyPackageId, {
        pagination: { page, per },
      }),
      api.listLoyaltyPackageCouponSet
    )
  );
}

export function* getCouponSetPDFSaga(
  action: ReturnType<typeof getCouponSetPDF>
): SagaIterator {
  yield put(
    callAPI(apiRequests.couponSetPDFs.get(action.payload), api.getCouponSetPDF)
  );
}

/**
 * Alert the user the KYC has been approved.
 *
 * * @param _
 */
export function* createCouponSetSuccessSaga(
  _: ReturnType<typeof api.createCouponSet.success>
): SagaIterator {
  yield put(notify('Coupon Set Created'));
}

export default all([
  takeLatest(getType(createCouponSet), createCouponSetSaga),
  takeLatest(getType(getCouponSetPDF), getCouponSetPDFSaga),
  takeLatest(
    getType(listLoyaltyPackageCouponSet),
    listLoyaltyPackageCouponSetSaga
  ),
  takeLatest(getType(api.createCouponSet.success), createCouponSetSuccessSaga),
]);

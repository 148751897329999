import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Link from '../../../components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  gridMessageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50
  },
  errorMsg: {
    padding: 20,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={3} />
        <Grid item xs={6} className={classes.gridMessageBox}>
          <Typography variant="h4">404</Typography>
          <Typography variant="subtitle1" className={classes.errorMsg}>
            Page cannot be found
          </Typography>
          <Link to="/">Home</Link>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
};

export default NotFound;

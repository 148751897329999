export const get = () => {
  return sessionStorage.getItem('token');
};

export const set = (token: string) => {
  sessionStorage.setItem('token', token);
};

export const remove = () => {
  sessionStorage.removeItem('token');
};

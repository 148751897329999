import React, { useEffect, useState } from 'react';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { ActionIconButton, Columns } from '../DataTable';
import NewMilestoneDialog from './NewMilestoneDialog';
import EditMilestoneDialog from './EditMilestoneDialog';
import DataTable from '../DataTable';
import RewardCardPreview from './RewardCardPreview';

import * as MilestoneService from '../../api/services/milestoneService';
import * as RewardService from '../../api/services/rewardService';
import { dateFormat } from '../../lib/date';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

//------------------------------------------------------------------------------
// Props
//------------------------------------------------------------------------------

type Props = {
  campaignId: string;
  merchantId: string;
  maxStamps: number;
  status: string;
};

type TableRow = {
  id: string;
  threshold: number;
  title: string;
  validityPeriodDays: number;
  updatedId: string;
  rewardExpiry: number | null;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function MilestonesList({
  campaignId,
  merchantId,
  maxStamps,
  status,
}: Props) {
  const classes = useStyles();

  const [milestones, setMilestones] = useState<MilestoneService.Milestone[]>(
    []
  );
  const [rows, setRows] = useState<TableRow[]>([]);
  const [refresh, setRefresh] = useState(true);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [expiryDialogOpen, setExpiryDialogOpen] = useState(false);
  const [previewId, setPreviewId] = useState<string | null>(null);
  const [selectedEditMilestone, setSelectedEditMilestone] =
    useState<MilestoneService.Milestone | null>(null);

  // const handleOpenPreview = (id: string) => {
  //   setPreviewId(id);
  // };

  const handleClosePreview = () => setPreviewId(null);
  const handleCloseCreateDialog = () => setCreateDialogOpen(false);

  // Display the preview if the id is present
  const previewModalOpen = !!previewId;

  const deleteMilestone = (milestoneId: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this milestone?'
    );

    if (confirmed) {
      try {
        MilestoneService.destroy(milestoneId);

        setRefresh(true);
      } catch (e) {
        alert('error');
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        const res = await MilestoneService.listByCampaign(campaignId);

        const rewardIds = uniq(map(res, 'reward.id'));

        // Fetch the rewards
        const rewardsRes = await RewardService.listByIds(rewardIds);
        const rewards = keyBy(rewardsRes.rewards, (r) => r.id);

        // Assemble the table rows
        const tableRows = orderBy(
          res.map((milestone) => {
            const reward = rewards[milestone.reward.id];

            return {
              id: milestone.id,
              title: reward.title,
              threshold: milestone.threshold,
              validityPeriodDays: milestone.validityPeriodDays,
              updatedAt: milestone.updatedAt,
              updatedId: milestone.id,
              rewardExpiry: milestone.rewardExpiryDate
                ? milestone.rewardExpiryDate
                : null,
              selectedMilestone: milestone,
            };
          }),
          ['threshold']
        );

        if (isMounted) {
          setMilestones(res);
          setRows(tableRows);
          setRefresh(false);
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (refresh) {
      fetch();
    }

    return () => {
      isMounted = false;
    };
  }, [campaignId, refresh]);

  const columns: Columns = [
    { title: 'No. of Stamps', field: 'threshold' },
    { title: 'Reward', field: 'title' },
    { title: 'Validity (days)', field: 'validityPeriodDays' },
    { title: 'Updated At', field: 'updatedAt', format: dateFormat },
    { title: 'Reward Expiry At', field: 'rewardExpiry', format: dateFormat },
    {
      title: '',
      field: 'selectedMilestone',
      align: 'left',
      format: (value: MilestoneService.Milestone) => (
        <>
          <ActionIconButton
            title="Edit Milestone"
            Icon={EditIcon}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedEditMilestone(value);
              setExpiryDialogOpen(true);
            }}
          />
        </>
      ),
    },
    {
      title: '',
      field: 'id',
      align: 'right',
      format: (value: string) => (
        <>
          {status === 'draft' && (
            <ActionIconButton
              title="Delete"
              Icon={DeleteIcon}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                deleteMilestone(value);
              }}
            />
          )}
        </>
      ),
    },
  ];

  if (!milestones) {
    return null;
  }

  return (
    <div className={classes.root}>
      <DataTable
        columns={columns}
        data={rows}
        Actions={
          status === 'draft' ? (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setCreateDialogOpen(true)}
              disabled={milestones.length === maxStamps}
            >
              New Milestone
            </Button>
          ) : null
        }
      />

      <Modal open={previewModalOpen} onClose={handleClosePreview}>
        <div>
          {previewId !== null && (
            <RewardCardPreview
              merchantId={merchantId}
              milestoneId={previewId}
            />
          )}
        </div>
      </Modal>

      <NewMilestoneDialog
        campaignId={campaignId}
        maxStamps={maxStamps}
        milestones={milestones}
        merchantId={merchantId}
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        onSuccess={(milestone) => {
          setRefresh(true);
          handleCloseCreateDialog();
        }}
      />
      {selectedEditMilestone && (
        <EditMilestoneDialog
          milestoneId={selectedEditMilestone.id}
          merchantId={merchantId}
          mValidityPeriodDays={selectedEditMilestone.validityPeriodDays}
          mTemplateId={selectedEditMilestone.rewardTemplate.id}
          mRewardExpiryDate={selectedEditMilestone.rewardExpiryDate}
          open={expiryDialogOpen}
          onClose={() => setExpiryDialogOpen(false)}
          onSuccess={() => {
            setRefresh(true);
            setExpiryDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}

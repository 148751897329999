import { RootState } from '../reducers';
import { ImagesState } from './reducer';
import { Image, KYCImage } from './models';
import values from 'lodash/values';
import pick from 'lodash/pick';
import { Image as StockImageState } from '../../api/resources/image';

export const imagesSelector = (state: RootState): ImagesState => state.images;

export const getImage = (state: RootState, id: string): Image | undefined => {
  const image = imagesSelector(state).byId[id];

  if (!image) {
    return undefined;
  }

  return {
    id: image.id,
    versions: image.attributes.versions,
  };
};

export const getKYCImage = (
  state: RootState,
  id: string
): KYCImage | undefined => {
  const image = imagesSelector(state).kycById[id];

  if (!image) {
    return undefined;
  }

  return {
    id: image.id,
    url: image.attributes.url,
  };
};

export const getStockImage = (
  state: RootState,
  id: string
): Image | undefined => {
  const image = imagesSelector(state).stockImagebyId[id];

  if (!image) {
    return undefined;
  }

  return {
    id: image.id,
    versions: image.attributes.versions,
  };
};

/**
 * Get list of stock image
 *
 * @param state
 * @param merchantId
 */
export const listStockImage = (state: RootState) => {
  const ids = getPagination(state).ids;
  const stockImages = pick(imagesSelector(state).stockImagebyId, ids);

  return values(stockImages).map(makeStockImage(state));
};

/**
 * Are stock images being fetched?
 *
 * @param state
 */
export const isFetching = (state: RootState) =>
  imagesSelector(state).ui.isFetching;

/**
 * Get the pagination data for the index page.
 *
 * @param state
 */
export const getPagination = (state: RootState) =>
  imagesSelector(state).pagination;

const makeStockImage = (state: RootState) => (stockImage: StockImageState) => {
  const { id, attributes } = stockImage;
  const { versions } = attributes;

  return {
    id,
    xl: versions.xl,
  };
};

import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

import DataTable from '../../../../../components/DataTable';
import FilterBar from './FilterBar';
import Link from '../../../../../components/Link';
import { Column, Grid } from '../../../../../components/Layout/Main';
import * as RewardCardService from '../../../../../api/services/rewardCardService';
import * as UserService from '../../../../../api/services/userService';
import { PaginationResult } from '../../../../../api/services/request';
import useFiltersQuery from '../../../../../hooks/useFiltersQuery';
import usePaginationQuery from '../../../../../hooks/usePaginationQuery';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

type TableRow = {
  id: string;
  status: string;
  user?: {
    id: string;
    name: string;
  };
};

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const columns = [
  {
    title: 'ID',
    field: 'id',
    width: '50px',
  },
  {
    title: 'Name',
    field: 'user',
    format: (value: any) =>
      value ? (
        <Link
          to={`/users/${value.id}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
            event.stopPropagation()
          }
        >
          {value.name}
        </Link>
      ) : undefined,
  },
  { title: 'Status', field: 'status' },
];

const TabRewardCards: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const { page, per, updatePage, updatePer } = usePaginationQuery();
  const { filters } = useFiltersQuery();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState<PaginationResult | undefined>(
    undefined
  );

  // Fetch the cards
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        setIsFetching(true);

        // Fetch the reward cards
        const res = await RewardCardService.listByAirdropCampaign(id, {
          pagination: { page, per },
          filters: filters,
        });

        const userIds = uniq(map(res.cards, 'owner.id'));

        // Fetch the users
        const userRes = await UserService.list({
          pagination: { page: 1, per: userIds.length },
          filters: { id: userIds },
        });

        const cards = res.cards;
        const users = keyBy(userRes.users, (u) => u.id);

        // Assemble the table rows
        const tableRows = cards.map((card) => {
          const user = users[card.owner.id];

          return {
            id: card.id,
            status: card.status,
            user: user
              ? {
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`,
                }
              : undefined,
          };
        });

        if (isMounted) {
          setRows(tableRows);
          setPagination(res.pagination);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [id, page, per, filters]);

  const handleChangePage = useCallback(
    (_, page: number) => {
      updatePage(page.toString());
    },
    [updatePage]
  );

  return (
    <Grid>
      <Column xs={12}>
        <FilterBar />
      </Column>

      <Column xs={12} sm={12}>
        <DataTable
          title="Reward Cards"
          isLoading={isFetching}
          columns={columns}
          data={rows}
          PaginationProps={
            pagination
              ? {
                  count: pagination.total,
                  rowsPerPage: pagination.per,
                  rowsPerPageOptions: [10, 25, 50],
                  page: pagination.page,
                  onChangePage: handleChangePage,
                  onChangeRowsPerPage: (event) => {
                    updatePer(event.target.value.toString());
                  },
                }
              : undefined
          }
        />
      </Column>
    </Grid>
  );
};

export default TabRewardCards;

import React, { useEffect } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import * as storeActions from '../../../../../../redux/stores/actions';
import { getMerchantSelector } from '../../../../../../redux/merchants/selectors';
import { getRewardTemplateSelector } from '../../../../../../redux/rewardTemplates/selectors';
import { getFirstMerchantStore } from '../../../../../../redux/stores/selectors';

import CardPreview from '../../../../../../components/RewardCardPreview';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  rewardTemplateId: string;
  merchantId: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Preview = React.forwardRef<HTMLDivElement, Props>(
  ({ rewardTemplateId, merchantId }, ref) => {
    const dispatch = useDispatch();

    const merchant = useSelector(getMerchantSelector(merchantId));
    const template = useSelector(getRewardTemplateSelector(rewardTemplateId));
    const store = useSelector(getFirstMerchantStore(merchantId));

    useEffect(() => {
      dispatch(storeActions.getMerchantStores({ merchantId }));
    }, [dispatch, merchantId]);

    if (!merchant || !template) {
      return null;
    }

    return (
      <CardPreview
        ref={ref}
        about={merchant.about}
        expiresAt={moment().add(3, 'M')}
        image={template.image!.versions.xl}
        info={template.info}
        merchantName={merchant.name}
        merchantLogo={merchant.smallLogo}
        store={store}
        title={template.title}
        what={template.what}
      />
    );
  }
);

export default Preview;

import request from './request';
import deserialize from './deserialize';

export type LoyaltyProgram = {
  id: string;
  createdAt: number;
  updatedAt: number;
  rateLimit: number;
  loyaltyCampaignRateLimitDays: number;
  announcementRateLimit: number;
  maxStampCampaigns: number;
  merchant: {
    type: 'merchants';
    id: string;
  };
};

export function getByMerchant(merchantId: string) {
  return request<LoyaltyProgram>({
    url: `/admin/api/v2/merchants/${merchantId}/loyalty_program`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function updateRateLimit(merchantId: string, limit: number) {
  return request<LoyaltyProgram>({
    url: `/admin/api/v2/merchants/${merchantId}/loyalty_program/rate_limit`,
    method: 'PATCH',
    data: {
      data: {
        type: 'loyalty_programs',
        attributes: {
          rateLimit: parseInt(limit.toString()),
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function updateLoyaltyCampaignRateLimit(
  merchantId: string,
  limit: number
) {
  return request<LoyaltyProgram>({
    url: `/admin/api/v2/merchants/${merchantId}/loyalty_program/loyalty_campaign_rate_limit`,
    method: 'PATCH',
    data: {
      data: {
        type: 'loyalty_programs',
        attributes: {
          rateLimitDays: parseInt(limit.toString()),
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function updateLoyaltyProgramMaxStampCampaign(
  merchantId: string,
  maxStampCampaigns: number
) {
  return request<LoyaltyProgram>({
    url: `/admin/api/v2/merchants/${merchantId}/loyalty_program/max_stamp_campaigns`,
    method: 'PATCH',
    data: {
      data: {
        type: 'loyalty_programs',
        attributes: {
          maxStampCampaigns: parseInt(maxStampCampaigns.toString()),
        },
      },
    },
    transformResponse: deserialize,
  });
}

export function updateAnnouncementRateLimit(merchantId: string, limit: number) {
  return request<LoyaltyProgram>({
    url: `/admin/api/v2/merchants/${merchantId}/loyalty_program/announcements_rate_limit`,
    method: 'PATCH',
    data: {
      data: {
        type: 'loyalty_programs',
        attributes: {
          rateLimitDays: parseInt(limit.toString()),
        },
      },
    },
    transformResponse: deserialize,
  });
}

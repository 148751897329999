import { createAction, createAsyncAction } from 'typesafe-actions';

import { APIError } from '../api/models';
import { LoyaltyProgramDoc } from '../../api/resources/loyaltyProgram';

/**
 * Creates a loyalty program
 */
export const createLoyaltyProgram = createAction(
  'loyalty_programs/CREATE',
  (attrs: { merchantId: string }) => attrs
)();

/**
 * Gets a loyalty program by merchant id
 */
export const getMerchantLoyaltyProgram = createAction(
  'loyalty_programs/GET_BY_MERCHANT',
  (merchantId: string) => merchantId
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  createLoyaltyProgram: createAsyncAction(
    'api/loyalty_programs/CREATE_REQUEST',
    'api/loyalty_programs/CREATE_SUCCESS',
    'api/loyalty_programs/CREATE_FAILURE'
  )<any, LoyaltyProgramDoc, APIError>(),

  fetchMerchantLoyaltyProgram: createAsyncAction(
    'api/loyalty_programs/FETCH_BY_MERCHANT_REQUEST',
    'api/loyalty_programs/FETCH_BY_MERCHANT_SUCCESS',
    'api/loyalty_programs/FETCH_BY_MERCHANT_FAILURE'
  )<any, LoyaltyProgramDoc, APIError>()
};

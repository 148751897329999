import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type WelcomeCampaign = {
  id: string;
  type: 'welcome_campaigns';
  delayHours: number;
  validityPeriodDays: number;
  status: string;
  createdAt: number;
  updatedAt: number;
  reward: {
    type: 'rewards';
    id: string;
  };
  rewardTemplate: {
    type: 'reward_templates';
    id: string;
  };
};

export type WelcomeCampaignList = {
  welcomeCampaigns: WelcomeCampaign[];
  pagination: PaginationResult;
};

export function listByCampaign(campaignId: string, opts?: ListOptions) {
  return request<WelcomeCampaignList>({
    url: `/admin/api/v2/loyalty_programs/${campaignId}/welcome_campaigns?${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        welcomeCampaigns: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function activateCampaign(campaignId: string) {
  return request<WelcomeCampaign>({
    url: `/admin/api/v2/welcome_campaigns/${campaignId}/activate`,
    method: 'PATCH',
    transformResponse: deserialize,
  });
}

export function deactivateCampaign(campaignId: string) {
  return request<WelcomeCampaign>({
    url: `/admin/api/v2/welcome_campaigns/${campaignId}/deactivate`,
    method: 'PATCH',
    transformResponse: deserialize,
  });
}

export function archiveCampaign(campaignId: string) {
  return request<WelcomeCampaign>({
    url: `/admin/api/v2/welcome_campaigns/${campaignId}/archive`,
    method: 'PATCH',
    transformResponse: deserialize,
  });
}

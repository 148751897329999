import React from 'react';
import { getIn, FieldProps } from 'formik';

import MarkdownEditor, {
  Props as MarkdownEditorProps,
} from '../../MarkdownEditor';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps &
  Omit<MarkdownEditorProps, 'onChange'> & {
    md: string;
  };

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const fieldToMarkdownEditor = ({
  form,
  field,
  ...props
}: Props): MarkdownEditorProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    onChange: (value: string) => {
      form.setFieldValue(name, value);
    },
    disabled: isSubmitting,
  };
};

/**
 * ImageUploader uploads the image selected with and ImageSelect and it for
 * Formik.
 */
const MarkdownEditorAdapter: React.FC<Props> = (props) => (
  <MarkdownEditor {...fieldToMarkdownEditor(props)} value={props.md} />
);

export default MarkdownEditorAdapter;

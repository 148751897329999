import React, { useCallback } from 'react';

import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { Row, Rows } from './types';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface StyleProps {
  isClickable: boolean;
}

export type TableBodyProps = {
  rows: Rows;
  onRowClick?: (data: Row) => void;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  row: {
    cursor: (props: StyleProps) => (props.isClickable ? 'pointer' : 'default'),
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },
  },
  cell: {
    padding: '8px 24px',
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const TableBody: React.FC<TableBodyProps> = ({ onRowClick, rows }) => {
  const classes = useStyles({ isClickable: !!onRowClick });

  const renderRow = useCallback(
    (row: Row, idx: number) => (
      <MuiTableRow
        key={idx}
        hover={true}
        onClick={() => {
          if (onRowClick) {
            onRowClick(row);
          }
        }}
        className={classes.row}
      >
        {row.map((cell, idx) => (
          <MuiTableCell
            key={idx}
            style={{
              width: cell.width || 'auto',
              textAlign: cell.align || 'left',
              // whiteSpace: 'nowrap',
              // overflow: 'hidden',
            }}
          >
            {cell.value}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    ),
    [classes, onRowClick]
  );

  return <MuiTableBody>{rows.map(renderRow)}</MuiTableBody>;
};

export default TableBody;

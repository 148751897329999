import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../../redux/images/actions';
import * as imageSelectors from '../../../redux/images/selectors';

import ButtonLink from '../../../components/ButtonLink';
import FeatureBar from '../../../components/FeatureBar';
import { Content, Grid, Column } from '../../../components/Layout/Main';
import StockImageCard from '../../../components/StockImageCard';
import usePaginationQuery from '../../../hooks/usePaginationQuery';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const List = () => {
  const paginationQuery = usePaginationQuery();
  const dispatch = useDispatch();

  const stockLists = useSelector(imageSelectors.listStockImage);

  // Load the campaigns
  useEffect(() => {
    const { page, per } = paginationQuery;

    dispatch(
      imageActions.listStockImages({
        pagination: { page, per },
      })
    );
  }, [dispatch, paginationQuery]);

  return (
    <Content>
      <FeatureBar>Stock Images</FeatureBar>

      <>
        <Grid>
          <Column xs={4}>
            <ButtonLink
              to="/stockImages/new"
              variant="contained"
              size="small"
              color="primary"
            >
              New Stock Image
            </ButtonLink>
          </Column>
        </Grid>
        {!stockLists ? (
          <Grid>
            <Column xs={12}>
              <Typography variant="body1">No Stock Image</Typography>
            </Column>
          </Grid>
        ) : (
          <Grid>
            {stockLists.map((stockImage, idx) => {
              return (
                <Column key={idx} xs={6} sm={4} md={3} xl={2}>
                  <StockImageCard image={stockImage.xl} />
                </Column>
              );
            })}
          </Grid>
        )}
      </>
    </Content>
  );
};

export default List;

import request, { ListOptions, PaginationResult } from './request';
import deserialize, { deserializePagination } from './deserialize';
import { toListQueryString } from './utils';

export type StampCampaign = {
  id: string;
  details: string;
  status: 'draft' | 'active' | 'inactive';
  maxStamps: number;
  createdAt: number;
  multipleCards: boolean;
  notification: string;
  type: string;
  description: string;
  updatedAt: number;
  reissuedWelcomeRewards: boolean;
  storeStampLimit: number;
  loyaltyProgram: {
    type: 'loyalty_programs';
    id: string;
  };
};

export type StampCampaignList = {
  campaigns: StampCampaign[];
  pagination: PaginationResult;
};

export type StampCard = {
  id: string;
  createdAt: number;
  email: string;
  name: string;
  totalStamps: number;
  updatedAt: number;
  loyalty_campaign: {
    type: 'loyalty_campaigns';
    id: string;
  };
  user: {
    type: 'users';
    id: string;
  };
};

export type StampCardList = {
  stampCards: StampCard[];
  pagination: PaginationResult;
};

export function listByProgram(programId: string, opts?: ListOptions) {
  return request<StampCampaignList>({
    url: `/admin/api/v2/loyalty_programs/${programId}/stamp_campaigns?${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        campaigns: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function getActive(programId: string) {
  return request<StampCampaign>({
    url: `/admin/api/v2/loyalty_programs/${programId}/stamp_campaign`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

export function get(campaignId: string) {
  return request<StampCampaign>({
    url: `/admin/api/v2/stamp_campaigns/${campaignId}`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

type CreateAttrs = {
  details: string;
  maxStamps: number;
  programId: string;
};

export function create(attrs: CreateAttrs) {
  const { maxStamps, details, programId } = attrs;

  return request<StampCampaign>({
    url: `/admin/api/v2/stamp_campaigns/reward`,
    method: 'POST',
    transformResponse: deserialize,
    data: {
      data: {
        type: 'stamp_campaigns',
        attributes: {
          maxStamps,
          details,
        },
        relationships: {
          loyaltyProgram: {
            data: {
              type: 'loyalty_programs',
              id: programId,
            },
          },
        },
      },
    },
  });
}

type CreatePrizeDrawAttrs = {
  details: string;
  maxStamps: number;
  programId: string;
  description: string;
  notification: string;
  multipleCards: boolean;
  reissuedWelcomeRewards: boolean;
  storeStampLimit: number;
};

export function createPrizeDraw(attrs: CreatePrizeDrawAttrs) {
  const {
    maxStamps,
    details,
    programId,
    description,
    notification,
    multipleCards,
    reissuedWelcomeRewards,
    storeStampLimit,
  } = attrs;

  return request<StampCampaign>({
    url: `/admin/api/v2/stamp_campaigns/prize`,
    method: 'POST',
    transformResponse: deserialize,
    data: {
      data: {
        type: 'stamp_campaigns',
        attributes: {
          maxStamps,
          details,
          description,
          notification,
          multipleCards,
          reissueWelcomeRewards: reissuedWelcomeRewards,
          storeStampLimit,
        },
        relationships: {
          loyaltyProgram: {
            data: {
              type: 'loyalty_programs',
              id: programId,
            },
          },
        },
      },
    },
  });
}

type UpdatePrizeDrawAttrs = {
  details: string;
  maxStamps: number;
  programId: string;
  description: string;
  notification: string;
  multipleCards: boolean;
  campaignId: string;
  reissuedWelcomeRewards: boolean;
  storeStampLimit: number;
};

export function updatePrizeDraw(attrs: UpdatePrizeDrawAttrs) {
  const {
    maxStamps,
    details,
    programId,
    description,
    notification,
    multipleCards,
    campaignId,
    reissuedWelcomeRewards,
    storeStampLimit,
  } = attrs;

  return request<StampCampaign>({
    url: `/admin/api/v2/stamp_campaigns/${campaignId}/prize`,
    method: 'PUT',
    transformResponse: deserialize,
    data: {
      data: {
        type: 'stamp_campaigns',
        attributes: {
          maxStamps,
          details,
          description,
          notification,
          multipleCards,
          reissueWelcomeRewards: reissuedWelcomeRewards,
          storeStampLimit,
        },
        relationships: {
          loyaltyProgram: {
            data: {
              type: 'loyalty_programs',
              id: programId,
            },
          },
        },
      },
    },
  });
}

export function activate(campaignId: string, issueStampCards: boolean) {
  return request<StampCampaign>({
    url: `/admin/api/v2/stamp_campaigns/${campaignId}/activate`,
    method: 'PATCH',
    transformResponse: deserialize,
    data: {
      data: {
        type: 'stamp_campaigns',
        attributes: {
          issueStampCards,
        },
      },
    },
  });
}

export function deactivate(campaignId: string) {
  return request<StampCampaign>({
    url: `/admin/api/v2/stamp_campaigns/${campaignId}/deactivate`,
    method: 'PATCH',
    transformResponse: deserialize,
  });
}

export function listMembersByCompletedStampCard(
  loyaltyProgramId: string,
  opts?: ListOptions
) {
  return request<StampCardList>({
    url: `/admin/api/v2/loyalty_programs/${loyaltyProgramId}/stamp_cards?${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        stampCards: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

export function exportByCompletedStampCard(stampCampaignId: string) {
  return request<string>({
    url: `/admin/api/v2/stamp_campaigns/${stampCampaignId}/stamp_cards/export?sort=desc-createdAt`,
    method: 'GET',
  });
}

export function listInactivePrizeCampaignByProgram(
  loyaltyProgramId: string,
  opts?: ListOptions
) {
  return request<StampCampaignList>({
    url: `/admin/api/v2/loyalty_programs/${loyaltyProgramId}/stamp_campaigns?filter[type][eq]=prize&filter[status][eq]=inactive&${toListQueryString(
      opts
    )}`,
    method: 'GET',
    transformResponse: (data) => {
      return {
        campaigns: deserialize(data),
        pagination: deserializePagination(data),
      };
    },
  });
}

import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import analytics from './analytics/reducer';
import api from './api/reducer';
import auth from './auth/reducer';
import birthdayCampaigns from './birthdayCampaigns/reducer';
import campaigns from './campaigns/reducer';
import coupons from './coupons/reducer';
import couponSets from './couponSets/reducer';
import dropZones from './dropZones/reducer';
import images from './images/reducer';
import kyc from './kyc/reducer';
import locations from './locations/reducer';
import loyaltyPrograms from './loyaltyPrograms/reducer';
import merchants from './merchants/reducer';
import notifications from './notifications/reducer';
import profile from './profile/reducer';
import rewards from './rewards/reducer';
import rewardTemplates from './rewardTemplates/reducer';
import stores from './stores/reducer';
import users from './users/reducer';
import welcomeCampaigns from './welcomeCampaigns/reducer';

const rootReducer = combineReducers({
  analytics,
  api,
  auth,
  birthdayCampaigns,
  campaigns,
  coupons,
  couponSets,
  dropZones,
  images,
  kyc,
  locations,
  loyaltyPrograms,
  merchants,
  notifications,
  profile,
  rewards,
  users,
  rewardTemplates,
  stores,
  welcomeCampaigns,
});

export default rootReducer;
export type RootState = StateType<typeof rootReducer>;

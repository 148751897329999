import orderBy from 'lodash/orderBy';

import { RootState } from '../reducers';
import {
  GenderDemographics,
  RedemptionAnalytics,
  RedemptionsByMerchant,
  SignupDemographics
} from './models';

// Helper function to calculate the percentage.
const percentage = (value: number) => {
  const perc = value * 100;

  return isNaN(perc) ? '0' : perc.toFixed(1);
};

/**
 * Get the analytics state
 *
 * @param state
 */
export const getAnalyticsState = (state: RootState) => state.analytics;

/**
 * Get the dashboard analytics
 *
 * @param state
 */
export const getDashboardAnalytics = (state: RootState) =>
  getAnalyticsState(state).dashboard;

/**
 * Get the gender demographics analytics
 *
 * @param state
 */
export const getGenderDemographics = (state: RootState): GenderDemographics => {
  const analytics = getDashboardAnalytics(state);

  const { male, female, unknown } = analytics.attributes.demographics.gender;
  const total = male + female + unknown;

  return {
    male: { count: male, percentage: percentage(male / total) },
    female: { count: female, percentage: percentage(female / total) },
    unknown: { count: unknown, percentage: percentage(unknown / total) }
  };
};

/**
 * Get the signup demographics analytics
 *
 * @param state
 */
export const getSignupDemographics = (state: RootState): SignupDemographics => {
  const analytics = getDashboardAnalytics(state);

  return analytics.attributes.demographics.signup;
};

/**
 * Get the redemption analytics
 * @param state \
 */
export const getRedemptionsAnalytics = (
  state: RootState
): RedemptionAnalytics => {
  const analytics = getDashboardAnalytics(state);

  const { total, today } = analytics.attributes.redemptions;

  return { total, today };
};

/**
 * Gets the total redemptions for each merchant.
 *
 * @param state
 */
export const getRedemptionsByMerchant = (
  state: RootState
): RedemptionsByMerchant => {
  const analytics = getDashboardAnalytics(state);

  const { byMerchant } = analytics.attributes.redemptions;

  return orderBy(byMerchant, ['total'], ['desc']);
};

import React from 'react';
import { getIn, FieldProps } from 'formik';

import FlexImageUploader, {
  Props as FlexImageUploaderProps
} from '../../ImageUploader';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps & Omit<FlexImageUploaderProps, 'onChange'>;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const fieldToImageUploader = ({
  form,
  field,
  ...props
}: Props): FlexImageUploaderProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    onChange: (value: string) => {
      form.setFieldValue(name, value);
    },
    onTouch: () => {
      form.setFieldTouched(name);
    },
    disabled: isSubmitting
  };
};

/**
 * ImageUploader uploads the image selected with and ImageSelect and it for
 * Formik.
 */
const ImageUploader: React.FC<Props> = props => (
  <FlexImageUploader {...fieldToImageUploader(props)} />
);

export default ImageUploader;

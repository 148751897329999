import React, { useState } from 'react';
import GoogleMapReact, { Props as GoogleMapReactProps } from 'google-map-react';

import { ZoneMarker } from '../Marker';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
const DEFAULT_CENTER = {
  lat: 1.355325,
  lng: 103.867905,
};
const DEFAULT_ZOOM = 11;
const MAX_ZOOM = 14;

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type MarkerProps = {
  map?: google.maps.Map;
  lat: number;
  lng: number;
  radius?: number;
};

type Props = GoogleMapReactProps & {
  coords?: {
    lat: number;
    lng: number;
  };
  radius?: number;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const SingleDropZoneMap = ({ coords, radius, ...props }: Props) => {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      defaultCenter={DEFAULT_CENTER}
      defaultZoom={DEFAULT_ZOOM}
      onGoogleApiLoaded={({ map }) => setMap(map)}
      yesIWantToUseGoogleMapApiInternals
      center={coords ? { lat: coords.lat, lng: coords.lng } : DEFAULT_CENTER}
      {...props}
      zoom={coords ? MAX_ZOOM : DEFAULT_ZOOM}
    >
      {coords && (
        <ZoneMarker
          map={map}
          lat={coords.lat}
          lng={coords.lng}
          radius={radius ? (radius as number) : undefined}
          fitBounds
        />
      )}
    </GoogleMapReact>
  );
};

export default SingleDropZoneMap;

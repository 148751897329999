import { SagaIterator } from 'redux-saga';
import { all, delay, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  api,
  autocompleteUsers,
  getUser,
  listUsers,
  listUsersByIds,
} from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* fetchUsersListSaga(
  action: ReturnType<typeof listUsers>
): SagaIterator {
  const { filters, pagination, query } = action.payload;

  yield put(
    callAPI(
      apiRequests.users.list({
        pagination,
        search: query ? { term: query } : undefined,
        filters,
      }),
      api.fetchUserList
    )
  );
}

export function* fetchUsersByIdsSaga(
  action: ReturnType<typeof listUsersByIds>
): SagaIterator {
  const ids = action.payload;

  yield put(
    callAPI(
      apiRequests.users.list({
        pagination: { page: 1, per: ids.length },
        filters: { id: ids },
      }),
      api.fetchUserByIdsList
    )
  );
}

export function* fetchUserSaga(
  action: ReturnType<typeof getUser>
): SagaIterator {
  yield put(callAPI(apiRequests.users.get(action.payload), api.fetchUser));
}

export function* autocompleteUsersSaga(
  action: ReturnType<typeof autocompleteUsers>
): SagaIterator {
  // Debounce 500ms
  yield delay(500);

  const term = action.payload;

  if (term !== '') {
    yield put(
      callAPI(
        apiRequests.users.list({
          pagination: { page: 1, per: 10 }, // Define a default per for an autocomplete search
          search: action.payload ? { term } : undefined,
        }),
        api.fetchAutocompleteUserList
      )
    );
  }
}

export default all([
  takeLatest(getType(listUsers), fetchUsersListSaga),
  takeLatest(getType(listUsersByIds), fetchUsersByIdsSaga),
  takeLatest(getType(getUser), fetchUserSaga),
  takeLatest(getType(autocompleteUsers), autocompleteUsersSaga),
]);

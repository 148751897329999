import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { api, listCouponSetCoupons } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

/**
 * Fetch the coupons under coupon set.
 *
 * @param action
 */
export function* listCouponSetCouponsSaga(
  action: ReturnType<typeof listCouponSetCoupons>
): SagaIterator {
  const {
    couponSetId,
    pagination: { page, per },
  } = action.payload;
  yield put(
    callAPI(
      apiRequests.coupons.listByCouponSetId(couponSetId, {
        pagination: { page, per },
      }),
      api.listCouponSetCoupons
    )
  );
}

export default all([
  takeLatest(getType(listCouponSetCoupons), listCouponSetCouponsSaga),
]);

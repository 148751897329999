import { all } from 'redux-saga/effects';

import analyticsSagas from './analytics/sagas';
import authSagas from './auth/sagas';
import birthdayCampaignSagas from './birthdayCampaigns/sagas';
import campaignSagas from './campaigns/sagas';
import couponSagas from './coupons/sagas';
import couponSetSagas from './couponSets/sagas';
import dropZoneSagas from './dropZones/sagas';
import imageSagas from './images/sagas';
import kycSagas from './kyc/sagas';
import locationSagas from './locations/sagas';
import loyaltyProgramSagas from './loyaltyPrograms/sagas';
import merchantSagas from './merchants/sagas';
import profileSagas from './profile/sagas';
import rewardSagas from './rewards/sagas';
import rewardTemplateSagas from './rewardTemplates/sagas';
import storeSagas from './stores/sagas';
import userSagas from './users/sagas';
import welcomeCampaignSagas from './welcomeCampaigns/sagas';

function* rootSaga() {
  yield all([
    analyticsSagas,
    authSagas,
    birthdayCampaignSagas,
    campaignSagas,
    couponSagas,
    couponSetSagas,
    dropZoneSagas,
    imageSagas,
    kycSagas,
    locationSagas,
    loyaltyProgramSagas,
    merchantSagas,
    profileSagas,
    rewardSagas,
    rewardTemplateSagas,
    storeSagas,
    userSagas,
    welcomeCampaignSagas,
  ]);
}

export default rootSaga;

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as notificationActions from '../../redux/notifications/actions';
import { getNotificationMsg } from '../../redux/notifications/selectors';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Notification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const msg = useSelector(getNotificationMsg);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (msg !== '') {
      setOpen(true);
    }
  }, [msg]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      onExited={() => {
        dispatch(notificationActions.clear());
      }}
      message={<span id="message-id">{msg}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

export default Notification;

import Jsona from 'jsona';

export default function deserialize(data: string) {
  if (typeof data === 'string') {
    try {
      const body = JSON.parse(data);

      if (body.errors) {
        return body.errors;
      }

      const formatter = new Jsona();

      return formatter.deserialize(data);
    } catch (e) {
      /* Ignore */
    }
  }
  return data;
}

export function deserializePagination(data: string) {
  if (typeof data === 'string') {
    try {
      const body = JSON.parse(data);

      if (body.errors) {
        return body.errors;
      }

      const { page, per, count, total } = body.meta;

      return {
        page,
        per,
        count,
        total,
      }
    } catch (e) {
      /* Ignore */
    }
  }
  return data;
}

import React from 'react';
import TextField from '../../TextField';
import { TextFieldProps, fieldToTextField } from 'formik-material-ui';

/**
 * Wraps a Material UI TextField for formik usage.
 *
 * This uses the `formik-material-ui` adapter function.
 *
 * @param props
 */
const TextFieldAdapter: React.FC<TextFieldProps> = ({ children, ...props }) => (
  <TextField {...fieldToTextField(props)}>{children}</TextField>
);

export default TextFieldAdapter;

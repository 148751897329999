import React, { useState } from 'react';
import { Redirect } from 'react-router';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { getType } from 'typesafe-actions';

import * as merchantActions from '../../../redux/merchants/actions';
import { promiseListener } from '../../../redux/store';
import { MerchantDoc } from '../../../api/resources/merchant';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FeatureBar from '../../../components/FeatureBar';
import { Column, Content, Grid } from '../../../components/Layout/Main';
import NewMerchantForm from './NewMerchantForm';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(4)
  }
}));

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New = () => {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState('');

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Content>
      <FeatureBar backEnabled>Merchants</FeatureBar>

      <Grid>
        <Column xs={12}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              className={classes.title}
            >
              New Merchant
            </Typography>

            <MakeAsyncFunction
              listener={promiseListener}
              start={getType(merchantActions.createMerchant)}
              resolve={getType(merchantActions.api.createMerchant.success)}
              reject={getType(merchantActions.api.createMerchant.failure)}
            >
              {(createMerchant: any) => (
                <NewMerchantForm
                  onSubmit={async (values, actions) => {
                    try {
                      const payload: MerchantDoc = await createMerchant(values);

                      actions.setSubmitting(false);
                      setRedirectTo(`/merchants/${payload.data.id}`);
                    } catch {
                      actions.setSubmitting(false);
                      alert('There was an error submitting this form');
                    }
                  }}
                />
              )}
            </MakeAsyncFunction>
          </Paper>
        </Column>
      </Grid>
    </Content>
  );
};

export default New;

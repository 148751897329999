import React, { useCallback, useMemo, useState, useReducer } from 'react';

import produce from 'immer';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forOwn from 'lodash/forOwn';
import map from 'lodash/map';

import { makeStyles } from '@material-ui/core/styles';

import AddFilterButton from '../../../../../../components/Filter/AddFilterButton';
import CheckboxFilter from '../../../../../../components/Filter/CheckboxFilter';
import FilterPopover from '../../../../../../components/Filter/FilterPopover';
import SelectedFilterChips from '../../../../../../components/Filter/SelectedFilterChips';
import useFiltersQuery, {
  Filters
} from '../../../../../../hooks/useFiltersQuery';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type OwnProps = {};

type Props = OwnProps;

type FilterType = 'type';

type CheckboxFilterOption = {
  key: string;
  label?: string;
  value: boolean;
};

type ReducerState = {
  type: CheckboxFilterOption[];
};

type ReducerAction =
  | {
      type: 'CHANGE';
      payload: { filterType: FilterType; key: string; value: boolean };
    }
  | {
      type: 'RESET';
      payload: Filters;
    }
  | {
      type: 'REMOVE';
      payload: { filterType: FilterType };
    };

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------ß

function init(params: Filters) {
  return {
    type: [
      {
        key: 'discount_item',
        value: includes(params['type'], 'discount_item')
      },
      { key: 'discount_sale', value: includes(params['type'], 'discount_sale') }
    ]
  };
}

const reducer = produce((draft: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'CHANGE':
      const option = find(draft[action.payload.filterType], {
        key: action.payload.key
      });

      if (option) {
        option.value = action.payload.value;
      }

      break;
    case 'RESET':
      return init(action.payload);
    case 'REMOVE':
      draft[action.payload.filterType].forEach(opt => {
        opt.value = false;
      });

      break;
    default:
      break;
  }
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const FilterBar: React.FC<Props> = () => {
  const classes = useStyles();
  const filtersQuery = useFiltersQuery();

  const [anchorEl, setAnchorEl] = useState(null);
  const [state, dispatch] = useReducer(reducer, filtersQuery.filters, init);

  const selectedFilters = useMemo(() => {
    const selected: Filters = {};

    forOwn(state, (opts, key) => {
      const values = map(filter(opts, 'value'), 'key');

      if (!isEmpty(values)) {
        selected[key] = values;
      }
    });

    return selected;
  }, [state]);

  const handleOpenPopoverClick = useCallback(
    (event: any) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  /**
   * Update the value of the checkbox
   *
   * @param filterType
   */
  const handleCheckboxChange = (filterType: FilterType) => (
    key: string,
    value: boolean
  ) => {
    dispatch({
      type: 'CHANGE',
      payload: { filterType, key, value }
    });
  };

  /**
   * Update the query string with the filters
   */
  const handleApply = useCallback(() => {
    handleClose();

    // Update the query string with the new filters
    filtersQuery.update(selectedFilters);
  }, [filtersQuery, handleClose, selectedFilters]);

  const handleCancel = useCallback(() => {
    handleClose();

    dispatch({ type: 'RESET', payload: filtersQuery.filters });
  }, [handleClose, dispatch, filtersQuery]);

  /**
   * Clear the filter
   */
  const handleClearFilter = useCallback(
    (key: string) => {
      filtersQuery.remove(key);

      dispatch({ type: 'REMOVE', payload: { filterType: key as FilterType } });
    },
    [dispatch, filtersQuery]
  );

  return (
    <div className={classes.root}>
      <AddFilterButton onClick={handleOpenPopoverClick} />
      <FilterPopover
        anchorEl={anchorEl}
        onClose={handleClose}
        menuLabels={['Type']}
        FilterPages={[
          <CheckboxFilter
            options={state.type}
            onChange={handleCheckboxChange('type')}
          />
        ]}
        onApply={handleApply}
        onCancel={handleCancel}
        selected={selectedFilters}
      />

      <SelectedFilterChips
        selected={selectedFilters}
        onDelete={handleClearFilter}
      />
    </div>
  );
};

export default React.memo(FilterBar);

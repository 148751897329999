import React from 'react';

import MUIDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Menu from './Menu';

// The width of the drawer;
export const DRAWER_WIDTH = 256;

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    }
  },
  drawerPaper: {
    backgroundColor: '#262f3d',
    width: DRAWER_WIDTH
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Drawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <MUIDrawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isOpen}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <Menu />
        </MUIDrawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <MUIDrawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <Menu />
        </MUIDrawer>
      </Hidden>
    </nav>
  );
};

export default Drawer;

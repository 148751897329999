import moment from 'moment';

// Formats a unix timestamp into a date.
export const dateFormat = (value: number) => {
  return value !== null ? moment.unix(value).format('DD MMM YYYY') : null;
};

export const datetimeFormat = (value: number) => {
  return value !== null
    ? moment.unix(value).format('DD MMM YYYY H:mm A')
    : null;
};

import React from 'react';
import upperFirst from 'lodash/upperFirst';

import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DefinitionListItem from '../DefinitionListItem';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  displayInfo: {
    width: 300,
  },
  detailsText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.grey['600'],
  },
  actions: {
    flex: 1,
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  createdAt: number;
  details?: string;
  totalStamps: number;
  status: string;
  description: string;
  notification: string;
  minSpend: string;
  disableMultipleStamp: boolean;
  resendWelcomeRewards: boolean;
  maxStoreStampLimit: number;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function CampaignSummary({
  details,
  status,
  totalStamps,
  description,
  notification,
  minSpend,
  disableMultipleStamp,
  resendWelcomeRewards,
  maxStoreStampLimit,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.displayInfo}>
        <Typography variant="h6">
          {upperFirst(status)} Prize-Draw Stamp Card
        </Typography>
        <List>
          <DefinitionListItem
            title="Stamp Numbers"
            value={totalStamps.toString()}
          />
          <DefinitionListItem title="Description" value={description} />
          <DefinitionListItem
            title="Success Notification"
            value={notification}
          />
          <DefinitionListItem title="Minimum Spend" value={minSpend} />
          <DefinitionListItem
            title="Allow Multiple Entries"
            value={disableMultipleStamp.toString()}
          />
          <DefinitionListItem
            title="Resend Welcome Rewards"
            value={resendWelcomeRewards.toString()}
          />
          <DefinitionListItem
            title="Max Store Stamp Limit"
            value={
              maxStoreStampLimit > 0
                ? maxStoreStampLimit.toString()
                : 'Unlimited'
            }
          />
        </List>
      </div>
    </div>
  );
}

import { createAction, createAsyncAction } from 'typesafe-actions';

import { APIError } from '../api/models';
import { UserDoc } from '../../api/resources';

export const getProfile = createAction('profile/GET')();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchProfile: createAsyncAction(
    'api/profile/FETCH_REQUEST',
    'api/profile/FETCH_SUCCESS',
    'api/profile/FETCH_FAILURE'
  )<any, UserDoc, APIError>()
};

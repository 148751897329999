import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  clickable: {
    '&:focus': {
      backgroundColor: 'transparent !important'
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  icon: {
    fontWeight: theme.typography.fontWeightMedium
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  outlined: {
    borderStyle: 'dashed'
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<ChipProps, 'onClick'>;

//------------------------------------------------------------------------------
//
//------------------------------------------------------------------------------

const AddFilterButton: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Chip
      label="Add Filter"
      size="small"
      variant="outlined"
      onClick={onClick}
      icon={<AddIcon />}
      classes={{
        clickable: classes.clickable,
        icon: classes.icon,
        label: classes.label,
        outlined: classes.outlined
      }}
    />
  );
};

export default AddFilterButton;

import React from 'react';

import MuiTablePagination, {
  TablePaginationProps as MuiTablePaginationProps
} from '@material-ui/core/TablePagination';

export type TablePaginationProps = Pick<
  MuiTablePaginationProps,
  | 'count'
  | 'onChangePage'
  | 'page'
  | 'rowsPerPage'
  | 'rowsPerPageOptions'
  | 'onChangeRowsPerPage'
>;

export const TablePagination: React.FC<TablePaginationProps> = ({
  count,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rowsPerPage,
  rowsPerPageOptions
}) => (
  <MuiTablePagination
    component="div"
    rowsPerPageOptions={rowsPerPageOptions || []}
    colSpan={100}
    page={page - 1} // Handle page number starting at 0
    count={count}
    rowsPerPage={rowsPerPage}
    onChangeRowsPerPage={onChangeRowsPerPage}
    onChangePage={(e, page) => {
      onChangePage(e, page + 1);
    }} // Handle page number starting at 0
  />
);

export default TablePagination;

import { RootState } from '../reducers';
import { CouponSet } from './models';

import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { CouponSet as CouponSetState } from '../../api/resources/couponSet';

/**
 * Get the coupon set state
 *
 * @param state
 */
export const getCouponSetState = (state: RootState) => state.couponSets;

/**
 * Get a loyalty campaign's coupon set ids
 *
 * @param state
 * @param loyaltyCampaignId
 */
export const getLoyaltyCampaignCouponIds = (
  state: RootState,
  loyaltyCampaignId: string
) => getCouponSetState(state).loyaltyCampaigns[loyaltyCampaignId] || [];

/**
 * Get a loyalty campaign's coupon set
 *
 * @param state
 * @param loyaltyCampaignId
 */
export const listLoyaltyCampaignCouponSet =
  (loyaltyCampaignId: string) => (state: RootState) => {
    const ids = getLoyaltyCampaignCouponIds(state, loyaltyCampaignId);
    const allCouponSets = getCouponSetState(state).byId;
    const couponSets = pick(allCouponSets, ids);

    return values(orderBy(couponSets, ['attributes.createdAt'], ['desc'])).map(
      makeCouponSet(state)
    );
  };
/**
 * List a loyalty campaign coupon set
 *
 * @param state
 * @param loyaltyCampaignId
 */
export const listLoyaltyCampaignCouponSetIds =
  (loyaltyCampaignId: string) => (state: RootState) =>
    getCouponSetState(state).loyaltyCampaigns[loyaltyCampaignId];

/**
 * List recently created loyalty campaign's coupon set
 *
 * @param state
 * @param loyaltyCampaignId
 */
export const listRecentlyCreatedCouponSet =
  (loyaltyCampaignId: string) => (state: RootState) => {
    const ids = listLoyaltyCampaignCouponSetIds(loyaltyCampaignId)(state);
    const couponSets = pick(getCouponSetState(state).byId, ids);

    return values(
      orderBy(couponSets, ['attributes.createdAt'], ['desc']).slice(0, 5)
    ).map(makeCouponSet(state));
  };

/**
 * Get the pagination data
 *
 * @param state
 */
export const getPagination = (state: RootState) =>
  getCouponSetState(state).pagination;

/**
 * Gets the pdf uri of the coupon set
 * @param state
 */
export const getCouponSetPDFURI = (id: string) => (state: RootState) =>
  getCouponSetState(state).pdfs[id];

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeCouponSet =
  (state: RootState) =>
  (couponSet: CouponSetState): CouponSet => {
    const { id, attributes, relationships } = couponSet;
    const { label, couponsCount, createdAt } = attributes;

    return {
      id,
      label,
      couponsCount,
      createdAt,
      loyaltyCampaignId: relationships.loyaltyCampaign.data.id,
    };
  };

import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { Reward } from './models';

import { RewardResource } from '../../api/resources/reward';
import { getImage } from '../images/selectors';

const getRootState = (state: RootState) => state;

/**
 * Get the rewards state
 *
 * @param state
 */
export const getRewardsState = (state: RootState) => state.rewards;

/**
 *
 */
export const makeGetReward = (id: string) =>
  createSelector([getRootState, getRewardsState], (state, rewardsState) => {
    const reward = rewardsState.byId[id];

    if (!reward) {
      return undefined;
    }

    return makeReward(state)(reward);
  });

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const makeReward = (state: RootState) => (reward: RewardResource): Reward => {
  return {
    id: reward.id,
    ...reward.attributes,
    image: getImage(state, reward.relationships.images.data[0].id),
  };
};

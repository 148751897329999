import React from 'react';
import GoogleMapReact, { Props as GoogleMapReactProps } from 'google-map-react';

import { PointMarker } from '../Marker';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
const DEFAULT_CENTER = {
  lat: 1.355325,
  lng: 103.867905
};
const DEFAULT_ZOOM = 12;

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = GoogleMapReactProps & {
  marker?: {
    lat: number;
    lng: number;
  };
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const SingleMarkerMap = ({ marker, ...props }: Props) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
    defaultCenter={DEFAULT_CENTER}
    defaultZoom={DEFAULT_ZOOM}
    center={marker ? { lat: marker.lat, lng: marker.lng } : DEFAULT_CENTER}
    {...props}
    zoom={marker ? 15 : DEFAULT_ZOOM}
  >
    {marker && <PointMarker lat={marker.lat} lng={marker.lng} />}
  </GoogleMapReact>
);

export default SingleMarkerMap;

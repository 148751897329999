import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ButtonLink from '../../../../../../components/ButtonLink';
import { LoadingCard } from '../../../../../../components/Card';
import StampCampaignCard from '../../../../../../components/StampCampaignCard';
import PrizeDrawCampaignCard from '../../../../../../components/PrizeDrawCampaignCard';
import * as StampCampaignService from '../../../../../../api/services/stampCampaignService';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useBlankCardStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  campaignRoot: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    margin: theme.spacing(2),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  programId: string;
  merchantId: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function BlankCard({
  toNewStampCard,
  toNewPrizeDrawCard,
}: {
  toNewStampCard: string;
  toNewPrizeDrawCard: string;
}) {
  const classes = useBlankCardStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h6" className={classes.title}>
          Choose A Campaign Type to Activate
        </Typography>

        <div className={classes.actionContainer}>
          <Paper className={classes.campaignRoot}>
            <Typography variant="body2" className={classes.title}>
              Stamp Card with Rewards
            </Typography>
            <ButtonLink
              variant="contained"
              color="primary"
              size="large"
              to={toNewStampCard}
            >
              Select
            </ButtonLink>
          </Paper>
          <Paper className={classes.campaignRoot}>
            <Typography variant="body2" className={classes.title}>
              Prize-Draw Stamp Card
            </Typography>
            <ButtonLink
              variant="contained"
              color="primary"
              size="large"
              to={toNewPrizeDrawCard}
            >
              Select
            </ButtonLink>
          </Paper>
        </div>
      </div>
    </Paper>
  );
}

export default function StampCampaign({ programId, merchantId }: Props) {
  const { pathname } = useLocation();
  const [campaign, setCampaign] =
    useState<StampCampaignService.StampCampaign | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        setIsFetching(true);

        const res = await StampCampaignService.getActive(programId);

        if (isMounted) {
          setCampaign(res);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [programId]);

  if (isFetching) {
    return <LoadingCard />;
  }

  if (!campaign) {
    return (
      <BlankCard
        toNewStampCard={`${pathname}/stamp_campaigns/new`}
        toNewPrizeDrawCard={`${pathname}/prize_draw_campaigns/new`}
      />
    );
  }

  if (campaign && campaign.type === 'prize') {
    return (
      <PrizeDrawCampaignCard
        onActivate={(campaign) => {
          setCampaign(campaign);
        }}
        onDeactivate={() => {
          setCampaign(null);
        }}
        campaign={campaign}
        merchantId={merchantId}
        programId={programId}
      />
    );
  } else {
    return (
      <StampCampaignCard
        onActivate={(campaign) => {
          setCampaign(campaign);
        }}
        onDeactivate={() => {
          setCampaign(null);
        }}
        campaign={campaign}
        merchantId={merchantId}
      />
    );
  }
}

import { ApiRequest } from './request';
import { LoginAttributes } from '../resources';

const login = (attributes: LoginAttributes): ApiRequest => ({
  path: '/admin/api/v2/login',
  method: 'POST',
  authenticated: false,
  body: {
    data: {
      type: 'login',
      attributes,
    },
  },
});

export default {
  login,
};

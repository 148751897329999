import { ApiRequest } from './request';

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/users/${id}`,
  method: 'GET',
  authenticated: true
});

export default {
  get
};

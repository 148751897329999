import { SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { api, getUserLocations, receivedLocationAddress } from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';
import { getUserMostRecentLocation } from './selectors';
import * as geocode from '../../lib/geocode';

export function* getUserLocationsSaga(
  action: ReturnType<typeof getUserLocations>
): SagaIterator {
  const userID = action.payload;

  yield put(
    callAPI(
      apiRequests.locations.getUserLocations(userID),
      api.fetchUsersLocations
    )
  );

  yield take(getType(api.fetchUsersLocations.success));

  const location = yield select(getUserMostRecentLocation, action.payload);
  const address =
    location !== null
      ? yield call(
          [geocode, 'reverse'],
          location.coords.lat,
          location.coords.lng
        )
      : undefined;

  yield put(
    receivedLocationAddress(
      userID,
      address !== undefined ? address.formatted_address : address
    )
  );
}

export default all([
  takeLatest(getType(getUserLocations), getUserLocationsSaga)
]);

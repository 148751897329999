import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import QRCode from 'qrcode.react';

import { Merchant } from '../../../../../redux/merchants/models';
import * as BillingService from '../../../../../api/services/billingService';
import * as MerchantService from '../../../../../api/services/merchantService';
import * as SubscriptionService from '../../../../../api/services/subscriptionService';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CropFreeIcon from '@material-ui/icons/CropFree';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';

import DefinitionListItem from '../../../../../components/DefinitionListItem';
import { dateFormat } from '../../../../../lib/date';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px`,
  },
  avatar: {
    backgroundColor: theme.palette.grey['100'],
  },
  avatarColorDefault: {
    color: theme.palette.text.primary,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  mediaPlaceholder: {
    paddingTop: '22%',
    paddingBottom: '22%',
    backgroundColor: theme.palette.grey['100'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderText: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  listItem: {
    minWidth: theme.spacing(4),
  },
}));

const useAppLinkDialogStyles = makeStyles((theme) => ({
  dialog: {
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogTitle: {
    textAlign: 'center',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  merchant: Merchant;
  merchantDetail: MerchantService.MerchantProfile | undefined;
};

type AppLinkDialogProps = {
  onClose: () => void;
  open: boolean;
  url: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

function AppLinkDialog({ onClose, open, url }: AppLinkDialogProps) {
  const classes = useAppLinkDialogStyles();

  const handleDownload = () => {
    const svg = document.getElementById('qrcode');
    const serializer = new XMLSerializer();
    if (svg) {
      let source = serializer.serializeToString(svg);

      if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }

      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

      var link = document.createElement('a');
      link.download = 'qrCode.svg';
      link.href =
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
      link.click();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        Merchant App Link
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <QRCode id="qrcode" value={url} renderAs="svg" />
      </DialogContent>
      <DialogActions>
        <Button
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
        >
          Open Link
        </Button>

        <Button color="primary" onClick={handleDownload}>
          Download QR Code
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const MerchantDetails: React.FC<Props> = ({
  merchant: {
    name,
    category,
    logo,
    cover,
    createdAt,
    about,
    id,
    url,
    applink,
    phone,
    maxNumStores,
  },
  merchantDetail,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [appLinkDialogOpen, setAppLinkDialogOpen] = useState(false);
  const [subscription, setSubscription] = useState<
    SubscriptionService.Subscription | undefined
  >(undefined);
  const [billing, setBilling] = useState<BillingService.Billing | undefined>(
    undefined
  );
  const [isLoadingSub, setIsLoadingSub] = useState(true);
  const [isLoadingBill, setIsLoadingBill] = useState(true);

  // fetch subscription
  useEffect(() => {
    const fetchSubscription = async () => {
      if (id) {
        try {
          const res = await SubscriptionService.getSubscription(id);

          if (res) {
            setSubscription(res);
            setIsLoadingSub(false);
          }
        } catch (e) {
          console.log('error: ', e);
        }
      }
    };

    if (isLoadingSub) {
      fetchSubscription();
    }
  }, [id, isLoadingSub]);

  // fetch billing info
  useEffect(() => {
    const fetchBilling = async () => {
      if (id) {
        try {
          const res = await BillingService.getBilling(id);

          if (res) {
            setBilling(res);
            setIsLoadingBill(false);
          }
        } catch (e) {
          console.log('error: ', e);
        }
      }
    };

    if (isLoadingBill) {
      fetchBilling();
    }
  }, [id, isLoadingBill]);

  // Open the menu
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Open the App Link Dialog
  const handleAppLinkDialogOpen = () => {
    handleMenuClose();
    setAppLinkDialogOpen(true);
  };

  const handleAppLinkDialogClose = () => {
    setAppLinkDialogOpen(false);
  };

  // Navigate to the edit merchant page
  const handleEdit = () => {
    history.push(`/merchants/${id}/edit`);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar
              src={logo}
              classes={{
                root: classes.avatar,
                colorDefault: classes.avatarColorDefault,
              }}
            >
              {!logo && name[0]}
            </Avatar>
          }
          action={
            <>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="merchant-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon className={classes.listItem}>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Edit</Typography>
                </MenuItem>
                <MenuItem onClick={handleAppLinkDialogOpen}>
                  <ListItemIcon className={classes.listItem}>
                    <CropFreeIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>App Link</Typography>
                </MenuItem>
              </Menu>
            </>
          }
          title={name}
          subheader={category.label}
        />
        {cover ? (
          <CardMedia className={classes.media} image={cover} />
        ) : (
          <div className={classes.mediaPlaceholder}>
            <Typography variant="subtitle1" className={classes.placeholderText}>
              Cover Image
            </Typography>
          </div>
        )}
        <CardContent>
          <List>
            <DefinitionListItem
              title="Contact Name"
              value={
                merchantDetail
                  ? merchantDetail.firstName + ' ' + merchantDetail.lastName
                  : ''
              }
            />

            <DefinitionListItem
              title="Email"
              value={merchantDetail ? merchantDetail.email : ''}
            />

            <DefinitionListItem title="Contact" value={phone} />

            <DefinitionListItem title="URL" value={url} />

            <DefinitionListItem
              title="Max No. of Stores"
              value={maxNumStores.toString()}
            />

            <DefinitionListItem
              title="About"
              value={<ReactMarkdown source={about} />}
            />
            <DefinitionListItem
              title="Joined on"
              value={dateFormat(createdAt)}
            />

            {subscription && (
              <DefinitionListItem
                title="Subscription Plan"
                value={subscription.plan}
              />
            )}
            {billing && (
              <>
                <DefinitionListItem
                  title="Billing Status"
                  value={billing.status}
                />
                <DefinitionListItem
                  title="Billing Period Start"
                  value={dateFormat(billing.currentPeriodStart)}
                />
                <DefinitionListItem
                  title="Billing Period End"
                  value={dateFormat(billing.currentPeriodEnd)}
                />
              </>
            )}
          </List>
        </CardContent>
      </Card>

      {/* App Link Dialog */}
      <AppLinkDialog
        open={appLinkDialogOpen}
        onClose={handleAppLinkDialogClose}
        url={applink}
      />
    </>
  );
};

export default MerchantDetails;

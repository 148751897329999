import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

const listByCouponSetId = (id: string, opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/coupon_sets/${id}/coupons?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true,
});

export default {
  listByCouponSetId,
};

import React from 'react';

import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  image?: string;
  logo?: string;
  name: string;
  title: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 21,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 500,
    fontStyle: 'normal',
  },
  subtitle: {
    fontSize: 16,
    fontFamily: 'ClarikaGeometric',
    fontWeight: 500,
    fontStyle: 'normal',
    marginTop: 12,
    color: '#989AA2',
  },
  media: {
    height: 220,
    paddingTop: '56.25%', // 16:9
  },
  mediaPlaceholder: {
    paddingTop: '22%',
    paddingBottom: '22%',
    backgroundColor: theme.palette.grey['100'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderText: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  merchantHeader: {
    padding: '48px 13px 16px',
    backgroundColor: 'white',
    marginBottom: 8,
    boxShadow: '0 2px 2px rgba(211, 211, 211, 0.5)',
    elevation: 1,
    position: 'relative',
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    backgroundColor: 'white',
    borderRadius: 25,
    boxShadow: '0 2px 10px rgba(199, 199, 199, 0.5)',
    elevation: 1,
    position: 'absolute',
    top: -25,
    left: '42.5%',
  },
  merchantLogo: {
    height: 50,
    width: 50,
    borderRadius: 25,
    objectFit: 'cover',
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const Header: React.FC<Props> = ({ image, logo, name, title }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {image ? (
        <CardMedia className={classes.media} image={image} />
      ) : (
        <div className={classes.mediaPlaceholder}>
          <Typography variant="subtitle1" className={classes.placeholderText}>
            Reward Image
          </Typography>
        </div>
      )}
      <div className={classes.merchantHeader}>
        <div className={classes.logoContainer}>
          {logo && (
            <img
              alt="merchantLogo"
              className={classes.merchantLogo}
              src={logo}
            />
          )}
        </div>
        <Typography gutterBottom align="center" className={classes.title}>
          {name}
        </Typography>
        <Typography gutterBottom align="center" className={classes.subtitle}>
          {title}
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default Header;

import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  createRewardTemplate,
  getMerchantRewardTemplates,
  getRewardTemplate,
  archiveRewardTemplate,
  api
} from './actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

/**
 * Fetch the reward template belong to a merchant.
 *
 * @param action
 */
export function* getMerchantRewardTemplatesSaga(
  action: ReturnType<typeof getMerchantRewardTemplates>
): SagaIterator {
  const {
    merchantId,
    pagination: { page, per },
    filters
  } = action.payload;

  yield put(
    callAPI(
      apiRequests.rewardTemplates.listByMerchantId(merchantId, {
        pagination: { page, per },
        filters
      }),
      api.fetchMerchantRewardTemplates
    )
  );
}

export function* createRewardTemplateSaga(
  action: ReturnType<typeof createRewardTemplate>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.rewardTemplates.create(action.payload),
      api.createRewardTemplate
    )
  );
}

export function* getRewardTemplateSaga(
  action: ReturnType<typeof getRewardTemplate>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.rewardTemplates.get(action.payload),
      api.fetchRewardTemplate
    )
  );
}

export function* archiveRewardTemplateSaga(
  action: ReturnType<typeof archiveRewardTemplate>
): SagaIterator {
  yield put(
    callAPI(
      apiRequests.rewardTemplates.remove(action.payload),
      api.archiveRewardTemplate
    )
  );
}

export default all([
  takeLatest(
    getType(getMerchantRewardTemplates),
    getMerchantRewardTemplatesSaga
  ),
  takeLatest(getType(createRewardTemplate), createRewardTemplateSaga),
  takeLatest(getType(getRewardTemplate), getRewardTemplateSaga),
  takeLatest(getType(archiveRewardTemplate), archiveRewardTemplateSaga)
]);

import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * LoadingPaper displays a loading spinner inside a Paper
 */
const LoadingPaper = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <CircularProgress />
    </Paper>
  );
};

export default LoadingPaper;

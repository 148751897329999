import produce from 'immer';
import normalize from 'json-api-normalizer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import reduce from 'lodash/reduce';
import without from 'lodash/without';

import * as storeActions from '../stores/actions';
import { Store } from '../../api/resources';

export type StoreActions = ActionType<typeof storeActions>;

/**
 * byId contains all the store objects
 */

interface StoreById {
  [key: string]: Store;
}

const byId = createReducer<StoreById, StoreActions>({})
  .handleAction(
    [
      storeActions.api.fetchStore.success,
      storeActions.api.fetchMerchantStores.success,
      storeActions.api.createStore.success,
      storeActions.api.updateStore.success,
    ],
    (state, action) => {
      const { stores } = normalize(action.payload);

      return stores ? { ...state, ...stores } : state;
    }
  )
  .handleAction([storeActions.api.deleteStore.success], (state, action) => {
    return produce(state, (draft) => {
      delete draft[action.payload.data.id];
    });
  });

/**
 * The stores keyed to the merchant
 */
interface Merchants {
  [key: string]: string[];
}

const merchants = createReducer<Merchants, StoreActions>({})
  .handleAction(
    [storeActions.api.fetchMerchantStores.success],
    (state, action) => {
      const { data } = action.payload;

      const mcht = reduce(
        data,
        (merchants, store) => {
          const id = store.id;
          const merchantId = store.relationships.merchant.data.id;

          if (merchants[merchantId]) {
            merchants[merchantId].push(id);
          } else {
            merchants[merchantId] = [id];
          }

          return merchants;
        },
        {} as Merchants
      );

      return { ...state, ...mcht };
    }
  )
  .handleAction(
    [
      storeActions.api.fetchStore.success,
      storeActions.api.createStore.success,
      storeActions.api.updateStore.success,
    ],
    (state, action) => {
      const { data } = action.payload;
      const merchantId = data.relationships.merchant.data.id;

      return produce(state, (draft) => {
        if (draft[merchantId] === undefined) {
          draft[merchantId] = [];
        }

        draft[merchantId].push(data.id);
      });
    }
  )
  .handleAction([storeActions.api.deleteStore.success], (state, action) => {
    return produce(state, (draft) => {
      const { data } = action.payload;
      const merchantId = data.relationships.merchant.data.id;

      draft[merchantId] = without(draft[merchantId], data.id);
    });
  });

/**
 * ui
 */

interface StoresUI {
  isFetching: boolean;
}
const ui = createReducer<StoresUI, StoreActions>({
  isFetching: false,
})
  .handleAction(
    [
      storeActions.api.fetchMerchantStores.success,
      storeActions.api.fetchMerchantStores.failure,
    ],
    (state) => ({
      ...state,
      ...{ isFetching: false },
    })
  )
  .handleAction([storeActions.api.fetchMerchantStores.request], (state) => ({
    ...state,
    ...{ isFetching: true },
  }));

/**
 * pagination contains the pagination data for Reward Card
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, StoreActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 25,
}).handleAction(
  [storeActions.api.fetchMerchantStores.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map((store) => store.id),
      count: count,
      page: page,
      total: total,
      per: per,
    };
  }
);

/**
 * RewardCard root reducer
 */
const storesReducer = combineReducers({
  byId,
  merchants,
  ui,
  pagination,
});

export default storesReducer;
export type StoreState = ReturnType<typeof storesReducer>;

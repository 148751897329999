import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as LoyaltyProgramService from '../../../../../../../api/services/loyaltyProgramService';
import { Column, Grid } from '../../../../../../../components/Layout/Main';
import NewStampCampaignForm from './NewStampCampaignForm';
import * as StampCampaignService from '../../../../../../../api/services/stampCampaignService';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const NewLoyaltyCampaign = () => {
  const classes = useStyles();
  const { id: merchantId } = useParams<RouteParams>();

  const [redirectTo, setRedirectTo] = useState('');
  const [program, setProgram] = useState<LoyaltyProgramService.LoyaltyProgram | undefined>(
    undefined
  );

  // Fetch the program
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {

      try {
        const res = await LoyaltyProgramService.getByMerchant(merchantId);

        if (isMounted) {
          setProgram(res);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [merchantId]);

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  if (!program) {
    return null;
  }

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            New Stamp Campaign
          </Typography>

          <NewStampCampaignForm
            onSubmit={async (values) => {
              try {
                const res = await StampCampaignService.create({
                  details: values.description,
                  maxStamps: values.redemptionCost,
                  programId: program.id,
                });

                setRedirectTo(
                  `/merchants/${merchantId}/loyalty/stamp_campaigns/${res.id}`
                );
              } catch {
                alert('There was an error submitting this form');
              }
            }}
          />
        </Paper>
      </Column>
    </Grid>
  );
};

export default NewLoyaltyCampaign;

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import keyBy from 'lodash/keyBy';
import FormData from 'form-data';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DataTable from '../../../../../../components/DataTable';
import Link from '../../../../../../components/Link';
import * as MembershipService from '../../../../../../api/services/membershipService';
import * as UsersService from '../../../../../../api/services/userService';
import * as UploadService from '../../../../../../api/services/uploadService';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  programId: string;
  merchantId: string;
};

type TableRow = {
  id: string;
  name: string;
  email: string;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  viewAll: {
    padding: theme.spacing(1.5),
    textAlign: 'right',
  },
  subheader: {
    color: theme.palette.common.black,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const columns = [
  { title: 'ID', field: 'id' },
  {
    title: 'Name',
    field: 'name',
  },
  { title: 'Email', field: 'email' },
];

const MembershipsTable: React.FC<Props> = ({ programId, merchantId }) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [file, setFile] = useState();
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(false);

  // Fetch the memberships
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        // Fetch the memberships
        const res = await MembershipService.listByProgram(programId, {
          pagination: { per: 5, page: 1 },
          sort: [
            {
              key: 'createdAt',
              direction: 'desc',
            },
          ],
        });

        // Fetch the users
        const userIds = res.memberships.map((m) => m.user.id);
        const usersRes = await UsersService.listByIds(userIds);
        const users = keyBy(usersRes.users, 'id');

        const tableRows = res.memberships.map((m) => {
          const user = users[m.user.id];

          return {
            id: m.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          };
        });

        if (isMounted) {
          setRows(tableRows);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [programId]);

  const refreshMemberList = async () => {
    let isMounted = true;
    try {
      // Fetch the memberships
      const res = await MembershipService.listByProgram(programId, {
        pagination: { per: 5, page: 1 },
        sort: [
          {
            key: 'createdAt',
            direction: 'desc',
          },
        ],
      });

      // Fetch the users
      const userIds = res.memberships.map((m) => m.user.id);
      const usersRes = await UsersService.listByIds(userIds);
      const users = keyBy(usersRes.users, 'id');

      const tableRows = res.memberships.map((m) => {
        const user = users[m.user.id];

        return {
          id: m.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        };
      });

      if (isMounted) {
        setRows(tableRows);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    setIsUploadButtonDisabled(true);

    if (file && merchantId) {
      console.log('File: ', file);
      try {
        let data = new FormData();

        data.append('companyID', merchantId);
        data.append('fileName', file);

        await UploadService.uploadMemberCSVFile({ formdata: data });
        setFile(undefined);
        let csvInputText = document.getElementById(
          'csvFileInput'
        ) as HTMLInputElement;
        if (csvInputText !== null) {
          csvInputText.value = '';
          setIsUploadButtonDisabled(false);
        }
        refreshMemberList();
      } catch (e) {
        console.log('Error: ', e);
        setIsUploadButtonDisabled(false);
      }
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          subheader="Members"
          classes={{
            subheader: classes.subheader,
          }}
        />

        <CardContent>
          <DataTable
            title=""
            columns={columns}
            data={rows}
            footer={
              <div className={classes.viewAll}>
                <Link to={`${url}/members`}>View all members &rarr;</Link>
              </div>
            }
          />
          <form id="uploadFileForm">
            <input
              id={'csvFileInput'}
              type={'file'}
              accept={'.csv'}
              onChange={handleOnChange}
            />
            <Button
              value="Submit"
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              disabled={isUploadButtonDisabled}
            >
              Upload Membership List
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default MembershipsTable;

import React, { useState, useEffect } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Redirect, useParams } from 'react-router';
import { getType } from 'typesafe-actions';

import { useDispatch, useSelector } from 'react-redux';
import * as storeActions from '../../../../../../redux/stores/actions';
import { getStoreSelector } from '../../../../../../redux/stores/selectors';
import { promiseListener } from '../../../../../../redux/store';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import EditStoreForm from './EditStoreForm';
import { Column, Grid } from '../../../../../../components/Layout/Main';

const DEFAULT_FORM_VALUES = {
  name: '',
  phone: '',
  place: null
};

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
  storeId: string;
}

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  submit: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  map: {
    marginTop: theme.spacing(3),
    height: '400px',
    width: '100%',
    padding: 8
  }
}));

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const Edit = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, storeId } = useParams<RouteParams>();

  const store = useSelector(getStoreSelector(storeId));

  const [redirectTo, setRedirectTo] = useState('');

  // Load the store
  useEffect(() => {
    dispatch(storeActions.getStore(storeId));
  }, [dispatch, storeId]);

  const initialValues = store
    ? {
        name: store.name,
        phone: store.phone,
        place: {
          lat: store.lat,
          lng: store.lng,
          address: store.address || '',
          neighborhood: store.neighborhood || ''
        }
      }
    : DEFAULT_FORM_VALUES;

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            Edit Store
          </Typography>

          {/*
              This has nested wrapping because we have two separate async
              functions for updating and deleting. We should make this a hook
              instead so we can remove this weird nesting.
            */}
          {/* This handles deleting */}
          <MakeAsyncFunction
            listener={promiseListener}
            start={getType(storeActions.deleteStore)}
            resolve={getType(storeActions.api.deleteStore.success)}
            reject={getType(storeActions.api.deleteStore.failure)}
          >
            {/* This handles updating */}
            {(deleteStore: any) => (
              <MakeAsyncFunction
                listener={promiseListener}
                start={getType(storeActions.updateStore)}
                resolve={getType(storeActions.api.updateStore.success)}
                reject={getType(storeActions.api.updateStore.failure)}
              >
                {(updateStore: any) => (
                  <EditStoreForm
                    onSubmit={async (values, actions) => {
                      const { name, phone, place } = values;

                      if (!place) {
                        alert('You must provide an address');

                        return;
                      }

                      const { address, lat, lng, neighborhood } = place;

                      try {
                        await updateStore({
                          id: storeId,
                          name,
                          phone,
                          address,
                          coords: {
                            lat,
                            lng
                          },
                          neighborhood
                        });

                        actions.setSubmitting(false);
                      } catch {
                        actions.setSubmitting(false);
                        alert('There was an error submitting this form');
                      }
                    }}
                    onDelete={async () => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this store?'
                        )
                      ) {
                        try {
                          await deleteStore(storeId);

                          setRedirectTo(`/merchants/${id}/stores`);
                        } catch {
                          alert('There was an error deleting this store');
                        }
                      }
                    }}
                    initialValues={initialValues}
                  />
                )}
              </MakeAsyncFunction>
            )}
          </MakeAsyncFunction>
        </Paper>
      </Column>
    </Grid>
  );
};

export default Edit;

import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  title: string;
  onClick: (e: React.MouseEvent) => void;
  Icon: React.ComponentType<SvgIconProps>;
};

export default function ActionIconButton({ Icon, onClick, title }: Props) {
  const classes = useStyles();

  return (
    <Tooltip title={title} className={classes.root}>
      <IconButton size="small" onClick={onClick}>
        <Icon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}

import React from 'react';
import { useHistory } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  main: {
    minHeight: 36,
    display: 'flex',
    flexDirection: 'row',
    padding: '0 56px 0 24px'
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
    height: 48,
    marginBottom: 8
  },
  title: {
    color: theme.palette.common.white,
    margin: 0,
    fontFamily: '"Google Sans",sans-serif',
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  backButton: {
    marginLeft: -12,
    marginRight: theme.spacing(1)
  },
  backButtonColorInherit: {
    color: 'rgba(255, 255, 255, 0.75)',

    '&:hover': {
      color: 'rgba(255, 255, 255, 1)'
    }
  }
}));

type Props = {
  backEnabled?: boolean;
};

const FeatureBar: React.FC<Props> = ({ backEnabled, children }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.titleBar}>
          {backEnabled && (
            <IconButton
              disableRipple
              classes={{
                root: classes.backButton,
                colorInherit: classes.backButtonColorInherit
              }}
              color="inherit"
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <div className={classes.title}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FeatureBar;

// import React, { useEffect } from 'react';
// import moment from 'moment';

// import { useDispatch, useSelector } from 'react-redux';
// import * as rewardActions from '../../redux/rewards/actions';
// import * as storeActions from '../../redux/stores/actions';
// import { getMerchantSelector } from '../../redux/merchants/selectors';
// import { makeGetReward } from '../../redux/rewards/selectors';
// import { getFirstMerchantStore } from '../../redux/stores/selectors';

// import CardPreview from '../RewardCardPreview';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  merchantId: string;
  milestoneId: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function RewardCardPreview({ merchantId, milestoneId }: Props) {
  // const dispatch = useDispatch();

  // const reward = useSelector(makeGetReward(rewardId));
  // const merchant = useSelector(getMerchantSelector(merchantId));
  // const store = useSelector(getFirstMerchantStore(merchantId));

  // // Get the reward
  // useEffect(() => {
  //   dispatch(rewardActions.getReward(rewardId));
  // }, [dispatch, rewardId]);

  // // Get the store
  // useEffect(() => {
  //   dispatch(storeActions.getMerchantStores(merchantId));
  // }, [dispatch, merchantId]);

  // // console.log(milestone);
  // // console.log(merchant);
  // // console.log(reward);
  // // console.log(store);

  // if (!merchant) {
  //   return null;
  // }

  // if (!reward) {
  //   return null;
  // }

  // return (
  //   <CardPreview
  //     about={merchant.about}
  //     expiresAt={moment().add(validityPeriodDays, 'd')}
  //     image={reward.image!.versions.xl}
  //     info={reward.info}
  //     merchantName={merchant.name}
  //     merchantLogo={merchant.smallLogo}
  //     store={store}
  //     title={reward.title}
  //     what={reward.what}
  //   />
  // );

  return null;
}

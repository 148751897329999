import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import StampCampaignCard from '../../../../../../../components/StampCampaignCard';
import { Column, Grid } from '../../../../../../../components/Layout/Main';
import * as StampCampaignService from '../../../../../../../api/services/stampCampaignService';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
  campaignId: string;
}

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function LoyaltyCampaign() {
  const history = useHistory();
  const { id: merchantId, campaignId } = useParams<RouteParams>();
  const [
    campaign,
    setCampaign,
  ] = useState<StampCampaignService.StampCampaign | null>(null);

  // Fetch the campaign
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        const res = await StampCampaignService.get(campaignId);

        if (isMounted) {
          setCampaign(res);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [campaignId]);

  if (!campaign) {
    return null;
  }

  return (
    <Grid>
      <Column xs={12} sm={12}>
        <StampCampaignCard
          campaign={campaign}
          merchantId={merchantId}
          onActivate={() => {
            history.push(`/merchants/${merchantId}/loyalty`);
          }}
          onDeactivate={(campaign) => {
            setCampaign(campaign);
          }}
        />
      </Column>
    </Grid>
  );
}

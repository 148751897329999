import { ApiRequest } from './request';

const getUserKYC = (id: string): ApiRequest => ({
  path: `/admin/api/v2/users/${id}/kyc`,
  method: 'GET',
  authenticated: true
});

const approveUserKYC = (id: string): ApiRequest => ({
  path: `/admin/api/v2/users/${id}/kyc/approve`,
  method: 'PATCH',
  authenticated: true
});

const rejectUserKYC = (id: string): ApiRequest => ({
  path: `/admin/api/v2/users/${id}/kyc/reject`,
  method: 'PATCH',
  authenticated: true
});

export default {
  approveUserKYC,
  getUserKYC,
  rejectUserKYC
};

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import RootRoute from './RootRoute';

import Campaigns from '../../screens/Campaigns';
import Login from '../../screens/Authentication/Login';
import Overview from '../../screens/Overview';
import Users from '../../screens/Users';
import Merchants from '../../screens/Merchants';
import Errors from '../../screens/Errors';
import StockImages from '../../screens/StockImages';

const AppRouter = () => (
  <Router>
    <div id="app">
      <Switch>
        <RootRoute exact path="/" />

        {/* Authentication Routes */}
        <PublicRoute path="/login" component={Login} />

        {/* App Routes */}
        <PrivateRoute exact path="/overview" component={Overview} />

        <PrivateRoute exact path="/users" component={Users.List} />
        <PrivateRoute exact path="/users/:id/kyc" component={Users.KYC} />
        <PrivateRoute path="/users/:id" component={Users.Show} />

        <PrivateRoute exact path="/merchants" component={Merchants.List} />
        <PrivateRoute exact path="/merchants/new" component={Merchants.New} />
        <PrivateRoute path="/merchants/:id/edit" component={Merchants.Edit} />
        <PrivateRoute path="/merchants/:id" component={Merchants.Show} />

        <PrivateRoute exact path="/campaigns" component={Campaigns.List} />
        <PrivateRoute path="/campaigns/:id" component={Campaigns.Show} />

        <PrivateRoute exact path="/stockImages" component={StockImages.List} />
        <PrivateRoute
          exact
          path="/stockImages/new"
          component={StockImages.New}
        />
        <PrivateRoute path="/stockImages/:id" component={StockImages.Show} />

        <Route component={Errors.NotFound} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;

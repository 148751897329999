export enum KYCStatus {
  Pending = 'pending',
  Verified = 'verified',
  Unverified = 'unverified',
  Rejected = 'rejected'
}

export interface UserKYC {
  name: string;
  dob: number;
  street: string;
  building: string;
  province: string;
  country: string;
  portraitURL?: string;
  identityURLs: string[];
  addressURL?: string;
  status: string;
}

import React, { useEffect, useMemo } from 'react';
import {
  Link as RouterLink,
  RouteComponentProps,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as campaignActions from '../../../redux/campaigns/actions';
import * as campaignSelectors from '../../../redux/campaigns/selectors';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FeatureBar from '../../../components/FeatureBar';
import { Content } from '../../../components/Layout/Main';
import TabDropZones from './Tabs/TabDropZones';
import TabOverview from './Tabs/TabOverview';
import TabRewardCards from './Tabs/TabRewardCards';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
  },
  tabsRoot: {
    height: 3,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.common.white,
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const Show: React.FC<Props> = ({
  match: {
    params: { id },
    path,
    url,
  },
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const campaign = useSelector(campaignSelectors.getCampaign(id));

  const tabPaths = useMemo(
    () => ({
      overview: `${url}`,
      rewardCards: `${url}/reward_cards`,
      dropZones: `${url}/drop_zones`,
    }),
    [url]
  );

  // Load the campaign
  useEffect(() => {
    dispatch(campaignActions.getCampaign(id));
  }, [dispatch, id]);

  return (
    <Content>
      <FeatureBar backEnabled>{campaign && campaign.title}</FeatureBar>

      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={location.pathname}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            component={RouterLink}
            label="Overview"
            value={tabPaths.overview}
            to={tabPaths.overview}
          />
          <Tab
            component={RouterLink}
            label="Reward Cards"
            value={tabPaths.rewardCards}
            to={tabPaths.rewardCards}
          />
          <Tab
            component={RouterLink}
            label="Drop Zones"
            value={tabPaths.dropZones}
            to={tabPaths.dropZones}
          />
        </Tabs>
      </AppBar>

      <Switch>
        <Route exact path={path} component={TabOverview} />
        <Route path={`${path}/reward_cards`} component={TabRewardCards} />
        <Route path={`${path}/drop_zones`} component={TabDropZones} />
      </Switch>
    </Content>
  );
};

export default Show;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import StampCampaigns from './StampCampaigns';
import Memberships from './Memberships';
import PrizeDrawCampaigns from './PrizeDrawCampaigns';

import New from './New';
import Overview from './Overview';
import CouponSets from './CouponSets';

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

export default function TabLoyalty() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <New />
      </Route>

      <Route exact path={path}>
        <Overview />
      </Route>

      <Route path={`${path}/members`}>
        <Memberships.List />
      </Route>

      <Route path={`${path}/stamp_campaigns/new`}>
        <StampCampaigns.New />
      </Route>

      <Route path={`${path}/stamp_campaigns/:campaignId`}>
        <StampCampaigns.Show />
      </Route>

      <Route path={`${path}/stamp_campaigns`}>
        <StampCampaigns.List />
      </Route>

      <Route path={`${path}/:campaignId/coupon_sets`}>
        <CouponSets.List />
      </Route>

      <Route path={`${path}/prize_draw_campaigns/new`}>
        <PrizeDrawCampaigns.New />
      </Route>

      <Route path={`${path}/prize_draw_campaigns/edit/:campaignId`}>
        <PrizeDrawCampaigns.Edit />
      </Route>

      <Route path={`${path}/prize_draw_campaigns/:campaignId`}>
        <PrizeDrawCampaigns.Show />
      </Route>
    </Switch>
  );
}

import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import uniq from 'lodash/uniq';

import {
  api,
  createMerchant,
  updateMerchant,
  getMerchant,
  listMerchants,
  listMerchantsByIds,
} from './actions';
import { api as campaignsApi } from '../campaigns/actions';
import { api as rewardTemplatesApi } from '../rewardTemplates/actions';
import { callAPI } from '../api/actions';
import * as apiRequests from '../../api/requests';

export function* fetchMerchantsListSaga(
  action: ReturnType<typeof listMerchants>
): SagaIterator {
  const { filters, pagination, query } = action.payload;

  yield put(
    callAPI(
      apiRequests.merchants.list({
        pagination,
        search: query ? { term: query } : undefined,
        filters,
      }),
      api.fetchMerchantList
    )
  );
}

export function* fetchMerchantsByIdsSaga(
  action: ReturnType<typeof listMerchantsByIds>
): SagaIterator {
  const ids = action.payload;

  yield put(
    callAPI(
      apiRequests.merchants.list({
        pagination: { page: 1, per: ids.length },
        filters: { id: ids },
      }),
      api.fetchMerchantByIdsList
    )
  );
}

export function* getMerchantSaga(
  action: ReturnType<typeof getMerchant>
): SagaIterator {
  yield put(
    callAPI(apiRequests.merchants.get(action.payload), api.fetchMerchant)
  );
}

/**
 * Fetches the merchants associated with the campaigns
 * @param action
 */
export function* fetchMerchantCampaignRelationshipsSaga(
  action: ReturnType<typeof campaignsApi.listCampaigns.success>
) {
  const ids = uniq(
    action.payload.data.map(
      (campaign) => campaign.relationships.merchant.data.id
    )
  );

  yield put(listMerchantsByIds(ids));
}

/**
 * Fetches the merchants associated with the reward template
 * @param action
 */
export function* fetchMerchantRewardTemplateRelationshipsSaga(
  action: ReturnType<
    typeof rewardTemplatesApi.fetchMerchantRewardTemplates.success
  >
) {
  const ids = uniq(
    action.payload.data.map(
      (rewardTemplate) => rewardTemplate.relationships.merchant.data.id
    )
  );

  yield put(listMerchantsByIds(ids));
}

export function* createMerchantSaga(
  action: ReturnType<typeof createMerchant>
): SagaIterator {
  yield put(
    callAPI(apiRequests.merchants.create(action.payload), api.createMerchant)
  );
}

export function* updateMerchantSaga(
  action: ReturnType<typeof updateMerchant>
): SagaIterator {
  yield put(
    callAPI(apiRequests.merchants.update(action.payload), api.updateMerchant)
  );
}

export default all([
  takeLatest(getType(listMerchants), fetchMerchantsListSaga),
  takeLatest(getType(listMerchantsByIds), fetchMerchantsByIdsSaga),
  takeLatest(
    getType(campaignsApi.listCampaigns.success),
    fetchMerchantCampaignRelationshipsSaga
  ),
  takeLatest(
    getType(rewardTemplatesApi.fetchMerchantRewardTemplates.success),
    fetchMerchantRewardTemplateRelationshipsSaga
  ),
  // OLD
  takeLatest(getType(getMerchant), getMerchantSaga),
  takeLeading(getType(createMerchant), createMerchantSaga),
  takeLeading(getType(updateMerchant), updateMerchantSaga),
]);

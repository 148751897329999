import React, { useEffect } from 'react';
import RoomIcon from '@material-ui/icons/Room';

type Point = {
  lat: number;
  lng: number;
};

//------------------------------------------------------------------------------
//
//------------------------------------------------------------------------------

type PointMarkerProps = Point;

const PointMarker: React.FC<PointMarkerProps> = () => (
  <RoomIcon fontSize="default" style={{ transform: 'translate(-50%, -50%)' }} />
);

//------------------------------------------------------------------------------
//
//------------------------------------------------------------------------------

type ZoneMarkerProps = Point & {
  map?: google.maps.Map;
  radius?: number;
  fitBounds?: boolean;
  mapBound?: google.maps.LatLngBounds;
  unionMapBound?: (radiusBound: google.maps.LatLngBounds) => void;
};

const ZoneMarker: React.FC<ZoneMarkerProps> = ({
  map,
  lat,
  lng,
  radius,
  fitBounds,
  mapBound,
  unionMapBound,
}) => {
  useEffect(() => {
    const circle = new google.maps.Circle({
      strokeWeight: 2,
      strokeColor: '#ea0043',
      strokeOpacity: 0.8,
      fillColor: '#ea0043',
      fillOpacity: 0.35,
      map,
      radius,
      center: { lat, lng },
    });

    // Fit the map to the radius
    if (map && fitBounds) {
      const bounds = circle.getBounds();

      if (mapBound && bounds && unionMapBound) {
        unionMapBound(bounds);
        map.fitBounds(mapBound);
      } else if (bounds) {
        map.fitBounds(bounds);
      }
    }

    return () => {
      circle.setMap(null);
    };
  }, [map, lat, lng, radius, fitBounds, mapBound, unionMapBound]);

  return <PointMarker {...{ lat, lng }} />;
};

export { PointMarker, ZoneMarker };

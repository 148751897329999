import { createAction, createAsyncAction } from 'typesafe-actions';
import { FilterOptions, PaginationOptions } from '../types';
import {
  CampaignDoc,
  CampaignCollectionDoc,
  CampaignStatsDoc
} from '../../api/resources/campaign';
import { APIError } from '../api/models';

/**
 * List campaigns
 */
export const listCampaigns = createAction(
  'campaigns/LIST',
  (opts: { pagination: PaginationOptions; filters: FilterOptions }) => opts
)();

/**
 * Lists a merchant's campaigns
 */
export const listMerchantCampaigns = createAction(
  'campaigns/LIST_BY_MERCHANT',
  (merchantID: string) => merchantID
)();

/**
 * Gets a campaign by id
 */
export const getCampaign = createAction('campaigns/GET', (id: string) => id)();

/**
 * Gets a campaign's stats
 */
export const getCampaignStats = createAction(
  'campaigns/GET_STATS',
  (id: string) => id
)();

/**
 * Creates a campaign
 */
export const createCampaign = createAction(
  'campaigns/CREATE',
  (attrs: {
    title: string;
    type: 'airdrop';
    expiresAt: number;
    rewardExpiresAt: number;
    notify: boolean;
    gender: 'all' | 'male' | 'female';
    minAge: number;
    maxAge: number;
    supply: number;
    merchantId: string;
    rewardTemplateId: string;
  }) => attrs
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  listCampaigns: createAsyncAction(
    'api/campaigns/LIST_REQUEST',
    'api/campaigns/LIST_SUCCESS',
    'api/campaigns/LIST_FAILURE'
  )<any, CampaignCollectionDoc, APIError>(),

  listMerchantCampaigns: createAsyncAction(
    'api/campaigns/LIST_BY_MERCHANT_REQUEST',
    'api/campaigns/LIST_BY_MERCHANT_SUCCESS',
    'api/campaigns/LIST_BY_MERCHANT_FAILURE'
  )<any, CampaignCollectionDoc, APIError>(),

  fetchCampaign: createAsyncAction(
    'api/campaigns/FETCH_REQUEST',
    'api/campaigns/FETCH_SUCCESS',
    'api/campaigns/FETCH_FAILURE'
  )<any, CampaignDoc, APIError>(),

  createCampaign: createAsyncAction(
    'api/campaigns/CREATE_REQUEST',
    'api/campaigns/CREATE_SUCCESS',
    'api/campaigns/CREATE_FAILURE'
  )<any, CampaignDoc, APIError>(),

  fetchCampaignStats: createAsyncAction(
    'api/campaigns/FETCH_STATS_REQUEST',
    'api/campaigns/FETCH_STATS_SUCCESS',
    'api/campaigns/FETCH_STATS_FAILURE'
  )<any, CampaignStatsDoc, APIError>()
};

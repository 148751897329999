import { RootState } from '../reducers';

export const getlocationsState = (state: RootState) => state.locations;

export const getUserMostRecentLocation = (state: RootState, userId: string) => {
  return getlocationsState(state).byUserId[userId];
};

export const getUserMostRecentAddress = (
  state: RootState,
  userId: string
): string | undefined => {
  const location = getUserMostRecentLocation(state, userId);

  if (location === undefined) {
    return undefined;
  }

  return location.address;
};

export const getUserMostRecentAddressSelector = (id: string) => (
  state: RootState
): string | undefined => getUserMostRecentAddress(state, id);

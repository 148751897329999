import jwt_decode from 'jwt-decode';
import moment from 'moment';

interface Claims {
  aud: string;
  exp: number;
  sub: string;
  iat: number;
  jti: string;
  role?: string;
  trusted: boolean;
}

export const decode = (token: string): Claims => {
  return jwt_decode<Claims>(token);
};

export const isJWTValid = (token: string): boolean => {
  return !isJWTExpired(token);
};

export const isJWTExpired = (token: string): boolean => {
  const decoded = jwt_decode<Claims>(token);

  const { exp: expiresAt } = decoded;
  const now = parseInt(moment().format('X'));

  return expiresAt < now;
};

export const getJWTSubject = (token: string): string => {
  const decoded = jwt_decode<Claims>(token);

  return decoded.sub;
};

import React, { useState } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Redirect, useParams } from 'react-router-dom';
import { getType } from 'typesafe-actions';

import { useSelector } from 'react-redux';
import * as loyaltyProgramActions from '../../../../../../redux/loyaltyPrograms/actions';
import { getMerchantSelector } from '../../../../../../redux/merchants/selectors';
import { promiseListener } from '../../../../../../redux/store';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Column, Grid } from '../../../../../../components/Layout/Main';
import NewLoyaltyRewardForm from './NewLoyaltyProgramForm';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(4)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New = () => {
  const classes = useStyles();
  const { id } = useParams<RouteParams>();

  const merchant = useSelector(getMerchantSelector(id));
  const [redirectTo, setRedirectTo] = useState('');

  if (!merchant) {
    return null;
  }

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            New Loyalty Program
          </Typography>

          <Typography gutterBottom align="center" className={classes.title}>
            This form currently does not have any fields to fill in
          </Typography>

          <MakeAsyncFunction
            listener={promiseListener}
            start={getType(loyaltyProgramActions.createLoyaltyProgram)}
            resolve={getType(
              loyaltyProgramActions.api.createLoyaltyProgram.success
            )}
            reject={getType(
              loyaltyProgramActions.api.createLoyaltyProgram.failure
            )}
          >
            {(createLoyaltyProgram: any) => (
              <NewLoyaltyRewardForm
                onSubmit={async (values, actions) => {
                  try {
                    await createLoyaltyProgram({
                      merchantId: merchant.id
                    });

                    actions.setSubmitting(false);
                    setRedirectTo(`/merchants/${id}/loyalty`);
                  } catch {
                    actions.setSubmitting(false);
                    alert('There was an error submitting this form');
                  }
                }}
              />
            )}
          </MakeAsyncFunction>
        </Paper>
      </Column>
    </Grid>
  );
};

export default New;

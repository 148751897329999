import { getJWTSubject } from '../../lib/jwt';
import { RootState } from '../reducers';
import { AuthState } from './reducer';

export const authSelector = (state: RootState): AuthState => state.auth;

export const getAuthToken = (state: RootState): string | null =>
  authSelector(state).token;

export const isAuthenticated = (state: RootState): boolean =>
  getAuthToken(state) != null;

export const getCurrentUserId = (state: RootState): string | null => {
  const token = getAuthToken(state);

  return token ? getJWTSubject(token) : null;
};

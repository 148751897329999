import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const DialogTitle = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(),
  },
}))(MuiDialogTitle);

const DialogActions = withStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      width: 230,
      marginBottom: theme.spacing(),
    },
  },
}));

type Props = Pick<DialogProps, 'open' | 'onClose'> & {
  id?: string;
  body: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  title: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

export default function ConfirmationDialog({
  id,
  body,
  cancelButtonText,
  confirmButtonText,
  onClose,
  onCancel,
  onConfirm,
  open,
  title,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} key={id ? id : 'none'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          {cancelButtonText && onCancel && (
            <Button onClick={onCancel} variant="contained" fullWidth>
              {cancelButtonText}
            </Button>
          )}
        </div>
        <div className={classes.buttonContainer}>
          <Button onClick={onConfirm} color="primary" fullWidth>
            {confirmButtonText || 'Confirm'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

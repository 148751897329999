import { createAction, createAsyncAction } from 'typesafe-actions';
import { APIError } from '../api/models';
import { KYCDataDoc } from '../../api/resources/kyc';

/**
 * Gets a user's KYC doc
 */
export const getUserKYC = createAction(
  'kyc/GET_USER_KYC',
  (userID: string) => userID
)();

/**
 * Approve a user's KYC application.
 */
export const approveUserKYC = createAction(
  'kyc/APPROVE_USER_KYC',
  (userID: string) => userID
)();

/**
 * Reject a user's KYC application.
 */
export const rejectUserKYC = createAction(
  'kyc/REJECT_USER_KYC',
  (userID: string) => userID
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchUserKYC: createAsyncAction(
    'api/kyc/FETCH_USER_KYC_REQUEST',
    'api/kyc/FETCH_USER_KYC_SUCCESS',
    'api/kyc/FETCH_USER_KYC_FAILURE'
  )<any, KYCDataDoc, APIError>(),

  approveUserKYC: createAsyncAction(
    'api/users/APPROVE_KYC_REQUEST',
    'api/users/APPROVE_KYC_SUCCESS',
    'api/users/APPROVE_KYC_FAILURE'
  )<any, KYCDataDoc, APIError>(),

  rejectUserKYC: createAsyncAction(
    'api/users/REJECT_KYC_REQUEST',
    'api/users/REJECT_KYC_SUCCESS',
    'api/users/REJECT_KYC_FAILURE'
  )<any, KYCDataDoc, APIError>()
};

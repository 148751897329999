import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

import ImageThumb from './ImageThumb';
import Placeholder from './Placeholder';

const MAX_FILE_SIZE = 5242880;

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    height: 140,
    width: 204,
    backgroundColor: theme.palette.grey['100'],
    borderRadius: theme.shape.borderRadius,
    borderColor: (props: StyleProps) => {
      if (props.error) {
        return theme.palette.error.main;
      }

      if (props.disabled) {
        return '#ced4da';
      }

      return theme.palette.primary.main;
    },
    borderStyle: 'dashed',
    borderWidth: (props: StyleProps) => (props.previewing ? 0 : 2),
    cursor: 'pointer',
    outline: 'none',
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type StyleProps = {
  previewing: boolean;
  error: boolean;
  disabled?: boolean;
};

export type Props = {
  onTouch?: () => void;
  onChange?: (file: File) => void;
  onError?: (errorMsg?: string) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  selectedURL?: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

/**
 * ImageSelect provides a drag and prop box to select an image.
 */
const ImageSelect: React.FC<Props> = ({
  disabled = false,
  error = false,
  helperText,
  onChange,
  onTouch,
  onError,
  selectedURL,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [preview, setPreview] = useState('');
  const classes = useStyles({
    previewing: preview !== '',
    error: error,
    disabled: disabled,
  });

  const handleTouch = useCallback(() => {
    if (onTouch) {
      onTouch();
    }
  }, [onTouch]);

  const handleDropAccepted = useCallback(
    (files: File[]) => {
      const file = files[0];

      setFile(file);

      if (onChange) {
        onChange(file);
      }
    },
    [setFile, onChange]
  );

  const handleDropRejected = useCallback(
    (files: File[]) => {
      const file = files[0];
      let errorMsg = 'File upload error';

      if (file && file.size > MAX_FILE_SIZE) {
        errorMsg = 'File size is bigger than 5MB';
      }

      if (onError) {
        onError(errorMsg);
      }
    },
    [onError]
  );

  // Set a existing preview image from the selectedURL
  useEffect(() => {
    setPreview(selectedURL || '');
  }, [selectedURL, setPreview]);

  // Generate a preview when the file changes
  useEffect(() => {
    if (file) {
      const preview = URL.createObjectURL(file);
      setPreview(preview);

      return () => {
        URL.revokeObjectURL(preview);
      };
    }
  }, [file, setPreview]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    maxSize: MAX_FILE_SIZE,
    multiple: false,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    onFileDialogCancel: handleTouch,
    disabled: disabled,
  });

  return (
    <FormControl>
      <div {...getRootProps()} className={classes.root}>
        <input {...getInputProps()} />

        {preview ? <ImageThumb src={preview} /> : <Placeholder />}
      </div>

      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default ImageSelect;

import React from 'react';

import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.grey['500'],

    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  title: string;
  value: React.ReactNode;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const DefinitionListItem: React.FC<Props> = ({ title, value }) => {
  const classes = useStyles();

  return (
    <ListItemText
      disableTypography
      primary={
        <Typography variant="caption" className={classes.title}>
          {title}
        </Typography>
      }
      secondary={
        typeof value === 'string' ? (
          <Typography variant="body2">{value}</Typography>
        ) : (
          value
        )
      }
    />
  );
};

export default DefinitionListItem;

import React, { useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getMerchantSelector } from '../../../../../../redux/merchants/selectors';

import StampCampaign from './StampCampaign';
import * as LoyaltyProgramService from '../../../../../../api/services/loyaltyProgramService';
import { Column, Grid } from '../../../../../../components/Layout/Main';
import { LoadingPaper } from '../../../../../../components/Paper';
import MembershipsTable from './MembershipsTable';
import WelcomeCampaign from './WelcomeCampaign';
import BirthdayCampaign from './BirthdayCampaign';
import BlankPage from './BlankPage';
import ContactlessRate from './ContactlessRate';
import LoyaltyCampaignDuration from './LoyaltyCampaignDuration';
import AnnouncementDuration from './AnnouncementDuration';
import MaxStampCampaign from './MaxStampCampaign';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

export default function Overview() {
  const { id: merchantId } = useParams<RouteParams>();
  const [program, setProgram] =
    useState<LoyaltyProgramService.LoyaltyProgram | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  let { url } = useRouteMatch();

  const merchant = useSelector(getMerchantSelector(merchantId));

  // Fetch the program
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        const res = await LoyaltyProgramService.getByMerchant(merchantId);

        if (isMounted) {
          setProgram(res);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [merchantId]);

  if (!merchant) {
    return null;
  }

  if (isFetching) {
    return <LoadingPaper />;
  }

  return (
    <Grid>
      <Column xs={12} sm={12}>
        {!program && (
          <BlankPage
            linkText="Get Started"
            title="No loyalty program exists"
            to={`${url}/new`}
          />
        )}
        {program && merchant && (
          <StampCampaign programId={program.id} merchantId={merchantId} />
        )}
      </Column>

      {program && merchant && (
        <>
          <Column xs={12} sm={12}>
            <WelcomeCampaign merchantId={merchant.id} programId={program.id} />
          </Column>
          <Column xs={12} sm={6}>
            <BirthdayCampaign merchantId={merchant.id} programId={program.id} />
            <MaxStampCampaign merchantId={merchant.id} programId={program.id} />
            <ContactlessRate merchantId={merchant.id} programId={program.id} />
            <LoyaltyCampaignDuration
              merchantId={merchant.id}
              programId={program.id}
            />
            <AnnouncementDuration
              merchantId={merchant.id}
              programId={program.id}
            />
          </Column>

          <Column xs={12} sm={6}>
            <MembershipsTable programId={program.id} merchantId={merchant.id} />
          </Column>
        </>
      )}
    </Grid>
  );
}

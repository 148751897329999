import React from 'react';
import moment from 'moment';

import { Store } from '../../redux/stores/models';

import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import MarkdownSection from './MarkdownSection';
import StoreSection from './StoreSection';
import ValiditySection from './ValiditySection';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  about: string;
  image?: string;
  info: string;
  merchantName: string;
  merchantLogo?: string;
  store?: Store;
  title: string;
  what: string;
  expiresAt: moment.Moment;
};

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    display: 'block',
    position: 'relative',
    transform: 'scale(1)',
    zIndex: 1,
    height: 868,
    width: 428,
    margin: '1rem auto',
    outline: 'none',
    backgroundColor: '#f4f5f8',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    objectFit: 'cover',
    overflowY: 'auto',
    maxHeight: '100vh',
    borderRadius: 8,
  },
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const RewardCardPreview = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      about,
      expiresAt,
      image,
      info,
      merchantName,
      merchantLogo,
      store,
      title,
      what,
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div className={classes.root} ref={ref}>
        <Header
          image={image}
          logo={merchantLogo}
          name={merchantName}
          title={title}
        />

        <MarkdownSection header="What You'll Get" markdownText={what} />
        {store && <StoreSection store={store} />}
        <ValiditySection expiresAt={expiresAt} />
        <MarkdownSection header="The Fine Print" markdownText={info} />
        <MarkdownSection header="About" markdownText={about} />
      </div>
    );
  }
);

export default RewardCardPreview;

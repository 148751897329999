import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import axios, { AxiosRequestConfig, Method } from 'axios';

import { callAPI, callAPIFailure } from './actions';
import { ActionType, getType } from 'typesafe-actions';

// Default request headers
const DEFAULT_HEADERS = {
  Accept: 'application/vnd.api+json, application/json',
  'Content-Type': 'application/vnd.api+json'
};

export const apiMiddleware: Middleware = (store: MiddlewareAPI) => (
  next: Dispatch
) => (action: ActionType<typeof callAPI>) => {
  // Skip if the action is not an 'callAPI' action
  if (action.type !== getType(callAPI)) {
    return next(action);
  }

  // Process the API call
  return (async () => {
    const state = store.getState();
    const { endpoint } = state.api;
    const { token } = state.auth;
    const {
      asyncAction: apiActions,
      opts,
      request: apiRequest
    } = action.payload;
    const { path, method, body, authenticated } = apiRequest;
    const url = `${endpoint}${path}`;

    log(opts.debug, 'Payload', action.payload);

    // Generate default Headers and merge custom headers
    const headers: any = {
      ...DEFAULT_HEADERS,
      ...apiRequest.headers // TODO - Handle null headers
    };

    // Insert the token if the request is authenticated
    // TODO - Handle no Auth token with Forbidden/Not Authenticated error
    if (authenticated) {
      headers['X-Session-Token'] = token;
    }

    // Assemble the request config
    const config: AxiosRequestConfig = {
      method: method as Method,
      url,
      headers,
      data: serializeBody(body)
    };

    log(opts.debug, 'Request', config);

    // Dispatch the Request FSA
    next(apiActions.request(config));

    //
    try {
      const response = await axios.request(config);

      log(opts.debug, 'Response', response);

      const payload = { ...response.data };

      if (opts.payloadMeta) {
        payload.meta = opts.payloadMeta(response);
      }

      // Return the success FSA
      return next(apiActions.success(payload));
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        log(opts.debug, 'Error', error);

        const { data, status, statusText } = error.response;
        const payload = { data, status, statusText };

        // Dispatch a general API failure call
        store.dispatch(callAPIFailure(payload));

        // Return the failure FSA
        return next(apiActions.failure(payload));
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  })();
};

const serializeBody = (body?: string | object | null): string | undefined => {
  if (body === undefined) {
    return undefined;
  }

  if (typeof body === 'string') {
    return body;
  }

  return JSON.stringify(body);
};

const log = (debug: boolean | undefined, msg: string, object: object) => {
  if (debug) {
    console.log(`[API Middleware] ${msg}`);
    console.log(object);
  }
};

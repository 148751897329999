import React from 'react';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { Filters } from '../../hooks/useFiltersQuery';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing()
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type OwnProps = {
  selected: Filters;
  onDelete: (name: string) => void;
};

type Props = OwnProps;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const FilterChip: React.FC<Props> = ({ onDelete, selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {map(selected, (values, name) => (
        <Chip
          key={name}
          size="small"
          label={`${capitalize(name)} = ${values.map(capitalize).join(' + ')}`}
          color="primary"
          onDelete={() => onDelete(name)}
          className={classes.root}
        />
      ))}
    </div>
  );
};

export default React.memo(FilterChip);

import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import { TextField } from '../../../components/FormikAdapter';
import { FormRow } from '../../../components/FormLayout';
import { SubmitButton } from '../../../components/Form';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface LoginFormValues {
  email: string;
  password: string;
}

type Props = {
  onSubmit: (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Not a valid email'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be greater than 8 characters'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Form translate="no" noValidate>
          <FormRow>
            <Field
              component={TextField}
              name="email"
              label="Email"
              autoFocus
              fullWidth
              required
            />
          </FormRow>

          <FormRow>
            <Field
              component={TextField}
              type="password"
              name="password"
              label="Password"
              fullWidth
              required
            />
          </FormRow>

          <SubmitButton fullWidth isSubmitting={formikBag.isSubmitting}>
            Login
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;

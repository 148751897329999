import React from 'react';
import get from 'lodash/get';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

import LoadingTableBody from './LoadingTableBody';
import TableBody, { TableBodyProps } from './TableBody';
import TableHead, { TableHeadProps } from './TableHead';
import TablePagination, { TablePaginationProps } from './TablePagination';
import TableToolbar, { TableToolbarProps } from './TableToolbar';
import { Cell } from './types';

const useStyles = makeStyles({
  root: {
    maxWidth: 936,
    margin: '0 auto',
    borderRadius: 8,
  },
});

type Props = Pick<TableToolbarProps, 'Actions' | 'title' | 'SearchProps'> &
  Pick<TableHeadProps, 'columns'> &
  Pick<TableBodyProps, 'onRowClick'> & {
    data: any;
    isLoading?: boolean;
    PaginationProps?: TablePaginationProps;
    footer?: React.ReactNode;
  };

const DataTable: React.FC<Props> = ({
  Actions,
  columns,
  data,
  footer,
  isLoading,
  onRowClick,
  PaginationProps,
  SearchProps,
  title,
}: Props) => {
  const classes = useStyles();

  const rows = data.map((obj: any) =>
    columns.map((col) => {
      let value = get(obj, col.field);

      if (col.format) {
        value = col.format(value);
      }

      const cell: Cell = { value, width: col.width, align: col.align };

      return cell;
    })
  );

  return (
    <Paper className={classes.root}>
      <TableToolbar Actions={Actions} title={title} SearchProps={SearchProps} />

      <Table>
        <TableHead columns={columns} />
        {isLoading && data.length === 0 ? (
          <LoadingTableBody />
        ) : (
          <TableBody rows={rows} onRowClick={onRowClick} />
        )}
      </Table>

      {PaginationProps && <TablePagination {...PaginationProps} />}
      {footer}
    </Paper>
  );
};

export default DataTable;
export { default as ActionIconButton } from './ActionIconButton';
export * from './types';

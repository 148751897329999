import React, { useState } from 'react';
import GoogleMapReact, { Props as GoogleMapReactProps } from 'google-map-react';

import { ZoneMarker } from '../Marker';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
const DEFAULT_CENTER = {
  lat: 1.355325,
  lng: 103.867905,
};
const DEFAULT_ZOOM = 11;

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type DropZone = {
  coords: {
    lat: number;
    lng: number;
  };
  radius: number;
};

type Props = GoogleMapReactProps & {
  zones: DropZone[];
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const DropZoneMap = ({ zones, ...props }: Props) => {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  let bounds = new google.maps.LatLngBounds();

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      defaultCenter={DEFAULT_CENTER}
      defaultZoom={DEFAULT_ZOOM}
      onGoogleApiLoaded={({ map }) => setMap(map)}
      yesIWantToUseGoogleMapApiInternals
      {...props}
    >
      {zones.map((zone, idx) => {
        return (
          <ZoneMarker
            key={idx}
            map={map}
            lat={zone.coords.lat}
            lng={zone.coords.lng}
            radius={zone.radius}
            mapBound={bounds}
            fitBounds
            unionMapBound={(radiusBound) => bounds.union(radiusBound)}
          />
        );
      })}
    </GoogleMapReact>
  );
};

export default DropZoneMap;

import React from 'react';
import { MapMarkerStar } from '../../Icon';
import GoogleMapReact, {
  Props as GoogleMapReactProps,
  MapOptions
} from 'google-map-react';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
const DEFAULT_CENTER = {
  lat: 1.355325,
  lng: 103.867905
};
const DEFAULT_ZOOM = 12;

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = GoogleMapReactProps & {
  marker?: {
    lat: number;
    lng: number;
  };
  mapOption?: MapOptions;
  markerType?: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const StarMarker: React.FC<{
  lat: number;
  lng: number;
}> = () => <MapMarkerStar height="36px" width="36px" color="#EA0043" />;

const StoreMap = ({ marker, mapOption, markerType, ...props }: Props) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
    defaultCenter={DEFAULT_CENTER}
    defaultZoom={DEFAULT_ZOOM}
    center={marker ? { lat: marker.lat, lng: marker.lng } : DEFAULT_CENTER}
    {...props}
    zoom={marker ? 15 : DEFAULT_ZOOM}
    options={mapOption}
  >
    {marker && <StarMarker {...{ lat: marker.lat, lng: marker.lng }} />}
  </GoogleMapReact>
);

export default StoreMap;

import normalize from 'json-api-normalizer';
import produce from 'immer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import reduce from 'lodash/reduce';

import * as dropZoneActions from '../dropZones/actions';
import { DropZoneResource } from '../../api/resources';

export type DropZoneActions = ActionType<typeof dropZoneActions>;

/**
 * byId contains all the drop zone resources.
 */

interface ById {
  [key: string]: DropZoneResource;
}

const byId = createReducer<ById, DropZoneActions>({}).handleAction(
  [
    dropZoneActions.api.fetchCampaignDropZones.success,
    dropZoneActions.api.createDropZone.success
  ],
  (state, action) => {
    const { dropZones } = normalize(action.payload);

    return dropZones ? { ...state, ...dropZones } : state;
  }
);

/**
 * The campaign reward card ids
 */
interface CampaignIds {
  [key: string]: string[];
}

const campaignIds = createReducer<CampaignIds, DropZoneActions>({})
  .handleAction(
    [dropZoneActions.api.fetchCampaignDropZones.success],
    (state, action) => {
      const { data } = action.payload;

      const byCampaignIds = reduce(
        data,
        (campaigns, dropZone) => {
          const id = dropZone.id;
          const campaignId = dropZone.relationships.campaign.data.id;

          if (campaigns[campaignId]) {
            campaigns[campaignId].push(id);
          } else {
            campaigns[campaignId] = [id];
          }

          return campaigns;
        },
        {} as CampaignIds
      );

      return { ...state, ...byCampaignIds };
    }
  )
  .handleAction(
    [dropZoneActions.api.createDropZone.success],
    (state, action) => {
      const { id, relationships } = action.payload.data;

      return produce(state, draft => {
        if (draft[relationships.campaign.data.id] === undefined) {
          draft[relationships.campaign.data.id] = [];
        }

        draft[relationships.campaign.data.id].push(id);
      });
    }
  );
/**
 * Drop Zone root reducer
 */
const dropZonesReducer = combineReducers({
  byId,
  campaignIds
});

export default dropZonesReducer;
export type DropZonesState = ReturnType<typeof dropZonesReducer>;

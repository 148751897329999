import request from './request';

/**
 * Generate the contactless store pdf.
 *
 * This endpoint returns the contactless pdf data
 *
 * @param companyId
 * @param storeId
 */
export function generateContactlessPDF(companyId: string, storeId: string) {
  return request<any>({
    url: `/admin/api/v2/merchants/${companyId}/stores/${storeId}.pdf/contactless`,
    method: 'GET',
    responseType: 'blob',
  });
}

import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { isAuthenticated } from '../../redux/auth/selectors';

type Props = RouteProps;

const RootRoute: React.FC<Props> = ({ ...rest }) => {
  const authenticated = useSelector(isAuthenticated);

  return (
    <Route
      {...rest}
      render={() =>
        authenticated ? (
          <Redirect
            to={{
              pathname: '/overview'
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        )
      }
    />
  );
};

export default RootRoute;

import request from './request';
import deserialize from './deserialize';

export type Milestone = {
  id: string;
  threshold: number;
  validityPeriodDays: number;
  createdAt: number;
  updatedAt: number;
  rewardExpiryDate: number;
  reward: {
    type: 'rewards';
    id: string;
  };
  rewardTemplate: {
    type: 'rewardTemplates';
    id: string;
  };
  stampCampaign: {
    type: 'stampCampaigns';
    id: string;
  };
};

export function listByCampaign(campaignId: string) {
  return request<Milestone[]>({
    url: `/admin/api/v2/stamp_campaigns/${campaignId}/milestones`,
    method: 'GET',
    transformResponse: deserialize,
  });
}

interface UpdateExpiryDateAttrs {
  rewardExpiryDate: number;
  id: string;
  rewardTemplateId: string;
  merchantId: string;
}

export function updateMilestoneRewardExpiryDate(attrs: UpdateExpiryDateAttrs) {
  return request<Milestone>({
    url: `/admin/api/v2/milestone/${attrs.id}`,
    method: 'PATCH',
    data: {
      data: {
        type: 'milestones',
        attributes: {
          rewardExpiryDate: attrs.rewardExpiryDate,
        },
        relationships: {
          rewardTemplate: {
            data: {
              type: 'reward_templates',
              id: attrs.rewardTemplateId,
            },
          },
          merchant: {
            data: {
              type: 'merchants',
              id: attrs.merchantId,
            },
          },
        },
      },
    },
  });
}

interface UpdateValidityPeriodDaysAttrs {
  validityPeriodDays: number;
  id: string;
  rewardTemplateId: string;
  merchantId: string;
}

export function updateMilestoneValidityPeriodDays(
  attrs: UpdateValidityPeriodDaysAttrs
) {
  return request<Milestone>({
    url: `/admin/api/v2/milestone/${attrs.id}`,
    method: 'PATCH',
    data: {
      data: {
        type: 'milestones',
        attributes: {
          validityPeriodDays: attrs.validityPeriodDays,
        },
        relationships: {
          rewardTemplate: {
            data: {
              type: 'reward_templates',
              id: attrs.rewardTemplateId,
            },
          },
          merchant: {
            data: {
              type: 'merchants',
              id: attrs.merchantId,
            },
          },
        },
      },
    },
  });
}

interface CreateAttrs {
  threshold: number;
  validityPeriodDays: number;
  stampCampaignId: string;
  rewardTemplateId: string;
}
export function create(attrs: CreateAttrs) {
  return request<Milestone>({
    url: `/admin/api/v2/milestones`,
    method: 'POST',
    data: {
      data: {
        type: 'milestones',
        attributes: {
          threshold: attrs.threshold,
          validityPeriodDays: attrs.validityPeriodDays,
        },
        relationships: {
          stampCampaign: {
            data: {
              type: 'stamp_campaigns',
              id: attrs.stampCampaignId,
            },
          },
          rewardTemplate: {
            data: {
              type: 'reward_templates',
              id: attrs.rewardTemplateId,
            },
          },
        },
      },
    },
  });
}

export function destroy(id: string) {
  return request({
    url: `/admin/api/v2/milestones/${id}`,
    method: 'DELETE',
  });
}

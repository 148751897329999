import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { FormRow } from '../FormLayout';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<DialogProps, 'open' | 'onExit'> & {
  onClose: () => void;
  handleActivate: (issueStamp: string) => void;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function ActivateStampCampaignDialog({
  open,
  onExit,
  onClose,
  handleActivate,
}: Props) {
  const [issueStamp, setIssueStamp] = useState('retain');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssueStamp((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog open={open} onClose={onClose} onExit={onExit}>
      <DialogTitle>Activate Loyalty Card</DialogTitle>

      <DialogContent>
        <FormRow>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={issueStamp}
            onChange={handleChange}
          >
            <FormControlLabel
              value="retain"
              control={<Radio />}
              label="Retain Legacy Cards"
            />
            <FormControlLabel
              value="remove"
              control={<Radio />}
              label="Remove All Legacy Cards"
            />
          </RadioGroup>
        </FormRow>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="contained"
          color="secondary"
        >
          I still need to make changes
        </Button>
        <Button
          type="submit"
          onClick={() => {
            handleActivate(issueStamp);
          }}
          color="primary"
          variant="contained"
        >
          Activate Now
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    borderRadius: 4
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 4
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type StyleProps = {
  isPreviewing: boolean;
};

type Props = {
  src: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const ImageThumb: React.FC<Props> = ({ src }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img alt="reward" src={src} className={classes.img} />
    </div>
  );
};

export default ImageThumb;

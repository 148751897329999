import { createAction, createAsyncAction } from 'typesafe-actions';

import { APIError } from '../api/models';
import {
  DropZoneDoc,
  DropZoneCollectionDoc
} from '../../api/resources/dropZone';

/**
 * List drop zones by campaign id
 */
export const listByCampaign = createAction(
  'drop_zones/LIST_BY_CAMPAIGN_ID',
  (id: string) => id
)();

/**
 * Creates a drop zone
 */
export const createDropZone = createAction(
  'drop_zones/CREATE',
  (attrs: { lat: number; lng: number; radius: number; campaignId: string }) =>
    attrs
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchCampaignDropZones: createAsyncAction(
    'api/drop_zones/LIST_BY_CAMPAIGN_ID_REQUEST',
    'api/drop_zones/LIST_BY_CAMPAIGN_ID_SUCCESS',
    'api/drop_zones/LIST_BY_CAMPAIGN_ID_FAILURE'
  )<any, DropZoneCollectionDoc, APIError>(),

  createDropZone: createAsyncAction(
    'api/drop_zones/CREATE_REQUEST',
    'api/drop_zones/CREATE_SUCCESS',
    'api/drop_zones/CREATE_FAILURE'
  )<any, DropZoneDoc, APIError>()
};

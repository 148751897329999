import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

import { SubmitButton } from '../../../../../components/Form';
import {
  AutocompletePlace,
  TextField,
} from '../../../../../components/FormikAdapter';
import { FormRow } from '../../../../../components/FormLayout';
import { SingleDropZoneMap } from '../../../../../components/Map';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  map: {
    width: 550,
    height: 350,
    marginTop: theme.spacing(2),
  },
  radiusTextField: {
    flex: 0.3,
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Place = {
  lat: number;
  lng: number;
  address: string;
  neighborhood: string;
};

interface NewDropZoneFormValues {
  place: Place | null;
  radius: number;
}

type Props = {
  onClose: () => void;
  onSubmit: (
    values: NewDropZoneFormValues,
    actions: FormikHelpers<NewDropZoneFormValues>
  ) => void;
  open: boolean;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  radius: Yup.number().required('Required').min(1, 'Required'),
  place: Yup.mixed().required('Required'),
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const NewDropZoneFormDialog: React.FC<Props> = ({
  onClose,
  open,
  onSubmit,
}) => {
  const classes = useStyles();

  const initialValues: NewDropZoneFormValues = {
    place: null,
    radius: 100,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values: { place, radius } }) => (
        <Dialog onClose={onClose} open={open}>
          <Form translate="no" noValidate>
            <DialogTitle>New Drop Zone</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Users in the drop zone will receive rewards from this campaign
              </DialogContentText>

              <FormRow>
                <Field
                  component={AutocompletePlace}
                  name="place"
                  label="Address"
                  TextFieldProps={{
                    label: 'Address',
                    required: true,
                    placeholder: 'Search for an address',
                    fullWidth: true,
                  }}
                />

                <Field
                  component={TextField}
                  type="number"
                  name="radius"
                  label="Radius"
                  required
                  className={classes.radiusTextField}
                />
              </FormRow>

              <div className={classes.map}>
                <SingleDropZoneMap
                  coords={
                    place ? { lat: place.lat, lng: place.lng } : undefined
                  }
                  radius={radius}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <SubmitButton isSubmitting={isSubmitting} variant="text">
                Create Drop Zone
              </SubmitButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default NewDropZoneFormDialog;

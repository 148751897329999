import normalize from 'json-api-normalizer';
import produce from 'immer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import reduce from 'lodash/reduce';

import * as rewardTemplateActions from '../rewardTemplates/actions';
import { RewardTemplate } from '../../api/resources';

export type RewardTemplateActions = ActionType<typeof rewardTemplateActions>;

/**
 * byId contains all the reward template objects
 */

interface RewardTemplatesById {
  [key: string]: RewardTemplate;
}

const byId = createReducer<RewardTemplatesById, RewardTemplateActions>({})
  .handleAction(
    [
      rewardTemplateActions.api.fetchMerchantRewardTemplates.success,
      rewardTemplateActions.api.createRewardTemplate.success,
      rewardTemplateActions.api.fetchRewardTemplate.success
    ],
    (state, action) => {
      const { rewardTemplates } = normalize(action.payload);

      return rewardTemplates ? { ...state, ...rewardTemplates } : state;
    }
  )
  .handleAction(
    [rewardTemplateActions.api.archiveRewardTemplate.success],
    (state, action) => {
      return produce(state, draft => {
        delete draft[action.payload.data.id];
      });
    }
  );

/**
 * The merchant reward template ids
 */
interface Merchants {
  [key: string]: string[];
}

const merchants = createReducer<Merchants, RewardTemplateActions>({})
  .handleAction(
    [rewardTemplateActions.api.fetchMerchantRewardTemplates.success],
    (state, action) => {
      const { data } = action.payload;

      const merchant = reduce(
        data,
        (merchants, rewardTemplate) => {
          const id = rewardTemplate.id;
          const merchantId = rewardTemplate.relationships.merchant.data.id;

          if (merchants[merchantId]) {
            merchants[merchantId].push(id);
          } else {
            merchants[merchantId] = [id];
          }

          return merchants;
        },
        {} as Merchants
      );

      return { ...state, ...merchant };
    }
  )
  .handleAction(
    [
      rewardTemplateActions.api.createRewardTemplate.success,
      rewardTemplateActions.api.fetchRewardTemplate.success
    ],
    (state, action) => {
      const { data } = action.payload;
      const merchantId = data.relationships.merchant.data.id;

      return produce(state, draft => {
        if (draft[merchantId] === undefined) {
          draft[merchantId] = [];
        }

        draft[merchantId].push(data.id);
      });
    }
  );

/**
 * ui
 */

interface RewardTemplateUI {
  isFetching: boolean;
}
const ui = createReducer<RewardTemplateUI, RewardTemplateActions>({
  isFetching: false
})
  .handleAction(
    [
      rewardTemplateActions.api.fetchMerchantRewardTemplates.success,
      rewardTemplateActions.api.fetchMerchantRewardTemplates.failure
    ],
    state => ({
      ...state,
      ...{ isFetching: false }
    })
  )
  .handleAction(
    [rewardTemplateActions.api.fetchMerchantRewardTemplates.request],
    state => ({
      ...state,
      ...{ isFetching: true }
    })
  );

/**
 * pagination contains the pagination data for Reward Card
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, RewardTemplateActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 50
}).handleAction(
  [rewardTemplateActions.api.fetchMerchantRewardTemplates.success],
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map(rewardTemplate => rewardTemplate.id),
      count: count,
      page: page,
      total: total,
      per: per
    };
  }
);

/**
 * RewardTemplate root reducer
 */
const rewardTemplatesReducer = combineReducers({
  byId,
  merchants,
  ui,
  pagination
});

export default rewardTemplatesReducer;
export type RewardTemplatesState = ReturnType<typeof rewardTemplatesReducer>;

import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const PAGE_KEY = 'page';
const PER_KEY = 'per';

export default function usePaginationQuery() {
  const location = useLocation();
  const history = useHistory();

  return useMemo(() => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params[PAGE_KEY];
    const per = params[PER_KEY];

    return {
      page: (page || 1) as number,
      per: (per || 25) as number,

      updatePage: (page: string) => {
        page === '' ? delete params[PAGE_KEY] : (params[PAGE_KEY] = page);

        history.push({ search: queryString.stringify(params) });
      },

      updatePer: (per: string) => {
        per === '' ? delete params[PER_KEY] : (params[PER_KEY] = per);

        history.push({ search: queryString.stringify(params) });
      }
    };
  }, [history, location]);
}

import React from 'react';
import { Formik, Field, Form } from 'formik';
import range from 'lodash/range';
import xor from 'lodash/xor';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { SubmitButton } from '../Form';
import { RewardTemplateSelect, TextField } from '../FormikAdapter';
import * as MilestoneService from '../../api/services/milestoneService';
import { FormRow } from '../FormLayout';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles({
  form: {
    width: 800,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    width: 50,
  },
});

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = Pick<DialogProps, 'open'> & {
  campaignId: string;
  maxStamps: number;
  milestones?: MilestoneService.Milestone[];
  merchantId: string;
  onClose: () => void;
  onSuccess: (milestone: MilestoneService.Milestone) => void;
};

interface NewMilestoneFormValues {
  redemptionCost: number | '';
  validityPeriodDays: number;
  rewardTemplateId: string;
}

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  redemptionCost: Yup.number().required('Required'),
  validityPeriodDays: Yup.number().required('Required').positive().integer(),
  rewardTemplateId: Yup.string().required('Required'),
});

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function NewMilestoneDialog({
  campaignId,
  maxStamps,
  merchantId,
  milestones = [],
  open,
  onClose,
  onSuccess,
}: Props) {
  const classes = useStyles();

  // Get the current milestone thresholds
  const thresholds = milestones.map((milestone) => milestone.threshold);
  const costOptions = xor(range(1, maxStamps + 1), thresholds);

  const initialValues: NewMilestoneFormValues = {
    redemptionCost: '',
    validityPeriodDays: 60,
    rewardTemplateId: '',
  };

  const handleSubmit = async (values: NewMilestoneFormValues) => {
    try {
      const res = await MilestoneService.create({
        threshold: values.redemptionCost !== '' ? values.redemptionCost : 0,
        validityPeriodDays: values.validityPeriodDays,
        rewardTemplateId: values.rewardTemplateId,
        stampCampaignId: campaignId,
      });

      onSuccess(res);
    } catch {
      alert('There was an error submitting this form');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Dialog open={open} onClose={onClose} maxWidth="xl">
          <Form translate="no" noValidate className={classes.form}>
            <DialogTitle>Create a milestone</DialogTitle>

            <DialogContent>
              <FormRow>
                <Field
                  component={TextField}
                  name="redemptionCost"
                  label="Redemption Cost"
                  autoFocus
                  fullWidth
                  required
                  select
                >
                  {costOptions.map((cost, idx) => (
                    <MenuItem value={cost} key={idx}>
                      {cost.toString()}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  component={TextField}
                  name="validityPeriodDays"
                  label="Validity Period (days)"
                  type="number"
                  fullWidth
                  required
                />
              </FormRow>

              <FormRow>
                <Field
                  component={RewardTemplateSelect}
                  merchantId={merchantId}
                  name="rewardTemplateId"
                  label="Reward Template"
                  fullWidth
                  required
                />
              </FormRow>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <SubmitButton
                type="submit"
                size="small"
                isSubmitting={isSubmitting}
              >
                Create Milestone
              </SubmitButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}

import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as campaignActions from '../../../redux/campaigns/actions';
import * as campaignSelectors from '../../../redux/campaigns/selectors';

import DataTable from '../../../components/DataTable';
import { dateFormat } from '../../../lib/date';
import FeatureBar from '../../../components/FeatureBar';
import FilterBar from './FilterBar';
import Link from '../../../components/Link';
import { Content, Grid, Column } from '../../../components/Layout/Main';
import useFiltersQuery from '../../../hooks/useFiltersQuery';
import usePaginationQuery from '../../../hooks/usePaginationQuery';

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const List = () => {
  const history = useHistory();
  const paginationQuery = usePaginationQuery();
  const filtersQuery = useFiltersQuery();
  const dispatch = useDispatch();

  const campaigns = useSelector(campaignSelectors.listCampaignsPage);
  const isFetching = useSelector(campaignSelectors.isFetching);
  const pagination = useSelector(campaignSelectors.getPagination);

  // Load the campaigns
  useEffect(() => {
    const { page, per } = paginationQuery;
    const { filters } = filtersQuery;

    dispatch(
      campaignActions.listCampaigns({
        pagination: { page, per },
        filters: filters
      })
    );
  }, [dispatch, filtersQuery, paginationQuery]);

  // Change the page
  const handleChangePage = useCallback(
    (_, page: number) => {
      paginationQuery.updatePage(page.toString());
    },
    [paginationQuery]
  );

  // Data Table definition
  const columns = useMemo(() => {
    return [
      { title: 'ID', field: 'id', width: '50px' },
      { title: 'Title', field: 'title', width: '30%' },
      { title: 'Type', field: 'type' },
      { title: 'Status', field: 'status' },
      {
        title: 'Merchant',
        field: 'merchant',
        format: (value: any) =>
          value ? (
            <Link
              to={`/merchants/${value.id}`}
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => event.stopPropagation()}
            >
              {value.name}
            </Link>
          ) : (
            undefined
          )
      },
      {
        title: 'Expires At',
        field: 'expiredAt',
        format: dateFormat,
        width: '20%'
      },
      {
        title: 'Reward Expires At',
        field: 'rewardExpiry',
        format: dateFormat,
        width: '20%'
      }
    ];
  }, []);

  return (
    <Content>
      <FeatureBar>Campaigns</FeatureBar>

      <Grid>
        <Column xs={12}>
          <FilterBar />
        </Column>

        <Column xs={12}>
          <DataTable
            columns={columns}
            data={campaigns}
            isLoading={isFetching}
            onRowClick={row => history.push(`/campaigns/${row[0].value}`)}
            PaginationProps={{
              count: pagination.total,
              rowsPerPage: pagination.per,
              rowsPerPageOptions: [10, 25, 50],
              page: pagination.page,
              onChangePage: handleChangePage,
              onChangeRowsPerPage: event => {
                paginationQuery.updatePer(event.target.value.toString());
              }
            }}
          />
        </Column>
      </Grid>
    </Content>
  );
};

export default List;

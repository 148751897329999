import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),

    '& > *': {
      flex: 1,
      marginLeft: theme.spacing(),
      marginRight: theme.spacing()
    },
    '& > *:first-child': { marginLeft: 0 },
    '& > *:last-child': { marginRight: 0 }
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const FormRow: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.row}>{children}</div>;
};

export default FormRow;

import React from 'react';
import { Formik, FormikHelpers, Field, Form } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../components/Form';
import { StockImageUploader } from '../../../components/FormikAdapter';
import { FormActions, FormSection } from '../../../components/FormLayout';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface NewStockImageFormValues {
  stockImageId?: string;
}

type Props = {
  onSubmit: (
    values: NewStockImageFormValues,
    actions: FormikHelpers<NewStockImageFormValues>
  ) => void;
};

//------------------------------------------------------------------------------
// Validation
//------------------------------------------------------------------------------

const ValidationSchema = Yup.object().shape({
  stockImageId: Yup.string().required('Required'),
});

//------------------------------------------------------------------------------
// Form
//------------------------------------------------------------------------------

const NewStockImageForm: React.FC<Props> = ({ onSubmit }) => {
  const initialValues: NewStockImageFormValues = {
    stockImageId: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Form translate="no" noValidate>
          <FormSection title="Stock Image">
            <Field component={StockImageUploader} name="stockImageId" />
          </FormSection>

          <FormActions>
            <SubmitButton isSubmitting={formikBag.isSubmitting}>
              Upload Stock Image
            </SubmitButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};

export default NewStockImageForm;

import React, { useState } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Redirect, useParams } from 'react-router-dom';
import { getType } from 'typesafe-actions';

import * as campaignActions from '../../../../../../redux/campaigns/actions';
import { promiseListener } from '../../../../../../redux/store';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Column, Grid } from '../../../../../../components/Layout/Main';
import NewCampaignForm from './NewCampaignForm';
import { CampaignDoc } from '../../../../../../api/resources';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  },
  merchant: {
    marginLeft: theme.spacing()
  },
  title: {
    marginBottom: theme.spacing(4)
  }
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

//------------------------------------------------------------------------------
// Screen
//------------------------------------------------------------------------------

const New = () => {
  const classes = useStyles();
  const { id } = useParams<RouteParams>();

  const [redirectTo, setRedirectTo] = useState('');

  if (redirectTo !== '') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid>
      <Column xs={12}>
        <Paper className={classes.paper}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            className={classes.title}
          >
            New Campaign
          </Typography>

          <MakeAsyncFunction
            listener={promiseListener}
            start={getType(campaignActions.createCampaign)}
            resolve={getType(campaignActions.api.createCampaign.success)}
            reject={getType(campaignActions.api.createCampaign.failure)}
          >
            {(createCampaign: any) => (
              <NewCampaignForm
                merchantId={id}
                onSubmit={async (values, actions) => {
                  try {
                    const rewardExpiresAt =
                      values.rewardExpiresAt != null
                        ? values.rewardExpiresAt.utc().unix()
                        : 0;
                    const expiresAt =
                      values.expiresAt != null
                        ? values.expiresAt.utc().unix()
                        : 0;

                    const payload: CampaignDoc = await createCampaign({
                      ...values,
                      type: 'airdrop',
                      expiresAt,
                      rewardExpiresAt,
                      merchantId: id
                    });

                    actions.setSubmitting(false);
                    setRedirectTo(`/campaigns/${payload.data.id}`);
                  } catch {
                    actions.setSubmitting(false);
                    alert('There was an error submitting this form');
                  }
                }}
              />
            )}
          </MakeAsyncFunction>
        </Paper>
      </Column>
    </Grid>
  );
};

export default New;

import { ApiRequest, ListOptions, PaginationOptions } from './request';
import { toListQueryString } from './utils';

const defaultPaginationOptions = { page: 1, per: 50 };

const list = (opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/campaigns?${toListQueryString(opts)}`,
  method: 'GET',
  authenticated: true
});

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/campaigns/${id}`,
  method: 'GET',
  authenticated: true
});

interface CreateAttrs {
  title: string;
  type: 'airdrop';
  expiresAt: number;
  rewardExpiresAt: number;
  notify: boolean;
  gender: 'all' | 'male' | 'female';
  minAge: number;
  maxAge: number;
  supply: number;
  merchantId: string;
  rewardTemplateId: string;
}
const create = ({
  title,
  type,
  expiresAt,
  rewardExpiresAt,
  notify,
  gender,
  minAge,
  maxAge,
  supply,
  merchantId,
  rewardTemplateId
}: CreateAttrs) => ({
  path: '/admin/api/v2/campaigns',
  method: 'POST',
  authenticated: true,
  body: {
    data: {
      type: 'campaigns',
      attributes: {
        title,
        type,
        expiresAt,
        rewardExpiresAt,
        notify,
        target: {
          gender,
          minAge,
          maxAge
        },
        supply: {
          max: supply
        }
      },
      relationships: {
        merchant: {
          data: {
            type: 'merchants',
            id: merchantId
          }
        },
        rewardTemplate: {
          data: {
            type: 'reward_templates',
            id: rewardTemplateId
          }
        }
      }
    }
  }
});

const listByMerchantId = (
  id: string,
  opts: PaginationOptions = defaultPaginationOptions
): ApiRequest => ({
  path: `/admin/api/v2/merchants/${id}/campaigns?page=${opts.page}&per=${opts.per}`,
  method: 'GET',
  authenticated: true
});

const getStats = (id: string): ApiRequest => ({
  path: `/admin/api/v2/campaigns/${id}/stats`,
  method: 'GET',
  authenticated: true
});

export default {
  create,
  get,
  getStats,
  list,
  listByMerchantId
};

import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  grid: {
    maxWidth: 960,
    margin: `${theme.spacing(4)}px auto 0`
  }
}));

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const MainGrid: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.grid}>
      {children}
    </Grid>
  );
};

export default MainGrid;

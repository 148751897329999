import React from 'react';
import { getIn, FieldProps } from 'formik';

import RewardTemplateSelect, {
  Props as RewardTemplateSelectProps
} from '../../RewardTemplateSelect';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = FieldProps &
  Omit<RewardTemplateSelectProps, 'error' | 'onChange' | 'value'>;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const fieldToRewardTemplateSelect = ({
  form,
  field,
  ...props
}: Props): RewardTemplateSelectProps => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    value: field.value,
    disabled: isSubmitting || props.disabled,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    onChange: (value: string) => {
      form.setFieldValue(name, value);
    }
  };
};

/**
 * RewardTemplateSelectAdapter wraps a RewardTemplateSelect for formik
 */
const RewardTemplateSelectAdapter: React.FC<Props> = props => (
  <RewardTemplateSelect {...fieldToRewardTemplateSelect(props)} />
);

export default RewardTemplateSelectAdapter;

import React from 'react';

type Props = {
  height: string;
  width: string;
  color: string;
};

const AngleRight: React.FC<Props> = ({ height, width, color }: Props) => (
  <svg width={width} height={height} viewBox="0 0 30 30" version="1.1">
    <g id="right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M6.04054384,10.0405438 L6.04054384,25.8492424 L1,25.8492424 L1,10.0405438 L1,5 L21.8492424,5 L21.8492424,10.0405438 L6.04054384,10.0405438 Z"
        id="down"
        fill={color}
        fillRule="nonzero"
        transform="translate(11.424621, 15.424621) rotate(-225.000000) translate(-11.424621, -15.424621) "
      ></path>
    </g>
  </svg>
);

export default AngleRight;

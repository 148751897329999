import { createAction, createAsyncAction } from 'typesafe-actions';
import { PaginationOptions, FilterOptions } from '../types';

import { APIError } from '../api/models';
import {
  MerchantDoc,
  MerchantCollectionDoc,
} from '../../api/resources/merchant';

/**
 * List merchants
 */
export const listMerchants = createAction(
  'merchants/LIST',
  (opts: {
    pagination: PaginationOptions;
    filters?: FilterOptions;
    query?: string;
  }) => opts
)();

/**
 * List merchants by the specific ids
 */
export const listMerchantsByIds = createAction(
  'merchants/LIST_BY_IDS',
  (ids: string[]) => ids
)();

/**
 * Gets a merchant by id
 */
export const getMerchant = createAction('merchants/GET', (id: string) => id)();

/**
 * Creates a merchant
 */
export const createMerchant = createAction(
  'merchants/CREATE',
  (attrs: {
    id: string;
    name: string;
    category: string;
    about: string;
    url: string;
    logoId?: string;
    coverId?: string;
    maxNumberStores: number;
  }) => attrs
)();

/**
 * Update a merchant detail
 */
export const updateMerchant = createAction(
  'merchants/UPDATE',
  (attrs: {
    id: string;
    name: string;
    category: string;
    about: string;
    url: string;
    logoId?: string;
    coverId?: string;
    maxNumberStores: number;
  }) => attrs
)();

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const api = {
  fetchMerchantList: createAsyncAction(
    'api/merchants/FETCH_LIST_REQUEST',
    'api/merchants/FETCH_LIST_SUCCESS',
    'api/merchants/FETCH_LIST_FAILURE'
  )<any, MerchantCollectionDoc, APIError>(),

  fetchMerchantByIdsList: createAsyncAction(
    'api/merchants/FETCH_BY_IDS_LIST_REQUEST',
    'api/merchants/FETCH_BY_IDS_LIST_SUCCESS',
    'api/merchants/FETCH_BY_IDS_LIST_FAILURE'
  )<any, MerchantCollectionDoc, APIError>(),

  fetchMerchant: createAsyncAction(
    'api/merchants/FETCH_REQUEST',
    'api/merchants/FETCH_SUCCESS',
    'api/merchants/FETCH_FAILURE'
  )<any, MerchantDoc, APIError>(),

  createMerchant: createAsyncAction(
    'api/merchants/CREATE_REQUEST',
    'api/merchants/CREATE_SUCCESS',
    'api/merchants/CREATE_FAILURE'
  )<any, MerchantDoc, APIError>(),

  updateMerchant: createAsyncAction(
    'api/merchants/UPDATE_REQUEST',
    'api/merchants/UPDATE_SUCCESS',
    'api/merchants/UPDATE_FAILURE'
  )<any, MerchantDoc, APIError>(),
};

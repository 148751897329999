import React from 'react';
import upperFirst from 'lodash/upperFirst';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  displayInfo: {
    width: 300,
  },
  detailsText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.grey['600'],
  },
  actions: {
    flex: 1,
  },
}));

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

type Props = {
  createdAt: number;
  details?: string;
  totalStamps: number;
  status: string;
};

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function CampaignSummary({
  details,
  status,
  totalStamps,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.displayInfo}>
        <Typography variant="h6">{upperFirst(status)} Campaign</Typography>
        {details && (
          <Typography
            variant="body2"
            gutterBottom
            className={classes.detailsText}
          >
            {details}
          </Typography>
        )}
        <Typography variant="body2">{totalStamps} Stamps Required</Typography>
      </div>
    </div>
  );
}

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import Edit from './Edit';
import New from './New';
import Overview from './Overview';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

//------------------------------------------------------------------------------
// Tab
//------------------------------------------------------------------------------

const TabStores: React.FC<Props> = ({ match: { path } }) => (
  <Switch>
    <Route path={`${path}/:storeId/edit`}>
      <Edit />
    </Route>

    <Route path={`${path}/new`}>
      <New />
    </Route>

    <Route exact path={path}>
      <Overview />
    </Route>
  </Switch>
);

export default TabStores;

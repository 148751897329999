import { ApiRequest, ListOptions } from './request';
import { toListQueryString } from './utils';

const listByMerchantId = (id: string, opts: ListOptions): ApiRequest => ({
  path: `/admin/api/v2/merchants/${id}/reward_templates?${toListQueryString(
    opts
  )}`,
  method: 'GET',
  authenticated: true
});

const get = (id: string): ApiRequest => ({
  path: `/admin/api/v2/reward_templates/${id}`,
  method: 'GET',
  authenticated: true
});

const remove = (id: string): ApiRequest => ({
  path: `/admin/api/v2/reward_templates/${id}`,
  method: 'DELETE',
  authenticated: true
});

type CreateAttrs = {
  title: string;
  what: string;
  info: string;
  gift: boolean;
  sell: boolean;
  imageId: string;
  merchantId: string;
};
const create = (attrs: CreateAttrs): ApiRequest => {
  const { imageId, merchantId, ...attributes } = attrs;

  return {
    path: `/admin/api/v2/reward_templates`,
    method: 'POST',
    authenticated: true,
    body: {
      data: {
        type: 'stores',
        attributes: {
          ...attributes
        },
        relationships: {
          merchant: {
            data: {
              type: 'merchants',
              id: merchantId
            }
          },
          images: {
            data: [
              {
                type: 'images',
                id: imageId
              }
            ]
          }
        }
      }
    }
  };
};

export default {
  create,
  get,
  remove,
  listByMerchantId
};

import React from 'react';
import MomentUtils from '@date-io/moment';

import {
  DateTimePicker as MuiDateTimePicker,
  MuiPickersUtilsProvider,
  DateTimePickerProps
} from '@material-ui/pickers';
import { useStyles } from '../TextField';

//------------------------------------------------------------------------------
// Styles
//------------------------------------------------------------------------------

export type Props = DateTimePickerProps;

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

const DateTimePicker: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiDateTimePicker
        {...props}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabel
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.root,
            input: classes.input,
            focused: classes.focused,
            error: classes.error
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePicker;

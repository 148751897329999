import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import normalize from 'json-api-normalizer';
import produce from 'immer';

import * as merchantActions from './actions';
import { Merchant } from '../../api/resources';

export type MerchantActions = ActionType<typeof merchantActions>;
interface MerchantsById {
  [key: string]: Merchant;
}

const byId = createReducer<MerchantsById, MerchantActions>({}).handleAction(
  [
    merchantActions.api.fetchMerchantList.success,
    merchantActions.api.fetchMerchantByIdsList.success,
    merchantActions.api.fetchMerchant.success,
    merchantActions.api.createMerchant.success,
    merchantActions.api.updateMerchant.success
  ],
  (state, action) => {
    const { merchants } = normalize(action.payload);

    return merchants ? { ...state, ...merchants } : state;
  }
);

/**
 *
 */

interface Pagination {
  ids: string[];
  count: number;
  total: number;
  page: number;
  per: number;
}

const pagination = createReducer<Pagination, MerchantActions>({
  ids: [],
  count: 0,
  total: 0,
  page: 1,
  per: 25
}).handleAction(
  merchantActions.api.fetchMerchantList.success,
  (state, action) => {
    const payload = action.payload;
    const { total, page, per, count } = payload.meta;

    return {
      ...state,
      ids: payload.data.map(merchant => merchant.id),
      count: count,
      page: page,
      total: total,
      per: per
    };
  }
);

/**
 *
 */

interface MerchantsUI {
  isFetching: boolean;
}
const ui = createReducer<MerchantsUI, MerchantActions>({
  isFetching: false
})
  .handleAction(
    merchantActions.api.fetchMerchantList.request,
    produce(state => {
      state.isFetching = true;
    })
  )
  .handleAction(
    [
      merchantActions.api.fetchMerchantList.success,
      merchantActions.api.fetchMerchantList.failure
    ],
    produce(state => {
      state.isFetching = false;
    })
  );

const merchantsReducer = combineReducers({
  byId,
  pagination,
  ui
});

export default merchantsReducer;
export type MerchantsState = ReturnType<typeof merchantsReducer>;

import React from 'react';
import { useParams } from 'react-router-dom';

import { Column, Grid } from '../../../../../../../components/Layout/Main';
import CouponSetsTable from './CouponSetsTable';

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------

interface RouteParams {
  campaignId: string;
}

//------------------------------------------------------------------------------
// Component
//------------------------------------------------------------------------------

export default function Memberships() {
  const { campaignId } = useParams<RouteParams>();

  return (
    <Grid>
      <Column xs={12} sm={12}>
        <CouponSetsTable campaignId={campaignId} />
      </Column>
    </Grid>
  );
}
